import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import MuiListItem from "@material-ui/core/ListItem";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DrugSVG from "../../../images/Drug.svg";
import AllergySVG from "../../../images/Allergy.svg";
import ConditionSVG from "../../../images/Condition.svg";
import CategorySVG from "../../../images/Category.svg";
import PrescriptionSVG from "../../../images/Prescription.svg";
import UserSVG from "../../../images/User.svg";
import Icon from "@material-ui/core/Icon";
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import { Switch, Route, useRouteMatch, Redirect, Link } from "react-router-dom";
import Drug from "./Drug";
import Allergy from "./Allergy";
import Categoryy from './Category';
import MedCondition from './MedCondition';
import User from './User';
import Deprescribing from './Deprescribing';
import DrugClass from "./DrugClass";
import DrugRule from "./DrugRule";
import DefineDrugRule from "./DefineDrugRule";
import Facility from "./facility";
import FacilityAssign from "./FacilityAssign";
// import PdfView from "./PdfView";
import DrugRuleViewFile from "./DrugRuleViewFile";

const drawerWidth = 260;

const ListItem = withStyles(theme => ({
    root: {
        borderRadius: " 25px 0px 0px 25px",
        "&$selected": {
            // backgroundColor: "#3F558C",
            backgroundColor: theme.palette.sideNavItem,
            color: "#1877f2"
        },
        "&:hover": {
            // backgroundColor: "#FF6E4E",
             backgroundColor: theme.palette.sideNavItemHover,
            color: "white"
        }
    },
    selected: {}
}))(MuiListItem);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        top: "9%",
        // background: "#072d5e",
        background: theme.palette.sideNav,
        color: "white",
        borderTopRightRadius: "90px",
        border: "none"
    },
    drawerContainer: {
        top: "7%",
        position: "relative"
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    fabRoot: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    fabAdd: {
        position: "fixed",
        bottom: "25px",
        right: "25px",
        height: "75px",
        width: "75px",
    },
    addSVG: {
        height: "1.5em",
        width: "1.5em"
    },
    sideNavList: {
        paddingLeft: "30px"
    },
    listItemText: {
        // fontWeight: "bolder"
        color: "white",
        fontSize: "1.2rem"
    },
    linkStyle: {
        color: "inherit",
        textDecoration: "inherit"
    }
}))

export const Landing = () => {
    const classes = useStyles();
    let { path, url } = useRouteMatch();
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [sideNavItems] = useState([
        {
            name: "Drugs",
            svgName: DrugSVG
        },
        {
            name: "Allergy",
            svgName: AllergySVG
        },
        {
            name: "Category",
            svgName: CategorySVG
        },
        {
            name: "Deprescribing",
            svgName: PrescriptionSVG
        },
        {
            name: "Condition",
            svgName: ConditionSVG
        },
        {
            name: "Users",
            svgName: UserSVG
        },
        {
            name: "DrugClass",
            svgName: DrugSVG
        },
        {
            name: "DrugRule",
            svgName: DrugSVG
        },
        {
            name: "Define DrugRule",
            svgName: DrugSVG
        },
        {
            name: "Facility",
            svgName: UserSVG
        },
        {
            name: "User-Facility",
            svgName: UserSVG
        }

    ])

    const getURL = (index) => {
        var toURL = ""
        if (index === 0) {
            toURL = `${url}/drugs`
        }
        else if (index === 1) {
            toURL = `${url}/allergy`
        }
        else if (index === 2) {
            toURL = `${url}/category`
        }
        else if (index === 3) {
            toURL = `${url}/deprescribing`
        }
        else if (index === 4) {
            toURL = `${url}/condition`
        }
        else if (index === 5) {
            toURL = `${url}/users`
        }
        else if (index === 6) {
            toURL = `${url}/drugclass`
        }
        else if (index === 7) {
            toURL = `${url}/drugrule`
        }
        else if (index === 8) {
            toURL = `${url}/definedrugrule`
        }
        else if (index === 9) {
            toURL = `${url}/facility`
        }
        else if (index === 10) {
            toURL = `${url}/userfacility`
        }
        return toURL
    }

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const renderSideNav = () => {
        return sideNavItems.map((sideNavItem, index) => {
            return (
                <Link
                    key={index}
                    className={classes.linkStyle}
                    // to={sideNavItem.name.toLocaleLowerCase()}>
                    to={getURL(index)}>
                    <ListItem
                        button
                        selected={selectedIndex === index}
                        onClick={(event) => handleListItemClick(event, index)}>
                        <ListItemIcon>
                            <Icon>
                                <img alt={sideNavItem.name} src={sideNavItem.svgName} height={24} width={24} />
                            </Icon>
                        </ListItemIcon>
                        <ListItemText
                            primary={sideNavItem.name}
                            classes={{
                                primary: classes.listItemText
                            }} />
                    </ListItem>
                </Link>
            )
        })
    }

    return (
        <div>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerContainer}>
                    <List component="nav" aria-label="Admin side nav" className={classes.sideNavList}>
                        {renderSideNav()}
                    </List>
                </div>
            </Drawer>
            <main className={classes.content}>
                <Toolbar />

            </main>

            <Switch>
                <Route
                    path={`${path}/drugs`}
                    render={() => (
                        <Drug />
                    )}
                />
                <Route
                    path={`${path}/allergy`}
                    render={() => (
                        <>
                            <Allergy />
                        </>
                    )}
                />
                <Route
                    path={`${path}/category`}
                    render={() => (
                        <>
                            <Categoryy />
                        </>
                    )}
                />
                <Route
                    path={`${path}/deprescribing`}
                    render={() => (
                        <>
                            <Deprescribing />
                        </>
                    )}
                />
                <Route
                    path={`${path}/condition`}
                    render={() => (
                        <>
                            <MedCondition />
                        </>
                    )}
                />
                <Route
                    path={`${path}/users`}
                    render={() => (
                        <>
                            <User />
                        </>
                    )}
                />

                <Route
                    path={`${path}/drugclass`}
                    render={() => (
                        <>
                            <DrugClass />
                        </>
                    )}
                />
                <Route
                    path={`${path}/drugrule`}
                    render={() => (
                        <>
                            <DrugRule />
                        </>
                    )}
                />
                <Route
                    path={`${path}/definedrugrule`}
                    render={() => (
                        <>
                            <DefineDrugRule />
                        </>
                    )}
                />
                <Route
                    path={`${path}/facility`}
                    render={() => (
                        <>
                            <Facility />
                        </>
                    )}
                />
                <Route
                    path={`${path}/userfacility`}
                    render={() => (
                        <>
                            <FacilityAssign />
                        </>
                    )}
                />

                <Route
                    path={`${path}/viewfile`}
                    render={() => (
                        <DrugRuleViewFile />
                    )}
                />

                <Redirect exact from={url} to={`${url}/drugs`} />

            </Switch>
        </div>
    )
}

export default Landing;
