import { NEW_DATA, EDIT_PATIENT, UPDATE_DATA, 
    // SELECTED_PATIENT, 
    NEW_PATIENT, SET_PATIENT_ID } from './actionType'

export function addPatients(dataArray, count) {
    return {
        type: NEW_DATA,
        payload: { dataArray, count }
    }
}

export function editPatient(patient) {
    return {
        type: EDIT_PATIENT,
        payload: patient
    }
}

export function updateData(Data, type) {
    return {
        type: UPDATE_DATA,
        payload: { Data, type }
    }
}

// export function selectPatient(data){
//     return{
//         type: SELECTED_PATIENT,
//         payload: data
//     }
// }

export function addNewPatient() {
    return{
        type: NEW_PATIENT,
        
    }
}

export function setPatientId(data){
    return{
        type: SET_PATIENT_ID,
        payload: data
    }
}