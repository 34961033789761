import { NEW_DATA, PAGE_NO, SEARCH_DATA, UPDATE_DATA } from './actionType'

export const initialState = {
  tableData: [],
  tableDataList: [],
  tableDataCount: 0,
  pageNo: 0
}

const tableDataReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case NEW_DATA: {
      const { payload } = action;
      const data = payload;

      const newState = {
        ...state,
        tableData: data.data,
        tableDataList: data.dataArray,
        tableDataCount: data.count,
      }
      return newState
    }

    //for page no change

    case PAGE_NO: {
      const { payload } = action;
      const data = payload;

      const newState = {
        ...state,
        pageNo: data
      }

      return newState;
    }

    case UPDATE_DATA: {
      const { payload } = action;
      const data = payload;
      const newDrug = data.Data;
      const newAllergy = data.Data;
      const newMedCondition = data.Data;
      const newCategory = data.Data;
      const newDepresribing = data.Data;
      const newDrugClass = data.Data;
      const newUser = data.Data;


      if (data.type === 'DRUG') {
        return {
          ...state,
          tableDataList: state.tableDataList.map((drug) => drug.drug_id === newDrug.drug_id ?
            { ...drug, bname: newDrug.bname, gname: newDrug.gname, cdrug: newDrug.cdrug, isactive: newDrug.isactive }
            :
            drug
          ),
          tableData: state.tableData.map((drug) => drug.drug_id === newDrug.drug_id ?
            { ...drug, bname: newDrug.bname, gname: newDrug.gname, cdrug: newDrug.cdrug, isactive: newDrug.isactive }
            :
            drug
          )
        }
      }
      if (data.type === 'ALLERGY') {
        return {
          ...state,
          tableDataList: state.tableDataList.map((allergy) => allergy.allergy_id === newAllergy.allergy_id ?
            { ...allergy, allergy_name: newAllergy.allergy_name, isactive: newAllergy.isactive }
            :
            allergy
          ),
          tableData: state.tableData.map((allergy) => allergy.allergy_id === newAllergy.allergy_id ?
            { ...allergy, allergy_name: newAllergy.allergy_name, isactive: newAllergy.isactive }
            :
            allergy
          )
        }
      }
      if (data.type === 'MEDCONDITION') {
        return {
          ...state,
          tableDataList: state.tableDataList.map((med) => med.medical_condition_id === newMedCondition.medical_condition_id ?
            { ...med, medical_condition_name: newMedCondition.medical_condition_name, isactive: newMedCondition.isactive }
            :
            med
          ),
          tableData: state.tableData.map((med) => med.medical_condition_id === newMedCondition.medical_condition_id ?
            { ...med, medical_condition_name: newMedCondition.medical_condition_name, isactive: newMedCondition.isactive }
            :
            med
          )
        }
      }

      if (data.type === 'CATEGORY') {
        return {
          ...state,
          tableDataList: state.tableDataList.map((category) => category.did === newCategory.did ?
            { ...category, dcatname: newCategory.dcatname, dcgtype: newCategory.dcgtype, isactive: newCategory.isactive }
            :
            category
          ),
          tableData: state.tableData.map((category) => category.did === newCategory.did ?
            { ...category, dcatname: newCategory.dcatname, dcgtype: newCategory.dcgtype, isactive: newCategory.isactive }
            :
            category
          )
        }
      }

      if (data.type === 'DEPRESCRIBING') {
        return {
          ...state,
          tableDataList: state.tableDataList.map((deprescribing) => deprescribing.deid === newDepresribing.deid ?
            { ...deprescribing, gname: newDepresribing.gname, dcatname: newDepresribing.dcatname, scatname: newDepresribing.scatname, sscatname: newDepresribing.sscatname, isactive: newDepresribing.isactive }
            :
            deprescribing
          ),
          tableData: state.tableData.map((deprescribing) => deprescribing.deid === newDepresribing.deid ?
            { ...deprescribing, gname: newDepresribing.gname, dcatname: newDepresribing.dcatname, scatname: newDepresribing.scatname, sscatname: newDepresribing.sscatname, isactive: newDepresribing.isactive }
            :
            deprescribing
          )
        }
      }
      if (data.type === 'DRUGCLASS') {
        return {
          ...state,
          tableDataList: state.tableDataList.map((drugclass) => drugclass.slno === newDrugClass.slno ?
            { ...drugclass, drugcat: newDrugClass.drugcat, isactive: newDrugClass.isactive }
            :
            drugclass
          ),
          tableData: state.tableData.map((drugclass) => drugclass.slno === newDepresribing.slno ?
            { ...drugclass, drugcat: newDrugClass.drugcat, isactive: newDrugClass.isactive }
            :
            drugclass
          )
        }
      }
      if (data.type === 'USER') {
        return {
          ...state,
          tableDataList: state.tableDataList.map((user) => user.user_id === newUser.user_id ?
            { ...user, userName: newUser.userName, user_type_id: newUser.user_type_id, emailAddress: newUser.emailAddress, mobile_number: newUser.mobile_number, sms_no1: newUser.sms_no1, sms_no2: newUser.sms_no2, sms_no3: newUser.sms_no3, fax_no1: newUser.fax_no1, fax_no2: newUser.fax_no2, fax_no3: newUser.fax_no3, isactive: newUser.isactive }
            :
            user
          ),
          tableData: state.tableData.map((user) => user.user_id === newUser.user_id ?
            { ...user, userName: newUser.userName, user_type_id: newUser.user_type_id, emailAddress: newUser.emailAddress, mobile_number: newUser.mobile_number, sms_no1: newUser.sms_no1, sms_no2: newUser.sms_no2, sms_no3: newUser.sms_no3, fax_no1: newUser.fax_no1, fax_no2: newUser.fax_no2, fax_no3: newUser.fax_no3, isactive: newUser.isactive }
            :
            user
          )
        }
      }

    }
      break;

    case SEARCH_DATA: {
      const { payload } = action;
      const data = payload;
      var searchQuery = data.query;
      var tableDataMatched = [];
      var contentArray = state.tableData;



      if (data.type === "PATIENTFILTER") {
        //console.log(' vals ==== ',searchQuery,data.dataArray);
        contentArray = data.dataArray;
        if(contentArray.length >0)
       
        // if (contentArray !== undefined && contentArray.length !== 0 && searchQuery.facility.facilityIdPcc === null ) {
        //   contentArray.forEach((patient) => {
        //     if (patient['first_name'].toLowerCase().includes(searchQuery.firstName.toLowerCase()) && patient['last_name'].toLowerCase().includes(searchQuery.lastName.toLowerCase()) ) {
        //       tableDataMatched.push(patient)
        //     }
        //   })
        // }
        if (contentArray !== undefined && contentArray.length !== 0 && searchQuery.facility.facilityIdPcc) {
          contentArray.forEach((patient) => {
            if (patient['firstName'].toLowerCase().includes(searchQuery.firstName.toLowerCase()) && patient['lastName'].toLowerCase().includes(searchQuery.lastName.toLowerCase())) {
              tableDataMatched.push(patient)
            }
          })
        } else {
          contentArray.forEach((patient) => {
            if (patient['first_name'].toLowerCase().includes(searchQuery.firstName.toLowerCase()) && patient['last_name'].toLowerCase().includes(searchQuery.lastName.toLowerCase())) {
              tableDataMatched.push(patient)
            }
          })
        }

        
      }



      if (data.type === 'DRUG') {

        if (contentArray !== undefined && contentArray.length !== 0 && searchQuery !== ' ') {
          // console.log(searchQuery);
          contentArray.forEach((drug) => {
            if (drug['bname'] !== undefined) {
              if (drug['bname'].toLowerCase().includes(searchQuery.toLowerCase()) || drug['gname'].toLowerCase().includes(searchQuery.toLowerCase())) {
                tableDataMatched.push(drug)
              }
            }
          })
        }

        // if(searchQuery === ' '){
        //   contentArray.forEach((drug) => {
        //     if (drug['bname'].toLowerCase().includes(searchQuery.toLowerCase()) || drug['gname'].toLowerCase().includes(searchQuery.toLowerCase())) {
        //       tableDataMatched.push(drug)
        //     }})
        // }

      }
      if (data.type === 'ALLERGY') {
        if (contentArray !== undefined && contentArray.length !== 0) {
          contentArray.forEach((allergy) => {
            if (allergy['allergy_name'] !== undefined) {
              if (allergy['allergy_name'].toLowerCase().includes(searchQuery.toLowerCase())) {
                tableDataMatched.push(allergy)
              }
            }
          })
        }
      }
      if (data.type === 'MEDCONDITION') {
        if (contentArray !== undefined && contentArray.length !== 0) {
          contentArray.forEach((med) => {
            if (med['medical_condition_name'] !== undefined) {
              if (med['medical_condition_name'].toLowerCase().includes(searchQuery.toLowerCase())) {
                tableDataMatched.push(med)
              }
            }
          })
        }
      }
      if (data.type === 'CATEGORY') {
        if (contentArray !== undefined && contentArray.length !== 0) {
          contentArray.forEach((category) => {
            if (category['dcatname'] !== undefined) {
              if (category['dcatname'].toLowerCase().includes(searchQuery.toLowerCase())) {
                tableDataMatched.push(category)
              }
            }
          })
        }
      }

      if (data.type === "DEPRESCRIBING") {
        if (contentArray !== undefined && contentArray.length !== 0) {
          contentArray.forEach((deprescribing) => {
            if (deprescribing['gname'] !== undefined) {
              if (deprescribing['gname'].toLowerCase().includes(searchQuery.toLowerCase())) {
                tableDataMatched.push(deprescribing)
              }
            }
          })
        }
      }

      if (data.type === "DRUGCLASS") {
        if (contentArray !== undefined && contentArray.length !== 0) {
          contentArray.forEach((drugclass) => {
            if (drugclass['drugcat'] !== undefined) {
              if (drugclass['drugcat'].toLowerCase().includes(searchQuery.toLowerCase())) {
                tableDataMatched.push(drugclass)
              }
            }
          })
        }
      }

      if (data.type === "USER") {
        if (contentArray !== undefined && contentArray.length !== 0) {
          contentArray.forEach((user) => {
            if (user['userName'] !== undefined) {
              if (user['userName'].toLowerCase().includes(searchQuery.toLowerCase())) {
                tableDataMatched.push(user)
              }
            }
          })
        }
      }

      if (data.type === "DRUGRULE") {
        if (contentArray !== undefined && contentArray.length !== 0) {
          contentArray.forEach((rule) => {
            if (rule['dslno'] !== undefined) {
              if (rule['dslno'].toLowerCase().includes(searchQuery)) {
                tableDataMatched.push(rule)
              }
            }
          })
        }
      }

      if (data.type === "FACILITY") {
        if (contentArray !== undefined && contentArray.length !== 0) {
          contentArray.forEach((facility) => {
            if (facility['facilityName'] !== undefined) {
              if (facility['facilityName'].toLowerCase().includes(searchQuery.toLowerCase())) {
                tableDataMatched.push(facility)
              }
            }
          })
        }
      }


      const newState = {
        ...state,
        tableDataList: tableDataMatched,
        tableDataCount: tableDataMatched.length
      }
      return newState
    }
    default:
      return state
  }
}

export default tableDataReducer;