import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import MuiListItem from "@material-ui/core/ListItem";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from "@material-ui/core/Icon";
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import AddPatientSVG from "../../../images/AddPatient.svg";
import { Switch, Route, useRouteMatch, Redirect, Link } from "react-router-dom";
import AddPatient from "./AddPatient";
import PdfView from "../Admin/PdfView";

const drawerWidth = 260;

const ListItem = withStyles(theme => ({
    root: {
        borderRadius: " 25px 0px 0px 25px",
        "&$selected": {
            // backgroundColor: "#617389",
            backgroundColor: theme.palette.sideNavItem,
            color: "white"
        },
        "&:hover": {
            backgroundColor: theme.palette.sideNavItemHover,
            color: "white"
        }
    },
    selected: {}
}))(MuiListItem);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        top: "9%",
        // background: "#072d5e",
        // background: "#36485c",
        background: theme.palette.sideNav,
        color: "white",
        borderTopRightRadius: "90px",
        border: "none"
    },
    drawerContainer: {
        top: "7%",
        position: "relative"
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    fabRoot: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    fabAdd: {
        position: "fixed",
        bottom: "25px",
        right: "25px",
        height: "75px",
        width: "75px",
    },
    addSVG: {
        height: "1.5em",
        width: "1.5em"
    },
    sideNavList: {
        paddingLeft: "30px"
    },
    listItemText: {
        // fontWeight: "bolder"
    },
    linkStyle: {
        color: "inherit",
        textDecoration: "inherit"
    }
}))

export const NurseLanding = () => {
    const classes = useStyles();
    let { path, url } = useRouteMatch();
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [sideNavItems] = useState([
        {
            name: "Home",
            svgName: AddPatientSVG
        },
    ])

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const getURL = (index) => {
        var toURL = ""
        if (index === 0) {
            toURL = `${url}/addpatient/1`
        }
       
        return toURL
    }

    const renderSideNav = () => {
        return sideNavItems.map((sideNavItem, index) => {
            return (
                <Link
                    key={index}
                    className={classes.linkStyle}
                    // to={sideNavItem.name.toLocaleLowerCase()}>
                    // to={`${url}/addpatient/1`}>
                    to={getURL(index)}>
                    <ListItem
                        button
                        selected={selectedIndex === index}
                        onClick={(event) => handleListItemClick(event, index)}>
                        <ListItemIcon>
                            <Icon>
                                <img alt={sideNavItem.name} src={sideNavItem.svgName} height={24} width={24} />
                            </Icon>
                        </ListItemIcon>
                        <ListItemText
                            primary={sideNavItem.name}
                            classes={{
                                primary: classes.listItemText
                            }} />
                    </ListItem>
                </Link>
            )
        })
    }

    return (
        <div>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerContainer}>
                    <List component="nav" aria-label="Patient side nav" className={classes.sideNavList}>
                        {renderSideNav()}
                    </List>
                </div>
            </Drawer>
            <main className={classes.content}>
                <Toolbar />
            </main>

            <Switch>
                <Route
                    path={`${path}/addpatient/:stepId`}
                    render={() => (
                        <AddPatient />
                    )}
                />
                <Route
                    path={`${path}/viewfile`}
                    render={() => (
                        <PdfView />
                    )}
                />

                <Redirect exact from={url} to={`${url}/addpatient/1`} />

            </Switch>
        </div>
    )
}

export default NurseLanding;
