import { DELETE_DOSAGE, NEW_DOSAGE, EDIT_DOSAGE, RESET_DOSAGE } from './actionType'

export function addDosage(dosage) {
    return {
        type: NEW_DOSAGE,
        payload: dosage
    }
}

export function editDosage(dosage) {
    return {
        type: EDIT_DOSAGE,
        payload: dosage
    }
}

export function deleteDosage(dosage) {
    return {
        type: DELETE_DOSAGE,
        payload: dosage
    }
}

export function resetDosage(){
    return{
        type: RESET_DOSAGE
    }
}
