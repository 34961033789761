import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import { closeDialog } from "../../../../store/dialog/actionCreator";
import { updateData } from "../../../../store/tableData/actionCreator";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Notify from "../../../Notification/Notify";
import { triggerNotification } from "../../../../store/notification/actionCreator";
import DrugSVG from "../../../../images/Drug.svg";
import Typography from '@material-ui/core/Typography';
import { dialogType } from "../../../../util/constants/dialogConstants";
import { updateDrug } from "../../../../util/api";
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    root: {
        width: "20vw"
    },
    tokenFileNameList: {
        fontSize: "1rem"
    },
    textroot: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    dialogTitle: {
        height: "20vh",
        width: "30vw",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FF6E4E",
        color: "white"
    },
    dialogContent: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    },
    checkBoxStyle: {
        marginTop: "16px"
    },
    buttonStyle: {
        marginTop: "16px"
    },
    textFieldStyle: {
        marginTop: "16px"
    },
    titleContent: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around"
    },
    closeButton: {
        position: 'absolute',
        right: "5px",
        top: "5px",
        color: "#FFFFFF",
    }
}));

export const AddDrug = () => {
    const classes = useStyles();
    const [combiDrugCheck, setCombiDrugCheck] = useState(false);
    const drugData = useSelector(state => state.tableData.tableDataList)
    const [status, setStatus] = useState(false);
    const [drug, setDrug] = useState({
        drugId: 0,
        brandName: "",
        genericName: ""
    })
    const dialog = useSelector(state => state.dialog);
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(false);

    const descriptionElementRef = React.useRef(null);
    useEffect(() => {
        var open = dialog.open;
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
        if (dialog.data !== {}) {
            setDrug({
                drugId: dialog.data.drug_id,
                brandName: dialog.data.bname,
                genericName: dialog.data.gname
            })
            const cdrug = dialog.data.cdrug === "1" ? 'Yes' : 'No' && dialog.data.cdrug === "Yes" ? 'Yes' : 'No'
            cdrug === 'Yes' ? setCombiDrugCheck(true) : setCombiDrugCheck(false)
            // dialog.data.cdrug === "Yes" ? setCombiDrugCheck('Yes') : setCombiDrugCheck('No') && dialog.data.cdrug === "1" ? setCombiDrugCheck('Yes') : setCombiDrugCheck('No')
            dialog.data.isactive === 1 ? setStatus(true) : setStatus(false)
        }
        // eslint-disable-next-line
    }, [dialog.open]);

    const handleCombiCheck = (event) => {
        setCombiDrugCheck(event.target.checked);
    };

    const handleStatusCheck = (event) => {
        setStatus(event.target.checked);
    };

    const handleClose = () => {
        dispatch(closeDialog());
    };

    const editDrugAPI = () => {

        if (drug.brandName === "") {

            let notify = {
                isShown: true,
                message: "Brand Name Mandatory",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (drug.genericName === "") {

            let notify = {
                isShown: true,
                message: "Generic Name Mandatory",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (drugData.length !== 0) {

        } else {
            let notify = {
                isShown: true,
                message: "Something went wrong, please try again",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (drugData.find(a => a.bname === drug.brandName)) {

            let notify = {
                isShown: true,
                message: "Brand name already exists",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (drugData.find(a => a.gname === drug.genericName)) {

            let notify = {
                isShown: true,
                message: "Generic name already exists",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }



        setDisable(true)



        var editDrug = {
            drug_id: drug.drugId,
            bname: drug.brandName,
            gname: drug.genericName,
            cdrug: combiDrugCheck === true ? 'Yes' : 'No',
            combs: drug.genericName,
            isactive: status === true ? 1 : 0
        }
        updateDrug(editDrug).then(response => {
            if (response.status === 200) {
                let notify = {
                    isShown: true,
                    message: "Drug updated successfully",
                    severity: "success"
                };
                setDisable(false)
                dispatch(updateData(editDrug, "DRUG"))
                dispatch(triggerNotification(notify));
                handleClose()
            }
        }).catch((error) => {

            let notify = {
                isShown: true,
                message: "Something went wrong!!",
                severity: "error"
            };
            dispatch(triggerNotification(notify));
            setDisable(false)

        });
    }

    const updateBrandName = (event) => {
        setDrug({
            drugId: drug.drugId,
            brandName: event.target.value,
            genericName: drug.genericName
        })
    }

    const updateGenericName = (event) => {
        setDrug({
            drugId: drug.drugId,
            brandName: drug.brandName,
            genericName: event.target.value
        })
    }

    return (
        <div>
            <Notify />
            <Dialog
                open={dialog.open && dialog.dialog === dialogType.editDrug}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="edit-drug"
                    className={classes.dialogTitle}
                    disableTypography>
                    <div className={classes.titleContent}>
                        <Typography variant="h5" className={classes.title} noWrap>
                            Update Drug
                        </Typography>
                        <img src={DrugSVG} alt="newdrug" height={120} width={120} />
                        <IconButton color="primary" aria-label="Close" className={classes.closeButton} onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                    <form className={classes.textRoot} noValidate>
                        <TextField
                            id="brand-name"
                            label="Brand Name"
                            size="medium"
                            value={drug.brandName}
                            onChange={updateBrandName} />
                        <br />
                        <TextField
                            id="generic-name"
                            label="Generic Name"
                            size="medium"
                            className={classes.textFieldStyle}
                            value={drug.genericName}
                            onChange={updateGenericName}
                        />
                        <br />
                        <FormControlLabel
                            className={classes.checkBoxStyle}
                            control={<Checkbox checked={combiDrugCheck} onChange={handleCombiCheck} name="combiDrugCheck" />}
                            label="Combinatin Drug"
                        />
                        <br />
                        <FormControlLabel
                            className={classes.checkBoxStyle}
                            control={<Checkbox checked={status} onChange={handleStatusCheck} name="status" />}
                            label="Status"
                        />
                    </form>
                    <Button
                        className={classes.buttonStyle}
                        variant="contained"
                        color="primary"
                        disabled={disable}
                        onClick={() => editDrugAPI()}>
                        UPDATE
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default AddDrug;