import { createMuiTheme, createStyles } from "@material-ui/core/styles";

export const customColors = {
  light: "#f05545",
  // main: "#072D5E",
  main: "#36485c",
  dark: "#FF6E4E",
  white: "#fff",
  grey: "#e0e0e0",
  sideNav: "#36485c",
  sideNavItem: "#617389",
  sideNavItemHover: "#617389",
};

const customTheme = createMuiTheme({
  overrides: {
    MuiDialog: createStyles({
      paper: {
        borderRadius: "15px"
      }
    }),
    MuiFormLabel: createStyles({
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131'
        },
      }
    }),
    MuiInput: createStyles({
      root: {
        cursor: "pointer"
      },
      // underline: {
      //   content: "none",
      //   "&&:before": {
      //     content: "none"
      //   },
      //   "&&:after": {
      //     content: "none"
      //   }
      // }
    })
  },
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: customColors,
    secondary: customColors,
    header : customColors.main,
    toolbarLight : customColors.white,
    search : customColors.grey,
    sideNav: customColors.sideNav,
    sideNavItem: customColors.sideNavItem,
    sideNavItemHover: customColors.sideNavItemHover
  }
});

export default customTheme;