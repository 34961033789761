import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import MuiListItem from "@material-ui/core/ListItem";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ReviewSvg from "../../../images/review.svg";
import Icon from "@material-ui/core/Icon";
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import { Switch, Route, useRouteMatch, Redirect, Link } from "react-router-dom";
import PhysicianReviewed from "./PhysicianReviewed";
import PdfView from "../Admin/PdfView";
import ViewFaxFile from "../Admin/ViewFaxFile";


const drawerWidth = 260;

const ListItem = withStyles(theme => ({
    root: {
        borderRadius: " 25px 0px 0px 25px",
        "&$selected": {
            backgroundColor: theme.palette.sideNavItem,
            color: "#1877f2"
        },
        "&:hover": {
            backgroundColor: theme.palette.sideNavItemHover,
            color: "white"
        }
    },
    selected: {}
}))(MuiListItem);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        top: "9%",
        background: theme.palette.sideNav,
        color: "white",
        borderTopRightRadius: "90px",
        border: "none"
    },
    drawerContainer: {
        top: "7%",
        position: "relative"
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    fabRoot: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    fabAdd: {
        position: "fixed",
        bottom: "25px",
        right: "25px",
        height: "75px",
        width: "75px",
    },
    addSVG: {
        height: "1.5em",
        width: "1.5em"
    },
    sideNavList: {
        paddingLeft: "30px"
    },
    listItemText: {
        // fontWeight: "bolder"
        color: "white",
        fontSize: "1.2rem"
    },
    linkStyle: {
        color: "inherit",
        textDecoration: "inherit"
    }
}))

export const FacilityLanding = () => {
    const classes = useStyles();
    let { path, url } = useRouteMatch();
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [sideNavItems] = useState([
        {
            name: "Phy Reviewed",
            svgName: ReviewSvg
        }

    ])

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const renderSideNav = () => {
        return sideNavItems.map((sideNavItem, index) => {
            return (
                <Link
                    key={index}
                    className={classes.linkStyle}
                    to={sideNavItem.name.toLocaleLowerCase().replace(/ /g, "")}>
                    <ListItem
                        button
                        selected={selectedIndex === index}
                        onClick={(event) => handleListItemClick(event, index)}>
                        <ListItemIcon>
                            <Icon>
                                <img alt={sideNavItem.name} src={sideNavItem.svgName} height={24} width={24} />
                            </Icon>
                        </ListItemIcon>
                        <ListItemText
                            primary={sideNavItem.name}
                            classes={{
                                primary: classes.listItemText
                            }} />
                    </ListItem>
                </Link>
            )
        })
    }

    return (
        <div>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerContainer}>
                    <List component="nav" aria-label="Admin side nav" className={classes.sideNavList}>
                        {renderSideNav()}
                    </List>
                </div>
            </Drawer>
            <main className={classes.content}>
                <Toolbar />

            </main>

            <Switch>
                <Route
                    path={`${path}/phyreviewed`}
                    render={() => (
                        <PhysicianReviewed />
                    )}
                />
                <Route
                    path={`${path}/viewfile`}
                    render={() => (
                        <PdfView />
                    )}
                />
                <Route
                    path={`${path}/viewFaxfile`}
                    render={() => (
                        <ViewFaxFile />
                    )}
                />
                <Redirect exact from={url} to={`${url}/phyreviewed`} />

            </Switch>
        </div>
    )
}

export default FacilityLanding;
