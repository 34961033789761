import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationAction from "../../common/TablePaginationAction";
import {
    // getAllPatients,
    // getPendingReviews,
    getPendingPhyReviews,
    getPhyReviewedPatients,
    getUser,
    notify,
    sendMail,
    sendSMS
} from "../../../util/api";
import { addPendingReq, setDisableMode } from "../../../store/pharmacist/actionCreator";
import { Typography, Paper, Checkbox, Button, TextField, Icon } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import { Autocomplete } from "@material-ui/lab";
import SearchSVG from "../../../images/Search.svg";
import InputBase from '@material-ui/core/InputBase';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { roles } from "../../../util/constants/roles";
import EmptySvg from "../../../images/Empty.svg";
import { addAdmissionFile, addFaxFile } from "../../../store/viewFiles/actionCreator";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import RightArrowSVG from "../../../images/RightArrow.svg";
import { triggerNotification } from "../../../store/notification/actionCreator";

const StyledTableCell = withStyles((theme) => ({
    root: {
        padding: "12px",
        borderBottom: "none",
        textAlign: "center"
    },
    head: {
        backgroundColor: theme.palette.header,
        color: theme.palette.common.white,
        fontWeight: "bold"
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width: "100vw",
        flexDirection: "column",
        paddingBottom: "0.5%"
    },
    paperRoot: {
        padding: "10px"
    },
    tableRoot: {
        borderRadius: "15px",
        position: "relative",
        // left: "25%",
        // width: "65vw"
    },
    title: {
        fontSize: "1.5em",
        color: theme.palette.primary.main,
        flexGrow: 1,
        display: 'none',
        fontWeight: "bold",
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    tableContainer: {
        borderRadius: "15px"
    },
    header: {
        marginLeft: "25%",
        fontWeight: "bold",
        marginBottom: "10px"
    },
    tableTitle: {
        fontWeight: "500"
    },
    Container: {
        paddingTop: "0.5%",
        paddingRight: "10%",
        width: "100vw",
        paddingBottom: "0.5%"
    },
    skeletonStyle: {
        marginBottom: "10px",
        borderRadius: "15px",
        // left: "25%",
        // width: "65vw"
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: "none"
    },
    lightToolBar: {
        background: theme.palette.toolbarLight,
        color: "black",
        paddingRight: "0px",
        paddingLeft: "0px"
    },
    allergyContainer: {
        paddingTop: "0.5%",
        paddingLeft: "25%",
        paddingRight: "10%",
        width: "100vw",
        paddingBottom: "0.5%"
    },
    allergyTitle: {
        fontSize: "2em",
        fontWeight: "bold"
    },
    allergyTitleroot: {
        flexGrow: 1,
    },
    search: {
        backgroundColor: theme.palette.search,
        position: 'relative',
        borderRadius: "10px",
        // backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: theme.palette.search
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
            backgroundColor: theme.palette.search,
            borderRadius: "10px",
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    searchSVG: {
        height: "1.1em"
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}))

const SentToPhysician = () => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [pageOne, setPageOne] = useState(0);
    const [rowsPerPageOne, setRowsPerPageOne] = useState(5);

    const pendingRequests = useSelector(state => state.pharmacist.pendingRequests);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const [loading1, setLoading1] = useState(true);
    const [reviewedPatients, setReviewedPatients] = useState([]);
    const [users, setUsers] = useState([]);
    const [alerts, setAlertes] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const userid = useSelector(state => state.auth.userId);
    const auth = useSelector(state => state.auth);
    // for search functionality
    const [pendingPatients, setpendingPatients] = useState([])
    const [reviewedPatientsForSearch, setReviewedPatientsForSearch] = useState([])
    const history = useHistory();


    useEffect(() => {


        getPendingPhyReviews(auth.userId, 20).then(response => {
            if (response.status === 200) {
                dispatch(addPendingReq(response.data.reverse(), response.data.length))
                setLoading(false)
                setpendingPatients(response.data);
            }
        });

        getPhyReviewedPatients(auth.userId, 30).then(res => {
            if (res.status === 200) {
                setReviewedPatients(res.data.reverse());
                setReviewedPatientsForSearch(res.data)
                setLoading1(false)
            }
        });


        // eslint-disable-next-line
    }, []);

    useEffect(() => {

        getUser().then(res1 => {
            if (res1.status === 200 && res1.data) {
                setUsers(res1.data)
            }
        })

    }, [])

    const arrayRemove = (arr, value) => {

        return arr.filter(function (ele) {
            return ele !== value;
        });
    }

    const hanldAlert = (event) => {

        if (event.target.checked) {
            setAlertes([...alerts, event.target.value])
            // alerts.push(event.target.value)
        }
        else {
            setAlertes(arrayRemove(alerts, event.target.value))
        }

    }

    const sendAlert = () => {

        if (!(selectedUser?.user_id)) {
            let notify = {
                isShown: true,
                message: "Please select the user to send alert.",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return
        }
        // eslint-disable-next-line
        alerts.map(patientID => {
            var comm = {
                patient_id: patientID,
                to_user_id: selectedUser.user_id,
                from_user_id: userid,
                sms_number: selectedUser.sms_no1,
                email_address: selectedUser.emailAddress,
                fax_number: selectedUser.fax_no1,
                message: `Record - ${patientID}`,
                message_type: 40,
                messag_status: 1
            }
            notify(comm).then(res => {

                if (res.status === 201) {

                    let data1 = {
                        sms_to: selectedUser.sms_no1,
                        message: `Record - ${patientID}`,
                    }
                    sendSMS(data1)
                    let data2 = {
                        emailTO: selectedUser.emailAddress,
                        subject: `Record - ${patientID}`,
                        message: `Record - ${patientID}`,
                    }
                    sendMail(data2)

                }
            })
        })



        setSelectedUser('');
        setAlertes([]);
        dispatch(addPendingReq([], 0))
        getPendingPhyReviews(auth.userId, 20).then(response => {
            if (response.status === 200) {
                dispatch(addPendingReq(response.data.reverse(), response.data.length))
                setLoading(false)
            }
        });

    }

    const handleChangeUser = (event, newValue) => {
        setSelectedUser(newValue);
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const handleChangePageOne = (event, newPage) => {
        setPageOne(newPage);
    };

    const handleChangeRowsPerPageOne = (event) => {
        setRowsPerPageOne(parseInt(event.target.value, 10));
        setPageOne(0);
    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('-').toString();
    }

    const formateTime = (d) => {
        var t = new Date(d),
            h = '' + t.getHours(),
            m = '' + t.getMinutes(),
            s = '' + t.getSeconds();

        if (h.length < 2)
            h = '0' + h;
        if (m.length < 2)
            m = '0' + m;
        if (s.length < 2)
            s = '0' + s;

        return [h, m, s].join(':').toString();
    }


    const filterSearchResultsForNotReviewed = (event) => {

        let searchText = event.target.value;
        let tableDataMatched = [];
        pendingPatients.forEach((patient) => {
            if (patient['first_name'] !== undefined) {
                if (patient['first_name'].toLowerCase().includes(searchText.toLowerCase()) || patient['last_name'].toLowerCase().includes(searchText.toLowerCase())) {
                    tableDataMatched.push(patient)
                }
            }
        })

        dispatch(addPendingReq(tableDataMatched, tableDataMatched.length))

    }


    const filterSearchResultsForReviewed = (event) => {

        let searchText = event.target.value;
        let tableDataMatched = [];
        reviewedPatientsForSearch.forEach((patient) => {
            if (patient['first_name'] !== undefined) {
                if (patient['first_name'].toLowerCase().includes(searchText.toLowerCase()) || patient['last_name'].toLowerCase().includes(searchText.toLowerCase())) {
                    tableDataMatched.push(patient)
                }
            }
        })

        setReviewedPatients(tableDataMatched)

    }

    const viewAdmissionFile = (patient) => {
        dispatch(addAdmissionFile(patient))

        switch (auth.roles[0]) {

            case roles.nurse:
                return history.push("/nurse/viewfile")
            case roles.pharmacist:
                return history.push("/pharmacist/viewfile")
            case roles.physician:
                return history.push("/physician/viewfile")
            default:
                return
        }

    }

    const viewFaxFile = (patient) => {
        dispatch(addFaxFile(patient))

        switch (auth.roles[0]) {

            case roles.physician:
                return history.push("/physician/viewFaxfile")
            case roles.pharmacist:
                return history.push("/pharmacist/viewFaxfile")
            case roles.facility:
                return history.push("/facility/viewFaxfile")
            default:
                return;
        }

    }

    const renderHeader = () => {
        
        return (
            <TableHead>
                <TableRow>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Patient Id</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>First Name</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Last Name</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Facility </b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>Admission File</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Date</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Time</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Physician</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Alert</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        
                    </StyledTableCell>
                </TableRow>
            </TableHead>
        )
    }

    const renderPhyReviewedHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Patient Id</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>First Name</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Last Name</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Facility </b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>Admission File</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}> Fax </b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Date</b>
                        </Typography>
                    </StyledTableCell>
                    {/* <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Time</b>
                        </Typography>
                    </StyledTableCell> */}
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Physician</b>
                        </Typography>
                    </StyledTableCell>
                </TableRow>
            </TableHead>
        )
    }


    const renderContent = () => {
      
        return (
            <TableBody>
                {(rowsPerPage > 0
                    ? pendingRequests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : pendingRequests
                ).map((patient, index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell>
                            <Typography variant="caption" noWrap>
                                <b className={classes.tableTitle}>{patient.patient_id}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="caption" noWrap>
                                <b className={classes.tableTitle}>{patient.first_name}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="caption" noWrap>
                                <b className={classes.tableTitle}>{patient.last_name}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                            <Typography variant="caption" noWrap>
                                <b className={classes.tableTitle}>{patient.facility_name}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Icon
                                style={{ cursor: "pointer" }}
                                onClick={() => viewAdmissionFile(patient)}
                                classes={{
                                    root: classes.customSVG
                                }}>
                                {/* {getfile(patient.patient_id)} */}
                                {patient.admission_file_name ? <img alt="add" src={EmptySvg} height={24} width={24} /> : ''}
                            </Icon>

                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="caption" noWrap>
                                <b className={classes.tableTitle}>{formatDate(patient.updatedatetime)}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="caption" noWrap>
                                <b className={classes.tableTitle}>{formateTime(patient.updatedatetime)}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                            <Typography variant="caption" noWrap>
                                <b className={classes.tableTitle}>{patient.sentToDoctor}</b>
                            </Typography>
                        </StyledTableCell>
                        
                        <StyledTableCell>
                            <Checkbox
                                color="primary"
                                size="small"
                                value={patient.patient_id}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={hanldAlert}
                            />
                        </StyledTableCell>
                        <StyledTableCell>
                            <Link to={"/pharmacist/pending_requests/"+patient.patient_id} onClick={()=>{dispatch(setDisableMode())}} >
                                <Icon
                                    // onClick={() => viewPatient(patient)}
                                    classes={{
                                        root: classes.customSVG
                                    }}>
                                    <img alt="add" src={RightArrowSVG} height={24} width={24} />
                                </Icon>
                            </Link>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        )
    }

    const renderPhyReviewedContent = () => {
        return (
            <TableBody>
                {(rowsPerPageOne > 0
                    ? reviewedPatients.slice(pageOne * rowsPerPageOne, pageOne * rowsPerPageOne + rowsPerPageOne)
                    : reviewedPatients
                ).map((patient, index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell>
                            <Typography variant="caption" noWrap>
                                <b className={classes.tableTitle}>{patient.patient_id}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="caption" noWrap>
                                <b className={classes.tableTitle}>{patient.first_name}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="caption" noWrap>
                                <b className={classes.tableTitle}>{patient.last_name}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                            <Typography variant="caption" noWrap>
                                <b className={classes.tableTitle}>{patient.facility_name}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Icon
                                style={{ cursor: "pointer" }}
                                onClick={() => viewAdmissionFile(patient)}
                                classes={{
                                    root: classes.customSVG
                                }}>
                                {/* {getfile(patient.patient_id)} */}
                                {patient.admission_file_name ? <img alt="add" src={EmptySvg} height={24} width={24} /> : ''}
                            </Icon>

                        </StyledTableCell>
                        <StyledTableCell>
                            <Icon
                                style={{ cursor: "pointer" }}
                                onClick={() => viewFaxFile(patient)}
                                classes={{
                                    root: classes.customSVG
                                }}>
                                {/* {getFaxFile(patient.patient_id)} */}
                                {patient.fax_file_name ? <img alt="add" src={EmptySvg} height={24} width={24} /> : ''}
                            </Icon>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="caption" noWrap>
                                <b className={classes.tableTitle}>{patient.updateddatetime}</b>
                            </Typography>
                        </StyledTableCell>
                        {/* <StyledTableCell>
                            <Typography variant="caption" noWrap>
                                <b className={classes.tableTitle}>{formateTime(patient.updatedatetime)}</b>
                            </Typography>
                        </StyledTableCell> */}
                        <StyledTableCell align="left">
                            <Typography variant="caption" noWrap>
                                {/* <b className={classes.tableTitle}>{patient.updatedby === 'ROLE_PHYSICIAN' ? 'Reviewed' : ''}</b> */}
                                <b className={classes.tableTitle}>{patient.sentToDoctor}</b>
                            </Typography>
                        </StyledTableCell>

                    </StyledTableRow>
                ))}
            </TableBody>
        )
    }

    const renderphyReviewed = () => {
        return (
            <>

                {/* <Typography
                    className={classes.header}
                    variant="h5">Physician Reviewed
                </Typography> */}
                <div className={classes.allergyTitleroot}>
                    <AppBar className={classes.appBar} position="static">
                        <Toolbar className={classes.lightToolBar}>
                            <Typography className={classes.title} variant="h6" noWrap>
                                Physician Reviewed
                            </Typography>
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <Icon classes={{
                                        root: classes.searchSVG
                                    }}>
                                        <img alt="SearchIcon" src={SearchSVG} height={24} width={24} />
                                    </Icon>
                                </div>
                                <InputBase
                                    placeholder="Search…"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={filterSearchResultsForReviewed}
                                />
                            </div>
                        </Toolbar>
                    </AppBar>
                </div>

                {loading1 ?

                    <Skeleton className={classes.skeletonStyle}
                        variant="rect"
                        animation="wave"
                        height={300} />

                    :
                    <Paper elevation={3} className={classes.tableRoot}>
                        <TableContainer classes={{
                            root: classes.tableContainer
                        }}>
                            <Table stickyHeader aria-label="sticky table">
                                {renderPhyReviewedHeader()}
                                {renderPhyReviewedContent()}
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5]}
                                            colSpan={9}
                                            count={reviewedPatients.length}
                                            rowsPerPage={5}
                                            page={pageOne}
                                            onChangePage={handleChangePageOne}
                                            onChangeRowsPerPage={handleChangeRowsPerPageOne}
                                            ActionsComponent={TablePaginationAction}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Paper>

                }

            </>
        )
    }

    return (
        <>


            <div className={classes.root}>
                <div className={classes.allergyContainer}>
                    {/* <Typography
                        className={classes.header}
                        variant="h5">Sent To Physician
                    </Typography> */}
                    <AppBar className={classes.appBar} position="static">
                        <Toolbar className={classes.lightToolBar}>
                            <Typography className={classes.title} variant="h6" noWrap>
                                Sent To Physician
                            </Typography>
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <Icon classes={{
                                        root: classes.searchSVG
                                    }}>
                                        <img alt="SearchIcon" src={SearchSVG} height={24} width={24} />
                                    </Icon>
                                </div>
                                <InputBase
                                    placeholder="Search…"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={filterSearchResultsForNotReviewed}
                                />
                            </div>
                        </Toolbar>
                    </AppBar>

                    {loading ?

                        <Skeleton className={classes.skeletonStyle}
                            variant="rect"
                            animation="wave"
                            height={300} />

                        :
                        <Paper elevation={3} className={classes.tableRoot}>
                            <TableContainer classes={{
                                root: classes.tableContainer
                            }}>
                                <Table stickyHeader aria-label="sticky table">
                                    {renderHeader()}
                                    {renderContent()}
                                    <TableFooter>
                                        <TableRow>
                                            {
                                                alerts.length !== 0 ?


                                                    <div style={{ display: "flex", flexFlow: "row", marginLeft: "30%" }}>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={users}
                                                            getOptionLabel={(option) => option.userName}
                                                            style={{ width: 200, margin: "5px 10px" }}
                                                            size="small"
                                                            value={selectedUser}
                                                            onChange={handleChangeUser}
                                                            renderInput={(params) => <TextField {...params} label="select user" variant="outlined" />}
                                                            
                                                        />
                                                        <Button variant="contained" onClick={() => sendAlert()} size="small" style={{ margin: "5px 10px" }} color="primary">
                                                            Send
                                                        </Button>
                                                    </div>
                                                    : ''
                                            }
                                            <TablePagination
                                                rowsPerPageOptions={[5]}
                                                colSpan={9}
                                                count={pendingRequests.length}
                                                rowsPerPage={5}
                                                page={page}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationAction}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Paper>

                    }
                    <div style={{ marginTop: "50px" }}>
                        {renderphyReviewed()}
                    </div>

                </div>
            </div>
        </>
    )


}

export default SentToPhysician;