import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Fab from '@material-ui/core/Fab';
import { useDispatch } from "react-redux";
import { openDialog } from "../../../store/dialog/actionCreator";
import AddSVG from "../../../images/Add.svg";
import Icon from "@material-ui/core/Icon";
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteSvg from "../../../images/Delete.svg";
import { getAllUserfacilities, deleteUserFacility, getUser, getFacility } from "../../../util/api";
import Skeleton from '@material-ui/lab/Skeleton';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationAction from "../../common/TablePaginationAction";
import { addNewData } from "../../../store/tableData/actionCreator";
import { dialogType } from "../../../util/constants/dialogConstants";
import AddFacilityToUser from "../Admin/dialog/AddFacilityToUser";
import Notify from "../../Notification/Notify";
import { triggerNotification } from "../../../store/notification/actionCreator";

const StyledTableCell = withStyles((theme) => ({
    root: {
        padding: "12px",
        borderBottom: "none ",
        textAlign: "center"
    },
    head: {
        backgroundColor: theme.palette.header,
        color: theme.palette.common.white,
        fontWeight: "bold"
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: "none"
    },
    lightToolBar: {
        background: theme.palette.toolbarLight,
        color: "black",
        paddingRight: "0px",
        paddingLeft: "0px"
    },
    facilityContainer: {
        paddingTop: "0.5%",
        paddingLeft: "25%",
        paddingRight: "10%",
        width: "100vw",
        paddingBottom: "0.5%"
    },
    facilityTitle: {
        fontSize: "2em",
        fontWeight: "bold"
    },
    facilityTitleroot: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        fontSize: "2.5em",
        color: theme.palette.primary.main,
        flexGrow: 1,
        display: 'none',
        fontWeight: "bold",
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    tableRoot: {
        borderRadius: "15px"
    },
    table: {
        minWidth: 700,
    },
    fabRoot: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    fabAdd: {
        position: "fixed",
        bottom: "25px",
        right: "25px",
        height: "75px",
        width: "75px",
    },
    addSVG: {
        height: "1.5em",
        width: "1.5em"
    },
    skeletonStyle: {
        marginBottom: "10px",
        borderRadius: "15px"
    },
    tableTitle: {
        fontWeight: "500"
    }
}))


const FacilityAssign = () => {
    const classes = useStyles();
    const facilityData = useSelector(state => state.tableData.tableDataList)
    const [loading, setLoading] = useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const dispatch = useDispatch();


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const [userColl, setUserColl] = useState([]);
    const [facColl, setFacColl] = useState([]);
    useEffect(() => {

        getUser().then(res => {
            if (res.status === 200) {
                setUserColl(res.data)
                getFacility().then(res1 => {
                    if (res1.status === 200) {
                        setFacColl(res1.data)
                        // console.log(res1.data);


                        getAllUserfacilities().then(response => {
                            if (response.status === 200) {
                                dispatch(addNewData(response.data, response.data, response.data.length))
                                setLoading(false)
                                // console.log(response.data);
                            }
                        });

                    }
                })
            }
        })

        // eslint-disable-next-line
    }, []);

    const getUserName = (user_id) => {
       
        if (userColl.length !== 0) {
            for (var i = 0; i < userColl.length; i++) {
                if (userColl[i].user_id === user_id) {
                    return userColl[i].userName;
                }
            }
        }
    }


    
    const getFacName = (facilityId) => {
       
        if (facColl.length !== 0) {
            for (var i = 0; i < facColl.length; i++) {
                if (facColl[i].facilityId === facilityId) {
                    return facColl[i].facilityName;
                }
            }
        }
    }

    const deleteFacility = (user) => {
        

        let deleteUser = {
            user_facility_id: user.user_facility_id,
            user_id: user.user_id,
            facilityId: user.facilityId
        }
        deleteUserFacility(deleteUser).then(res => {
            if (res.status === 200) {
                let notify = {
                    isShown: true,
                    message: "facility deleted successfully",
                    severity: "success"
                };
               
                getAllUserfacilities().then(response => {
                    if (response.status === 200) {
                        dispatch(addNewData(response.data, response.data.reverse(), response.data.length))
                        setLoading(false)
                    }
                });
                dispatch(triggerNotification(notify));
            }
        })
    }


    const renderHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}> User Id </b>

                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}> User Name </b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}> Facility </b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}> Status </b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">

                    </StyledTableCell>

                </TableRow>
            </TableHead>
        )
    }

    const renderAllergyContent = () => {
        return (
            <TableBody>
                {(rowsPerPage > 0
                    ? facilityData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : facilityData
                ).map((user, index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell align="left">
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableTitle}>{user.user_id}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableTitle}>{getUserName(user.user_id)}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableTitle}>{getFacName(user.facilityId)}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableTitle}> {user.isactive ? 'Active' : 'Inactive'} </b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Icon classes={{
                                root: classes.addSVG
                            }}>
                                <img alt="add" src={DeleteSvg} height={24} width={24}
                                    // onClick={() => dispatch(openDialog(dialogType.editFacilityToUser, user))}
                                    onClick={() => deleteFacility(user)}

                                />
                            </Icon>
                        </StyledTableCell>

                    </StyledTableRow>

                ))}
            </TableBody>
        )
    }


    return (
        <>
            <div className={classes.root}>
                <div className={classes.facilityContainer}>
                    {/* Display Manage drug row with title and search box */}
                    <div className={classes.facilityTitleroot}>
                        <AppBar className={classes.appBar} position="static">
                            <Toolbar className={classes.lightToolBar}>
                                <Typography className={classes.title} variant="h6" noWrap>
                                    Manage User Facility
                            </Typography>
                            </Toolbar>
                        </AppBar>
                    </div>

                    {/* Display content header row */}
                    {
                        loading ?
                            <div>
                                <Skeleton className={classes.skeletonStyle}
                                    variant="rect"
                                    animation="wave"
                                    height={400} />
                            </div>
                            :
                            <Paper elevation={3} className={classes.tableRoot}>
                                <TableContainer className={classes.tableRoot}>
                                    <Table stickyHeader aria-label="sticky table">
                                        {renderHeader()}
                                        {renderAllergyContent()}
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[5]}
                                                    colSpan={5}
                                                    count={facilityData.length}
                                                    rowsPerPage={5}
                                                    page={page}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationAction}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </Paper>
                    }
                </div>

                {/* Display FAB to add new drug */}
                <div className={classes.fabRoot}>
                    <Fab color="primary" aria-label="add" className={classes.fabAdd}
                        onClick={() => dispatch(openDialog(dialogType.addFacilityToUser, {}))}>
                        <Icon classes={{
                            root: classes.addSVG
                        }}>
                            <img alt="add" src={AddSVG} height={24} width={24} />
                        </Icon>
                    </Fab>
                </div>
                <AddFacilityToUser />

            </div>
            <Notify />
        </>
    )



}

export default FacilityAssign;