import { TRIGGER_NOTIFY } from "./actionType";

export const initialState = {
  message: "",
  severity: "",
  isShown: false
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRIGGER_NOTIFY: {
      
      const newState = {
        ...state,
        message: action.payload.message,
        severity: action.payload.severity,
        isShown: action.payload.isShown
      };
      return newState;
    }
    default:
      return state;
  }
};

export default notificationReducer;