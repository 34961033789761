import { ADD_USER } from "./actionType";

export const initialState = {
  userId: 0,
  email: "",
  username: "",
  roles: [],
  isLoggedIn: false
};

const authReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_USER: {
      const { payload } = action;
      const data = payload;

      const newState = {
        ...state,
        userId: data.id,
        username: data.username,
        email: data.email,
        roles: data.roles,
        isLoggedIn: true
      };
      return newState;
    }
    default:
      return state;
  }
};

export default authReducer;