import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { triggerNotification } from "../../store/notification/actionCreator";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}));

export const Notify = () => {
  const classes = useStyles();
  const notify = useSelector(state => state.notification);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    let notify = {
      isShown: false
    };
    dispatch(triggerNotification(notify));
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={notify.isShown}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={notify.severity}>
          {notify.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Notify;