import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector, useDispatch } from "react-redux";
import { closeDialog } from "../../../../store/dialog/actionCreator";
import TextField from '@material-ui/core/TextField';
import { getUser, getFacility, createUserFac, getAllUserfacilities, getUserFacilityById } from "../../../../util/api";
import Notify from "../../../Notification/Notify";
import { triggerNotification } from "../../../../store/notification/actionCreator";
import DrugSVG from "../../../../images/Drug.svg";
import Typography from '@material-ui/core/Typography';
import { dialogType } from "../../../../util/constants/dialogConstants";
import { addNewData } from "../../../../store/tableData/actionCreator";
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const drugClassStyles = makeStyles(theme => ({
    root: {
        width: "20vw"
    },
    tokenFileNameList: {
        fontSize: "1rem"
    },
    textroot: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    dialogTitle: {
        height: "20vh",
        width: "30vw",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FF6E4E",
        color: "white"
    },
    dialogContent: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    },
    checkBoxStyle: {
        marginTop: "16px"
    },
    buttonStyle: {
        marginTop: "16px"
    },
    textFieldStyle: {
        marginTop: "16px"
    },
    titleContent: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around"
    },
    closeButton: {
        position: 'absolute',
        right: "5px",
        top: "5px",
        color: "#FFFFFF",
    }
}));

export const AddFacilityToUser = () => {

    const classes = drugClassStyles();
    const [userColl, setUserColl] = useState([]);
    const [facDataColl, setFacDataColl] = useState([])
    const [facColl, setFacColl] = useState([]);
    const [selectedFacColl, SetSelectedFacColl] = useState([])
    const [facility, setFacility] = useState({
        selectedUser: "",
        facilitiesSelected: "",
        assign: ""
    })
    const [disable, setDisable] = useState(false);

    const dialog = useSelector(state => state.dialog);
    const dispatch = useDispatch();

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        var open = dialog.open;
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [dialog.open]);


    useEffect(() => {
        getUser().then((res) => {
            if (res.status === 200 && res.data) {
                setUserColl(res.data);
                // console.log(res);
                // res.data.map((item) => {
                //     return userColl.push(item);
                // })
            }
        })

        getFacility().then((res1) => {
            if (res1.status === 200 && res1.data) {
                // setFacColl(res1.data);
                setFacDataColl(res1.data);
                // console.log(res1);
                // res1.data.map((item) => {
                //     return facColl.push(item);
                // })

            }
        })

        // eslint-disable-next-line
    }, [])


    const handleClose = () => {
        dispatch(closeDialog());

    };

    const handleChangeUser = (event, value) => {
        setFacility({
            ...facility,
            selectedUser: value
        })
        setFacColl([])
        SetSelectedFacColl([])
        if (value) {
            getUserFacilityById(value.user_id).then(res1 => {
                if (res1.status === 200 && res1.data) {

                    // res1.data.map( f => {
                    //    selectedFacColl.push(f.facilityName)
                    // } )
                    SetSelectedFacColl(res1.data)

                    let arrayTwo = [];
                    arrayTwo = res1.data;

                    const results = facDataColl.filter(({ facilityId: id1 }) => !arrayTwo.some(({ facilityId: id2 }) => id2 === id1));
                    setFacColl(results);

                    // setFacColl(res1.data)

                }
            })
        }
    }

    const handleChangeFacilities = (event, value) => {
        setFacility({
            ...facility,
            facilitiesSelected: value
        })
        SetSelectedFacColl(value);

        let arrayTwo = [];
        arrayTwo = value;

        const results = facDataColl.filter(({ facilityId: id1 }) => !arrayTwo.some(({ facilityId: id2 }) => id2 === id1));
        setFacColl(results);


        // let array = [];
        // value.map(r => {
        //     array.push(r.facilityId)
        // })
        // selectedFacColl.map(s => {
        //     if(array.includes(s.facilityId) ){
        //        console.log("includes");
        //     }else{
        //         SetSelectedFacColl(value)
        //     }
        // })

    }


    const save = () => {


        if (facility.selectedUser) {

        } else {

            let notify = {
                isShown: true,
                message: "Please select user",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        

        if (selectedFacColl.length !== 0) {

        } else {

            let notify = {
                isShown: true,
                message: "Please Add Facilities to user",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }


        setDisable(true)

        let array = [];

        for (var i = 0; i < selectedFacColl.length; i++) {
            var newFac = {
                user_id: facility.selectedUser.user_id,
                facilityId: selectedFacColl[i].facilityId
                // facility.facilitiesSelected.facilityIdPcc ||
            }
            array.push(newFac)
        }

        createUserFac(array).then(res => {
            if (res.status === 201) {
                let notify = {
                    isShown: true,
                    message: "facility Created successfully",
                    severity: "success"
                };
                
                handleClose();
                setDisable(false)
                getAllUserfacilities().then(response => {
                    if (response.status === 200) {
                        dispatch(addNewData(response.data, response.data.reverse(), response.data.length))
                        // setLoading(false)
                        SetSelectedFacColl([])
                    }
                });
                dispatch(triggerNotification(notify));
            }
        }).catch(error => {
            setDisable(false)
            if (error.response.status === 400) {
                let notify = {
                    isShown: true,
                    message: "Bad Request",
                    severity: "error"
                };
                dispatch(triggerNotification(notify));
                // handleClose();
            }
            if (error.response.status === 500) {
                let notify = {
                    isShown: true,
                    message: "Something went wrong!!",
                    severity: "error"
                };
                dispatch(triggerNotification(notify));
                // handleClose();
            }

        });

    }





    return (
        <div>
            <Notify />
            <Dialog
                open={dialog.open && dialog.dialog === dialogType.addFacilityToUser}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    className={classes.dialogTitle}
                    disableTypography>
                    <div className={classes.titleContent}>
                        <Typography variant="h5" className={classes.title} noWrap>
                            Assign Facilties
                        </Typography>
                        <img src={DrugSVG} alt="newdrug" height={120} width={120} />
                        <IconButton color="primary" aria-label="Close" className={classes.closeButton} onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>

                    <Grid container spacing={3} className={classes.gridContainer}>
                        <Grid item xs={12}>
                            <Autocomplete
                                openOnFocus={true}
                                id="select-user"
                                options={userColl}
                                className={classes.formControl}
                                // value={facility.selectedUser}
                                style={{ marginTop: "18px" }}
                                onChange={handleChangeUser}
                                getOptionLabel={(option) => option.userName}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Select User"
                                        placeholder="Select User"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                // fullWidth={true}
                                style={{ width: 300 }}
                                openOnFocus={true}
                                id="select-facility"
                                options={facColl}
                                className={classes.formControl}
                                value={selectedFacColl}
                                onChange={handleChangeFacilities}
                                getOptionLabel={(option) => option.facilityName}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Select facilities"
                                        placeholder="Select Facilities"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>



                    <Button
                        className={classes.buttonStyle}
                        variant="contained"
                        color="primary"
                        disabled={disable}
                        onClick={() => save()}
                    >
                        SAVE
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    )


};
export default AddFacilityToUser;