import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
// import Table from '@material-ui/core/Table';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableRow from '@material-ui/core/TableRow';
import { getDrugRules, updateDrugRule, getAllDrugFiles } from "../../../util/api";
import Skeleton from '@material-ui/lab/Skeleton';
// import TableFooter from '@material-ui/core/TableFooter';
// import TablePagination from '@material-ui/core/TablePagination';
// import TablePaginationAction from "../../common/TablePaginationAction";
import Grid from '@material-ui/core/Grid';
import { addNewData } from "../../../store/tableData/actionCreator";
import Notify from "../../Notification/Notify";
import { triggerNotification } from "../../../store/notification/actionCreator";
import EmptySvg from "../../../images/Empty.svg"
import Icon from "@material-ui/core/Icon";
import { roles } from '../../../util/constants/roles';
import { addDrugRuleFile, reset } from '../../../store/viewFiles/actionCreator';
import { useHistory } from 'react-router-dom';
import { Divider } from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: "none"
    },
    lightToolBar: {
        background: theme.palette.toolbarLight,
        color: "black",
        paddingRight: "0px",
        paddingLeft: "0px"
    },
    drugContainer: {
        paddingTop: "0.5%",
        paddingLeft: "25%",
        paddingRight: "10%",
        width: "100vw",
        paddingBottom: "0.5%"
    },
    drugTitle: {
        fontSize: "2em",
        fontWeight: "bold"
    },
    drugTitleroot: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        fontSize: "2.5em",
        color: theme.palette.primary.main,
        flexGrow: 1,
        display: 'none',
        fontWeight: "bold",
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    tableRoot: {
        borderRadius: "15px",
        borderTop: ".5px solid #f1ecec",
        padding: "15px",
        marginBottom: "15px"

    },
    table: {
        minWidth: 700,
    },
    fabRoot: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    fabAdd: {
        position: "fixed",
        bottom: "25px",
        right: "25px",
        height: "75px",
        width: "75px",
    },
    addSVG: {
        height: "1.5em",
        width: "1.5em",
        cursor: "pointer"
    },
    skeletonStyle: {
        marginBottom: "10px",
        borderRadius: "15px"
    },
    box: {
        borderBottom: "3px solid #e7eef8"
    },
    line1: {
        paddingLeft: "20px",
        paddingTop: "15px"
    },
    line2: {
        paddingLeft: "20px",
        paddingRight: "20px"
    },
    deactive: {
        width: "100px"
    },
    activeLink: {
        "&:hover": {
            cursor: 'pointer',
            textDecoration: 'underline'
        },
        color: ' #1877f2ed'
    },
    deActiveLink: {
        "&:hover": {
            cursor: 'pointer',
            textDecoration: 'underline'
        },
        color: '#ff6e4e'
    }

}))

const DrugRule = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    // const [page, setPage] = React.useState(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState(2);
    const dispatch = useDispatch();
    const drugRules = useSelector(state => state.tableData.tableDataList)
    const [filesColl, setFilesColl] = useState([]);
    const history = useHistory();
    const auth = useSelector(state => state.auth);

    useEffect(() => {
        getDrugRules().then(response => {
            if (response.status === 200 && response.data) {
                dispatch(addNewData(response.data, response.data, response.data.length))
                setLoading(false)

                getAllDrugFiles().then(res1 => {
                    if (res1.status === 200 && res1.data) {
                        setFilesColl(res1.data)
                    }
                })
            }
        })

        // eslint-disable-next-line
    }, []);



    const getfile = (dslno) => {

        if (filesColl.length !== 0) {
            for (var i = 0; i < filesColl.length; i++) {
                if (filesColl[i].dslno === dslno) {
                    return (
                        <>
                            <img alt="add" style={{ cursor: "pointer" }} src={EmptySvg} height={24} width={24} />
                        </>
                    )
                }
            }
        }
    }

    const viewFile = (rule) => {


        dispatch(reset())
        dispatch(addDrugRuleFile(rule))


        switch (auth.roles[0]) {
            case roles.admin:
                return history.push("/admin/viewfile")
            // case roles.nurse:
            //     return history.push("/nurse/viewfile")
            // case roles.pharmacist:
            //     return history.push("/pharmacist/viewfile")
            // case roles.physician:
            //     return history.push("/physician/viewfile")
            default:
                return
        }
    }

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(0);
    // };

    const handleClickActivate = (rule) => {
        if (rule.status === "processing") {
            let notify = {
                isShown: true,
                message: "Selected Drug Rule Already is in Active status",
                severity: "success"
            };
            dispatch(triggerNotification(notify));
        } else {
            // console.log(rule);

            var updateRule = {
                dslno: rule.dslno,
                status: "processing"
            }
            updateDrugRule(updateRule).then(res => {
                if (res.status === 200) {
                    // console.log(res);
                    let notify = {
                        isShown: true,
                        message: `${res.data.message}`,
                        severity: "success"
                    };
                    setLoading(true);
                    getDrugRules().then(response => {
                        if (response.status === 200 && response.data) {
                            dispatch(addNewData(response.data, response.data, response.data.length));
                            dispatch(triggerNotification(notify));
                            setLoading(false);
                        }
                    })
                }
            })

        }
    }

    const handleClickDeActivate = (rule) => {
        if (rule.status === "stop") {
            let notify = {
                isShown: true,
                message: "Selected Drug Rule Already is in DeActive status",
                severity: "success"
            };
            dispatch(triggerNotification(notify));
        } else {
            var updateRule = {
                dslno: rule.dslno,
                status: "stop"
            }
            updateDrugRule(updateRule).then(res => {
                if (res.status === 200) {
                    // console.log(res);
                    let notify = {
                        isShown: true,
                        message: `${res.data.message}`,
                        severity: "success"
                    };
                    setLoading(true);
                    getDrugRules().then(response => {
                        if (response.status === 200 && response.data) {
                            dispatch(addNewData(response.data, response.data, response.data.length));
                            dispatch(triggerNotification(notify));
                            setLoading(false);
                        }
                    })
                }
            })
        }
    }

    // const renderDrugRuleContent = () => {
    //     return (
    //         <>

    //             {(rowsPerPage > 0
    //                 ? drugRules.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    //                 : drugRules
    //             ).map((rule, index) => (
    //                 <>
    //                     <div className={classes.box}>
    //                         <Grid container className={classes.line1} >
    //                             {/* <Grid item xs={12}> */}
    //                             <Grid item xs={12}>
    //                                 <Typography variant="body1" gutterBottom>
    //                                     <Grid container>
    //                                         <Grid item xs={2}>
    //                                             Drug Rule : {rule.dslno}
    //                                         </Grid>
    //                                         <Grid item xs={1}>
    //                                             {rule.status === "Processing" ? '' : <Typography variant="body1" className={classes.activeLink} onClick={() => handleClickActivate(rule)} gutterBottom>Activate</Typography>}
    //                                         </Grid>
    //                                         <Grid item className={classes.deactive}>
    //                                             {rule.status === "stop" ? <Typography variant="body1" className={classes.deActiveLink} onClick={() => handleClickDeActivate(rule)} gutterBottom>DeActivate</Typography> : ''}
    //                                             {/* <Button color="secondary"  onClick = {() => handleClickDeActivate(rule)} >Secondary</Button> */}
    //                                         </Grid>
    //                                         <Grid item xs={2}>
    //                                             ( {rule.status} )
    //                                         </Grid>
    //                                         <Grid item xs={3}></Grid>
    //                                         <Grid item xs={2} style={{ textAlign: "end" }}>
    //                                             <Icon
    //                                                 onClick={() => viewFile(rule)}
    //                                                 classes={{
    //                                                     root: classes.customSVG
    //                                                 }}>
    //                                                 {getfile(rule.dslno)}

    //                                             </Icon>

    //                                         </Grid>

    //                                     </Grid>
    //                                 </Typography>

    //                             </Grid>
    //                         </Grid>
    //                         {/* </Grid> */}
    //                         <Grid container className={classes.line2} >
    //                             <Grid item xs={12}>
    //                                 <Typography variant="body1" gutterBottom>
    //                                     Using {rule.pdname}, And On {rule.sdname}
    //                                 </Typography>
    //                             </Grid>
    //                         </Grid>
    //                         <Grid container className={classes.line2} >
    //                             <Grid item xs={12}>
    //                                 <Typography variant="body1" gutterBottom>
    //                                     {rule.yes_sel === "Message" && rule.no_sel === "Message" ? "Message" : "Question"} : {rule.ques}
    //                                 </Typography>
    //                             </Grid>
    //                         </Grid>
    //                         <Grid container className={classes.line2} >
    //                             <Grid item xs={12}>
    //                                 <Typography variant="body1" gutterBottom>
    //                                     Yes : {rule.yes_sel}
    //                                 </Typography>
    //                             </Grid>
    //                             <Grid item xs={12}>
    //                                 <Typography variant="body1" gutterBottom>
    //                                     No : {rule.no_sel}
    //                                 </Typography>
    //                             </Grid>
    //                         </Grid>
    //                         <Grid container className={classes.line2} >
    //                             <Grid item xs={12}>
    //                                 <Typography variant="body1" gutterBottom>
    //                                     Message : {rule.msg}
    //                                 </Typography>
    //                             </Grid>
    //                         </Grid>

    //                     </div>

    //                 </>
    //             ))}

    //         </>
    //     )
    // }


    const renderDrugRules = () => {
        return (
            <>

                {
                    drugRules.map(rule => (
                        <>


                            <Grid item xs={12}>
                                {rule.slno === 1 ? <>  {rule.dslno === 1 ? '' : <Divider />}

                                    
                                    <Grid container style={{ marginTop: "10px" }}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" gutterBottom>
                                                <Grid container>
                                                    <Grid item xs={2}>
                                                        Drug Rule : {rule.dslno}
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Typography variant="body1" className={classes.activeLink} onClick={() => handleClickActivate(rule)} gutterBottom>Activate</Typography>
                                                    </Grid>
                                                    <Grid item className={classes.deactive}>
                                                        <Typography variant="body1" className={classes.deActiveLink} onClick={() => handleClickDeActivate(rule)} gutterBottom>DeActivate</Typography>
                                                        
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        ( {rule.status} )
                                                    </Grid>
                                                    <Grid item xs={3}></Grid>
                                                    <Grid item xs={2} style={{ textAlign: "end" }}>
                                                        <Icon
                                                            onClick={() => viewFile(rule)}
                                                            classes={{
                                                                root: classes.customSVG
                                                            }}>
                                                            {getfile(rule.dslno)}

                                                        </Icon>

                                                    </Grid>

                                                </Grid>
                                            </Typography>

                                        </Grid>
                                    </Grid>


                                    <h4> Using {rule.pdname}, And On {rule.sdname} </h4> </> : ''}

                                {
                                    rule.yes_sel === "Message" && rule.no_sel === "Message" ?
                                        <>
                                            <h4>Yes : {rule.ques}</h4>
                                            <h4>No : {rule.msg}</h4>
                                        </>
                                        :
                                        <>
                                            {rule.yes_sel === "Message" ?
                                                <>
                                                    <h4>Yes :  {rule.msg} </h4>
                                                </>
                                                :
                                                <>
                                                    {rule.no_sel === "Message" ? <> <h4> No : {rule.msg} </h4> </> : ''}
                                                </>

                                            }
                                        </>
                                }

                                {
                                    <>
                                        {rule.yes_sel === "Check" ? <h4> Yes :  {rule.ques} </h4> : ''}
                                        {rule.no_sel === "Check" ? <h4> No :  {rule.ques} </h4> : ''}
                                    </>
                                }
                                {/* {rule.yes_sel === "Check" ? setQues(`Yes :  ${rule.ques} `) : ''}
                                {rule.no_sel === "Check" ? setQues(` No :  ${rule.ques} `) : ''} */}

                            </Grid>

                        </>
                    ))
                }

            </>
        )
    }

    return (
        <>
            <div className={classes.root}>
                <div className={classes.drugContainer}>
                    {/* Display Manage drug row with title and search box */}
                    <div className={classes.drugTitleroot}>
                        <AppBar className={classes.appBar} position="static">
                            <Toolbar className={classes.lightToolBar}>
                                <Typography className={classes.title} variant="h6" noWrap>
                                    View Drug Rules
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </div>

                    {/* Display content header row */}
                    {
                        loading ?
                            <div>
                                <Skeleton className={classes.skeletonStyle}
                                    variant="rect"
                                    animation="wave"
                                    height={400} />
                            </div>
                            :
                            <Paper elevation={3} className={classes.tableRoot}>
                                <Grid container >
                                    {renderDrugRules()}

                                </Grid>


                                {/* <TableContainer className={classes.tableRoot}>
                                    <Table stickyHeader aria-label="sticky table">
                                        {renderDrugRuleContent()}
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[2]}
                                                    colSpan={4}
                                                    count={drugRules.length}
                                                    rowsPerPage={2}
                                                    page={page}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationAction}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer> */}

                            </Paper>
                    }
                </div>


            </div>
            <Notify />
        </>
    )
}

export default DrugRule;