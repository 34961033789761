import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Icon from "@material-ui/core/Icon";
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import SearchSVG from "../../../images/Search.svg";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationAction from "../../common/TablePaginationAction";
import {
    addNewData,
    searchData
} from "../../../store/tableData/actionCreator";
import { getPhyReviewedPatients } from "../../../util/api";
import { roles } from "../../../util/constants/roles";
import { useHistory } from "react-router";
import { addAdmissionFile, addFaxFile } from "../../../store/viewFiles/actionCreator";
import EmptySvg from "../../../images/Empty.svg";
// import { useHistory } from 'react-router-dom';

const StyledTableCell = withStyles((theme) => ({
    root: {
        padding: "12px",
        borderBottom: "none ",
        textAlign: "center"
    },
    head: {
        backgroundColor: theme.palette.header,
        color: theme.palette.common.white,
        fontWeight: "bold"
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: "none"
    },
    lightToolBar: {
        background: theme.palette.toolbarLight,
        color: "black",
        paddingRight: "0px",
        paddingLeft: "0px"
    },
    allergyContainer: {
        paddingTop: "0.5%",
        paddingLeft: "25%",
        paddingRight: "10%",
        width: "100vw",
        paddingBottom: "0.5%"
    },
    allergyTitle: {
        fontSize: "2em",
        fontWeight: "bold"
    },
    allergyTitleroot: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        fontSize: "2.5em",
        color: theme.palette.primary.main,
        flexGrow: 1,
        display: 'none',
        fontWeight: "bold",
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        backgroundColor: theme.palette.search,
        position: 'relative',
        borderRadius: "10px",
        // backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: theme.palette.search,
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
            backgroundColor: theme.palette.search,
            borderRadius: "10px",
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    searchSVG: {
        height: "1.1em"
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    tableRoot: {
        borderRadius: "15px"
    },
    table: {
        minWidth: 700,
    },
    fabRoot: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    fabAdd: {
        position: "fixed",
        bottom: "25px",
        right: "25px",
        height: "75px",
        width: "75px",
    },
    addSVG: {
        height: "1.5em",
        width: "1.5em"
    },
    skeletonStyle: {
        marginBottom: "10px",
        borderRadius: "15px"
    },
    tableTitle: {
        fontWeight: "500"
    },
    pdf: {
        cursor: "pointer"
    }
}))


const PhysicianReviewed = () => {
    const classes = useStyles();
    const [searchText, setSearchText] = useState("")
    const facilityData = useSelector(state => state.tableData.tableDataList)
    const [loading, setLoading] = useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const history = useHistory();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // const formatDate = (d1) => {
    //     var date = new Date(d1);
    //     if (!isNaN(date.getTime())) {
    //         var d = new Date(date),
    //             month = '' + (d.getMonth() + 1),
    //             day = '' + d.getDate(),
    //             year = d.getFullYear();

    //         if (month.length < 2)
    //             month = '0' + month;
    //         if (day.length < 2)
    //             day = '0' + day;

    //         return [month, day, year].join('-').toString();
    //     }
    // }

    // const formateTime = (d) => {
    //     var t = new Date(d),
    //         h = '' + t.getHours(),
    //         m = '' + t.getMinutes(),
    //         s = '' + t.getSeconds();

    //     if (h.length < 2)
    //         h = '0' + h;
    //     if (m.length < 2)
    //         m = '0' + m;
    //     if (s.length < 2)
    //         s = '0' + s;

    //     return [h, m, s].join(':').toString();
    // }



    useEffect(() => {
        getPhyReviewedPatients(auth.userId, 30).then(response => {
            if (response.status === 200) {
                dispatch(addNewData(response.data, response.data.reverse(), response.data.length))
                setLoading(false)
            }
        });


        // eslint-disable-next-line
    }, []);


    useEffect(() => {

        dispatch(searchData(searchText, facilityData, "FACILITY"))

        // eslint-disable-next-line
    }, [searchText])



    // const handlePdf = () => {
    //     let newUrl = "/facility/report";
    //     history.push(newUrl)
    // }

    const viewFile = (patient) => {
        dispatch(addAdmissionFile(patient))

        switch (auth.roles[0]) {

            case roles.physician:
                return history.push("/physician/viewfile")
            case roles.facility:
                return history.push("/facility/viewfile")
            default:
                return;
        }

    }

    const viewFaxFile = (patient) => {
        dispatch(addFaxFile(patient))

        switch (auth.roles[0]) {

            case roles.physician:
                return history.push("/physician/viewFaxfile")
            case roles.facility:
                return history.push("/facility/viewFaxfile")
            default:
                return;
        }

    }

    const renderHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>Patient ID</b>

                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>Patient Name</b>

                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}> Admission File </b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}> Fax </b>
                        </Typography>
                    </StyledTableCell>
                    {/* <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}> Phy Id </b>
                        </Typography>
                    </StyledTableCell> */}
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}> Phy Name </b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}> Date </b>
                        </Typography>
                    </StyledTableCell>
                    {/* <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}> Time </b>
                        </Typography>
                    </StyledTableCell> */}
                    {/* <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}> Report </b>
                        </Typography>
                    </StyledTableCell> */}

                </TableRow>
            </TableHead>
        )
    }

    const renderAllergyContent = () => {
        return (
            <TableBody>
                {(rowsPerPage > 0
                    ? facilityData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : facilityData
                ).map((facility, index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell align="left">
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableTitle}>{facility.patient_id}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableTitle}>{facility.first_name} {facility.last_name}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Icon
                                style={{ cursor: "pointer" }}
                                onClick={() => viewFile(facility)}
                                classes={{
                                    root: classes.customSVG
                                }}>
                                {facility.admission_file_name ? <img alt="add" src={EmptySvg} height={24} width={24} /> : ''}
                            </Icon>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Icon
                                style={{ cursor: "pointer" }}
                                onClick={() => viewFaxFile(facility)}
                                classes={{
                                    root: classes.customSVG
                                }}>
                                {/* {getFaxFile(patient.patient_id)} */}
                                {facility.fax_file_name ? <img alt="add" src={EmptySvg} height={24} width={24} /> : ''}
                            </Icon>
                        </StyledTableCell>
                        {/* <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableTitle}>36</b>
                            </Typography>
                        </StyledTableCell> */}
                        <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableTitle}>{facility.sentToDoctor} </b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableTitle}>{facility.updateddatetime}</b>
                            </Typography>
                        </StyledTableCell>
                        {/* <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableTitle}> {formateTime(facility.updatedatetime)} </b>
                            </Typography>
                        </StyledTableCell> */}
                        {/* <StyledTableCell>
                            <img src={EmptySVG} alt="empty" className={classes.pdf} onClick={handlePdf} height={20} weight={15} />
                        </StyledTableCell> */}
                    </StyledTableRow>

                ))}
            </TableBody>
        )
    }

    const filterSearchResults = (event) => {
        var searchText = event.target.value;
        setSearchText(searchText)
    }

    return (
        <div className={classes.root}>
            <div className={classes.allergyContainer}>
                {/* Display Manage drug row with title and search box */}
                <div className={classes.allergyTitleroot}>
                    <AppBar className={classes.appBar} position="static">
                        <Toolbar className={classes.lightToolBar}>
                            <Typography className={classes.title} variant="h6" noWrap>
                                Reviewed By Physician
                            </Typography>
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <Icon classes={{
                                        root: classes.searchSVG
                                    }}>
                                        <img alt="SearchIcon" src={SearchSVG} height={24} width={24} />
                                    </Icon>
                                </div>
                                <InputBase
                                    placeholder="Search…"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={filterSearchResults}
                                />
                            </div>
                        </Toolbar>
                    </AppBar>
                </div>

                {/* Display content header row */}
                {
                    loading ?
                        <div>
                            <Skeleton className={classes.skeletonStyle}
                                variant="rect"
                                animation="wave"
                                height={400} />
                        </div>
                        :
                        <Paper elevation={3} className={classes.tableRoot}>
                            <TableContainer className={classes.tableRoot}>
                                <Table stickyHeader aria-label="sticky table">
                                    {renderHeader()}
                                    {renderAllergyContent()}
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[5]}
                                                colSpan={7}
                                                count={facilityData.length}
                                                rowsPerPage={5}
                                                page={page}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationAction}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Paper>
                }
            </div>



        </div>
    )



}

export default PhysicianReviewed;