import {ADD_Admission_File, Add_DRUGRULE_FILE, ADD_FAX_FILE, RESET} from "./actionType";


export function addAdmissionFile(patient) {
    return{
        type: ADD_Admission_File,
        payload: patient
    }
}

export function addDrugRuleFile(rule) {
    return{
        type: Add_DRUGRULE_FILE, 
        payload: rule       
    }
}

export function addFaxFile(patient) {
    return{
        type: ADD_FAX_FILE, 
        payload: patient       
    }
}


export function reset() {
    return{
        type : RESET
    }
}