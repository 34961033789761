import { OPEN_DIALOG, CLOSE_DIALOG } from "./actionType";

export const initialState = {
  open: false,
  dialog: "",
  data: {}
};

const dialogReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case OPEN_DIALOG: {
      const newState = {
        ...state,
        open: true,
        dialog: action.payload.dialogType,
        data: action.payload.data
      };
      return newState;
    }

    case CLOSE_DIALOG: {
      const newState = {
        ...state,
        open: action.payload
      };
      return newState;
    }
    default:
      return state;
  }
};

export default dialogReducer;