import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import UnAuthorisedSVG from "../../images/UnAuthorised.svg";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center"
    },
    loginContainer: {
        minHeight: "50vh",
        minWidth: "50vw",
        borderRadius: "15px",
        // backgroundColor: "#E7EEF8"
    },
    textFieldAlign: {
        marginBottom: "30px",
        color: "black"
    },
    imageGrid: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        color: "white",
        borderTopLeftRadius: "15px",
        borderBottomLeftRadius: "15px"
    },
    imageStyle: {
        marginBottom: "50px"
    }
}));

const UnAuthorised = () => {
    const classes = useStyles();
    const history = useHistory();

    const goBrowserBack = () => {
        history.goBack();
    }

    return (
        <div className={classes.root}>
            {/* <img src={UnAuthorisedSVG} classNamealt="" height={200} width={200} />
            <h1 className={classes.root}>UnAuthorised</h1> */}
            <Paper elevation={0} className={classes.loginContainer}>
                <Grid container>
                    <Grid item xs={12} className={classes.imageGrid}>
                        <div>
                            <img src={UnAuthorisedSVG} className={classes.imageStyle} alt="" height={200} width={200} />
                            <Typography
                                variant="h4"
                                className={classes.textFieldAlign}><b>You are not authorised to access this resource!</b>
                            </Typography>
                            <Button
                                variant="contained"
                                disableElevation
                                startIcon={<ArrowBackRounded />}
                                onClick={() =>  goBrowserBack() }
                                color="secondary">Back</Button>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default UnAuthorised;