import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import { closeDialog } from "../../../../store/dialog/actionCreator";
import TextField from '@material-ui/core/TextField';
import { addUser, getUser, getUserRoles, getFacility } from "../../../../util/api";
import Notify from "../../../Notification/Notify";
import { triggerNotification } from "../../../../store/notification/actionCreator";
import usersvg from "../../../../images/User.svg";
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { addNewData } from "../../../../store/tableData/actionCreator";
import { dialogType } from "../../../../util/constants/dialogConstants";
import CloseIcon from '@material-ui/icons/Close';

const userStyles = makeStyles(theme => ({
    root: {
        width: "20vw"
    },
    tokenFileNameList: {
        fontSize: "1rem"
    },
    textroot: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    dialogTitle: {
        height: "20vh",
        width: "55vw",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FF6E4E",
        color: "white"
    },
    dialogContent: {
        display: "flex",
        width: "55vw",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    },
    checkBoxStyle: {
        marginTop: "16px"
    },
    buttonStyle: {
        marginTop: "40px",
        marginBottom: "15px"
    },
    textFieldStyle: {
        minWidth: "210px",
        margin: "0px",
        marginTop: "18px",
        marginLeft: "8px"
    },
    titleContent: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around"
    },
    margin: {
        // margin: theme.spacing(1),
        margin: "0px",
        minWidth: "210px",
        marginTop: "16px",
        marginLeft: "8px"
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '25ch',
        minWidth: "210px",
        marginTop: "16px",
        marginLeft: "8px"
    },
    formControl: {
        // margin: theme.spacing(1),
        // width: "-webkit-fill-available",
        minWidth: "210px",
        margin: "0px",
        marginTop: "17px",
        marginLeft: "8px"
    },
    // margin:{
    //     margin:"8px"
    // },
    password: {
        marginTop: "8px"
    },
    userType: {
        margin: "8px"
    },
    userStatus: {
        margin: "8px"
    },
    dialog: {
        // width:"55vw"
    },
    paper: { minWidth: "55vw" },
    closeButton: {
        position: 'absolute',
        right: "5px",
        top: "5px",
        color: "#FFFFFF",
    }
}));

export const AddUser = () => {

    const classes = userStyles();

    const [User, setUser] = useState({
        name: "",
        // address: "",
        email: "",
        mob: "",
        upwd: "",
        user_type_id: "",
        ustatus: "",
        smsno1: "",
        smsno2: "",
        smsno3: "",
        faxno1: "",
        faxno2: "",
        faxno3: "",
        showPassword: false,
        work_number: "",
        facility: ""
    })

    const [disableButton, SetDisableButton] = useState(false);

    const [utype, setUtype] = useState('');
    const [role, setRole] = useState('');
    const [userRoles, setUserRoles] = useState([]);
    const [facilityColl, setFacilityColl] = useState([]);


    // const userRoles = [
    //     { id: "1", Role: "NURSE" },
    //     { id: "2", Role: "PHARMACIST" },
    //     { id: "3", Role: "PHYSICIAN" },
    //     { id: "4", Role: "FACILITY" },
    //     { id: "5", Role: "ADMIN" },
    // ]

    const handleClickShowPassword = () => {
        setUser({ ...User, showPassword: !User.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const dialog = useSelector(state => state.dialog);
    const dispatch = useDispatch();

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        var open = dialog.open;
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [dialog.open]);

    useEffect(() => {
        getUserRoles().then(res => {
            setUserRoles(res.data);
        })
        getFacility().then(res1 => {
            if (res1.status === 200) {
                setFacilityColl(res1.data)

            }
        });
    }, [])



    const handleClose = () => {
        dispatch(closeDialog());
    };

    const addUserAPI = () => {

        if (User.name === "") {

            let notify = {
                isShown: true,
                message: "Please Enter User Name",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (User.name.length < 5) {

            let notify = {
                isShown: true,
                message: "Please choose a username with at least 5 characters",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (User.email !== "") {

            if (/\S+@\S+\.\S+/.test(User.email)) {
                // value is ok, use it
            } else {
                let notify = {
                    isShown: true,
                    message: "Enter Valid Email",
                    severity: "warning"
                };
                dispatch(triggerNotification(notify));
                return;
            }

        } else {
            let notify = {
                isShown: true,
                message: "Email Mandatory",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (utype === "") {

            let notify = {
                isShown: true,
                message: "Please Select User Type",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (User.facility === "") {

            let notify = {
                isShown: true,
                message: "Please Select Facility",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }


        if (User.mob === "") {

            let notify = {
                isShown: true,
                message: "Please Enter Mobile number",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }
        if (/^\d{10}$/.test(User.mob)) {

        } else {
            let notify = {
                isShown: true,
                message: "Please Enter Valid Mobile number",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }


        if (User.faxno1 === "") {

            let notify = {
                isShown: true,
                message: "Please Enter Fax number",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (User.upwd === "") {

            let notify = {
                isShown: true,
                message: "Please Enter Password ",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (User.upwd.length < 6) {

            let notify = {
                isShown: true,
                message: "Please choose a password with at least 6 characters",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        SetDisableButton(true);


        var newUser = {
            userName: User.name,
            password: User.upwd,
            emailAddress: User.email,
            user_type_id: utype,
            mobile_number: User.mob,
            sms_no1: User.smsno1,
            sms_no2: User.smsno2,
            sms_no3: User.smsno3,
            fax_no1: User.faxno1,
            fax_no2: User.faxno2,
            fax_no3: User.faxno3,
            facility_id: User.facility,
            role: role

        }

        addUser(newUser).then(res1 => {

            if (res1.status === 200) {
                let notify = {
                    isShown: true,
                    message: "New User added successfully",
                    severity: "success"
                };
                dispatch(triggerNotification(notify));
                dispatch(closeDialog());
                setUser('');
                setRole('');
                setUtype('');
                getUser().then(res2 => {
                    if (res2.status === 200) {
                        dispatch(addNewData(res2.data, res2.data, res2.data.length))
                    }
                });
                SetDisableButton(false);
            }

        }).catch(error => {

            if (error.response.status === 400) {
                let notify = {
                    isShown: true,
                    message: error.response.data.message,
                    severity: "warning"
                };
                dispatch(triggerNotification(notify));

            } else {
                let notify = {
                    isShown: true,
                    message: "something went wrong",
                    severity: "warning"
                };
                dispatch(triggerNotification(notify));

            }
            SetDisableButton(false);


        })
    }

    const updateName = (event) => {
        setUser({
            ...User,
            name: event.target.value
        })
    }


    const updateEmail = (event) => {
        setUser({
            ...User,
            email: event.target.value
        })
    }
    const updateMobile = (event) => {
        setUser({
            ...User,
            mob: event.target.value
        })
    }
    const updateUpwd = (event) => {
        setUser({
            ...User,
            upwd: event.target.value
        })
    }

    const handleRole = (event) => {
        setUtype(event.target.value.userTypeId);
        // switch (event.target.value) {
        //     case '1':
        //         return setRole(["NURSE"]);
        //     case '2':
        //         return setRole(["PHARMACIST"]);
        //     case '3':
        //         return setRole(["PHYSICIAN"]);
        //     case '4':
        //         return setRole(["FACILITY"]);
        //     case '5':
        //         return setRole(["ADMIN"]);
        //     default:
        //         return '';
        // }
        setRole([`${event.target.value.userTypeName}`]);
    }

    const handleChangeFacility = (event) => {
        setUser({
            ...User,
            facility: event.target.value
        })
    }


    const updateSMS1 = (event) => {
        setUser({
            ...User,
            smsno1: event.target.value
        })
    }
    const updateSMS2 = (event) => {
        setUser({
            ...User,
            smsno2: event.target.value
        })
    }
    const updateSMS3 = (event) => {
        setUser({
            ...User,
            smsno3: event.target.value
        })
    }
    const updateFaxNo1 = (event) => {
        setUser({
            ...User,
            faxno1: event.target.value
        })
    }
    const updateFaxNo2 = (event) => {
        setUser({
            ...User,
            faxno2: event.target.value
        })
    }
    const updateFaxNo3 = (event) => {
        setUser({
            ...User,
            faxno3: event.target.value
        })
    }
    // const updateWorkNumber = (event) => {
    //     setUser({
    //         ...User,
    //         workNumber: event.target.value
    //     })
    // }

    // const hadleRoleChange = ()
    // const hadleRoleChange = (event) => {
    //     setUser( {

    //         utype: event.target.value.id,
    //         // ...User
    //     });
    //     setRole(event.target.value.Role);
    // }


    return (
        <div>
            <Notify />
            <Dialog
                open={dialog.open && dialog.dialog === dialogType.addUser}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                classes={{ paper: classes.paper }}
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    className={classes.dialogTitle}
                    disableTypography>
                    <div className={classes.titleContent}>
                        <Typography variant="h5" className={classes.title} noWrap>
                            Register New User
                        </Typography>
                        <img src={usersvg} alt="newdrug" height={120} width={120} />
                        <IconButton color="primary" aria-label="Close" className={classes.closeButton} onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                    <form className={classes.textRoot} noValidate autoComplete="off">

                        <Grid container >
                            <Grid item xs={4}>
                                <TextField
                                    label="User Name"
                                    size="medium"
                                    className={classes.textFieldStyle}
                                    onChange={updateName}
                                />


                                <FormControl className={classes.formControl}>
                                    <InputLabel id="userTypelabel" size="medium"  >User Type</InputLabel>
                                    <Select
                                        labelId="role-select-lable"
                                        id="role"
                                        size="medium"
                                        onChange={handleRole}
                                    >
                                        {userRoles.map((data, index) => (<MenuItem value={data} key={index} > {data.userTypeName} </MenuItem>))}
                                    </Select>
                                </FormControl>

                                <TextField
                                    id="faxno1"
                                    label="Fax Number"
                                    size="medium"
                                    type="number"
                                    className={classes.margin}
                                    onChange={updateFaxNo1}
                                />

                            </Grid>
                            <Grid item xs={4}>

                                <TextField
                                    margin="dense"
                                    id="email"
                                    label="Email Address"
                                    type="email"
                                    className={classes.textFieldStyle}
                                    size="medium"
                                    onChange={updateEmail}
                                />

                                <FormControl className={classes.formControl}>
                                    <InputLabel id="facility-lable" size="medium"  >User Facility</InputLabel>
                                    <Select
                                        labelId="facility-select-lable"
                                        id="facility"
                                        size="medium"
                                        onChange={handleChangeFacility}
                                    >
                                        {facilityColl.map((data, index) => (<MenuItem value={data.facilityId} key={index} > {data.facilityName} </MenuItem>))}
                                    </Select>
                                </FormControl>



                                <TextField
                                    id="smsno1"
                                    label="SMS Number 1"
                                    size="medium"
                                    type="number"
                                    style={{ display: "none" }}
                                    className={classes.margin}
                                    onChange={updateSMS1}
                                />
                                <TextField
                                    id="smsno2"
                                    label="SMS Number 2"
                                    size="medium"
                                    type="number"
                                    style={{ display: "none" }}
                                    className={classes.margin}
                                    onChange={updateSMS2}
                                />
                                <TextField
                                    id="smsno3"
                                    label="SMS Number 3"
                                    size="medium"
                                    type="number"
                                    style={{ display: "none" }}
                                    className={classes.margin}
                                    onChange={updateSMS3}
                                />
                            </Grid>
                            <Grid item xs={4}>

                                <FormControl className={clsx(classes.textField)}>
                                    <InputLabel htmlFor="password">Password</InputLabel>
                                    <Input
                                        id="password"
                                        type={User.showPassword ? 'text' : 'password'}
                                        value={User.password}
                                        onChange={updateUpwd}

                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {User.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>

                                <TextField
                                    margin="dense"
                                    id="mobileNumber"
                                    label="Mobile Number"
                                    className={classes.textFieldStyle}
                                    type="number"
                                    size="medium"
                                    onChange={updateMobile}
                                />


                                <TextField
                                    id="faxno2"
                                    label="Fax Number 2"
                                    size="medium"
                                    type="number"
                                    style={{ display: "none" }}
                                    className={classes.margin}
                                    onChange={updateFaxNo2}
                                />

                                <TextField
                                    id="faxno3"
                                    label="Fax Number 3"
                                    size="medium"
                                    type="number"
                                    style={{ display: "none" }}
                                    className={classes.margin}
                                    onChange={updateFaxNo3}
                                />



                            </Grid>

                        </Grid>
                    </form>

                    <Button
                        className={classes.buttonStyle}
                        variant="contained"
                        color="primary"
                        disabled={disableButton}
                        onClick={() => addUserAPI()}>
                        Register User
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    )


};

export default AddUser;