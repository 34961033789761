import React, { useEffect } from "react";
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { getUser, notify, sendSMS, sendMail } from "../../../util/api";
import { useSelector } from "react-redux";
import Notify from "../../Notification/Notify";
import { triggerNotification } from "../../../store/notification/actionCreator";
import { useDispatch } from "react-redux";
// import { resetStore } from "../../../store/resetStore/actionCreator";
import { useHistory, useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 200,
        },
        // flexFlow: "1"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
        maxWidth: 200,
        marginLeft: "0px"
    },
    container: {
        background: "white",
        // marginTop: "5px",
        borderRadius: "15px",
        backgroundColor: "white",
        border: "1px solid #dadada"
    },
    grid: {
        paddingBottom: "25px",
        paddingTop: "25px",
        // backgroundColor: "white",
        borderRadius: "15px"
    },
    patient: {
        minWidth: "100px"
    },
    submit: {
        marginTop: "18px",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto"

    },
    message: {
        marginTop: "18px"
    },
    subject: {
        marginTop: "8px"
    }
}));


const Submit = () => {
    const classes = useStyles();

    const [users, setUsers] = React.useState([])
    const [emailList, setEmailList] = React.useState([]);
    const [smsList, setSmsList] = React.useState([]);
    // const [faxList, setFaxList] = React.useState([]);
    const patientid = useSelector(state => state.patientSearch.patient_id);
    const userid = useSelector(state => state.auth.userId);
    const [toUserId, setToUserId] = React.useState('');
    const [data, setData] = React.useState({
        patient_id: patientid,
        slno: patientid,
        smsto: '',
        selectEmail: '',
        selectSms: '',
        fax: '',
        subject: `Record : ${patientid}`,
        message: `Record : ${patientid}`
    })
    const dispatch = useDispatch();
    const history = useHistory();
    const { url } = useRouteMatch();

    const handleChangeEmail = (event) => {
        setData({
            ...data,
            selectEmail: event.target.value

        })
    };

    const handleChangeSMS = (event) => {
        setData({
            ...data,
            selectSms: event.target.value
        })
    }

    // const handleChangeFax = (event) => {
    //     setData({
    //         ...data,
    //         fax: event.target.value
    //     })
    // }
    const handleChangeMessage = (event) => {
        setData({
            ...data,
            message: event.target.value
        })
    }

    const handleChangeUsers = (event) => {
        setData({
            ...data,
            smsto: event.target.value.userName
        })
        setSmsList([event.target.value.mobile_number]);
        setEmailList([event.target.value.emailAddress]);
        // setFaxList([event.target.value.fax_no1, event.target.value.fax_no2, event.target.value.fax_no3])
        setToUserId(event.target.value.user_id);
        if (event.target.value === "") {
            setData({
                ...data,
                smsto: ""
            })
        }
    }


    useEffect(() => {
        getUser().then(response => {
            if (response.status === 200 && response.data) {

                let pharmacistList = [];

                let data = response.data;

                data.forEach((u) => {
                    if (u.user_type_id === 2) {
                        pharmacistList.push(u);
                    }
                })

                setUsers(pharmacistList)
            }
        })
    }, [])


    const submit = () => {

        if (!toUserId) {
            let notify = {
                isShown: true,
                message: 'Please select user',
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (!data.selectSms) {
            let notify = {
                isShown: true,
                message: 'Please select SMS No',
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (!data.selectEmail) {
            let notify = {
                isShown: true,
                message: 'Please select Email Address',
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (!data.message) {
            let notify = {
                isShown: true,
                message: 'Please Enter Message',
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }


        var comm = {
            patient_id: data.patient_id,
            to_user_id: toUserId,
            from_user_id: userid,
            sms_number: data.selectSms,
            email_address: data.selectEmail,
            fax_number: data.fax,
            message: data.message,
            message_type: 40,
            messag_status: 1
        }
        notify(comm).then(res => {

            if (res.status === 201) {
                let notify = {
                    isShown: true,
                    message: `Notification Sent to ${data.smsto} successfully`,
                    severity: "success"
                };
                dispatch(triggerNotification(notify));

                let data1 = {
                    sms_to: data.selectSms,
                    message: data.message
                }
                sendSMS(data1).then(res1 => {
                    if (res1.status === 200) {
                    }
                })
                let data2 = {
                    emailTO: data.selectEmail,
                    subject: data.subject,
                    message: data.message
                }
                sendMail(data2).then(res2 => {
                    if (res2.status === 200) {
                    }
                })


                let newUrl = url.replace(/.$/, "1");
                history.push(newUrl)
            }
        })


    }

    return (
        <>
            <Typography variant="h6" gutterBottom>
                <b>SUBMIT</b>
            </Typography>
            <Container className={classes.container} fixed>
                <Grid container >
                    <Grid item xs={4}>

                    </Grid>
                    <Grid item xs={4}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-select-user">Select User</InputLabel>
                            <Select
                                labelId="select-user"
                                id="select-user"
                                // value={users.use}
                                onChange={handleChangeUsers}
                            >
                                <MenuItem aria-label="None" value=""> None </MenuItem>
                                {users?.map((user) => (<MenuItem value={user} key={user.user_id} > {user.userName} </MenuItem>))}
                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>
                <Grid container className={classes.grid} >
                    <Grid item xs={2}>

                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            id="Patient Id"
                            label="Patient Id"
                            value={data.patient_id}
                            // onChange={handleChangePatient}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <TextField
                            id="slno"
                            label="SLNO"
                            value={data.slno}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <TextField
                            id="sms"
                            label="SMS TO"
                            value={data.smsto}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-select-email-id">Select Email Id</InputLabel>
                            <Select
                                labelId="select-email-id"
                                id="select-email-id"
                                value={data.selectEmail}
                                onChange={handleChangeEmail}
                            >
                                <MenuItem aria-label="None" value=""> None </MenuItem>
                                {emailList?.map((email) => (<MenuItem value={email} key={email} > {email} </MenuItem>))}
                            </Select>
                        </FormControl>


                    </Grid>
                    <Grid item xs={4}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-smsno">Select SMS No</InputLabel>
                            <Select
                                labelId="demo-smsno-label"
                                id="select_smsno"
                                value={data.selectSms}
                                onChange={handleChangeSMS}
                            >
                                <MenuItem aria-label="None" value=""> None </MenuItem>
                                {smsList?.map((sms) => (<MenuItem value={sms} key={sms} > {sms} </MenuItem>))}
                            </Select>
                        </FormControl>
                        {/* <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-smsno">Select Fax No</InputLabel>
                            <Select
                                labelId="demo-fax-label"
                                id="select_fax"
                                value={data.fax}
                                onChange={handleChangeFax}
                            >   
                                 <MenuItem aria-label="None" value=""> None </MenuItem>
                                {faxList?.map((fax) => (<MenuItem value={fax} key={fax} > {fax} </MenuItem>))}
                            </Select>
                        </FormControl> */}
                        <TextField
                            id="standard-read-only-input"
                            label="Subject for the Email"
                            value={data.subject}
                            className={classes.subject}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <TextField
                            id="standard-multiline-static"
                            label="Message"
                            multiline
                            value={data.message}
                            rows={2}
                            onChange={handleChangeMessage}
                            className={classes.message}
                        // InputProps={{
                        //     readOnly: true,
                        // }}
                        />

                    </Grid>

                    <Grid item xs={2}>

                    </Grid>
                    <div className={classes.submit} >
                        <Button variant="contained" onClick={() => submit()} color="primary">
                            Send
                        </Button>
                    </div>
                </Grid>


            </Container>
            <Notify />
        </>
    )

}

export default Submit;