import React, { useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TablePaginationAction from "../../common/TablePaginationAction";
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from "@material-ui/core/styles";
import Skeleton from '@material-ui/lab/Skeleton';
// import RightArrowSVG from "../../../images/RightArrow.svg";
// import Icon from "@material-ui/core/Icon";
import { useSelector, useDispatch } from "react-redux";
import Paper from '@material-ui/core/Paper';
import { triggerNotification } from "../../../store/notification/actionCreator";
import { addPatientAPI, addPatientDosageAPI, updatePatient, updatePatientDrugDosage, makeFileUploadApi, getDrugs, addDrug } from "../../../util/api";
import Button from '@material-ui/core/Button';
import Notify from "../../Notification/Notify";
import { setPatientId, editPatient } from "../../../store/patientSearch/actionCreator";
import { resetDosage } from "../../../store/drugDosage/actionCreator";
import { useHistory, useRouteMatch } from 'react-router-dom';
// import DeleteSvg from "../../../images/Delete.svg";
// import { editDosage } from "../../../store/drugDosage/actionCreator";

const StyledTableCell = withStyles((theme) => ({
    root: {
        padding: "12px",
        borderBottom: "none",
        textAlign: "center"
    },
    head: {
        backgroundColor: theme.palette.header,
        color: theme.palette.common.white,
        fontWeight: "bold"
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        border: "1px solid #dadada",
        borderRadius: "15px",
        marginBottom: "10px",
        padding: "10px"
    },
    container: {
        background: "white",
        marginTop: "5px",
        borderRadius: "15px"
    },
    tableRoot: {
        borderRadius: "15px",
    },
    tableContainer: {
        borderRadius: "15px"
    },
    grid: {
        paddingBottom: "5px",
        paddingTop: "5px"
    },
    button: {
        textAlign: 'center',
        paddingBottom: "20px",
        paddingTop: "40px"
    },
    lable: {
        fontSize: "18px"
    },
    tableTitle: {
        fontWeight: "500"
    },
    file: {
        marginTop: "25px"
    }
}));





export const Finish = () => {

    const classes = useStyles();
    const dosageData = useSelector(state => state.drugDosage.drugDosageList);
    const selectedPatient = useSelector(state => state.patientSearch.filterPatient)
    const existingPatientId = useSelector(state => state.patientSearch.filterPatient)
    
    // const [patientId, setPatientId] = useState('')
    // const pid = useSelector(state => state.patientSearch.selectedPatient)
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const history = useHistory();
    const { url } = useRouteMatch();
    const auth = useSelector(state => state.auth);
    const [disableSubmit, setDisableSubmit] = useState(false);


    useEffect(() => {


        if (selectedPatient !== '') {

            setLoading(false);
        }


        // eslint-disable-next-line 
    }, []);



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    function getAge(dobi) {
        const birthDate = new Date(dobi);
        const difference = Date.now() - birthDate.getTime();
        const age = new Date(difference);
        return Math.abs(age.getUTCFullYear() - 1970);
    }

    const editPatientInfo = () => {
        dispatch(editPatient(selectedPatient))
        let newUrl = url.replace(/.$/, "2");
        history.push(newUrl)
    }

    const editPatientDosage = () => {
        let newUrl = url.replace(/.$/, "3");
        history.push(newUrl)
    }
    // function ChangeFormateDate(oldDate) {
    //     return oldDate.toString().split("/").reverse().join("/");
    // }

    const checkNewDrugs = () => {
        // if (selectedPatient.pccPatientId) {
        var array1 = []
        var array2 = []
        var array3 = []
        dosageData.map(d => {

            return array1.push(d.dgname)

        })

        array1 = array1.filter(function (item, index, inputArray) {
            return inputArray.indexOf(item) === index;
        });

        getDrugs().then(resDrugs => {
            if (resDrugs.status === 200 && resDrugs.data) {
                resDrugs.data.map(drug => {
                    return array2.push(drug.gname)
                })


                array1.map(d => {

                    if (array2.includes(d)) {
                        // eslint-disable-next-line
                        return
                    } else {
                        // eslint-disable-next-line
                        return array3.push(d)

                    }
                })
                // console.log("new drugs", array3);
                // eslint-disable-next-line
                array3.map(v => {

                    var newDrug = {
                        bname: '',
                        gname: v,
                        cdrug: 0,

                    }

                    addDrug(newDrug).then(response => {
                        // console.log(response);
                    })

                })

            }
        });

        // }


    }

    const submit = () => {
        // let formData = new FormData(); 
        // formData.append("file",file)
        // let patientId = 
        // makeFileUploadApi(formData, patientId).then(res1 => {
        //     console.log("file", res1);
        // })

        let notify = {
            isShown: true,
            message: "Please wait until the Process Completed successfully",
            severity: "success"
        };
        dispatch(triggerNotification(notify));

        setDisableSubmit(true)

        checkNewDrugs();

        var newPatient = {
            first_name: selectedPatient.first_name,
            last_name: selectedPatient.last_name,
            middle_name: selectedPatient.middle_name,
            // // age: selectedPatient.age,
            age: getAge(selectedPatient.birth_date),
            birth_date: selectedPatient.birth_date,
            facility_id: selectedPatient.facility_id || 1,
            gender: selectedPatient.gender,
            height: selectedPatient.height,
            weight: selectedPatient.weight,
            systolic: selectedPatient.systolic,
            kidneyfx: `${selectedPatient.kidneyfx}-${selectedPatient.kidneyFxNumber}`,
            life_expectancy: selectedPatient.life_expectancy,
            diastolic: selectedPatient.diastolic,
            hospice: selectedPatient.hospice,
            phq9: selectedPatient.phq9,
            bims: selectedPatient.bims,
            bmi: selectedPatient.bmi,
            medcond: selectedPatient.medcond,
            // medcond: selectedPatient?.medcond.map(a => a).join(),
            pcc_patientid: selectedPatient.pccPatientId,

            createdatetime: selectedPatient.createdatetime || new Date(),
            updatedatetime: new Date(),
            lastaccessedby: selectedPatient.lastaccessedby || auth.roles[0],
            createdby: selectedPatient.createdby || auth.roles[0],
            updatedby: auth.roles[0],

            // status 10
            cur_status: 10

        }
        // addPatientAPI(newPatient).then(response => {
        //     console.log(response);
        //     if (response.status === 201) {

        if (existingPatientId.patient_id) {
            // var id = dosageData.map(v => ({ ...v, patient_id: existingPatientId.patient_id }))
            // dispatch(setPatientId(existingPatientId.patient_id))
            // addPatientDosageAPI(id).then(response => {
            //     console.log(response);
            //     if (response.status === 201) {
            //         let notify = {
            //             isShown: true,
            //             message: "Existing Patient Dosage updated successfully",
            //             severity: "success"
            //         };
            //         dispatch(triggerNotification(notify));
            //         let newUrl = url.replace(/.$/, "5");
            //         history.push(newUrl)
            //     }
            // })
            var id = dosageData.map(v => ({ ...v, patient_id: existingPatientId.patient_id }))
            dispatch(setPatientId(existingPatientId.patient_id))
            var patientId = {
                patient_id: existingPatientId.patient_id,
                isactive: "1"
            }
            var payload = { ...newPatient, ...patientId }

            if (file !== null) {

                var formData = new FormData();
                formData.append("file", file)
                formData.append("patientid", existingPatientId.patient_id)
                makeFileUploadApi(formData).catch(error => {
                    let notify = {
                        isShown: true,
                        message: "File not upload successfully",
                        severity: "warning"
                    };
                    dispatch(triggerNotification(notify));
                })
            }

            // console.log(payload);
            updatePatient(payload).then(res => {
                if (res.status === 200) {
                    updatePatientDrugDosage(id).then(response => {
                        // console.log(response);
                        if (response.status === 200) {
                            let notify = {
                                isShown: true,
                                message: "Existing Patient Dosage updated successfully",
                                severity: "success"
                            };
                            dispatch(triggerNotification(notify));
                            dispatch(resetDosage());
                            setDisableSubmit(false)
                            let newUrl = url.replace(/.$/, "5");
                            history.push(newUrl)
                        }
                    }).catch(error => {
                        let notify = {
                            isShown: true,
                            message: "Something went wrong while updating patient Medication",
                            severity: "warning"
                        };
                        dispatch(triggerNotification(notify));
                    })
                }
            }).catch(error => {
                let notify = {
                    isShown: true,
                    message: "Something went wrong while updating patient",
                    severity: "warning"
                };
                dispatch(triggerNotification(notify));
                setDisableSubmit(false)
            })
        } else {
            addPatientAPI(newPatient).then(response => {
                // console.log(response);
                if (response.status === 201) {
                    // let formFile = new FormData();
                    // formFile.append("file", file)
                    // let pId = response.data.patient_id
                    // var payload = {
                    //     file: formFile,
                    //     patientid: pId
                    // }
                    // makeFileUploadApi(payload).then(res1 => {
                    //     console.log("file", res1);
                    // })
                    if (file !== null) {
                        var formData = new FormData();
                        formData.append("file", file)
                        formData.append("patientid", response.data.patient_id)
                        makeFileUploadApi(formData).catch(error => {
                            let notify = {
                                isShown: true,
                                message: "File not upload successfully",
                                severity: "warning"
                            };
                            dispatch(triggerNotification(notify));
                        })
                    }




                    var id = dosageData.map(v => ({ ...v, patient_id: response.data.patient_id }))
                    dispatch(setPatientId(response.data.patient_id))
                    addPatientDosageAPI(id).then(response => {
                        // console.log(response);
                        if (response.status === 201) {
                            let notify = {
                                isShown: true,
                                message: "New Patient with new Dosage added successfully",
                                severity: "success"
                            };
                            dispatch(triggerNotification(notify));
                            dispatch(resetDosage());
                            setDisableSubmit(false)
                            let newUrl = url.replace(/.$/, "5");
                            history.push(newUrl)
                        }
                    }).catch(error => {
                        let notify = {
                            isShown: true,
                            message: "Something went wrong while adding patient Medication",
                            severity: "warning"
                        };
                        dispatch(triggerNotification(notify));
                        setDisableSubmit(false)
                    })

                }

            }).catch(error => {
                let notify = {
                    isShown: true,
                    message: "Something went wrong while adding patient",
                    severity: "warning"
                };
                dispatch(triggerNotification(notify));
                setDisableSubmit(false)
            })
        }


    }


    const formatDate = (stopdate) => {
        if (stopdate) {
            var date = new Date(stopdate);
            if (!isNaN(date.getTime())) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                return [month, day, year].join('-').toString();
            }
        } else {
            return "";
        }
    }

    const [file, setFile] = useState(null)
    const handleFileChange = (event) => {
        setFile(event.target.files[0])

    }

    const getMedConds = () => {
        try {

            // here first we have to split by "," and saperate
            // selectedPatient.medcond.split(',Drug Allergy-')
            // var split = selectedPatient.medcond.split(',Drug Allergy-')
            // var medList = split[0];
            // var allergyList = split[1];
            var medList = [];

            if (selectedPatient.medcond.includes(',')) {
                let split = selectedPatient.medcond.split(',');

                if (split.length !== 0) {

                    for (let index = 0; index < split.length; index++) {

                        if (split[index].includes('Drug Allergy-')) {

                            // let s = split[index].split("Drug Allergy-")
                            // allergyList.push(s[1])
                        } else {
                            medList.push(split[index])
                        }

                    }
                }
            }


        }
        catch {
            medList = [];
            // allergyList = [];
        }
        return medList.length !== 0 ? medList.join(', ') : ''
    }

    const getAllergy = () => {
        try {
            // selectedPatient.medcond.split(',Drug Allergy-')
            // var split = selectedPatient.medcond.split(',Drug Allergy-')
            // // var medList = split[0];
            // var allergyList = split[1];

            var allergyList = [];

            if (selectedPatient.medcond.includes(',')) {
                let split = selectedPatient.medcond.split(',');

                if (split.length !== 0) {

                    for (let index = 0; index < split.length; index++) {

                        if (split[index].includes('Drug Allergy-')) {

                            let s = split[index].split("Drug Allergy-")
                            allergyList.push(s[1])
                        }

                    }
                }
            }
        }
        catch {
            // medList = [];
            allergyList = [];
        }
        return allergyList.length !== 0 ? allergyList.join(", ") : ''
    }


    const renderHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Medication</b>

                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Strength</b>

                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Units</b>

                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Admin Qty</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Dosage Form</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Route</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Frequency</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>PRN</b>
                        </Typography>
                    </StyledTableCell>
                    {/* <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Allergy</b>
                        </Typography>
                    </StyledTableCell> */}
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Stop Date</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Additional Info</b>
                        </Typography>
                    </StyledTableCell>
                    {/* <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Action</b>
                        </Typography>
                    </StyledTableCell> */}

                </TableRow>
            </TableHead>
        )
    }

    const renderContent = () => {
        return (
            <TableBody>
                {(
                    rowsPerPage > 0
                        ? dosageData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : dosageData

                ).map((data, index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell >
                            <Typography variant="caption" className={classes.customtablecell} noWrap>
                                <b className={classes.tableTitle}>{data?.dgname}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell >
                            <Typography variant="caption" className={classes.customtablecell} noWrap>
                                {/* <b className={classes.tableTitle}>{data.strength}</b> */}
                                <b className={classes.tableTitle}>{data?.dosg}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell >
                            <Typography variant="caption" className={classes.customtablecell} noWrap>
                                {/* <b className={classes.tableTitle}>{data.units}</b> */}
                                <b className={classes.tableTitle}>{data?.dosgtype}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell >
                            <Typography variant="caption" className={classes.customtablecell} noWrap>
                                {/* <b className={classes.tableTitle}>{data.adminQty}</b> */}
                                <b className={classes.tableTitle}>{data?.times}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell >
                            <Typography variant="caption" className={classes.customtablecell} noWrap>
                                {/* <b className={classes.tableTitle}>{data.dosageForm}</b> */}
                                <b className={classes.tableTitle}>{data?.dsgform}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell >
                            <Typography variant="caption" className={classes.customtablecell} noWrap>
                                {/* <b className={classes.tableTitle}>{data.route}</b> */}
                                <b className={classes.tableTitle}>{data?.route}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell >
                            <Typography variant="caption" className={classes.customtablecell} noWrap>
                                {/* <b className={classes.tableTitle}>{data.frequency}</b> */}
                                <b className={classes.tableTitle}>{data?.days}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell >
                            <Typography variant="caption" className={classes.customtablecell} noWrap>
                                {/* <b className={classes.tableTitle}>{data.prn}</b> */}
                                <b className={classes.tableTitle}>{data?.prn}</b>
                            </Typography>
                        </StyledTableCell>
                        {/* <StyledTableCell >
                            <Typography variant="caption" className={classes.customtablecell} noWrap>
                                <b className={classes.tableTitle}>{data?.allergy}</b>
                            </Typography>
                        </StyledTableCell> */}
                        <StyledTableCell >
                            <Typography variant="caption" className={classes.customtablecell} noWrap>
                                {/* <b className={classes.tableTitle}>{data.stopDate}</b> */}
                                <b className={classes.tableTitle}>{formatDate(data?.stpdate)}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell >
                            <Typography variant="caption" className={classes.customtablecell} noWrap>
                                {/* <b className={classes.tableTitle}>{data.aditionalInfo}</b> */}
                                <b className={classes.tableTitle}>{data?.ainfo}</b>
                            </Typography>
                        </StyledTableCell>

                    </StyledTableRow>
                ))}
            </TableBody>
        )
    }


    return (
        <>
            <Typography variant="h6" gutterBottom>
                <b>REVIEW</b>
            </Typography>
            <Container className={classes.container} fixed>
                <div className={classes.root}>
                    <Grid container className={classes.grid} >
                        <Grid item xs={4}>
                            <Typography variant="h6" className={classes.lable} gutterBottom>
                                First Name : {selectedPatient.first_name}
                            </Typography>
                            <Typography variant="h6" className={classes.lable} gutterBottom>
                                Middle Name : {selectedPatient.middle_name}
                            </Typography>
                            <Typography variant="h6" className={classes.lable} gutterBottom>
                                Last Name : {selectedPatient.last_name}
                            </Typography>
                            <Typography variant="h6" className={classes.lable} gutterBottom>
                                Date of Birth : {formatDate(selectedPatient.birth_date)}
                            </Typography>
                            <Typography variant="h6" className={classes.lable} gutterBottom>
                                Age : {getAge(selectedPatient.birth_date)}
                            </Typography>
                            <Typography variant="h6" className={classes.lable} gutterBottom>
                                {/* Gender : {selectedPatient.gender === 1 ? "Male" : selectedPatient.gender === 2 ? "Female" : "other"} */}
                                Gender : {selectedPatient.gender === "1" ? "Male" : selectedPatient.gender === "2" ? "Female" : "other"}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="h6" className={classes.lable} gutterBottom>
                                Weight : {selectedPatient.weight}
                            </Typography>
                            <Typography variant="h6" className={classes.lable} gutterBottom>
                                Height : {selectedPatient.height}
                            </Typography>
                            <Typography variant="h6" className={classes.lable} gutterBottom>
                                Blood Pressure
                            </Typography>
                            <Typography variant="h6" className={classes.lable} gutterBottom>
                                Sys : {selectedPatient.systolic}
                            </Typography>
                            <Typography variant="h6" className={classes.lable} gutterBottom>
                                Dia : {selectedPatient.diastolic}
                            </Typography>
                            {/* <Typography variant="h6" className={classes.lable} gutterBottom>
                                Medication : {
                                // selectedPatient.medcond.toString()
                                getMedConds()
                                }
                            </Typography> */}

                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="h6" className={classes.lable} gutterBottom>
                                PHQ9 : {selectedPatient.phq9}
                            </Typography>
                            <Typography variant="h6" className={classes.lable} gutterBottom>
                                BIMS : {selectedPatient.bmi}
                            </Typography>
                            <Typography variant="h6" className={classes.lable} gutterBottom>
                                {/* Kidney Fx : {selectedPatient.kidneyfx === "0" ? "Creatine" : selectedPatient.kidneyfx === "1" ? "GFR" : selectedPatient.kidneyFxNumber} */}
                                Kidney Fx : {selectedPatient.kidneyfx}-{selectedPatient.kidneyFxNumber}
                            </Typography>
                            <Typography variant="h6" className={classes.lable} gutterBottom>
                                Hospice : {selectedPatient.hospice === 'Y' ? 'Yes' : 'No'}
                            </Typography>
                            <Typography variant="h6" className={classes.lable} gutterBottom>
                                Life Expectancy {'>'} 12 months : {selectedPatient.life_expectancy === 'Y' ? 'Yes' : 'No'}
                            </Typography>

                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" className={classes.lable} gutterBottom>
                                Medical Condition : {
                                    // selectedPatient.medcond.toString()
                                    getMedConds()
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" className={classes.lable} gutterBottom>
                                Allergy : {
                                    // selectedPatient.medcond.toString()
                                    getAllergy()
                                }
                            </Typography>
                        </Grid>

                    </Grid>
                </div>

                {
                    loading ?
                        <div>
                            <Skeleton className={classes.skeletonStyle}
                                variant="rect"
                                animation="wave"
                                height={150} />
                        </div>
                        :
                        <Paper className={classes.tableRoot}>
                            <TableContainer classes={{
                                root: classes.tableContainer
                            }}>
                                <Table stickyHeader aria-label="sticky table">
                                    {renderHeader()}
                                    {renderContent()}
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[5]}
                                                colSpan={11}
                                                count={dosageData.length}
                                                rowsPerPage={5}
                                                page={page}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationAction}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Paper>
                }

                <div className={classes.file}>
                    <Grid container className={classes.grid} >
                        <Grid item xs={4}></Grid>
                        <Grid item xs={3}>
                            <Typography variant="h6" className={classes.lable} gutterBottom>
                                Select Admission File
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <input type="file" disabled={disableSubmit} accept="image/*,.pdf" onChange={handleFileChange} />
                        </Grid>
                    </Grid>
                </div>



                <div className={classes.button} >
                    <Button variant="contained" disabled={disableSubmit} style={{ marginRight: "20px" }} onClick={() => editPatientInfo()} color="primary">
                        edit Patient Info
                    </Button>
                    <Button variant="contained" disabled={disableSubmit} style={{ marginRight: "20px" }} onClick={() => editPatientDosage()} color="primary">
                        edit Patient Vitals
                    </Button>

                    {/* <input type="file" accept="image/*,.pdf" style={{ marginLeft: "10px", marginRight: "10px" }} onChange={handleFileChange} /> */}
                    <Button variant="contained" disabled={disableSubmit} onClick={() => submit()} color="primary">
                        Next
                    </Button>
                </div>

            </Container>
            <Notify />
        </>
    )
}

export default Finish;