import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
// import Print from "@material-ui/icons/Print";
// import SearchSVG from "../../../images/Search.svg";
import AddInfoSVG from "../../../images/edit-svg.svg";
import Review from "../../../images/review.svg";
import Notify from "../../../images/new-message.svg";
import DrugRefillSVG from "../../../images/refill.svg";
import searchPatients from "../../../images/searchPatient.svg";
import Icon from "@material-ui/core/Icon";
import SearchPatient from "./SearchPatient"
import { Redirect, useParams } from "react-router-dom";
import AddPatientDetail from "./AddPatientDetails";
import AddDrugDosage from "./AddDrugDosage";
import Finish from "./finish";
import Submit from "./Submit";

const ColorlibConnector = withStyles({
    root: {
        backgroundColor: "none"
    },
    alternativeLabel: {
        top: 22
    },
    active: {
        "& $line": {
            // backgroundColor: "#072d5e"
            backgroundColor:"#36485c"
        }
    },
    completed: {
        "& $line": {
            // backgroundColor: "#072d5e"
            backgroundColor:"#36485c"
        }
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: "#888",
        borderRadius: 1
    }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: "#888",
        zIndex: 1,
        color: "#fff",
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
    },
    active: {
        // backgroundImage: "linear-gradient(to right, #8e0e00, #1f1c18); "
        // background: "#072d5e"
        backgroundColor:"#36485c"
    },
    completed: {
        // backgroundImage: "linear-gradient(to right, #8e0e00, #1f1c18);"
        // background: "#072d5e"
        backgroundColor:"#36485c"
    },
    searchSVG: {
        height: "1.1em"
    }
});

const ColorlibStepIcon = props => {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <Icon classes={{
            root: classes.searchSVG
        }}>
            <img alt="SearchIcon" src={searchPatients} height={24} width={24} />
        </Icon>,
        2: <Icon classes={{
            root: classes.searchSVG
        }}>
            <img alt="SearchIcon" src={AddInfoSVG} height={24} width={24} />
        </Icon>,
        3: <Icon classes={{
            root: classes.searchSVG
        }}>
            <img alt="SearchIcon" src={DrugRefillSVG} height={24} width={24} />
        </Icon>,
        4: <Icon classes={{
            root: classes.searchSVG
        }}>
            <img alt="SearchIcon" src={Review} height={24} width={24} />
        </Icon>,
        5: <Icon classes={{
            root: classes.searchSVG
        }}>
            <img alt="SearchIcon" src={Notify} height={24} width={24} />
        </Icon>,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
};

const getSteps = () => {
    return ["Search", "Add Info", "Add Dosage", "Review", "Notify"];
}

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        width: "100vw",
        // backgroundColor: "#E7EEF8",
    },
    stepperRoot: {
        // backgroundColor: "#E7EEF8",
        padding: "10px"
    },
    patientContainer: {
        paddingLeft: "25%",
        paddingRight: "10%",
        width: "100vw",
        paddingBottom: "5px"
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    notFoundDiv: {
        top: "0vh"
    },
    stepperLabel: {
        marginTop: "10px",
        fontFamily: 'Open Sans'

    }
}));

const AddPatient = () => {
    const classes = useStyles();
    const steps = getSteps();
    const { stepId } = useParams();

    const renderTask = () => {
        switch (stepId - 1) {
            case 0:
                return < SearchPatient />;
            case 1:
                return < AddPatientDetail />;
            case 2:
                return < AddDrugDosage />;
            case 3:
                return < Finish />;
            case 4:
                return < Submit />;
            default:
                return <Redirect to="/404/notfound" />;
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.patientContainer}>
                <Stepper
                    alternativeLabel
                    activeStep={stepId - 1}
                    connector={<ColorlibConnector />}
                    classes={{
                        root: classes.stepperRoot
                    }}
                >
                    {steps.map(label => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>{renderTask()}</div>
            </div>
        </div>
    )
}

export default AddPatient;