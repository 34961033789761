import {ADD_RULE, RESET_RULES, GET_RULES} from "./actionType";


export function addNewRule(rule) {
    return{
        type: ADD_RULE,
        payload: rule
    }
}

export function resetRules() {
    return{
        type: RESET_RULES
    }
}

export function getRules() {
    return{
        type : GET_RULES
    }
}