import React, {useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import { closeDialog } from "../../../../store/dialog/actionCreator";
import TextField from '@material-ui/core/TextField';
import { updateAllergy } from "../../../../util/api";
import Notify from "../../../Notification/Notify";
import { triggerNotification } from "../../../../store/notification/actionCreator";
import AllergySVG from "../../../../images/Allergy.svg";
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { updateData } from "../../../../store/tableData/actionCreator";
import { dialogType } from "../../../../util/constants/dialogConstants";
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const allergyStyles = makeStyles(theme => ({
    root: {
        width: "20vw"
    },
    tokenFileNameList: {
        fontSize: "1rem"
    },
    textroot: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    dialogTitle: {
        height: "20vh",
        width: "30vw",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FF6E4E",
        color: "white"
    },
    dialogContent: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    },
    checkBoxStyle: {
        marginTop: "16px"
    },
    buttonStyle: {
        marginTop: "16px"
    },
    textFieldStyle: {
        marginTop: "16px"
    },
    titleContent: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around"
    },
    closeButton: {
        position: 'absolute',
        right: "5px",
        top: "5px",
        color: "#FFFFFF",
    }
}));

export const EditAllergy = () => {

    const classes = allergyStyles();
    const [status, setStatus] = useState(false);
    const [disable, setDisable] = useState(false);
    const [Allergy, setAllergy] = useState({
        agName: "",

    })
    const dialog = useSelector(state => state.dialog);
    const allergyData = useSelector(state => state.tableData.tableDataList)
    const dispatch = useDispatch();

    const descriptionElementRef = React.useRef(null);
    useEffect(() => {
        var open = dialog.open;
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
        if (dialog.data !== {}) {
            setAllergy({
                agName: dialog.data.allergy_name,
                allergy_id: dialog.data.allergy_id
            })
            dialog.data.isactive === 1 ? setStatus(true) : setStatus(false)
        }
        // eslint-disable-next-line
    }, [dialog.open]);

    const handleStatusCheck = (event) => {
        setStatus(event.target.checked);
    };
    
    const handleClose = () => {
        dispatch(closeDialog());

    };


    const editAllergyAPI = () => {

        if (Allergy.agName === "") {


            let notify = {
                isShown: true,
                message: "Please Enter Value",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (allergyData.find(a => a.allergy_name === Allergy.agName)) {

            let notify = {
                isShown: true,
                message: " Allergy name already exists ",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        setDisable(true)

        var editAllergy = {
            allergy_id: Allergy.allergy_id ||  dialog.data.allergy_id,
            allergy_name: Allergy.agName,
            isactive: status === true ? 1 : 0
        }

        updateAllergy(editAllergy).then(response => {
            if (response.status === 200) {

                let notify = {
                    isShown: true,
                    message: "Allergy Updated successfully",
                    severity: "success"
                };
                dispatch(triggerNotification(notify));  
                setDisable(false)
                dispatch(updateData(editAllergy, "ALLERGY"));             
                handleClose();
            }
        }).catch( e => {

            let notify = {
                isShown: true,
                message: "Something went wrong!!",
                severity: "error"
            };
            dispatch(triggerNotification(notify));
            setDisable(false)
        })
    }

    const updateAllergyName = (event) => {
        setAllergy({
            agName: event.target.value
        })
    }





    return (
        <div>
            <Notify />
            <Dialog
                open={dialog.open && dialog.dialog === dialogType.editAllergy}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    className={classes.dialogTitle}
                    disableTypography>
                    <div className={classes.titleContent}>
                        <Typography variant="h5" className={classes.title} noWrap>
                            Update New Allergy
                        </Typography>
                        <img src={AllergySVG} alt="newdrug" height={120} width={120} />
                        <IconButton color="primary" aria-label="Close" className={classes.closeButton} onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                    <form className={classes.textRoot} noValidate autoComplete="off">


                        <TextField
                            id="standard-basic"
                            label="Allergy Name"
                            size="medium"
                            value={Allergy.agName}
                            className={classes.textFieldStyle}
                            onChange={updateAllergyName}
                        />
                        <br />
                        <br />
                        <FormControlLabel
                            className={classes.checkBoxStyle}
                            control={<Checkbox checked={status} onChange={handleStatusCheck} name="status" />}
                            label="Status"
                        />

                    </form>
                    <Button
                        className={classes.buttonStyle}
                        variant="contained"
                        color="primary"
                        disabled={disable}
                        onClick={() => editAllergyAPI()}>
                        Update Allergy
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    )


};

export default EditAllergy;