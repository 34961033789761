import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import { closeDialog } from "../../../../store/dialog/actionCreator";
import { getCategory, updateDeprescribing } from "../../../../util/api";
import Notify from "../../../Notification/Notify";
import { triggerNotification } from "../../../../store/notification/actionCreator";
import Typography from '@material-ui/core/Typography';
import CategorySVG from "../../../../images/Category.svg";
import { dialogType } from "../../../../util/constants/dialogConstants";
import { updateData } from "../../../../store/tableData/actionCreator";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { IconButton, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from "@material-ui/lab/Autocomplete";

const deprescribingStyles = makeStyles(theme => ({
    root: {
        width: "20vw"
    },
    tokenFileNameList: {
        fontSize: "1rem"
    },
    textroot: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    dialogTitle: {
        height: "20vh",
        width: "30vw",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FF6E4E",
        color: "white"
    },
    dialogContent: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    },
    buttonStyle: {
        marginTop: "30px",
        marginBottom: "20px"
    },
    textFieldStyle: {
        marginTop: "16px",
        margin: "8px"
    },
    titleContent: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around"
    },
    formControl: {
        margin: theme.spacing(1),
        // width: "-webkit-fill-available",
        // maxWidth: "250px",
        width: "300px"
    },
    closeButton: {
        position: 'absolute',
        right: "5px",
        top: "5px",
        color: "#FFFFFF",
    }
}));

export const EditDeprescribing = () => {

    const classes = deprescribingStyles();

    const [genericDrug, setGeneric] = React.useState('');
    const [drugCategory, setDrugCategory] = React.useState('');
    const [subCategory, setSubCategory] = React.useState('');
    const [secondSubCategory, setSecondSubCategory] = React.useState('');

    // const [genericDrugColl, setGenericDrugColl] = React.useState([]);
    // const [drugCategoryColl, setDrugCategoryColl] = React.useState([]);

    const [status, setStatus] = React.useState(false);
    const [disable, setDisable] = React.useState(false);

    const [subCategoryCollection, setSubCategoryCollection] = React.useState([]);
    const [secSubCategoryCollection, setSecSubCategoryCollection] = React.useState([]);
    const [drugCategoryCollection, setDrugCategoryCollection] = React.useState([]);



    React.useEffect(() => {
        // getDrugs().then((response) => {
        //     if (response.status === 200 && response.data) {
        //         setGenericDrugColl(response.data)
        //     }
        // })

    }, [])

    React.useEffect(() => {
        getCategory().then(category => {
            if (category.status === 200 && category.data) {

                // setDrugCategoryColl(category.data)
                let responseData = [];
                let drugColl = [];
                let subCategoryColl = [];
                let secSubCategoryColl = [];
                responseData = category.data;
                // subCategoryColl = category.data;
                // drugColl = category.data;
                // secSubCategoryColl = category.data;

                // eslint-disable-next-line
                responseData.map(d => {

                    if (d.dcgtype === "Drug Category") {
                        drugColl.push(d);
                    } else if (d.dcgtype === "Sub-Category") {
                        subCategoryColl.push(d);
                    } else if (d.dcgtype === "Second Sub-Category") {
                        secSubCategoryColl.push(d);
                    }

                })

                setDrugCategoryCollection(drugColl);
                setSubCategoryCollection(subCategoryColl);
                setSecSubCategoryCollection(secSubCategoryColl);

            }
        })
    }, [])




    const dialog = useSelector(state => state.dialog);
    const dispatch = useDispatch();

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        var open = dialog.open;
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }

        if (dialog.data !== {}) {
            // console.log(dialog.data);
            setGeneric(dialog.data.gname);
            setDrugCategory(dialog.data.dcatname);
            setSubCategory(dialog.data.scatname);
            setSecondSubCategory(dialog.data.sscatname);
            dialog.data.isactive === 1 ? setStatus(true) : setStatus(false);
        }

        // eslint-disable-next-line
    }, [dialog.open]);

    const handleStatusCheck = (event) => {
        setStatus(event.target.checked);
    };

    const handleClose = () => {
        dispatch(closeDialog());
    };


    const handleDrugCategory = (event, value) => {
        setDrugCategory(value);

    }

    const handleSubCategory = (event, value) => {
        setSubCategory(value);
    }

    const handleSecondSubCategory = (event, value) => {
        setSecondSubCategory(value);
    }

    const editDepressAPI = () => {

        if (genericDrug === "") {

            let notify = {
                isShown: true,
                message: "Please Select Generic Drug",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }


        if (drugCategory === "") {

            let notify = {
                isShown: true,
                message: "Please Select Drug Category",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }


        if (subCategory === "") {

            let notify = {
                isShown: true,
                message: "Please Select Sub Category",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (secondSubCategory === "") {

            let notify = {
                isShown: true,
                message: "Please Select Second Sub Category",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        setDisable(true)





        var editDeprescribing = {
            deid: dialog.data.deid,
            gname: genericDrug,
            dcatname: drugCategory,
            scatname: subCategory,
            sscatname: secondSubCategory,
            isactive: status === true ? 1 : 0
        }


        updateDeprescribing(editDeprescribing).then(response => {
            if (response.status === 200) {

                let notify = {
                    isShown: true,
                    message: " Deprescribing Updates successfully",
                    severity: "success"
                };
                setDisable(false)
                dispatch(triggerNotification(notify));
                dispatch(updateData(editDeprescribing, "DEPRESCRIBING"));
                handleClose();
            }
        }).catch((error) => {

            let notify = {
                isShown: true,
                message: "Something went wrong!!",
                severity: "error"
            };
            dispatch(triggerNotification(notify));
            setDisable(false)

        });
    }




    return (
        <div>
            <Notify />
            <Dialog
                open={dialog.open && dialog.dialog === dialogType.editDeprescribing}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    className={classes.dialogTitle}
                    disableTypography>
                    <div className={classes.titleContent}>
                        <Typography variant="h5" className={classes.title} noWrap>
                            Update Deprescribing
                        </Typography>
                        <img src={CategorySVG} alt="newdrug" height={120} width={120} />
                        <IconButton color="primary" aria-label="Close" className={classes.closeButton} onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                    <form className={classes.textRoot} noValidate autoComplete="off">

                        {/* <FormControl className={classes.formControl}>
                            <InputLabel id="generic-simple-select-label" size="medium"  >Generic Drug</InputLabel>
                            <Select
                                labelId="generic-simple-select"
                                id="generic-simple-select"
                                size="medium"
                                value={genericDrug}
                                // onChange={handleGeneric}
                                disabled
                            >
                                {genericDrugColl?.map((data, index) => (<MenuItem value={data.gname} key={index}   > {data.gname} </MenuItem>))}
                                
                            </Select>
                        </FormControl> */}
                        <Autocomplete
                            openOnFocus={true}
                            style={{ width: 300 }}
                            id="generic-simple-select-label"
                            freeSolo
                            value={genericDrug}
                            disabled
                            // onInputChange={handleDrugCategory}
                            // options={drugCategoryCollection.map((option) => option.dcatname)}
                            // groupBy={(option) => option.firstLetter}
                            renderInput={(params) => <TextField variant="standard" {...params} label="Generic Drug" />}
                        />
                        <br />

                        {/* <FormControl className={classes.formControl}>
                            <InputLabel id="drug-category-select-label" size="medium"  >Drug Category</InputLabel>
                            <Select
                                labelId="drug-category-select"
                                id="drug-category-select"
                                size="medium"
                                value={drugCategory}
                                onChange={handleDrugCategory}
                            >
                                {drugCategoryColl?.map((category, index) => category.dcgtype === "Drug Category" ? <MenuItem value={category.dcatname} key={index} > {category.dcatname} </MenuItem> : null)}

                            </Select>
                        </FormControl> */}
                        {/* <Autocomplete
                            openOnFocus={true}
                            id="drug-category-select-label"
                            options={drugCategoryCollection}
                            noOptionsText={"No Options Found"}
                            freeSolo
                            value={drugCategory}    
                            renderTags={() => null}                        
                            // style={{ marginTop: "8px" }}                            
                            onInputChange={handleDrugCategory}
                            // onChange={handleYesQuesChange}                            
                            getOptionLabel={(option) => option.dcatname }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Drug Category"
                                />
                            )}
                        /> */}
                        <Autocomplete
                            openOnFocus={true}
                            style={{ width: 300 }}
                            id="drug-category-select-label"
                            freeSolo
                            value={drugCategory}
                            onInputChange={handleDrugCategory}
                            options={drugCategoryCollection.map((option) => option.dcatname)}
                            groupBy={(option) => option.firstLetter}
                            renderInput={(params) => <TextField variant="standard" {...params} label="Drug Category" />}
                        />
                        <br />

                        {/* <FormControl className={classes.formControl}>
                            <InputLabel id="sub-category-select-label" size="medium"  >Sub Category</InputLabel>
                            <Select
                                labelId="sub-category-select"
                                id="sub-category-select"
                                size="medium"
                                value={subCategory}
                                onChange={handleSubCategory}
                            >
                                {drugCategoryColl?.map((category, index) => category.dcgtype === "Sub-Category" ? <MenuItem value={category.dcatname} key={index} > {category.dcatname} </MenuItem> : null)}
                            </Select>
                        </FormControl> */}
                        <Autocomplete
                            openOnFocus={true}
                            style={{ width: 300 }}
                            id="sub-category"
                            freeSolo
                            value={subCategory}
                            onInputChange={handleSubCategory}
                            options={subCategoryCollection.map((option) => option.dcatname)}
                            groupBy={(option) => option.firstLetter}
                            renderInput={(params) => <TextField variant="standard" {...params} label="Sub Category" />}
                        />
                        <br />

                        <Autocomplete
                            openOnFocus={true}
                            style={{ width: 300 }}
                            id="second-sub-category"
                            freeSolo
                            value={secondSubCategory}
                            onInputChange={handleSecondSubCategory}
                            options={secSubCategoryCollection.map((option) => option.dcatname)}
                            groupBy={(option) => option.firstLetter}
                            renderInput={(params) => <TextField variant="standard" {...params} label="Sub Category" />}
                        />

                        {/* <FormControl className={classes.formControl}>
                            <InputLabel id="second-sub-category-select-label" size="medium"  >Second Sub Category</InputLabel>
                            <Select
                                labelId="second-sub-category-select"
                                id="second-sub-category-select"
                                size="medium"
                                value={secondSubCategory}
                                onChange={handleSecondSubCategory}
                            >
                                {drugCategoryColl?.map((category, index) => category.dcgtype === "Second Sub-Category" ? <MenuItem value={category.dcatname} key={index} > {category.dcatname} </MenuItem> : null)}
                            </Select>
                        </FormControl> */}
                        <br />
                        <FormControlLabel
                            className={classes.checkBoxStyle}
                            control={<Checkbox checked={status} onChange={handleStatusCheck} name="status" />}
                            label="Status"
                        />
                        <br />

                    </form>
                    <Button
                        className={classes.buttonStyle}
                        variant="contained"
                        color="primary"
                        disabled={disable}
                        onClick={() => editDepressAPI()}>
                        Update Deprescribing
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    )


};

export default EditDeprescribing;