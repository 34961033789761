export const dialogType = {
    addDrug: "ADD_DRUG",
    editDrug: "EDIT_DRUG",
    addAllergy:"ADD_ALLERGY",
    editAllergy:"EDIT_ALLERGY",
    addMedCondition:"ADD_MEDCONDITION",
    editMedCondition:"EDIT_MEDCONDITION",
    addCategory:"ADD_CATEGORY",
    editCategory:"EDIT_CATEGORY",
    addUser:"ADD_USER",
    editUser:"EDIT_USER",
    addDeprescribing:"ADD_DEPRESCRIBING",
    editDeprescribing:"EDIT_DEPRESCRIBING",
    addDrugClass:"ADD_DRUGCLASS",
    editDrugClass:"EDIT_DRUGCLASS",
    addFacility:"ADD_FACILITY",
    editFacility:"EDIT_FACILITY",
    addFacilityToUser:"ADD_FACILITY_TO_USER",
    editFacilityToUser : "EDIT_FACILITY_TO_USER"
}