import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
// import InputBase from '@material-ui/core/InputBase';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Button, TextField } from '@material-ui/core';
import { useSelector } from "react-redux";
import { signatureUpload, signin } from '../../../util/api';
import { triggerNotification } from "../../../store/notification/actionCreator";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: "none"
    },
    lightToolBar: {
        background: theme.palette.toolbarLight,
        color: "black",
        paddingRight: "0px",
        paddingLeft: "0px"
    },
    Container: {
        paddingTop: "0.5%",
        paddingLeft: "25%",
        paddingRight: "10%",
        width: "100vw",
        paddingBottom: "0.5%"
    },
    Title: {
        fontSize: "2em",
        fontWeight: "bold"
    },
    Titleroot: {
        flexGrow: 1,
    },
    paper: {
        textAlign: "left",
        padding: "20px",
        borderTop: "1px solid #eeecec",
        marginLeft: "20px"
    },
    button: {
        textAlign: "left",
        marginTop: "2rem"
    },
    button1: {
        textAlign: "right",
        marginTop: "-2rem"
    },
    title: {
        fontSize: "2.5em",
        color: theme.palette.primary.main,
        flexGrow: 1,
        display: 'none',
        fontWeight: "bold",
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },

}))

const Signature = () => {
    const classes = useStyles();
    const user = useSelector(state => state.auth)
    const [file, setFile] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const handleFileChange = (event) => {
        setFile(event.target.files[0])
    }

    const handleChangePassword = (event) => {
        setPassword(event.target.value)
    }

    const submit = () => {
        if (password && user.username) {

            var login = {
                username: user.username,
                password: password
            }
            signin(login).then(res => {
                if (res.status === 200) {
                    const formData = new FormData();
                    formData.append("signaturefile", file)
                    formData.append("phyid", user.userId)

                    signatureUpload(formData).then(res1 => {
                        if (res1.status === 200) {
                            setPassword('');
                            setFile('');

                         
                                let notify = {
                                    isShown: true,
                                    message: "Signature uploaded successfully.",
                                    severity: "success"
                                };
                                dispatch(triggerNotification(notify));
                                
                           
                        }else{
                            let notify = {
                                isShown: true,
                                message: "The signiature upload is failed. Please verify the file.",
                                severity: "error"
                            };
                            dispatch(triggerNotification(notify));
                        }

                    }).catch(err=>{
                     
                            let notify = {
                                isShown: true,
                                message: "The signiature upload is failed.",
                                severity: "error"
                            };
                            dispatch(triggerNotification(notify));
                        
                    })
                    

                }
            })
        }else{
            let notify = {
                isShown: true,
                message: "Please enter password.",
                severity: "warning"
            };
            dispatch(triggerNotification(notify)); 
        }
    }
    return (
        <>
            <div className={classes.root}>
                <div className={classes.Container}>
                    <div className={classes.Titleroot}>
                        <AppBar className={classes.appBar} position="static">
                            <Toolbar className={classes.lightToolBar}>
                                <Typography className={classes.title} variant="h6" noWrap>
                                    Signature Upload
                                </Typography>

                            </Toolbar>
                        </AppBar>
                    </div>
                    {/* <Paper elevation={3} > */}
                    <Grid container>
                        <Grid item xs={3} >
                        </Grid>
                        <Grid item xs={6} >
                            <Paper elevation={3} className={classes.paper} >
                                <Grid container>
                                    <Grid item xs={6} >
                                        <Typography variant="subtitle1" noWrap>
                                            User Id
                                        </Typography>
                                        <Typography variant="subtitle1" noWrap>
                                            User Name
                                        </Typography>
                                        <Typography variant="subtitle1" noWrap>
                                            Selected File Name
                                        </Typography>
                                        <Typography variant="subtitle1" noWrap>
                                            Password
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <Typography variant="subtitle1" noWrap>
                                            :  {user.userId}
                                        </Typography>
                                        <Typography variant="subtitle1" noWrap>
                                            :  {user.username}
                                        </Typography>
                                        <Typography variant="subtitle1" noWrap>
                                            :  {file.name}
                                        </Typography>
                                        <TextField
                                            placeholder="Password to sign upload"
                                            size="medium"
                                            type="password"
                                            value={password}
                                            // className={classes.formControl}
                                            onChange={handleChangePassword}
                                        />
                                    </Grid>
                                </Grid>

                                {/* <input type="file" style={{ marginLeft: "10px", marginRight: "10px" }} onChange={handleFileChange} /> */}
                                <div className={classes.button}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        onChange={handleFileChange}
                                    >
                                        Upload File
                                    <input
                                            type="file"
                                            hidden
                                        />
                                    </Button>

                                </div>
                                <div className={classes.button1}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component="label"
                                        onClick={() => submit()}
                                    >
                                        Submit
                                    </Button>
                                </div>

                            </Paper>
                        </Grid>
                    </Grid>
                    {/* </Paper> */}
                </div>
            </div>

        </>
    )
}

export default Signature;