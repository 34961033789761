import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Fab from '@material-ui/core/Fab';
import AddUser from '../Admin/dialog/AddUser';
import { useDispatch } from "react-redux";
import { openDialog } from "../../../store/dialog/actionCreator";
import AddSVG from "../../../images/Add.svg";
import Icon from "@material-ui/core/Icon";
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import SearchSVG from "../../../images/Search.svg";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditSvg from "../../../images/Edit.svg";
import { getUser, getUserRoles } from "../../../util/api";
import Skeleton from '@material-ui/lab/Skeleton';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationAction from "../../common/TablePaginationAction";
import { addNewData, searchData } from "../../../store/tableData/actionCreator";
import { dialogType } from "../../../util/constants/dialogConstants";
import EditUser from "./dialog/EditUser";


const StyledTableCell = withStyles((theme) => ({
    root: {
        padding: "12px",
        borderBottom: "none ",
        textAlign: "center"
    },
    head: {
        backgroundColor: theme.palette.header,
        color: theme.palette.common.white,
        fontWeight: "bold"
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: "none"
    },
    lightToolBar: {
        background: theme.palette.toolbarLight,
        color: "black",
        paddingRight: "0px",
        paddingLeft: "0px"
    },
    userContainer: {
        paddingTop: "0.5%",
        paddingLeft: "25%",
        paddingRight: "10%",
        width: "100vw",
        paddingBottom: "0.5%"
    },
    userTitle: {
        fontSize: "2em",
        fontWeight: "bold"
    },
    userTitleroot: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        fontSize: "2.5em",
        color: theme.palette.primary.main,
        flexGrow: 1,
        display: 'none',
        fontWeight: "bold",
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        backgroundColor: theme.palette.search,
        position: 'relative',
        borderRadius: "10px",
        // backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: theme.palette.search,
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
            backgroundColor: theme.palette.search,
            borderRadius: "10px",
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    searchSVG: {
        height: "1.1em"
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    tableRoot: {
        borderRadius: "15px"
    },
    table: {
        minWidth: 700,
    },
    fabRoot: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    fabAdd: {
        position: "fixed",
        bottom: "25px",
        right: "25px",
        height: "75px",
        width: "75px",
    },
    addSVG: {
        height: "1.5em",
        width: "1.5em"
    },
    skeletonStyle: {
        marginBottom: "10px",
        borderRadius: "15px"
    },
    tableTitle: {
        fontWeight: "500"
    }
}))

const User = () => {
    const classes = useStyles();
    const [searchText, setSearchText] = useState("")
    const userData = useSelector(state => state.tableData.tableDataList)
    const [loading, setLoading] = useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const dispatch = useDispatch();
    const [roles, setRoles] = useState([]);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        // if (searchText !== '') {
        dispatch(searchData(searchText, userData.tableData, "USER"))
        // }
        // eslint-disable-next-line
    }, [searchText])

    useEffect(() => {
        getUser().then(response => {
            if (response.status === 200) {
                dispatch(addNewData(response.data, response.data, response.data.length))
                setLoading(false)
            }
        });

        getUserRoles().then(res => {
            if (res.status === 200) {
                setRoles(res.data)
            }
        })


        // eslint-disable-next-line
    }, []);


    const renderHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>User ID</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>User Name</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>User Type</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>Email Address</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>Mobile Number</b>
                        </Typography>
                    </StyledTableCell>

                    {/* <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b>SMS Number</b>
                        </Typography>
                    </StyledTableCell> */}
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>Fax </b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>Status</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">

                    </StyledTableCell>
                </TableRow>
            </TableHead>
        )
    }

    const renderMedContent = () => {
        return (
            <TableBody>
                {(rowsPerPage > 0
                    ? userData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : userData
                ).map((user, index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell align="left">
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableTitle}>{user.user_id}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableTitle}>{user.userName}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                {/* { getUserRole(user.user_type_id) } */}
                                {roles.map(r => r.userTypeId === user.user_type_id ? r.userTypeDesc : '')}
                                {/* <b>{user[0]?.roles.userTypeName}</b> */}
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableTitle}>{user.emailAddress}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableTitle}>{user.work_number || user.mobile_number} </b>
                            </Typography>
                        </StyledTableCell>

                        {/* <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                <b>{user.sms_no1}  </b>
                            </Typography>
                        </StyledTableCell> */}
                        <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableTitle}>{user.fax_no1} </b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableTitle}> {user.isactive ? 'Active' : 'Inactive'} </b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Icon classes={{
                                root: classes.addSVG
                            }}>
                                <img alt="add" src={EditSvg} height={24} width={24}
                                    onClick={() => dispatch(openDialog(dialogType.editUser, user))}

                                />
                            </Icon>


                        </StyledTableCell>

                    </StyledTableRow>
                ))}
            </TableBody>
        )
    }

    const filterSearchResults = (event) => {
        var searchText = event.target.value;
        setSearchText(searchText)
    }
    return (
        <div className={classes.root}>
            <div className={classes.userContainer}>
                {/* Display Manage drug row with title and search box */}
                <div className={classes.userTitleroot}>
                    <AppBar className={classes.appBar} position="static">
                        <Toolbar className={classes.lightToolBar}>
                            <Typography className={classes.title} variant="h6" noWrap>
                                Manage Users
                            </Typography>
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <Icon classes={{
                                        root: classes.searchSVG
                                    }}>
                                        <img alt="SearchIcon" src={SearchSVG} height={24} width={24} />
                                    </Icon>
                                </div>
                                <InputBase
                                    placeholder="Search…"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={filterSearchResults}
                                />
                            </div>
                        </Toolbar>
                    </AppBar>
                </div>

                {/* Display content header row */}
                {
                    loading ?
                        <div>
                            <Skeleton className={classes.skeletonStyle}
                                variant="rect"
                                animation="wave"
                                height={400} />
                        </div>
                        :
                        <Paper elevation={4} className={classes.tableRoot}>
                            <TableContainer className={classes.tableRoot}>
                                <Table stickyHeader aria-label="sticky table">
                                    {renderHeader()}
                                    {renderMedContent()}
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[5]}
                                                colSpan={9}
                                                count={userData.length}
                                                rowsPerPage={5}
                                                page={page}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationAction}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Paper>
                }
            </div>

            {/* Display FAB to add new drug */}
            <div className={classes.fabRoot}>
                <Fab color="primary" aria-label="add" className={classes.fabAdd}
                    onClick={() => dispatch(openDialog(dialogType.addUser, {}))}>
                    <Icon classes={{
                        root: classes.addSVG
                    }}>
                        <img alt="add" src={AddSVG} height={24} width={24} />
                    </Icon>
                </Fab>
            </div>
            <AddUser />
            <EditUser />
        </div>
    )


}

export default User;