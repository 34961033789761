import {ADD_RULE, RESET_RULES, GET_RULES} from "./actionType";

export const initialState = {
    drugRuleArray : []
}

const drugRuleReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case ADD_RULE: {
            const { payload } = action;
            const data = payload
           
                const newState = {
                    ...state,
                    drugRuleArray: [...state.drugRuleArray, data]
                }
                return newState
        }
        case RESET_RULES: {
            const newState = {
                drugRuleArray : []
            }
            return newState
        }
        case GET_RULES : {
            return state.drugRuleArray
        }
        default:
            return state
    }
}

export default drugRuleReducer;