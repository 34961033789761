import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import { closeDialog } from "../../../../store/dialog/actionCreator";
import TextField from '@material-ui/core/TextField';
import { addFacility, getFacility, getAllStates } from "../../../../util/api";
import Notify from "../../../Notification/Notify";
import { triggerNotification } from "../../../../store/notification/actionCreator";
import DrugSVG from "../../../../images/Drug.svg";
import Typography from '@material-ui/core/Typography';
import { dialogType } from "../../../../util/constants/dialogConstants";
import { addNewData } from "../../../../store/tableData/actionCreator";
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from "@material-ui/lab/Autocomplete";


const useStyles = makeStyles(theme => ({
    root: {
        width: "20vw"
    },
    tokenFileNameList: {
        fontSize: "1rem"
    },
    textroot: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    dialogTitle: {
        height: "20vh",
        width: "50vw",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FF6E4E",
        color: "white"
    },
    dialogContent: {
        display: "flex",
        width: "50vw",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    },
    checkBoxStyle: {
        marginTop: "16px"
    },
    buttonStyle: {
        marginTop: "16px"
    },
    textFieldStyle: {
        // minWidth: "210px",
        margin: "0px",
        marginTop: "18px",
        marginLeft: "8px"
    },
    titleContent: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around"
    },
    paper: { maxWidth: "50vw" },
    closeButton: {
        position: 'absolute',
        right: "5px",
        top: "5px",
        color: "#FFFFFF",
    }
}));

export const AddFacility = () => {
    const classes = useStyles();
    const dialog = useSelector(state => state.dialog);
    const facilityData = useSelector(state => state.tableData.tableDataList)
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(false);
    const [facility, setFacility] = useState({
        facilityName: '',
        city: '',
        stateId: '',
        postalCode: '',
        phone: '',
        fax: '',
        email: '',
        address1: '',
        address2: ''

    })
    const [statesList, setStatesList] = useState([])

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        var open = dialog.open;
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
            setFacility({
                facilityName: '',
                city: '',
                stateId: '',
                postalCode: '',
                phone: '',
                fax: '',
                email: '',
                address1: '',
                address2: ''
            })
        }
    }, [dialog.open]);


    React.useEffect(() => {

        getAllStates().then(res => {
            if (res.status === 200) {
                setStatesList(res.data)
            }
        })

    }, [])


    const handleClose = () => {
        dispatch(closeDialog());
    };



    const handleFacilityName = (event) => {
        setFacility({ ...facility, facilityName: event.target.value });
    }

    // const handlePostalCode = (event) => {
    //     setFacility({ ...facility, postalCode: event.target.value });
    // }

    const handleEmail = (event) => {
        setFacility({ ...facility, email: event.target.value });
    }

    const handleCity = (event) => {
        setFacility({ ...facility, city: event.target.value });
    }

    const handlePhone = (event) => {
        setFacility({ ...facility, phone: event.target.value });
    }

    const handleAddress1 = (event) => {
        setFacility({ ...facility, address1: event.target.value });
    }

    // const handleAddress2 = (event) => {
    //     setFacility({ ...facility, address2: event.target.value });
    // }

    const handleStateId = (event, value) => {

        setFacility({ ...facility, stateId: value });
    }

    const handleFax = (event) => {
        setFacility({ ...facility, fax: event.target.value });
    }


    const AddFacility = () => {

        if (facility.facilityName === "") {

            let notify = {
                isShown: true,
                message: "Please Enter Facility Name",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (facility.email === "") {

            let notify = {
                isShown: true,
                message: "Please Enter Email Address",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }



        if (facility.city === "") {

            let notify = {
                isShown: true,
                message: "Please Enter City",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (facility.phone === "") {

            let notify = {
                isShown: true,
                message: "Please Enter Phone number",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }
        if (/^\d{10}$/.test(facility.phone)) {

        } else {
            let notify = {
                isShown: true,
                message: "Please Enter Valid Phone number",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        // if (facility.postalCode === "") {

        //     let notify = {
        //         isShown: true,
        //         message: "Please Enter Postal Code",
        //         severity: "warning"
        //     };
        //     dispatch(triggerNotification(notify));
        //     return;
        // }

        if (facility.stateId.state_id) {

            
        }else{
            let notify = {
                isShown: true,
                message: "Please Select State",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (facility.fax === "") {

            let notify = {
                isShown: true,
                message: "Please Enter Fax number",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (facility.address1 === "") {

            let notify = {
                isShown: true,
                message: "Please Enter Address",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (facilityData.find(a => a.facilityName === facility.facilityName)) {

            let notify = {
                isShown: true,
                message: " Facility Name already taken ",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        setDisable(true)


        var newFacility = {
            facilityName: facility.facilityName,
            countryId: 1,
            addressLine1: facility.address1,
            addressLine2: facility.address2,
            postalCode: facility.postalCode,
            phone: facility.phone,
            city: facility.city,
            stateId: facility.stateId.state_id,
            fax: facility.fax,
            emailAddress: facility.email,
            bedCount: 2,
            facilityCode: null
        }
        addFacility(newFacility).then(res => {
            if (res.status === 201) {
                let notify = {
                    isShown: true,
                    message: "New Parameter added successfully",
                    severity: "success"
                };
                dispatch(triggerNotification(notify));
                handleClose();
                setDisable(false)
                setFacility({
                    facilityName: '',
                    city: '',
                    stateId: '',
                    postalCode: '',
                    phone: '',
                    fax: '',
                    email: '',
                    address1: '',
                    address2: ''
                })
                getFacility().then(res1 => {
                    if (res1.status === 200) {
                        dispatch(addNewData(res1.data, res1.data.reverse(), res1.data.length))
                    }
                });
            }
        }).catch(error => {

            setDisable(false)
            if (error.response.status === 400) {
                let notify = {
                    isShown: true,
                    message: "Bad Request",
                    severity: "error"
                };
                dispatch(triggerNotification(notify));
                handleClose();
            }
            if (error.response.status === 500) {
                let notify = {
                    isShown: true,
                    message: "Internal server Error",
                    severity: "error"
                };
                dispatch(triggerNotification(notify));
                handleClose();
            }

        });
    }



    return (
        <div>
            <Notify />
            <Dialog
                open={dialog.open && dialog.dialog === dialogType.addFacility}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                classes={{ paper: classes.paper }}
            >
                <DialogTitle
                    id="add-drug"
                    className={classes.dialogTitle}
                    disableTypography>
                    <div className={classes.titleContent}>
                        <Typography variant="h5" className={classes.title} noWrap>
                            Add New Facility
                        </Typography>
                        <img src={DrugSVG} alt="newdrug" height={120} width={120} />
                        <IconButton color="primary" aria-label="Close" className={classes.closeButton} onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                    <form className={classes.textRoot} noValidate autoComplete="off">
                        <Grid container >
                            <Grid item xs={4}>
                                <TextField id="facility-name" onChange={handleFacilityName} className={classes.textFieldStyle} label="Facility Name" />
                                <TextField id="email" onChange={handleEmail} className={classes.textFieldStyle} label="Email Address" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id="city" onChange={handleCity} className={classes.textFieldStyle} label="City" />
                                <TextField id="phone" onChange={handlePhone} type="number" className={classes.textFieldStyle} label="Phone" />

                            </Grid>
                            <Grid item xs={4} style={{ marginTop: "15px" }}>

                                <Autocomplete
                                    id="combo-box-demo"
                                    options={statesList}
                                    getOptionLabel={(option) => option.state_name}
                                    style={{ width: 200 }}
                                    value={facility.stateId}
                                    onChange={handleStateId}
                                    renderInput={(params) => <TextField {...params} label="State" variant="standard" />}
                                />

                                {/* <br /> */}
                                {/* <TextField id="postal-code" onChange={handlePostalCode} type="number" className={classes.textFieldStyle} label="Postal Code" /> */}
                                {/* <TextField id="state-id" onChange={handleStateId} type="number" className={classes.textFieldStyle} label="State Id" /> */}
                                <TextField id="fax" type="number" onChange={handleFax} className={classes.textFieldStyle} label="Fax" />
                                {/* <TextField id="address2" onChange={handleAddress2} multiline rows={2} className={classes.textFieldStyle} label="Address 2" /> */}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField id="address1" style={{ width: "100%" }} onChange={handleAddress1} multiline rows={3} className={classes.textFieldStyle} label="Address " />
                            </Grid>
                        </Grid>
                    </form>
                    <Button
                        className={classes.buttonStyle}
                        variant="contained"
                        color="primary"
                        disabled={disable}
                        onClick={() => AddFacility()}>
                        ADD Facility
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default AddFacility;