import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import { closeDialog } from "../../../../store/dialog/actionCreator";
import { getAllPendingDeprescribedDrugs, getCategory, addDeprescribing, depdone, getDeprescribing } from "../../../../util/api";
import Notify from "../../../Notification/Notify";
import { triggerNotification } from "../../../../store/notification/actionCreator";
import Typography from '@material-ui/core/Typography';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
import CategorySVG from "../../../../images/Category.svg";
// import { addNewData } from "../../../../store/tableData/actionCreator";
import { dialogType } from "../../../../util/constants/dialogConstants";
import { addNewData } from "../../../../store/tableData/actionCreator";
import { IconButton, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from "@material-ui/lab/Autocomplete";

const deprescribingStyles = makeStyles(theme => ({
    root: {
        width: "20vw"
    },
    tokenFileNameList: {
        fontSize: "1rem"
    },
    textroot: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    dialogTitle: {
        height: "20vh",
        width: "30vw",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FF6E4E",
        color: "white"
    },
    dialogContent: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    },
    buttonStyle: {
        marginTop: "30px",
        marginBottom: "20px"
    },
    textFieldStyle: {
        marginTop: "16px",
        margin: "8px"
    },
    titleContent: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around"
    },
    formControl: {
        margin: theme.spacing(1),
        // width: "-webkit-fill-available",
        // maxWidth: "250px",
        width: "300px"
    },
    closeButton: {
        position: 'absolute',
        right: "5px",
        top: "5px",
        color: "#FFFFFF",
    }
}));

export const AddDeprescribing = () => {

    const classes = deprescribingStyles();

    const [genericDrug, setGenericDrug] = React.useState('');
    const [drugCategory, setDrugCategory] = React.useState('');
    const [subCategory, setSubCategory] = React.useState('');
    const [secondSubCategory, setSecondSubCategory] = React.useState('');
    const [selectedDrugId, setSelectedDrugId] = React.useState('');
    const [disable, setDisable] = React.useState(false);

    const [genericDrugColl, setGenericDrugColl] = React.useState([]);
    // const [drugCategoryColl, setDrugCategoryColl] = React.useState([]);
    
    const [subCategoryCollection, setSubCategoryCollection] = React.useState([]);
    const [secSubCategoryCollection, setSecSubCategoryCollection] = React.useState([]);
    const [drugCategoryCollection, setDrugCategoryCollection] = React.useState([]);



    const hadleGeneric = (event, value) => {
        setGenericDrug(value.gname);
        setSelectedDrugId(value.drug_id);
        
    }

    const handleDrugCategory = (event) => {
        setDrugCategory(event.target.value);
    }

    const handleSubCategory = (event) => {
        setSubCategory(event.target.value);
    }

    const handleSecondSubCategory = (event) => {
        setSecondSubCategory(event.target.value);
    }


    React.useEffect(() => {
        getAllPendingDeprescribedDrugs().then((response) => {
            if (response.status === 200 && response.data) {
                // console.log(response.data.gname);
                setGenericDrugColl(response.data);
            }
        })

    }, [])

    React.useEffect(() => {
        getCategory().then(category => {
            if (category.status === 200 && category.data) {
                // setDrugCategoryColl(category.data)
                let responseData = [];
                let drugColl = [];
                let subCategoryColl = [];
                let secSubCategoryColl = [];
                responseData = category.data;
                // subCategoryColl = category.data;
                // drugColl = category.data;
                // secSubCategoryColl = category.data;

                // eslint-disable-next-line
                responseData.map(d => {

                    if (d.dcgtype === "Drug Category") {
                        drugColl.push(d);
                    }else if(d.dcgtype === "Sub-Category") {
                        subCategoryColl.push(d);
                    }else if(d.dcgtype === "Second Sub-Category"){
                        secSubCategoryColl.push(d);
                    }

                })

                setDrugCategoryCollection(drugColl);
                setSubCategoryCollection(subCategoryColl);
                setSecSubCategoryCollection(secSubCategoryColl);

            }
        })
    }, [])




    const dialog = useSelector(state => state.dialog);
    const dispatch = useDispatch();

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        var open = dialog.open;
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [dialog.open]);



    const handleClose = () => {
        dispatch(closeDialog());
    };

    const addDepressAPI = () => {


        if (genericDrug === "") {

            let notify = {
                isShown: true,
                message: "Please Select Generic Drug",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }


        if (drugCategory === "") {

            let notify = {
                isShown: true,
                message: "Please Select Drug Category",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }


        if (subCategory === "") {

            let notify = {
                isShown: true,
                message: "Please Select Sub Category",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (secondSubCategory === "") {

            let notify = {
                isShown: true,
                message: "Please Select Second Sub Category",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        setDisable(true)

        var newDeprescribing = {
            gname: genericDrug,
            dcatname: drugCategory,
            scatname: subCategory,
            sscatname: secondSubCategory,
        }

        addDeprescribing(newDeprescribing).then(response => {

            if (response.status === 201) {

                var depdonestatus = {
                    drug_id: selectedDrugId,
                    is_dep_done: 1
                }

                depdone(depdonestatus).then(response1 => {
                    if (response1.status === 200) {

                        let notify = {
                            isShown: true,
                            message: "New Deprescribing added successfully",
                            severity: "success"
                        };
                        dispatch(triggerNotification(notify));
                        setDisable(false)
                        handleClose();
                        setGenericDrug('')
                        setDrugCategory('')
                        setSubCategory('')
                        setSecondSubCategory('')
                        getDeprescribing().then(response2 => {
                            if (response2.status === 200) {
                                dispatch(addNewData(response2.data, response2.data, response2.data.length))
                            }
                        });
                    }

                })
            }


        }).catch((error) => {
            let notify = {
                isShown: true,
                message: " Something went wrong ",
                severity: "error"
            };
            dispatch(triggerNotification(notify));
            setDisable(false)

        });


    }




    return (
        <div>
            <Notify />
            <Dialog
                open={dialog.open && dialog.dialog === dialogType.addDeprescribing}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    className={classes.dialogTitle}
                    disableTypography>
                    <div className={classes.titleContent}>
                        <Typography variant="h5" className={classes.title} noWrap>
                            Add New Deprescribing
                        </Typography>
                        <img src={CategorySVG} alt="newdrug" height={120} width={120} />
                        <IconButton color="primary" aria-label="Close" className={classes.closeButton} onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                    <form className={classes.textRoot} noValidate autoComplete="off">

                        {/* <FormControl className={classes.formControl}>
                            <InputLabel id="generic-simple-select-label" size="medium"  >Generic Drug</InputLabel>
                            <Select
                                labelId="generic-simple-select"
                                id="generic-simple-select"
                                size="medium"
                                onChange={hadleGeneric}
                            >
                                {genericDrugColl?.map((drug) => drug.is_dep_done === 0 ? <MenuItem value={drug} key={drug.drug_id} > {drug.gname} </MenuItem> : null)}
                            </Select>
                        </FormControl> */}
                         <Autocomplete
                            openOnFocus={true}
                            style={{ width: 300 }}
                            id="generic-label"
                            
                            // value={drugCategory}
                            inputMode="none"
                            onChange={hadleGeneric}
                            // options={genericDrugColl}
                            options={genericDrugColl.sort((a, b) => -b.gname.localeCompare(a.gname))}
                            getOptionLabel={(option) => option.gname }     
                            groupBy={(option) => option.firstLetter}                                                   
                            renderInput={(params) => <TextField variant="standard" {...params} label="Generic Drug" />}
                        />
                        <br />

                        {/* <FormControl className={classes.formControl}>
                            <InputLabel id="drug-category-select-label" size="medium"  >Drug Category</InputLabel>
                            <Select
                                labelId="drug-category-select"
                                id="drug-category-select"
                                size="medium"
                                onChange={handleDrugCategory}
                            >
                                {drugCategoryColl?.map((category, index) => category.dcgtype === "Drug Category" ? <MenuItem value={category.dcatname} key={index} > {category.dcatname} </MenuItem> : null)}

                            </Select>
                        </FormControl> */}
                        <Autocomplete
                            openOnFocus={true}
                            id="drug-category-select-label"
                            options={drugCategoryCollection.sort((a, b) => -b.dcatname.localeCompare(a.dcatname))}
                            groupBy={(option) => option.firstLetter}
                            noOptionsText={"No Options Found"}
                            freeSolo
                            // value={yesPart.question}                            
                            // style={{ marginTop: "8px" }}                            
                            onInputChange={handleDrugCategory}
                            // onChange={handleYesQuesChange}                            
                            getOptionLabel={(option) => option.dcatname }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Drug Category"
                                />
                            )}
                        />
                        <br />

                        <Autocomplete
                            openOnFocus={true}
                            id="sub-category-select-label"
                            options={subCategoryCollection.sort((a, b) => -b.dcatname.localeCompare(a.dcatname))}
                            groupBy={(option) => option.firstLetter}
                            noOptionsText={"No Options Found"}
                            freeSolo
                            // value={yesPart.question}                            
                            // style={{ marginTop: "8px" }}                            
                            onInputChange={handleSubCategory}
                            // onChange={handleYesQuesChange}                            
                            getOptionLabel={(option) => option.dcatname }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Sub Category"
                                />
                            )}
                        />
                        {/* <FormControl className={classes.formControl}>
                            <InputLabel id="sub-category-select-label" size="medium"  >Sub Category</InputLabel>
                            <Select
                                labelId="sub-category-select"
                                id="sub-category-select"
                                size="medium"
                                onChange={handleSubCategory}
                            >
                                {drugCategoryColl?.map((category, index) => category.dcgtype === "Sub-Category" ? <MenuItem value={category.dcatname} key={index} > {category.dcatname} </MenuItem> : null)}
                            </Select>
                        </FormControl> */}

                        <br />
                        <Autocomplete
                            openOnFocus={true}
                            id="second-sub-category-select-label"
                            options={secSubCategoryCollection.sort((a, b) => -b.dcatname.localeCompare(a.dcatname))}
                            groupBy={(option) => option.firstLetter}
                            noOptionsText={"No Options Found"}
                            freeSolo
                            // value={yesPart.question}                            
                            // style={{ marginTop: "8px" }}                            
                            onInputChange={handleSecondSubCategory}
                            // onChange={handleYesQuesChange}                            
                            getOptionLabel={(option) => option.dcatname }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Second Sub Category"
                                />
                            )}
                        />

                        {/* <FormControl className={classes.formControl}>
                            <InputLabel id="second-sub-category-select-label" size="medium"  >Second Sub Category</InputLabel>
                            <Select
                                labelId="second-sub-category-select"
                                id="second-sub-category-select"
                                size="medium"
                                onChange={handleSecondSubCategory}
                            >
                                {drugCategoryColl?.map((category, index) => category.dcgtype === "Second Sub-Category" ? <MenuItem value={category.dcatname} key={index} > {category.dcatname} </MenuItem> : null)}
                            </Select>
                        </FormControl> */}


                        <br />
                        <br />

                    </form>
                    <Button
                        className={classes.buttonStyle}
                        variant="contained"
                        color="primary"
                        disabled={disable}
                        onClick={() => addDepressAPI()}>
                        ADD Deprescribing
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    )


};

export default AddDeprescribing;