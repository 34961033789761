import React, { useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
// import DateFnsUtils from '@date-io/date-fns';
// import {
//     MuiPickersUtilsProvider,
//     KeyboardDatePicker,
// } from '@material-ui/pickers';
import 'date-fns';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import {
    getMedCondition, getUserFacilityById, getPccPatientVitals,
    getPccDrugDosage, getAllergy, getPccPatientAllergies,
    getPccPatientMedications, getPccHospice, getPccMedicalConditions,
    getMedCondInfo, addMedCondition, addAllergy
} from "../../../util/api";
import { useSelector } from "react-redux";
import Notify from "../../Notification/Notify";
import { triggerNotification } from "../../../store/notification/actionCreator";
import { useDispatch } from "react-redux";
import { editPatient } from "../../../store/patientSearch/actionCreator";
import { useHistory, useRouteMatch } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { addDosage } from "../../../store/drugDosage/actionCreator";
import Skeleton from '@material-ui/lab/Skeleton';
import patientMedData from "../../../fixtures/medicalCondition.json"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingTop: "24px"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textFieldStyle: {
        minWidth: 250,
        margin: "0px",
        marginBottom: "20px"
    },
    textFieldStyle1: {
        minWidth: 250,
        margin: "0px",
    },
    textFieldStyle2: {
        marginBottom: "20px"
    },
    legend: {
        color: "#ff6e4e",
        fontWeight: "bold"
    },
    container: {
        background: "white",
        borderRadius: "15px",
        borderTop: "1px solid #dadada",
        borderBottom: "1px solid #dadada",
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    customtablecell: {
        whiteSpace: "normal",
        wordWrap: "break-word",
    },
    heading1: {
        textAlign: "center",
        paddingTop: "18px"
    },
    dateofbirth: {
        // marginLeft: "8px"
        minWidth: 250,
        marginTop: "18px",
        marginBottom: "18px"
    },
    lable: {
        // marginTop: "20px",
        // marginLeft: "8px"
        width: "80%",
    },
    gridItem1: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRight: "2px solid #e0e0e0"
    },
    gridItem2: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    gridItem3: {
        display: "flex",
        justifyContent: "center"
    },
    lableContent: {
        display: "flex",
        flexDirection: "column"
        // marginTop: "8px"
    },
    selectmed: {
        marginLeft: '8px',
        marginTop: '18px',
        overflowX: 'hidden',
        boxSizing: 'border-box',
    },
    heading2: {
        marginTop: "50px",
        textAlign: 'center'
    },
    units: {
        minWidth: 100
    },
    weight: {
        // marginTop: "10px",
        marginLeft: "0px",
        display: "flex",
        justifyContent: "center"
    },
    button: {
        textAlign: 'center',
        paddingBottom: "18px",
        paddingTop: "30px"
    },
    selectminwidth: {
        minWidth: "100px"
    },
    dosageForm: {
        minWidth: "200px"
    },
    medicationselect: {
        margin: "inherit"
    },
    route: {
        minWidth: "100px",
        marginTop: "10px",
        marginLeft: "8px"
    },
    frequency: {
        minWidth: "100px",
        marginTop: "10px",
        marginLeft: "8px"
    },
    addDrugDropDown: {
        margin: theme.spacing(1),
        minWidth: "250px",
        maxWidth: "250px"
    },
    bloodPressure: {
        marginTop: "25px",
        textAlign: "center"
    },
    gridItemInside: {
        display: "flex",
        justifyContent: "center"
    }
}));


export const AddPatientDetails = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [patient, setPatient] = useState({
        first_name: "",
        last_name: "",
        middle_name: "",
        age: "",
        birth_date: "",
        gender: "1",
        systolic: "",
        diastolic: "",
        kidneyfx: "G",
        kidneyFxNumber: 0,
        weight: "",
        height: "",
        phq9: "",
        bmi: "",
        bims: "",
        hospice: "N",
        life_expectancy: "Y",
        medcond: [],
        facility_id: "",
        pccPatientId: "",
        patient_id: "",
        allergy: []
    })
    const [allergyColl, setAllergyColl] = useState([]);
    const [medColl, setMedColl] = useState([]);
    const patientBasics = useSelector(state => state.patientSearch.filterPatient)
    const [facilityColl, setFacilityColl] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const { url } = useRouteMatch();
    const userID = useSelector(state => state.auth.userId)
    const [patientMedConditions, setPatientMedConditions] = useState([]);
    const [allMedConditions, setAllMedConditions] = useState([]);
    // const selectedPatient = useSelector(state => state.patientSearch.selectedPatient)

    useEffect(() => {

        if (patientBasics.pccPatientId) {
            getPccDrugDosage(patientBasics.facility_id, patientBasics.pccPatientId).then(res1 => {
                if (res1.status === 200) {
                    // console.log("dosage", res1.data.data);
                    var array1 = res1.data.data;
                    for (var i = 0; i < array1.length; i++) {
                        let dosage = {
                            patient_id: '',
                            dgname: array1[i].description || "-",
                            dosg: array1[i].strength || "-",
                            dosgtype: array1[i].strengthUOM || "-",
                            days: array1[i].schedules[0].frequency || "-",
                            times: "-",
                            prn: "-",
                            prn30: '-',
                            allergy: '-',
                            dsgform: array1[i].schedules[0].doseUOM || "-",
                            route: array1[i].administration.route.coding[0].code || "-",
                            stpdate: "-",
                            ainfo: "-"
                        }
                        dispatch(addDosage(dosage))
                        // console.log("new dosage", dosage);
                    }
                }
            }).catch(error => {
                console.error(error)
            })
        }



        if (patientBasics.first_name !== '') {

            try {

                var medList = [];
                var allergyList = [];

                if (patientBasics.medcond.includes(',')) {
                    let split = patientBasics.medcond.split(',');

                    if (split.length !== 0) {

                        for (let index = 0; index < split.length; index++) {

                            if (split[index].includes('Drug Allergy-')) {

                                let s = split[index].split("Drug Allergy-")
                                allergyList.push(s[1])
                            } else {
                                medList.push(split[index])
                            }

                        }
                    }
                }

                // if(patientBasics.medcond.includes(',Drug Allergy-')){

                //     let split = patientBasics.medcond.split(',Drug Allergy-')
                //      medList = split[0] || [];
                //      allergyList = split[1] || [];

                // }else if(patientBasics.medcond.includes('Drug Allergy-')) {
                //     let split = patientBasics.medcond.split('Drug Allergy-')
                //      medList = split[0] || [];
                //      allergyList = split[1] || [];
                // }else {
                //     medList = patientBasics.medcond;
                //     allergyList = [];
                // }

                // var split = patientBasics.medcond.split(',Drug Allergy-')
                // var medList = split[0] || [];
                // var allergyList = split[1] || [];
                var split1 = patientBasics.kidneyfx.split('-')
                var kidneySplit = split1[0]
                var KidneyNumberSplit;
                
                if (patientBasics.kidneyfx.includes("-")) {

                    KidneyNumberSplit = split1[1]
                } else {
                    KidneyNumberSplit = patientBasics.kidneyFxNumber
                }

            }
            catch {
                medList = [];
                allergyList = [];
            }

            setPatient({
                first_name: patientBasics.first_name,
                last_name: patientBasics.last_name,
                middle_name: patientBasics.middle_name,
                age: patientBasics.age,
                birth_date: patientBasics.birth_date,
                facility_id: patientBasics.facility_id || patientBasics.facId,
                // gender: patientBasics.gender === 1 || "1" ? "1" : patientBasics.gender === 2 || "2" ? "2" : patientBasics.gender === 3 || "3" ? "3" : patientBasics.gender === "MALE" ? "1" : patientBasics.gender === "FEMALE" ? "2" : "3",
                gender: patientBasics.gender === 1 ? "1" : patientBasics.gender === 2 ? "2" : patientBasics.gender === 3 ? "3" : patientBasics.gender === "MALE" ? "1" : patientBasics.gender === "FEMALE" ? "2" : patientBasics.gender === "1" ? "1" : patientBasics.gender === "2" ? "2" : "3",
                height: patientBasics.height,
                weight: patientBasics.weight,
                systolic: patientBasics.systolic,
                // kidneyfx: patientBasics.kidneyfx === 0 || "0" ? "0" : patientBasics.kidneyfx === 1 || "1" ? "1" : null,
                // kidneyFxNumber: patientBasics.kidneyFxNumber || 0,
                kidneyfx: kidneySplit || "G",
                kidneyFxNumber: KidneyNumberSplit || 0,
                life_expectancy: patientBasics.life_expectancy || "Y",
                diastolic: patientBasics.diastolic,
                hospice: patientBasics.hospice || "N",
                phq9: patientBasics.phq9,
                bmi: patientBasics.bmi,
                bims: patientBasics.bims,
                pccPatientId: patientBasics.pccPatientId,
                // medcond: patientBasics.medcond === "" && undefined ? [] : patientBasics.medcond.split(",")
                // medcond: patientBasics.medcond === "" || "undefined" || undefined ? [] : typeof(patientBasics.medcond) === 'string' ? patientBasics.medcond.split(",") : patientBasics.medcond
                // medcond: typeof(patientBasics.medcond) === 'string' ? patientBasics.medcond.split(",") : patientBasics.medcond === "" || "undefined" || undefined ? [] : patientBasics.medcond
                // medcond: typeof (patientBasics.medcond) === 'string' ? patientBasics.medcond.split(",") : typeof (patientBasics.medcond) === 'object' ? Object.values(patientBasics.medcond) : patientBasics.medcond === "" || "undefined" || undefined ? [] : patientBasics.medcond,
                patient_id: patientBasics.patient_id,
                allergy: allergyList.length !== 0 ? allergyList : [],
                medcond: medList.length !== 0 ? medList : []
            })

        }
        setLoading(true);
        getMedCondition().then(med => {
            if (med.status === 200 && med.data) {
                var names = med.data
                setAllMedConditions(med.data);

                let data = []
                // test = names.map( (con) => {
                //     return { med : con.medical_condition_name};
                // });
                names.map((item) => {
                    return data.push(item.medical_condition_name);
                    // return data.push({ med: item.medical_condition_name });
                })
                if (data.length !== 0) {
                    // var d = JSON.stringify(test)
                    data = data.filter((item, index) => data.indexOf(item) === index);
                    setMedColl(data);

                }


                if (patientBasics.pccPatientId && patientBasics.pccPatientId != '') {
                    getPccMedicalConditions(patientBasics.pccPatientId).then(resp1 => {

                        if (resp1.data) {
                            let conditonsAry = resp1.data.data

                            let notContins = [];
                            for (var i = 0; i < conditonsAry.length; i++) {
                                if (conditonsAry[i].icd10.length >= 2) {
                                    let val = names.filter(e => e.icd_10_code === conditonsAry[i].icd10.replace(".", ""));
                                    val.length > 0 && array5.push(val[0].medical_condition_name);
                                    notContins = names.filter(e => e.icd_10_code != conditonsAry[i].icd10.replace(".", ""));

                                }
                            }
                            // array5.push(conditonsAry[i].icd10Description)
                            // let count = 0;
                            // for (let obj of notContins) {
                            //     if (count > 10)
                            //         break;
                            //     count++;
                            //     addMedCondition(obj).then(resp => {

                            //     });
                            // }

                            updatePatientData(array, array2, array3, array4, array5, h, w, sys, dia, hospice, medList, kidneySplit, KidneyNumberSplit, allergyList);
                            setLoading(false);
                        }
                    }).catch(err => {
                        console.error(err);
                        setLoading(false);
                    })
                } else {
                    setLoading(false);
                }

            }
        })



        getAllergy().then(allergy => {
            if (allergy.status === 200 && allergy.data) {
                var names = allergy.data
                let allergyData = []

                names.map((item) => {
                    // return allergyData.push({ allergy: item.allergy_name });
                    return allergyData.push(item.allergy_name);
                })
                if (allergyData !== '') {
                    setAllergyColl(allergyData);
                }

            }
        })

        getUserFacilityById(userID).then(res1 => {
            if (res1.status === 200) {
                setFacilityColl(res1.data)
                // console.log(res1.data);
            }
        });

        //allergy
        var array2 = [];
        var array3 = [];

        // medication
        let array4 = [];
        let array5 = [];


        var h = '';
        var w = '';
        var sys = '';
        var dia = '';
        var hospice = '';
        if (patientBasics.pccPatientId) {


            var array = [];


            getPccPatientAllergies(patientBasics.pccPatientId).then(res1 => {

                if (res1.status === 200) {

                    if (res1.data) {
                        array2 = res1.data.data
                        for (var i = 0; i < array2.length; i++) {
                            array3.push(array2[i].allergen)
                        }

                    }
                    if (allergyColl && allergyColl.length > 0 && array3.length > 0) {
                        let unique1 = allergyColl.filter((o) => array3.indexOf(o) === -1);
                        let unique2 = array3.filter((o) => allergyColl.indexOf(o) === -1);

                        const unique = unique1.concat(unique2);
                        for (let aVal of unique) {
                            addAllergy({ allergy_name: aVal }).then(resp => {
                                console.log(resp);
                            })
                        }

                    }
                }
            }).catch(error => {
                console.log(error);
            })

            getPccPatientVitals(patientBasics.pccPatientId).then(res => {
                if (res.status === 200) {
                    array = res.data.data




                    getPccPatientMedications(patientBasics.pccPatientId,patientBasics.facility_id || patientBasics.facId).then(res2 => {
                        if (res2.status === 200) {

                            if (res2.data) {
                                array4 = res2.data.data
                                for (var i = 0; i < array4.length; i++) {
                                    array5.push(array4[i].icd10Description)
                                }

                            }


                            getPccHospice(patientBasics.pccPatientId).then(res3 => {
                                if (res3.status === 200) {

                                    if (res3.data && res3.data.data[0].value) {
                                        hospice = res3.data.data[0].value
                                    } else {
                                        hospice = 'N'
                                    }



                                    // eslint-disable-next-line
                                    array.map(m => {
                                        if (m.type === "bloodPressure") {
                                            // setPatient({...patient, systolic : m.systolicValue, diastolic : m.diastolicValue })
                                            sys = m.systolicValue
                                            dia = m.diastolicValue
                                        }
                                        if (m.type === "weight") {
                                            // setPatient({...patient, weight : m.value })
                                            w = m.value
                                        }
                                        if (m.type === "height") {
                                            // setPatient({...patient, height : m.value })
                                            h = m.value
                                        }
                                    })

                                    try {
                                        // var split = patientBasics.medcond.split(',Drug Allergy-')
                                        // var medList = split[0] || [];
                                        // var allergyList = split[1] || [];

                                        var medList = [];
                                        var allergyList = [];

                                        if (patientBasics.medcond.includes(',')) {
                                            let split = patientBasics.medcond.split(',');

                                            if (split.length !== 0) {

                                                for (let index = 0; index < split.length; index++) {

                                                    if (split[index].includes('Drug Allergy-')) {

                                                        let s = split[index].split("Drug Allergy-")
                                                        allergyList.push(s[1])
                                                    } else {
                                                        medList.push(split[index])
                                                    }

                                                }
                                            }
                                        }


                                        var split1 = patientBasics.kidneyfx.split('-')
                                        var kidneySplit = split1[0]
                                        var KidneyNumberSplit = split1[1]
                                    }
                                    catch {
                                        medList = [];
                                        allergyList = [];
                                    }


                                }
                            })
                        }
                        updatePatientData(array, array2, array3, array4, array5, h, w, sys, dia, hospice, medList, kidneySplit, KidneyNumberSplit, allergyList);
                    }).catch(e => {
                        updatePatientData(array, array2, array3, array4, array5, h, w, sys, dia, hospice, medList, kidneySplit, KidneyNumberSplit, allergyList);
                    })



                }
            })
        }



        // eslint-disable-next-line
    }, [])

    const updatePatientData = (array, array2, array3, array4, array5, h, w, sys, dia, hospice, medList, kidneySplit, KidneyNumberSplit, allergyList) => {

        setPatient({
            first_name: patientBasics.first_name,
            last_name: patientBasics.last_name,
            middle_name: patientBasics.middle_name,
            age: patientBasics.age,
            birth_date: patientBasics.birth_date,
            facility_id: patientBasics.facility_id,
            gender: patientBasics.gender === 1 ? "1" : patientBasics.gender === 2 ? "2" : patientBasics.gender === 3 ? "3" : patientBasics.gender === "MALE" ? "1" : patientBasics.gender === "FEMALE" ? "2" : patientBasics.gender === "1" ? "1" : patientBasics.gender === "2" ? "2" : "3",
            height: h,
            weight: w,
            systolic: sys,
            kidneyfx: kidneySplit || "G",
            kidneyFxNumber: KidneyNumberSplit || 0,
            life_expectancy: patientBasics.life_expectancy || "Y",
            diastolic: dia,
            hospice: hospice || patientBasics.hospice || "N",
            phq9: patientBasics.phq9,
            bmi: patientBasics.bmi,
            bims: patientBasics.bims,
            pccPatientId: patientBasics.pccPatientId,
            // medcond: patientBasics.medcond === "" && undefined ? [] : patientBasics.medcond.split(",")
            // medcond: patientBasics.medcond === "" || "undefined" || undefined ? [] : typeof(patientBasics.medcond) === 'string' ? patientBasics.medcond.split(",") : patientBasics.medcond
            // medcond: typeof(patientBasics.medcond) === 'string' ? patientBasics.medcond.split(",") : patientBasics.medcond === "" || "undefined" || undefined ? [] : patientBasics.medcond
            // medcond: typeof (patientBasics.medcond) === 'string' ? patientBasics.medcond.split(",") : typeof (patientBasics.medcond) === 'object' ? Object.values(patientBasics.medcond) : patientBasics.medcond === "" || "undefined" || undefined ? [] : patientBasics.medcond,
            patient_id: patientBasics.patient_id,
            allergy: array3.length !== 0 ? array3 : allergyList.length !== 0 ? allergyList : [],
            medcond: array5.length !== 0 ? array5 : medList.length !== 0 ? medList : []
        })
    }


    const updateFirstName = (event) => {
       
            setPatient({
                ...patient,
                first_name: event.target.value.replace(/[^a-zA-Z ]/g, '')
            })
         
       
    }

    const updateLastName = (event) => {
        setPatient({
            ...patient,
            last_name: event.target.value.replace(/[^a-zA-Z ]/g, '')
        })
    }

    const handleChangeDateOfBirth = (event, date) => {
        setPatient({
            ...patient,
            birth_date: event.target.value
        })
    }

    const handleGenderChange = (event) => {
        setPatient({
            ...patient,
            gender: event.target.value
        })
    }

    const handleChangeSys = (event) => {
        setPatient({
            ...patient,
            systolic: event.target.value
        })
    }

    const handleChangeDia = (event) => {
        setPatient({
            ...patient,
            diastolic: event.target.value
        })
    }

    const handleChangeKidneyFx = (event) => {
        setPatient({
            ...patient,
            kidneyfx: event.target.value
        })
    }

    const handleChangeKidneyFxNumber = (event) => {
        setPatient({
            ...patient,
            kidneyFxNumber: event.target.value
        })
    }

    const handleChangeWeight = (event) => {

        if (patient.height !== '' && event.target.value !== '') {
            let bmi = (event.target.value / ((patient.height * patient.height) / 703)).toFixed(2);
            setPatient({
                ...patient,
                weight: event.target.value,
                bmi: bmi
            })
        } else {
            setPatient({
                ...patient,
                weight: event.target.value
            })
        }
    }

    const handleChangeHeight = (event) => {


        if (patient.weight !== '' && event.target.value !== '') {
            let bmi = (patient.weight / ((event.target.value * event.target.value) / 703)).toFixed(2);
            setPatient({
                ...patient,
                height: event.target.value,
                bmi: bmi
            })
        } else {
            setPatient({
                ...patient,
                height: event.target.value
            })
        }
    }

    const handleChangePHQ = (event) => {
        setPatient({
            ...patient,
            phq9: event.target.value
        })
    }

    const handleChangeBIMS = (event) => {
        setPatient({
            ...patient,
            bims: event.target.value
        })
    }

    const handleChangeBMI = (event) => {
        setPatient({
            ...patient,
            bmi: event.target.value
        })
    }

    const handleChangeHospice = (event) => {
        setPatient({
            ...patient,
            hospice: event.target.value
        })
    }

    const handleChangeLife = (event) => {
        setPatient({
            ...patient,
            life_expectancy: event.target.value
        })
    }

    const handleChangeMedCondition = (event, value) => {
        setPatient({
            ...patient,
            medcond: value
        })
    }

    const handleChangeAllergy = (event, value) => {
        setPatient({
            ...patient,
            allergy: value
        })
    }

    const updateMiddleName = (event) => {
        setPatient({
            ...patient,
            middle_name: event.target.value.replace(/[^a-zA-Z ]/g, '')
        })
    }

    const handleChangeFacility = (event) => {
        setPatient({
            ...patient,
            facility_id: event.target.value
        })
    }

    function getAge(dobi) {
        const birthDate = new Date(dobi);
        const difference = Date.now() - birthDate.getTime();
        const age = new Date(difference);
        return Math.abs(age.getUTCFullYear() - 1970);
    }

    //     useEffect(() => {
    //     const getGender = () => {
    //         switch (patient.gender) {
    //             case 'Male':
    //                 return setGender('1');
    //             case 'Female':
    //                 return setGender('2');;
    //             case 'Other':
    //                 return setGender('3');;
    //             default:
    //                 return null;
    //         }
    //     }

    // }, [handleGenderChange]);

    const addPatient = () => {

        if (patient.first_name === "" || patient.first_name === undefined) {
            let notify = {
                isShown: true,
                message: "Please Enter First Name",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return
        } else if (patient.last_name === "" || patient.last_name === undefined) {
            let notify = {
                isShown: true,
                message: "Please Enter Last Name",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return
        } else if (patient.birth_date === "" || patient.birth_date === undefined) {
            let notify = {
                isShown: true,
                message: "Please Enter Date of birth",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return
        } else if (patient.facility_id === "" || patient.facility_id === undefined) {
            let notify = {
                isShown: true,
                message: "Please Select Facility",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return
        }

        // have to for loop here for in front of each allergy name we have to add Drug Allergy-
        if (patient.allergy.length !== 0) {

            // let array = patient.allergy;

            for (let index = 0; index < patient.allergy.length; index++) {

                patient.allergy[index] = `Drug Allergy-${patient.allergy[index]}`
            }


        }


        var newPatient = {
            first_name: patient.first_name,
            last_name: patient.last_name,
            middle_name: patient.middle_name,
            age: patient.age,
            birth_date: patient.birth_date,
            facility_id: patient.facility_id,
            gender: patient.gender,
            height: patient.height,
            weight: patient.weight,
            systolic: patient.systolic,
            kidneyfx: patient.kidneyfx,
            kidneyFxNumber: patient.kidneyFxNumber,
            life_expectancy: patient.life_expectancy,
            diastolic: patient.diastolic,
            hospice: patient.hospice,
            phq9: patient.phq9,
            bmi: patient.bmi,
            bims: patient.bims,
            pccPatientId: patient.pccPatientId,
            // medcond: patient?.medcond.map(a => a).join(),
            medcond: patient.medcond.concat(patient.allergy).map(a => a).join(),
            patient_id: patientBasics.patient_id,
            // medcond: patient.medicondition.map(a => JSON.stringify(a)).join()
            // medcond: patient.medicondition.toString()
            allergies: patient.allergy,
            allergy: patient.allergy


        }


        dispatch(editPatient(newPatient));
        let newUrl = url.replace(/.$/, "3");
        history.push(newUrl)

    }

    return (
        <>
            <Typography variant="h6" gutterBottom>
                <b>DEPRESCRIBING : RECOMMENDATION GENERATOR</b>
            </Typography>
            <Container className={classes.container} fixed>
                <div className={classes.root}>
                    <Grid container spacing={2}>
                        <Grid item xs={4} className={classes.gridItem1}>
                            <TextField
                                placeholder="First Name"
                                label="First Name"
                                size="medium"
                                value={patient.first_name}
                                className={classes.textFieldStyle}
                                onChange={updateFirstName}
                                required
                            />
                            <TextField
                                placeholder="Middle Name"
                                label="Middle Name"
                                size="medium"
                                value={patient.middle_name}
                                className={classes.textFieldStyle}
                                onChange={updateMiddleName}
                            />
                            <TextField
                                placeholder="Last Name"
                                label="Last Name"
                                size="medium"
                                value={patient.last_name}
                                className={classes.textFieldStyle1}
                                onChange={updateLastName}
                                required
                            />
                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                <KeyboardDatePicker
                                    autoOk={true}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    placeholder="Date picker inline"
                                    value={patient.birth_date}
                                    className={classes.dateofbirth}
                                    onChange={handleChangeDateOfBirth}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider> */}
                            <TextField
                                id="date"
                                label="Date of Birth"
                                type="date"
                                value={patient.birth_date}
                                onChange={handleChangeDateOfBirth}
                                // defaultValue="2020-05-24"
                                className={classes.dateofbirth}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                                helperText={`Age - ${getAge(patient.birth_date)}`}
                            />

                            <FormControl required component="fieldset" className={classes.lable} >
                                <FormLabel component="legend" className={classes.legend}>Gender</FormLabel>
                                <RadioGroup row aria-label="position" name="gender" value={patient.gender} className={classes.lableContent} onChange={handleGenderChange} defaultValue="top">
                                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Male" />
                                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Female" />
                                    <FormControlLabel value="3" control={<Radio color="primary" />} label="Other" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} className={classes.gridItem1}>
                            <Grid container spacing={2} className={classes.weight}>
                                <Grid item xs={5}>
                                    <TextField id="weight" type="number" value={patient.weight} onChange={handleChangeWeight} helperText="Pounds" placeholder="Weight" label="Weight" />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField id="height" type="number" value={patient.height} onChange={handleChangeHeight} helperText="Inches" placeholder="Height" label="Height" />
                                </Grid>
                            </Grid>
                            <FormControl component="fieldset" className={classes.bloodPressure}>
                                <FormLabel className={classes.legend} component="legend">Blood Pressure</FormLabel>
                                <Grid container spacing={2} className={classes.gridItemInside}>
                                    <Grid item xs={5} >
                                        <TextField id="sys" className={classes.textFieldStyle2} value={patient.systolic} name="sys" onChange={handleChangeSys} type="number" placeholder="sys" label="Sys" />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField id="dia" className={classes.textFieldStyle2} value={patient.diastolic} type="number" onChange={handleChangeDia} name="dia" placeholder="Dia" label="Dia" />
                                    </Grid>
                                </Grid>
                            </FormControl>
                            <Grid container spacing={2} className={classes.gridItemInside} >
                                <Grid item xs={5}>
                                    <TextField id="PHQ9" name="phq9" value={patient.phq9} onChange={handleChangePHQ} type="number" placeholder="PHQ9" label="PHQ9" />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField id="BMI" name="BMI" value={patient.bmi} onChange={handleChangeBMI} placeholder="BMI" label="BMI" />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField id="BIMS" name="BIMS" value={patient.bims} onChange={handleChangeBIMS} type="number" placeholder="BIMS" label="BIMS" />
                                </Grid>
                            </Grid>

                            <FormControl required className={classes.formControl} style={{ marginTop: "22px" }}>
                                <InputLabel id="facility-lable" size="medium"  >Select Facility</InputLabel>
                                <Select
                                    labelId="facility-select-lable"
                                    id="facility"
                                    value={patient.facility_id}
                                    size="medium"
                                    onChange={handleChangeFacility}
                                >
                                    {facilityColl.map((data, index) => (<MenuItem value={data.facilityId} key={index} > {data.facilityName} </MenuItem>))}
                                </Select>
                            </FormControl>

                        </Grid>
                        <Grid item xs={4} className={classes.gridItem2}>
                            <FormControl component="fieldset" className={classes.lable}>
                                <FormLabel className={classes.legend} component="legend">Kidney Fx</FormLabel>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <RadioGroup aria-label="position" defaultValue="top" value={patient.kidneyfx} name="kidneyfx" onChange={handleChangeKidneyFx} className={classes.lableContent}>
                                            <FormControlLabel value="C" control={<Radio color="primary" />} label="Creatine" />
                                            <FormControlLabel value="G" control={<Radio color="primary" />} label="GFR" />
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField id="kidneyfx" type="number" value={patient.kidneyFxNumber} onChange={handleChangeKidneyFxNumber} label="optional" />
                                    </Grid>
                                </Grid>
                            </FormControl>

                            <FormControl component="fieldset" className={classes.lable}>
                                <FormLabel className={classes.legend} component="legend">Hospice</FormLabel>
                                <RadioGroup aria-label="hospice" name="hospice" value={patient.hospice} onChange={handleChangeHospice} defaultValue="top">
                                    <FormControlLabel value="Y" control={<Radio color="primary" />} label="Yes" />
                                    <FormControlLabel value="N" control={<Radio color="primary" />} label="No" />
                                </RadioGroup>
                            </FormControl>
                            <FormControl component="fieldset" className={classes.lable}>
                                <FormLabel className={classes.legend} component="legend">Life Expectancy {">"}12 months</FormLabel>
                                <RadioGroup aria-label="life" name="life" value={patient.life_expectancy} onChange={handleChangeLife} defaultValue="top">
                                    <FormControlLabel value="Y" control={<Radio color="primary" />} label="Yes" />
                                    <FormControlLabel value="N" control={<Radio color="primary" />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>


                    {loading &&
                        <div><Skeleton className={classes.skeletonStyle}
                            variant="rect"
                            animation="wave"
                            height={50} />
                        </div>}
                    <Grid container spacing={2} className={classes.gridItem3}>

                        <Grid item xs={6} >
                            <Autocomplete
                                multiple
                                value={patient?.medcond}
                                onChange={handleChangeMedCondition}
                                className={classes.selectmed}
                                openOnFocus={true}
                                menuStyle={{ maxHeight: '300px' }}
                                id="tags-standard"
                                options={medColl}
                                filterSelectedOptions
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Add MedCondition"
                                        placeholder="Select Med Condition"
                                    />
                                )}
                            />

                        </Grid>
                        <Grid item xs={6} >
                            <Autocomplete
                                multiple
                                className={classes.selectmed}
                                openOnFocus={true}
                                menuStyle={{ maxHeight: '300px' }}
                                onChange={handleChangeAllergy}
                                value={patient.allergy}
                                id="tags-standard"
                                options={allergyColl}
                                filterSelectedOptions
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Add allergies"
                                        placeholder="Select Allergy"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <div className={classes.button} >
                        <Button variant="contained" onClick={() => addPatient()} color="primary">
                            Next
                        </Button>
                        {/* <Button variant="contained" onClick={() => addDrugDosage()} color="primary">
                            Next
                    </Button> */}
                    </div>
                </div>
                <Notify />
            </Container>
        </>
    )

}

export default AddPatientDetails;