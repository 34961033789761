import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
// import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import {
    // getDrugs, getCategory, getMedCondition, 
    addDrugRule, maxDslno,
    getDrugsInfo, getMedCondInfo, getDrugsCatInfo, uploadDrugFile
} from "../../../util/api";
import { useDispatch, useSelector } from "react-redux";
import { addNewRule, resetRules } from "../../../store/DrugRule/actionCreator";
import { triggerNotification } from "../../../store/notification/actionCreator";
import Notify from "../../Notification/Notify";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: "none"
    },
    lightToolBar: {
        background: theme.palette.toolbarLight,
        color: "black",
        paddingRight: "0px",
        paddingLeft: "0px"
    },
    drugContainer: {
        paddingTop: "0.5%",
        paddingLeft: "25%",
        paddingRight: "10%",
        width: "100vw",
        paddingBottom: "0.5%"
    },
    drugTitle: {
        fontSize: "2em",
        fontWeight: "bold"
    },
    drugTitleroot: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        fontSize: "2.5em",
        color: theme.palette.primary.main,
        flexGrow: 1,
        display: 'none',
        fontWeight: "bold",
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    tableRoot: {
        borderRadius: "15px",
        borderTop: "1px solid #80808038",
        paddingTop: "15px"
    },
    table: {
        minWidth: 700,
    },
    fabRoot: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    fabAdd: {
        position: "fixed",
        bottom: "25px",
        right: "25px",
        height: "75px",
        width: "75px",
    },
    addSVG: {
        height: "1.5em",
        width: "1.5em",
        cursor: "pointer"
    },
    skeletonStyle: {
        marginBottom: "10px",
        borderRadius: "15px"
    },
    box: {
        backgroundColor: "white",
        borderRadius: "15px",
        padding: "15px",
        border: "1px solid #dadada"
    },
    action: {
        minWidth: 120
    },
    button: {
        textAlign: "center",
        marginTop: "20px",
        paddingBottom: "20px"
    },
    yes: {
        background: "#1877f2",
        color: "white"
    },
    no: {
        background: "#ff6e4e",
        color: "white"
    },
    yespart: {
        borderRight: ".5px solid #808080a8",
        paddingTop: "18px"
    },
    nopart: {
        paddingTop: "18px"
    },
    quesOneTitle: {
        fontSize: "1rem",
        marginTop: "18px",
    },
    quesTwoTitle: {
        fontSize: "1rem",
        marginTop: "18px"
    },
    titleColour: {
        color: "#ff6e4e"
    },
    file: {
        marginTop: "25px"
    }
}))

const DefineDrugRule = () => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const drugRules = useSelector(state => state.drugRuleReducer.drugRuleArray)
    const [primaryDrug, setPrimaryDrug] = useState({});
    const [secondaryDrug, setSecondaryDrug] = useState({});
    // const [secDrug, setSecDrug] = useState('');
    // const [drugRulesArray, setDrugRulesuArray] = useState([])
    // const [drugTwo] = useState([]);

    // useEffect(() => {
    //     setDrugRulesArray(drugRules);
    // }, [])
    const [otherData, setOtherData] = useState({
        text: '',
        sys: '',
        dia: ''
    });
    const [disableOpt, setDisableOpt] = useState({
        disable1: true,
        disable2: true
    })

    // const handleYesQuesChange = (event, value) => { 
    //     setYesQues(value)
    //     console.log("yes Ques", value);
    // }
    const handleYesQuesChange = (event, value) => {
        setYesPart({
            ...yesPart,
            question: value
        })
        // removing selected item from array 
        // let array = []
        // // eslint-disable-next-line
        // secDrugArray.map(entry => {
        //     if (entry !== value) {
        //         array.push(entry)
        //     }
        // })
        // setSecDrugArray(array)
    }

    // const handleSecondaryDrug = (event, value) => {
    //     setSecondaryDrug(value)
    // }

    const handleChangePrimaryDrug = (event, value) => {
        setPrimaryDrug(value);

        // setDataArray1([]);
        secDrugArray.length = 0
        setSecondaryDrug({})
        // eslint-disable-next-line
        originalArray.map((item) => {

            if (JSON.stringify(item) === JSON.stringify(value)) {
                // console.log(item);
            } else {
                secDrugArray.push(item)
                // quesArray.push(item)
            }
        })

        for (let i = 0; i < othersArray.length; i++) {
            secDrugArray.push(othersArray[i])
        }

        // var array = []

        // eslint-disable-next-line
        // secDrugArray.map(entry => {
        //     if (entry !== value) {
        //         array.push(entry)
        //     }
        // })
        // console.log(array.length, value);
        // setSecDrugArray(array)
        // setDrugTwo(secDrugArray.map(entry => entry !== primaryDrug));
    }

    const handleChangeSecondaryDrug = (event, value) => {
        // setSecDrug(value);
        setSecondaryDrug(value);

        // eslint-disable-next-line
        secDrugArray.map((item) => {

            if (JSON.stringify(item) === JSON.stringify(value)) {
                // console.log(item);
            } else {
                noQuesArray.push(item)
                quesArray.push(item)
            }
        })



        // if (value.value === "OT-BP") {
        //     setDisableOpt({ disable1: true, disable2: false })
        // } else {
        //     setDisableOpt({ disable1: false, disable2: true })
        //     setOtherData({ disable1: false, disable2: true })
        // }

        // if (value.value === "OT-BP") {
        //     setDisableOpt({ disable1: true, disable2: false })
        // } else {
        //     setDisableOpt({ disable1: false, disable2: true })
        //     setOtherData({ disable1: false, disable2: true })
        // }


        // && (primaryDrug.gname.substring(0,3) !== 'DR-'|| )
        if (value) {
            if (value.value === "OT-GFR" || value.value === "OT-Creatine") {
                setDisableOpt({ disable1: false, disable2: true })
            } else if (value.value === "OT-BP") {
                setDisableOpt({ disable2: false, disable1: true })
            } else {
                setDisableOpt({ disable1: true, disable2: true })
                setOtherData({ text: '', sys: '', dia: '' })

            }
        }
    }




    // yes part

    const [yesPart, setYesPart] = useState({
        action: "Check",
        condition: "",
        question: {},
        operator: "",
        value: "",
        message: ""
    })
    const [yesDisable, setYesDisabled] = useState(false)
    const [condDisable, setCondDisable] = useState(true)
    const [msgDisable, setMsgDisable] = useState(true)
    const handleChangeYesAction = (event) => {
        setYesPart({
            ...yesPart,
            action: event.target.value
        })
        setYesDisabled(event.target.value === "Check" ? false : true)
        setMsgDisable(event.target.value === "Message" ? false : true)
    }

    const handleChangeYesCondition = (event) => {
        setYesPart({
            ...yesPart,
            condition: event.target.value
        })
        setCondDisable(event.target.value === "condition" && yesDisable === false ? false : true)
        if (event.target.value === "NoCondition") {
            setYesPart({
                ...yesPart,
                operator: "",
                value: ""
            })
        }
        if (event.target.value === "condition") {
            setYesPart({
                ...yesPart,
                operator: "=",
                value: ""
            })
        }
    }




    const handleChangeYesOperater = (event) => {
        setYesPart({
            ...yesPart,
            operator: event.target.value
        })
    }


    const handleChangeYesValue = (event) => {
        setYesPart({
            ...yesPart,
            value: event.target.value
        })
    }

    const handleChangeYesMsg = (event) => {
        setYesPart({
            ...yesPart,
            message: event.target.value
        })
    }


    // no part
    const [noPart, setNoPart] = useState({
        action: "Message",
        condition: "",
        question: "",
        operator: "",
        value: "",
        message: ""
    })
    const [noDisable, setNoDisabled] = useState(true)
    const [noCondDisable, setNoCondDisable] = useState(true)
    const [noMsgDisable, setNoMsgDisable] = useState(false)
    const handleChangeNoAction = (event) => {
        setNoPart({
            ...noPart,
            action: event.target.value
        })
        setNoDisabled(event.target.value === "Check" ? false : true)
        setNoMsgDisable(event.target.value === "Message" ? false : true)
    }

    const handleChangeNoCondition = (event) => {
        setNoPart({
            ...noPart,
            condition: event.target.value
        })
        setNoCondDisable(event.target.value === "condition" && noDisable === false ? false : true)
        if (event.target.value === "NoCondition") {
            setNoPart({
                ...noPart,
                operator: "",
                value: ""
            })
        }
        if (event.target.value === "condition") {
            setNoPart({
                ...noPart,
                operator: "=",
                value: ""
            })
        }
    }


    const handleChangeNoQuestion = (event, value) => {
        setNoPart({
            ...noPart,
            question: value
        })
    }

    const handleChangeNoOperater = (event) => {
        setNoPart({
            ...noPart,
            operator: event.target.value
        })
    }


    const handleChangeNoValue = (event) => {
        setNoPart({
            ...noPart,
            value: event.target.value
        })
    }

    const handleChangeNoMsg = (event) => {
        setNoPart({
            ...noPart,
            message: event.target.value
        })
    }




    // primary drug collection
    // const primaryDrugColl = useState([]);
    // const [array, setArray] = useState([])
    const [originalArray] = useState([]);
    const [primaryDrugArray] = useState([]);
    const [secDrugArray] = useState([]);
    const [quesArray] = useState([
        { value: "OT-GFR" },
        { value: "OT-Creatine" },
        { value: "OT-BP" },
        { value: "OT-BP-SYS" },
        { value: "OT-BP-DIA" },
        { value: "OT-AGE" },
        { value: "OT-WT" },
        { value: "OT-BIMS" },
        { value: "OT-PHQ9" }
    ]);
    const [noQuesArray] = useState([]);

    const othersArray = [
        { value: "OT-GFR" },
        { value: "OT-Creatine" },
        { value: "OT-BP" },
        { value: "OT-BP-SYS" },
        { value: "OT-BP-DIA" },
        { value: "OT-AGE" },
        { value: "OT-WT" },
        { value: "OT-BIMS" },
        { value: "OT-PHQ9" },
        { value: "None" }
    ]



    React.useEffect(() => {


        getDrugsInfo().then(res1 => {
            if (res1.status === 200 && res1.data) {
                res1.data.map((item) => {
                    originalArray.push(item)
                    secDrugArray.push(item)
                    // quesArray.push(item)
                    return primaryDrugArray.push(item)
                })
            }
        })

        getDrugsCatInfo().then(res2 => {
            if (res2.status === 200 && res2.data) {

                res2.data.map((item) => {
                    originalArray.push(item)
                    secDrugArray.push(item)
                    // quesArray.push(item)
                    return primaryDrugArray.push(item)
                })



            }
        })

        getMedCondInfo().then(res3 => {
            if (res3.status === 200 && res3.data) {
                res3.data.map((item) => {
                    originalArray.push(item)
                    secDrugArray.push(item)
                    // quesArray.push(item)
                    return primaryDrugArray.push(item)
                })
            }
        })




        // eslint-disable-next-line
    }, [])


    const [file, setFile] = useState(null)
    const handleFileChange = (event) => {
        setFile(event.target.files[0])
    }


    const Define = () => {
        setDisableOpt({ disable1: true, disable2: true })
        var SecDName = '';
        if (otherData.text) {
            SecDName = `${getSelectedSecondaryDrug()}:${otherData.text}`
            setSecondaryDrug({ "value": `${getSelectedSecondaryDrug()}:${otherData.text}` })
            setDisableOpt({ ...disableOpt, disable1: true, disable2: true });
            setOtherData({ ...otherData, text: '', sys: '', dia: '' });
        } else if (otherData.sys && otherData.dia) {
            SecDName = `${getSelectedSecondaryDrug()}:${otherData.sys}/${otherData.dia}`
            setSecondaryDrug({ "value": `${getSelectedSecondaryDrug()}:${otherData.sys}/${otherData.dia}` })
            setDisableOpt({ ...disableOpt, disable1: true, disable2: true });
            setOtherData({ ...otherData, text: '', sys: '', dia: '' });
        }

        if (yesPart.action === "Check" && noPart.action === "Check" && (primaryDrug.gname || primaryDrug.dcatname || primaryDrug.medical_condition_name) && (secondaryDrug.gname || secondaryDrug.dcatname || secondaryDrug.medical_condition_name || secondaryDrug.value)) {
            let notify = {
                isShown: true,
                message: "Both Sides Must not be check Condition",
                severity: "warning"
            };
            // let array = []
            // eslint-disable-next-line
            // secDrugArray.map(entry => {
            //     if (entry !== yesPart.question && noPart.question) {
            //         array.push(entry)
            //     }
            // })
            // setSecDrugArray(array)
            dispatch(triggerNotification(notify));
            setYesPart({
                ...yesPart,
                // action: "Check",
                condition: "",
                question: "",
                // operator: "=",
                value: "",
                message: ""
            })
            setNoPart({
                ...noPart,
                // action: "Message",
                condition: "",
                question: "",
                // operator: "=",
                value: "",
                message: ""
            })

            // return;
        } else if (yesPart.action === "Check" && noPart.action === "Message" && (primaryDrug.gname || primaryDrug.dcatname || primaryDrug.medical_condition_name) && (secondaryDrug.gname || secondaryDrug.dcatname || secondaryDrug.medical_condition_name || secondaryDrug.value)) {

            // if (otherData.text) {
            //     setSecondaryDrug({ "value": `${getSelectedSecondaryDrug()}:${otherData.text}` })
            //     setDisableOpt({ ...disableOpt, disable1: true, disable2: true });
            //     setOtherData({ ...otherData, text: '', sys: '', dia: '' });
            // } else if (otherData.sys && otherData.dia) {
            //     setSecondaryDrug({ "value": `${getSelectedSecondaryDrug()}:${otherData.sys}/${otherData.dia}` })
            //     setDisableOpt({ ...disableOpt, disable1: true, disable2: true });
            //     setOtherData({ ...otherData, text: '', sys: '', dia: '' });
            // }

            var rule1 = {
                slno: drugRules.length + 1,
                pdname: getSelectedPrimaryDrug(),
                // sdname: getSelectedSecondaryDrug(),
                sdname: SecDName || getSelectedSecondaryDrug(),
                ques: `${yesPart.question}#${yesPart.operator || '-'}#${yesPart.value || '-'}`,
                chk_cond: drugRules.length === 0 ? getSelectedSecondaryDrug() : "NO-",
                yes_sel: yesPart.action,
                no_sel: noPart.action,
                msg: noPart.message,
                status: "Processing"
            }
            // console.log("check and Mesg", rule1);
            dispatch(addNewRule(rule1))
            setYesPart({
                ...yesPart,
                action: "Check",
                condition: "",
                question: "",
                // operator: "=",
                value: "",
                message: ""
            })
            setNoPart({
                ...noPart,
                action: "Message",
                condition: "",
                question: "",
                // operator: "=",
                value: "",
                message: ""
            })



        } else if (yesPart.action === "Message" && noPart.action === "Check" && (primaryDrug.gname || primaryDrug.dcatname || primaryDrug.medical_condition_name) && (secondaryDrug.gname || secondaryDrug.dcatname || secondaryDrug.medical_condition_name || secondaryDrug.value)) {

            // if (otherData.text) {
            //     setSecondaryDrug({ "value": `${getSelectedSecondaryDrug()}:${otherData.text}` })
            //     setDisableOpt({ ...disableOpt, disable1: true, disable2: true });
            //     setOtherData({ ...otherData, text: '', sys: '', dia: '' });
            // } else if (otherData.sys && otherData.dia) {
            //     setSecondaryDrug({ "value": `${getSelectedSecondaryDrug()}:${otherData.sys}/${otherData.dia}` })
            //     setDisableOpt({ ...disableOpt, disable1: true, disable2: true });
            //     setOtherData({ ...otherData, text: '', sys: '', dia: '' });
            // }


            var rule2 = {
                slno: drugRules.length + 1,
                pdname: getSelectedPrimaryDrug(),
                // sdname: getSelectedSecondaryDrug(),
                sdname: SecDName || getSelectedSecondaryDrug(),
                ques: `${noPart.question}#${noPart.operator || '-'}#${noPart.value || '-'}`,
                chk_cond: getSelectedSecondaryDrug(),
                yes_sel: yesPart.action,
                no_sel: noPart.action,
                msg: yesPart.message,
                status: "Processing"
            }
            // console.log("Mesg and check", rule2);
            dispatch(addNewRule(rule2))
            setYesPart({
                ...yesPart,
                // action: "Check",
                condition: "",
                question: "",
                // operator: "=",
                value: "",
                message: ""
            })
            setNoPart({
                ...noPart,
                // action: "Message",
                condition: "",
                question: "",
                // operator: "=",
                value: "",
                message: ""
            })

        } else if (yesPart.action === "Message" && noPart.action === "Message" && (primaryDrug.gname || primaryDrug.dcatname || primaryDrug.medical_condition_name) && (secondaryDrug.gname || secondaryDrug.dcatname || secondaryDrug.medical_condition_name || secondaryDrug.value)) {


            // if (otherData.text) {
            //     setSecondaryDrug({ "value": `${getSelectedSecondaryDrug()}:${otherData.text}` })
            //     setDisableOpt({ disable1: true, disable2: true });
            //     setOtherData({ text: '', sys: '', dia: '' });
            // } else if (otherData.sys && otherData.dia) {
            //     setSecondaryDrug({ "value": `${getSelectedSecondaryDrug()}:${otherData.sys}/${otherData.dia}` })
            //     setDisableOpt({ disable1: true, disable2: true });
            //     setOtherData({ text: '', sys: '', dia: '' });
            // }           

            var rule3 = {
                slno: drugRules.length + 1,
                pdname: getSelectedPrimaryDrug(),
                // sdname: getSelectedSecondaryDrug(),
                sdname: SecDName || getSelectedSecondaryDrug(),
                ques: yesPart.message,
                chk_cond: getSelectedSecondaryDrug(),
                yes_sel: yesPart.action,
                no_sel: noPart.action,
                msg: noPart.message,
                status: "Processing"
            }
            // console.log("Mesg and Mesg", rule3);
            dispatch(addNewRule(rule3));
            setYesPart({
                ...yesPart,
                action: "Check",
                condition: "",
                question: "",
                // operator: "=",
                value: "",
                message: ""
            })
            setNoPart({
                ...noPart,
                action: "Message",
                condition: "",
                question: "",
                // operator: "=",
                value: "",
                message: ""
            })
            setYesDisabled(false)
            setMsgDisable(true)
            // setPrimaryDrug('');
            // setSecDrug('');
            // dispatch(resetRules());
            if ((primaryDrug.gname || primaryDrug.dcatname || primaryDrug.medical_condition_name) && (secondaryDrug.gname || secondaryDrug.dcatname || secondaryDrug.medical_condition_name || secondaryDrug.value)) {
                maxDslno().then(res1 => {
                    if (res1.status === 200 && res1.data) {
                        var dslno = res1.data + 1;
                        let rulesArray = [];
                        drugRules.push(rule3)
                        if (drugRules) {

                            rulesArray = drugRules.map(v => ({ ...v, dslno: dslno }))
                        }

                        addDrugRule(rulesArray).then(res2 => {
                            if (res2.status === 201) {

                                // file upload
                                var formData = new FormData();
                                formData.append("file", file)
                                formData.append("dslno", dslno)
                                uploadDrugFile(formData).then(res3 => {
                                    // console.log("file", res3);
                                })


                                //clearing values
                                setPrimaryDrug({});
                                setSecondaryDrug('');
                                dispatch(resetRules());
                                setYesPart({
                                    ...yesPart,
                                    action: "Check",
                                    condition: "",
                                    question: "",
                                    // operator: "=",
                                    value: "",
                                    message: ""
                                })
                                setNoPart({
                                    ...noPart,
                                    action: "Message",
                                    condition: "",
                                    question: "",
                                    // operator: "=",
                                    value: "",
                                    message: ""
                                })
                                let notify = {
                                    isShown: true,
                                    message: `Defined Drug Rules Added to ${dslno} `,
                                    severity: "success"
                                };
                                dispatch(triggerNotification(notify));
                            }
                        })
                    }
                })
            }
        }

        // console.log(drugRules);
    }

    // const [file, setFile] = useState('')
    // const handleFileChange = (event) => {
    //     setFile(URL.createObjectURL(event.target.files[0]))
    //     console.log(URL.createObjectURL(event.target.files[0].name));
    // }

    // eslint-disable-next-line
    const handleTextChange = (event) => {
        setOtherData({
            ...otherData,
            text: event.target.value
        })
        // setSecDrug(`${secDrug}:${event.target.value}`)
        // console.log((`${secDrug}:${event.target.value}`));
    }

    // eslint-disable-next-line
    const handleSysChange = (event) => {
        setOtherData({
            ...otherData,
            sys: event.target.value
        })
        // setSecDrug(`${secDrug}:${event.target.value}/-`)
    }

    // eslint-disable-next-line
    const handleDiaChange = (event) => {
        setOtherData({
            ...otherData,
            dia: event.target.value
        })
        // setSecDrug(`${secDrug}:${otherData.sys}/${event.target.value}`)
        // console.log(`${secDrug}:${otherData.sys}/${event.target.value}`);
    }

    const filterOptions = createFilterOptions({
        // matchFrom: 'start',
        stringify: (option) => option.gname + option.bname + option.dcatname + option.medical_condition_name,
    });
    const filterSecDrugOptions = createFilterOptions({
        // matchFrom: 'start',
        stringify: (option) => option.gname + option.bname + option.dcatname + option.medical_condition_name + option.value,
    });

    const getSelectedSecondaryDrug = () => {
        return secondaryDrug.gname || secondaryDrug.dcatname || secondaryDrug.medical_condition_name || secondaryDrug.value;
    }
    const getSelectedPrimaryDrug = () => {
        return primaryDrug.gname || primaryDrug.dcatname || primaryDrug.medical_condition_name;
    }

    return (
        <>
            <div className={classes.root}>
                <div className={classes.drugContainer}>
                    {/* Display Manage drug row with title and search box */}
                    <div className={classes.drugTitleroot}>
                        <AppBar className={classes.appBar} position="static">
                            <Toolbar className={classes.lightToolBar}>
                                <Typography className={classes.title} variant="h6" noWrap>
                                    Define Drug Rule
                                </Typography>

                            </Toolbar>
                        </AppBar>
                    </div>

                    {/* <Box width={1} className={classes.box} p={1} my={0.5}> */}
                    <Paper elevation={3} className={classes.tableRoot}>
                        {/* <pa */}

                        <Grid container >
                            <Grid item xs={1}>
                            </Grid>
                            <Grid item xs={4}>
                                {/* <Autocomplete
                                options={array}
                                getOptionLabel={(option) => option}
                                id="primary-drug"
                                onChange={handleChangePrimaryDrug}
                                debug
                                renderInput={(params) => <TextField {...params} label=" Primary Drug" margin="normal" />}
                            /> */}
                                <Autocomplete
                                    openOnFocus={true}
                                    id="primary-drug"
                                    options={primaryDrugArray}
                                    noOptionsText={"No Options Found"}
                                    loading={true}
                                    value={primaryDrug}
                                    disabled={drugRules.length !== 0}
                                    onChange={handleChangePrimaryDrug}
                                    filterOptions={filterOptions}
                                    getOptionLabel={(option) => option.gname || option.bname || option.dcatname || option.medical_condition_name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Primary Drug"
                                            placeholder="Select Primary Drug"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={1}>
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    openOnFocus={true}
                                    id="Secondary-drug"
                                    options={secDrugArray}
                                    value={secondaryDrug}
                                    disabled={primaryDrug === '' || drugRules.length !== 0}
                                    onChange={handleChangeSecondaryDrug}
                                    filterOptions={filterSecDrugOptions}
                                    getOptionLabel={(option) => option.gname || option.bname || option.dcatname || option.medical_condition_name || option.value}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Secondary-drug / Med Cond"
                                            placeholder="Select Secondary Drug / Med Cond"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={1}>
                            </Grid>

                        </Grid>
                        <Grid container >
                            <Grid item xs={3}>
                            </Grid>
                            <Grid item xs={8}>
                                {disableOpt.disable1 === false ? <TextField id="text" style={{ width: "60px", marginRight: "10px", marginTop: "10px" }} onChange={handleTextChange} label="value" /> : ''}
                                {disableOpt.disable2 === false ? <TextField id="sys" style={{ width: "60px", marginRight: "10px", marginTop: "10px" }} onChange={handleSysChange} label="Sys" /> : ''}
                                {disableOpt.disable2 === false ? <TextField id="dia" style={{ width: "60px", marginLeft: "10px", marginTop: "10px" }} onChange={handleDiaChange} label="Dia" /> : ''}
                            </Grid>
                        </Grid>
                        <Grid container >
                            <Grid item xs={3}>
                            </Grid>
                            <Grid item xs={8}>
                                {/* {
                                    drugRules.length === 0 ?
                                        // secDrug !== '' ?
                                        (secondaryDrug.gname || secondaryDrug.dcatname || secondaryDrug.medical_condition_name || secondaryDrug.value) && (primaryDrug.gname !== '' || primaryDrug.dcatname || primaryDrug.medical_condition_name) && primaryDrug !== {} && secondaryDrug !== {} ?
                                            <Typography className={classes.quesOneTitle} variant="h6" >
                                                Prev Question  <span className={classes.titleColour}> On</span> {'>'} <span className={classes.titleColour}>{(secondaryDrug.gname || secondaryDrug.dcatname || secondaryDrug.medical_condition_name || secondaryDrug.value)}</span>
                                            </Typography> : null
                                        :
                                        (secondaryDrug.gname || secondaryDrug.dcatname || secondaryDrug.medical_condition_name || secondaryDrug.value) && (primaryDrug.gname || primaryDrug.dcatname || primaryDrug.medical_condition_name) && primaryDrug !== {} && secondaryDrug !== {} ?
                                            <Typography className={classes.quesTwoTitle} variant="h6" >
                                                Prev Question <span className={classes.titleColour}>{drugRules[0].ques}</span>
                                            </Typography>
                                            : <></>
                                } */}
                                {
                                    drugRules.length === 0 ?
                                        // secDrug !== '' ?
                                        JSON.stringify(secondaryDrug) !== '{}' && JSON.stringify(primaryDrug) !== '{}' && secondaryDrug !== null && primaryDrug !== null ?
                                            <Typography className={classes.quesOneTitle} variant="h6" >
                                                Prev Question  <span className={classes.titleColour}> On</span> {'>'} <span className={classes.titleColour}>{(secondaryDrug.gname || secondaryDrug.dcatname || secondaryDrug.medical_condition_name || secondaryDrug.value)}</span>
                                            </Typography> : null
                                        :
                                        (secondaryDrug.gname || secondaryDrug.dcatname || secondaryDrug.medical_condition_name || secondaryDrug.value) && (primaryDrug.gname || primaryDrug.dcatname || primaryDrug.medical_condition_name) && primaryDrug !== '{}' && secondaryDrug !== '{}' ?
                                            <Typography className={classes.quesTwoTitle} variant="h6" >
                                                Prev Question <span className={classes.titleColour}>{drugRules[0].ques}</span>
                                            </Typography>
                                            : <></>
                                }

                            </Grid>
                        </Grid>


                        <br />

                        <Grid container  >
                            <Grid item style={{ textAlign: "center" }} xs={6}>
                                <Typography variant="body1" className={classes.yes} noWrap>
                                    Yes
                                </Typography>
                            </Grid>
                            <Grid item style={{ textAlign: "center" }} xs={6}>
                                <Typography variant="body1" className={classes.no} noWrap>
                                    No
                                </Typography>
                            </Grid>
                        </Grid>


                        <Grid container  >
                            <Grid item xs={6} className={classes.yespart}>
                                <Grid container  >
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={3} >
                                        <FormControl className={classes.action}>
                                            <InputLabel id="action-label">Action</InputLabel>
                                            <Select
                                                labelId="action-select-label"
                                                id="action-select"
                                                onChange={handleChangeYesAction}
                                                value={yesPart.action}
                                            >
                                                <MenuItem value="Check">Check</MenuItem>
                                                <MenuItem value="Message">Message</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item style={{ marginLeft: "20px", marginTop: "18px" }}  >
                                        <RadioGroup row aria-label="condition" onChange={handleChangeYesCondition} name="NoCondition" defaultValue="NoCondition">
                                            <FormControlLabel value="condition" disabled={yesDisable} control={<Radio color="primary" />} label="Condition" />
                                            <FormControlLabel value="NoCondition" disabled={yesDisable} control={<Radio color="primary" />} label="No Condition" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                                <Grid container  >
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={7} >
                                        {/* <Autocomplete
                                            options={secDrugArray}
                                            getOptionLabel={(option) => option}
                                            id="question"
                                            freeSolo
                                            includeInputInList
                                            value={yesPart.question}
                                            onInputChange={handleAutocompleteInputChange}
                                            // onChange={(e, option, reason) => {
                                            //     if (reason === "remove-option") {
                                            //         console.log("delete", e.currentTarget.parentElement.innerText);
                                            //         let array = []
                                            //         // eslint-disable-next-line
                                            //         secDrugArray.map(entry => {
                                            //             if (entry !== e.currentTarget.parentElement.innerText) {
                                            //                 array.push(e.currentTarget.parentElement.innerText)
                                            //             }
                                            //         })
                                            //         setSecDrugArray(array)
                                            //     }

                                            // }}
                                            disabled={yesDisable}
                                            // onChange={handleChangeYesQuestion}
                                            renderInput={(params) => <TextField {...params} label="Question" margin="normal" />}
                                        /> */}

                                        <Autocomplete
                                            openOnFocus={true}
                                            id="question-yes"
                                            options={quesArray}
                                            noOptionsText={"No Options Found"}
                                            freeSolo
                                            // value={yesPart.question}
                                            inputValue={yesPart.question}
                                            style={{ marginTop: "16px" }}
                                            disabled={yesDisable}
                                            onInputChange={handleYesQuesChange}
                                            // onChange={handleYesQuesChange}
                                            filterOptions={filterSecDrugOptions}
                                            getOptionLabel={(option) => option.gname || option.bname || option.dcatname || option.medical_condition_name || option.value }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Question"
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={2} >
                                        <FormControl style={{ minWidth: "50px", marginTop: "16px", marginLeft: "20px" }}>
                                            <InputLabel id="demo-simple-select-label">Ope</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                defaultValue={"="}
                                                value={yesPart.operator}
                                                disabled={yesDisable || condDisable}
                                                onChange={handleChangeYesOperater}
                                            >
                                                <MenuItem value={"="}>{"="}</MenuItem>
                                                <MenuItem value={">"} >{">"}</MenuItem>
                                                <MenuItem value={"<"}>{"<"}</MenuItem>
                                                <MenuItem value={"<="}>{"<="}</MenuItem>
                                                <MenuItem value={">="}>{">="}</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                    <Grid item xs={1} >
                                        <TextField style={{ minWidth: "50px", marginTop: "16px" }} id="value" value={yesPart.value} disabled={yesDisable || condDisable} onChange={handleChangeYesValue} label="Value" />
                                    </Grid>
                                </Grid>
                                <Grid container  >
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={8} >
                                        <TextField
                                            style={{ marginTop: "16px", minWidth: "400px" }}
                                            id="message"
                                            label="Message"
                                            multiline
                                            value={yesPart.message}
                                            disabled={msgDisable}
                                            rows={3}
                                            // defaultValue="Message"
                                            onChange={handleChangeYesMsg}
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>

                            {/*  no part */}
                            <Grid item xs={6}>
                                <Grid container className={classes.nopart} >
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={3} >
                                        <FormControl className={classes.action}>
                                            <InputLabel id="action-no">Action</InputLabel>
                                            <Select
                                                labelId="action-select-label"
                                                id="action-select-no"
                                                onChange={handleChangeNoAction}
                                                value={noPart.action}
                                            >
                                                <MenuItem value="Check">Check</MenuItem>
                                                <MenuItem value="Message">Message</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item style={{ marginLeft: "20px", marginTop: "18px" }}  >
                                        <RadioGroup row aria-label="condition" onChange={handleChangeNoCondition} name="NoCondition" defaultValue="NoCondition">
                                            <FormControlLabel value="condition" disabled={noDisable} control={<Radio color="primary" />} label="Condition" />
                                            <FormControlLabel value="NoCondition" disabled={noDisable} control={<Radio color="primary" />} label="No Condition" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                                <Grid container  >
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={7} >
                                        {/* <Autocomplete
                                            options={secDrugArray}
                                            getOptionLabel={(option) => option}
                                            id="question"
                                            freeSolo
                                            // disabled = { primaryDrug === '' && secDrug === '' }
                                            includeInputInList
                                            onInputChange={handleChangeQuestion}
                                            disabled={noDisable}
                                            value={noPart.question}
                                            // onChange={handleChangeYesQuestion}
                                            renderInput={(params) => <TextField {...params} label="Question" margin="normal" />}
                                        /> */}
                                        <Autocomplete
                                            openOnFocus={true}
                                            id="question-no"
                                            options={noQuesArray}
                                            noOptionsText={"No Options Found"}
                                            freeSolo
                                            // value={noPart.question}
                                            inputValue={noPart.question}
                                            style={{ marginTop: "17px" }}
                                            disabled={noDisable}
                                            onInputChange={handleChangeNoQuestion}
                                            filterOptions={filterSecDrugOptions}
                                            getOptionLabel={(option) => option.gname || option.bname || option.dcatname || option.medical_condition_name}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Question"
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={2} >
                                        <FormControl style={{ minWidth: "50px", marginTop: "16px", marginLeft: "20px" }}>
                                            <InputLabel id="demo-simple-select-label">Ope</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                defaultValue={"="}
                                                value={noPart.operator}
                                                disabled={noDisable || noCondDisable}
                                                onChange={handleChangeNoOperater}
                                            >
                                                <MenuItem value={"="}>{"="}</MenuItem>
                                                <MenuItem value={">"} >{">"}</MenuItem>
                                                <MenuItem value={"<"}>{"<"}</MenuItem>
                                                <MenuItem value={"<="}>{"<="}</MenuItem>
                                                <MenuItem value={">="}>{">="}</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                    <Grid item xs={1} >
                                        <TextField style={{ minWidth: "50px", marginTop: "16px" }} value={noPart.value} id="value" disabled={noDisable || noCondDisable} onChange={handleChangeNoValue} label="Value" />
                                    </Grid>
                                </Grid>
                                <Grid container  >
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={8} >
                                        <TextField
                                            style={{ marginTop: "16px", minWidth: "400px" }}
                                            id="message"
                                            label="Message"
                                            multiline
                                            disabled={noMsgDisable}
                                            rows={3}
                                            value={noPart.message}
                                            // defaultValue="Message"
                                            onChange={handleChangeNoMsg}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            yesPart.action === "Message" && noPart.action === "Message" && primaryDrug !== null && secondaryDrug !== null && JSON.stringify(primaryDrug) !== '{}' && JSON.stringify(secondaryDrug) !== '{}' ?
                                <div className={classes.file}>
                                    <Grid container className={classes.grid} >
                                        <Grid item xs={4}></Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body1" className={classes.lable} gutterBottom>
                                                Select File  :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <input type="file" accept=".pdf" onChange={handleFileChange} />
                                        </Grid>
                                    </Grid>
                                </div>
                                : <> </>
                        }
                        <div className={classes.button}>
                            <Button variant="contained" onClick={() => Define()} >Define</Button>
                        </div>

                        {/* </Box> */}
                    </Paper>

                </div>


            </div >


            <Notify />
        </>
    )
}

export default DefineDrugRule;