import {
  NEW_DATA, EDIT_PATIENT, UPDATE_DATA,
  // SELECTED_PATIENT, 
  NEW_PATIENT, SET_PATIENT_ID
} from './actionType'

export const initialState = {
  patient_id: '',
  patientList: [],
  patientListToFilter: [],
  filterPatient: {
    pccPatientId: '',
    patient_id: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    age: '',
    birth_date: '',
    facility_id: '',
    gender: '',
    height: '',
    weight: '',
    systolic: '',
    kidneyfx: '',
    kidneyFxNumber: null,
    life_expectancy: '',
    diastolic: '',
    hospice: '',
    phq9: '',
    bmi: '',
    bims: '',
    medcond: '',
    createdatetime: '',
    updatedatetime: '',
    lastaccessedby: '',
    createdby: '',
    updatedby: '',
    isactive:''
  },
  patientCount: 0,
  // selectedPatient: {
  //   first_name: '',
  //   last_name: '',
  //   middle_name: '',
  //   age: '',
  //   birth_date: '',
  //   facility_id: '',
  //   gender: '',
  //   height: '',
  //   weight: '',
  //   systolic: '',
  //   kidneyfx: '',
  //   life_expectancy: '',
  //   diastolic: '',
  //   hospice: '',
  //   phq9: '',
  //   bmi: '',
  //   medcond: '',
  //   pccPatientId: ''
  // }
}


const patientSearchReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case NEW_DATA: {
      const { payload } = action;
      const data = payload;

      const newState = {
        ...state,
        patientList: data.dataArray,
        patientListToFilter: data.dataArray,
        patientCount: data.count,
      }
      return newState
    }

    case EDIT_PATIENT: {
      const { payload } = action;
      const patient = payload;

      const newState = {
        ...state,
        filterPatient: {
          // firstName: patient.first_name,
          // lastName: patient.last_name,
          // facility: patient.facility_id,
          // dateOfBirth: patient.birth_date,
          // middleName: patient.middle_name,
          // age: patient.age
          pccPatientId: patient.pcc_patientid || patient.pccPatientId || patient.patientId || '', // patientId coming from pcc
          patient_id: patient.patient_id,
          first_name: patient.first_name || patient.firstName,
          last_name: patient.last_name || patient.lastName,
          middle_name: patient.middle_name,
          age: patient.age,
          birth_date: patient.birth_date || patient.birthDate,
          facility_id: patient.facility_id || patient.facId,
          gender: patient.gender,
          height: patient.height,
          weight: patient.weight,
          systolic: patient.systolic,
          kidneyfx: patient.kidneyfx,
          kidneyFxNumber: patient.kidneyFxNumber,
          life_expectancy: patient.life_expectancy,
          diastolic: patient.diastolic,
          hospice: patient.hospice,
          phq9: patient.phq9,
          bmi: patient.bmi,
          bims: patient.bims,
          medcond: patient.medcond,
          createdatetime: patient.createdatetime,
          updatedatetime: patient.updatedatetime,
          lastaccessedby: patient.lastaccessedby,
          createdby: patient.lastaccessedby,
          updatedby: patient.updatedby,
          isactive: patient.isactive,
          allergy:patient.allergy,
          allergies:patient.allergies,
        },
        // selectedPatient: {
        //   first_name: '',
        //   last_name: '',
        //   middle_name: '',
        //   age: '',
        //   birth_date: '',
        //   facility_id: '',
        //   gender: '',
        //   height: '',
        //   weight: '',
        //   systolic: '',
        //   kidneyfx: '',
        //   life_expectancy: '',
        //   diastolic: '',
        //   hospice: '',
        //   phq9: '',
        //   bmi: '',
        //   medcond: '',
        //   pccPatientId: ''
        // }
      }
      return newState
    }

    case UPDATE_DATA: {
      const newState = {
        ...state,
      }
      return newState
    }

    // case SELECTED_PATIENT: {
    //   const { payload } = action;
    //   const selectedPatient = payload;

    //   const newState = {
    //     ...state,
    //     selectedPatient: {
    //       ...selectedPatient,
    //       // patient_id: selectedPatient.patient_id,
    //       // firstName: selectedPatient.firstName,
    //       first_name: selectedPatient.first_name,
    //       last_name: selectedPatient.last_name,
    //       middle_name: selectedPatient.middle_name,
    //       age: selectedPatient.age,
    //       birth_date: selectedPatient.birth_date,
    //       facility_id: selectedPatient.facility_id,
    //       gender: selectedPatient.gender,
    //       height: selectedPatient.height,
    //       weight: selectedPatient.weight,
    //       systolic: selectedPatient.systolic,
    //       kidneyfx: selectedPatient.kidneyfx || selectedPatient.kidneyFxNumber,
    //       life_expectancy: selectedPatient.life_expectancy,
    //       diastolic: selectedPatient.diastolic,
    //       hospice: selectedPatient.hospice,
    //       phq9: selectedPatient.phq9,
    //       bmi: selectedPatient.bmi,
    //       medcond: selectedPatient.medcond,
    //       pccPatientId: selectedPatient.pccPatientId
    //     }
    //   }
    //   return newState
    // }

    case NEW_PATIENT: {
      const newState = {
        ...state,
        filterPatient: {
          patient_id: '',
          first_name: '',
          last_name: '',
          middle_name: '',
          age: '',
          birth_date: '',
          facility_id: '',
          gender: '',
          height: '',
          weight: '',
          systolic: '',
          kidneyfx: '',
          kidneyFxNumber: null,
          life_expectancy: '',
          diastolic: '',
          hospice: '',
          phq9: '',
          bmi: '',
          bims: '',
          medcond: '',
          pccPatientId: '',
          createdatetime: '',
          updatedatetime: '',
          lastaccessedby: '',
          createdby: '',
          updatedby: '',
          isactive:''
        },
        // selectedPatient: {
        //   first_name: '',
        //   last_name: '',
        //   middle_name: '',
        //   age: '',
        //   birth_date: '',
        //   facility_id: '',
        //   gender: '',
        //   height: '',
        //   weight: '',
        //   systolic: '',
        //   kidneyfx: '',
        //   life_expectancy: '',
        //   diastolic: '',
        //   hospice: '',
        //   phq9: '',
        //   bmi: '',
        //   medcond: '',
        //   pccPatientId: ''
        // }
      }
      return newState
    }
    case SET_PATIENT_ID: {

      const { payload } = action;
      const pid = payload;
      const newState = {
        ...state,
        patient_id: pid
      }
      return newState
    }


    default:
      return state
  }
}

export default patientSearchReducer;