import {ADD_Admission_File, Add_DRUGRULE_FILE, ADD_FAX_FILE, RESET} from "./actionType";

export const initialState = {
    admissionFile: {
        patientId: '',
        admissionFileName: '',
    },
    rule: {
        dslno: '',
        // fileName: ''
    },
    faxFile:{
        patientId: '',
        faxFileName: '',
    }

}

const viewFiles = (state = initialState, action = {}) => {
    switch (action.type) {
        case ADD_Admission_File: {
            const { payload } = action;
            const data = payload

            const newState = {
                ...state,
                admissionFile: {
                    patientId: data.patient_id,
                    admissionFileName: data.admission_file_name
                }
            }
            return newState
        }

        case Add_DRUGRULE_FILE: {
            const { payload } = action;
            const data = payload

            const newState = {
                ...state,
                rule: {
                    dslno: data.dslno,
                    // admissionFileName: data
                }
            }
            return newState
        }

        case ADD_FAX_FILE: {
            const { payload } = action;
            const data = payload

            const newState = {
                ...state,
                faxFile: {
                    patientId: data.patient_id,  
                    faxFileName: data.fax_file_name                  
                }
            }
            return newState
        }

        case RESET: {
            const newState = {
                patient: {
                    patientId: '',
                    // admissionFileName: '',
                },
                faxFile: {
                    patientId: ''                    
                },
                rule: {
                    dslno: '',
                    // fileName: ''
                }
            }
            return newState
        }
        
        default:
            return state
    }
}

export default viewFiles;