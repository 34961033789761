import { NEW_DOSAGE, DELETE_DOSAGE, RESET_DOSAGE } from './actionType'

export const initialState = {
    drugDosageList: [],
    // selectedDosage: {
    //     patient_id: '',
    //     dgname: '',
    //     dosg: '',
    //     dosgtype: '',
    //     days: '',
    //     times: '',
    //     prn: '',
    //     prn30: '',
    //     allergy: [],
    //     dsgform: '',
    //     route: '',
    //     stpdate: '',
    //     ainfo: ''
    // }


}


const drugDosageReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case NEW_DOSAGE: {
            const { payload } = action;
            const data = payload

            const newState = {
                ...state,
                drugDosageList: [...state.drugDosageList, data]
            }
            return newState
        }

        // case EDIT_DOSAGE: {
        //     const { payload } = action;

        //     const dosage = payload
        //     if (dosage !== '') {
        //         const newState = {
        //             ...state,
        //             selectedDosage: {

        //                 patient_id: dosage.patient_id,
        //                 dgname: dosage.dgname,
        //                 dosg: dosage.dosg,
        //                 dosgtype: dosage.dosgtype,
        //                 days: dosage.days,
        //                 times: dosage.times,
        //                 prn: dosage.prn,
        //                 prn30: dosage.prn30,
        //                 allergy: dosage.allergy,
        //                 dsgform: dosage.dsgform,
        //                 route: dosage.route,
        //                 stpdate: dosage.stpdate,
        //                 ainfo: dosage.ainfo
        //             }
        //         }
        //         return newState
        //     }

        //     break;
        // }

        case DELETE_DOSAGE: {
            const { payload } = action;
            const dosage = payload
            const array = [];

            // eslint-disable-next-line
            state.drugDosageList.map((item) => {
                if (JSON.stringify(item) === JSON.stringify(dosage)) {
                } else {
                    array.push(item)
                }
                
            })

            const newState = {
                ...state,
                drugDosageList: array
            }
            return newState


        }

        case RESET_DOSAGE: {

            const newState = {
                ...state,
                drugDosageList: []
            }
            return newState

        }





        default:
            return state
    }
}

export default drugDosageReducer;
