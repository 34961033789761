import React, { useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
// import DateFnsUtils from '@date-io/date-fns';
// import {
//     MuiPickersUtilsProvider,
//     KeyboardDatePicker,
// } from '@material-ui/pickers';
import 'date-fns';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationAction from "../../common/TablePaginationAction";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from "@material-ui/core/styles";
import {
    // getAllergy, 
    getDrugs, getPatientDosageById
} from "../../../util/api"
import { triggerNotification } from "../../../store/notification/actionCreator";
import Notify from "../../Notification/Notify";
import { useDispatch, useSelector } from "react-redux";
import { addDosage, deleteDosage } from "../../../store/drugDosage/actionCreator";
// import Skeleton from '@material-ui/lab/Skeleton';
// import RightArrowSVG from "../../../images/RightArrow.svg";
import DeleteSvg from "../../../images/Delete.svg";
import Icon from "@material-ui/core/Icon";
import { useHistory, useRouteMatch } from 'react-router-dom';

const StyledTableCell = withStyles((theme) => ({
    root: {
        padding: "12px",
        borderBottom: "none",
        textAlign: "center"
    },
    head: {
        backgroundColor: theme.palette.header,
        color: theme.palette.common.white,
        fontWeight: "bold"
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);


const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        border: "1px solid #dadada",
        borderRadius: "15px",
        marginBottom: "10px",
        padding: "8px"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textFieldStyle: {
        minWidth: 250,
        margin: "0px",
        marginTop: "18px",
        marginLeft: "8px"
    },
    container: {
        background: "white",
        marginTop: "20px",
        borderRadius: "15px"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    customtablecell: {
        whiteSpace: "normal",
        wordWrap: "break-word",
    },
    heading1: {
        textAlign: "center",
        marginBottom: "20px",
        paddingTop: "20px"
    },
    dateofbirth: {
        marginLeft: "8px",
        marginTop: "18px"
    },
    lable: {
        marginTop: "20px",
        marginLeft: "8px"
    },
    lableContent: {
        marginTop: "8px"
    },
    lableContent: {
        marginTop: "0px"
    },
    selectmed: {
        marginLeft: '8px',
        marginTop: '28px',
        overflowX: 'hidden',
        boxSizing: 'border-box',
    },
    heading2: {
        marginTop: "50px",
        textAlign: 'center'
    },
    units: {
        minWidth: 100
    },
    weight: {
        marginLeft: "0px"
    },
    phq: {
        marginLeft: "0px"
    },
    addButton: {
        textAlign: 'center',
        paddingBottom: "20px",
        paddingTop: "40px"
    },
    selectminwidth: {
        minWidth: "100px"
    },
    dosageForm: {
        minWidth: "200px"
    },
    medicationselect: {
        margin: "inherit"
    },
    route: {
        minWidth: "100px",
        marginTop: "10px",
        marginLeft: "8px"
    },
    frequency: {
        minWidth: "100px",
        marginTop: "10px",
        marginLeft: "-8px"
    },
    addDrugDropDown: {
        // margin: theme.spacing(1),
        minWidth: "275px",
        maxWidth: "275px"
    },
    nextButton: {
        // textAlign: "center",
        display: "flex",
        paddingBottom: "20px",
        paddingTop: "40px"
    },
    tableRoot: {
        borderRadius: "15px",
    },
    tableContainer: {
        borderRadius: "15px"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    tableTitle: {
        fontWeight: "500"
    },
    next: {
        marginLeft: "auto"
    }
}));


export const AddDrugDosage = () => {

    const classes = useStyles();


    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    // const [allergyData, setAllergyData] = useState([]);
    const [drugColl, setDrugColl] = React.useState([]);
    const [data] = useState([]);
    const [rows, setRows] = useState([]);
    const dosageData = useSelector(state => state.drugDosage.drugDosageList);
    // const editData = useSelector(state => state.drugDosage.selectedDosage)
    const history = useHistory();
    const { url } = useRouteMatch();
    // const [medicationInfo, setMedicationInfo] = useState({
    //     medication: '',
    //     allergy: [],
    //     strength: "",
    //     units: "",
    //     adminQty: "",
    //     dosageForm: "",
    //     route: "",
    //     frequency: "",
    //     prn: "",
    //     aditionalInfo: "",
    //     stopDate: new Date(),
    //     patientId: ""
    // })

    const [medicationInfo, setMedicationInfo] = useState({

        patient_id: '',
        dgname: '',
        dosg: '',
        dosgtype: 'mg',
        days: 'QD',
        times: '',
        prn: 'No',
        prn30: 'No',
        allergy: '',
        dsgform: "Tabs/Caps",
        route: 'P.O',
        stpdate: "",
        ainfo: ''
    })
    // const [drugDosageColl, setDrugDosageColl] = useState([]);
    const patientBasics = useSelector(state => state.patientSearch.filterPatient)
    const patient = useSelector(state => state.patientSearch.filterPatient)
    const dispatch = useDispatch();
    const [dosageArray] = useState([]);
    const [loading, setLoading] = useState(true);
    const handleChangeMedication = (event, value) => {
        setMedicationInfo({
            ...medicationInfo,
            // medication: event.target.value
            // dgname: value.gname
            dgname: value,
            allergy: patient.allergy.toString()
            // medication: `${event.target.value.bname ,",", event.target.value.combs }`
        })
    }

    const handleChangeStrength = (event) => {
        setMedicationInfo({
            ...medicationInfo,
            // strength: event.target.value
            dosg: event.target.value
        })
    }

    const handleChangeUnits = (event, value) => {
        setMedicationInfo({
            ...medicationInfo,
            // units: event.target.value
            dosgtype: value
        })
    }

    const handleChangeAdminQty = (event) => {
        setMedicationInfo({
            ...medicationInfo,
            // adminQty: event.target.value
            times: event.target.value

        })
    }

    const handleChangeDosageForm = (event, value) => {
        setMedicationInfo({
            ...medicationInfo,
            // dosageForm: event.target.value
            dsgform: value
        })
    }

    const handleChangeRoute = (event, value) => {
        setMedicationInfo({
            ...medicationInfo,
            route: value
        })
    }

    const handleChangeFrequency = (event, value) => {
        setMedicationInfo({
            ...medicationInfo,
            // frequency: event.target.value
            days: value
        })
    }

    const handleChangePRN = (event) => {
        setMedicationInfo({
            ...medicationInfo,
            prn: event.target.value
        })
    }
    const handleChangePRN30 = (event) => {
        setMedicationInfo({
            ...medicationInfo,
            prn30: event.target.value
        })
    }

    const handleChangeAditionalInfo = (event) => {
        setMedicationInfo({
            ...medicationInfo,
            // aditionalInfo: event.target.value
            ainfo: event.target.value
        })
    }

    const handleStopDate = (event, date) => {
        setMedicationInfo({
            ...medicationInfo,
            // stopDate: date
            stpdate: event.target.value
        })
    }

    // const handleChangeAllergy = (event, value) => {
    //     setMedicationInfo({
    //         ...medicationInfo,
    //         allergy: value
    //     })
    // }

    // addDosage

    useEffect(() => {
        if (patient && !dosageData.length) {
            getPatientDosageById(patient.patient_id + '').then(res => {
                if (res.status === 200 && res.data) {
                    let data = [];
                    data = res.data
                    data.map(
                        d => dispatch(addDosage(d))
                    )

                }
            })
        }

        // eslint-disable-next-line 
    }, []);

    useEffect(() => {


        if (dosageData !== '') {
            setLoading(false);
        }

        // eslint-disable-next-line 
    }, [dosageData]);


    // useEffect(() => {
    //     console.log(patientBasics);
    //     // setMedicationInfo({
    //     //     patientId: patientBasics.patientId
    //     // })

    //     getAllergy().then(allergy => {
    //         if (allergy.status === 200 && allergy.data) {
    //             var names = allergy.data
    //             let allergyData = []

    //             names.map((item) => {
    //                 // return allergyData.push({ allergy: item.allergy_name });
    //                 return allergyData.push(item.allergy_name);
    //             })
    //             if (allergyData !== '') {

    //                 setAllergyData(allergyData);
    //             }

    //         }
    //     })

    //     // eslint-disable-next-line
    // }, [])

    const unitsColl = ["mg", "ml", "mcg", "mEq", "%", "cm", "g", "IU", "grain", "mg/ml", "U", "units/ml"]


    const dosageFormColl = ["Tabs/Caps", "Amp", "Application", "Apply", "Drop", "Enema", "Gram", "gtts", "INH", "INJ", "mg", "ml", "Patch", "Spray", "tbsp", "vial", "U"]

    const routeColl = ["P.O", "A.D", "A.S", "AAA", "EN", "G-Tube", "ID", "IM", "IN", "INH", "IV", "N", "Neb", "O.D", "o.S", "O.U", "PEG-Tube", "P.R", "P.V", "S.L", "SQ", "Top"]

    const frequencyColl = ["QD", "QAM", "BID", "TID", "QID", "QH", "Q2H", "Q3H", "Q4H", "Q1-2H", "Q2-3H", "Q2-4H", "Q4-6H", "Q6-8H", "Q6H", "Q8H", "Q12H", "QOD", "QW", "Q2W", "Q3W", "Q4W", "QM", "HS", "ACQD", "ACBID", "ACTID", "ACHS", "PCQD", "PCBID", "PCTID", "PCHS", "QPM"];


    useEffect(() => {

        getDrugs().then(response => {
            if (response.status === 200 && response.data) {
                setDrugColl(response.data);

            }
        })
    }, [])

    const removeDosage = (dosage) => {
        dispatch(deleteDosage(dosage))
        // console.log("edit dosage", dosage);
    }



    //    var dosageArray =[]

    const next = () => {

        if (dosageData.length === 0) {
            let notify = {
                isShown: true,
                message: "Please add atleast one medication",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        // if (medicationInfo !== '') {

        //     array.push(medicationInfo)
        //     if (array !== '') {
        //         // setDrugDosageColl(...drugDosageColl, array);
        //         drugDosageColl.push(array);
        //         console.log(drugDosageColl);
        //     }

        // }

        // addPatientDosageAPI(dosageArray).then(response => {
        //     console.log(response);
        //     if (response.status === 201) {
        //         let notify = {
        //             isShown: true,
        //             message: "New Dosage added successfully",
        //             severity: "success"
        //         };
        //         dispatch(triggerNotification(notify));
        //     }
        // })
        // dispatch(addDosage(dosageArray))
        let newUrl = url.replace(/.$/, "4");
        history.push(newUrl)

    }

    const back = () => {
        let newUrl = url.replace(/.$/, "2");
        history.push(newUrl)
    }


    const addDosageArray = () => {
        // var array = [];
        // var dosageArray =[];

        // array.push(medicationInfo)
        // if (medicationInfo !== '') {

        //     array.push(medicationInfo)
        //     if (array !== '') {
        //         // setDrugDosageColl(...drugDosageColl, array);
        //         drugDosageColl.push(array);
        //         console.log(drugDosageColl);
        //     }

        // }
        medicationInfo.allergy = patient.allergy.toString();
        if (medicationInfo.dgname.gname === "" || medicationInfo.dgname.gname === undefined) {
            let notify = {
                isShown: true,
                message: "Please Select Medication",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return
        }

        if (dosageData.length !== 0) {
            for (let i = 0; i < dosageData.length; i++) {
                if (dosageData[i].dgname === medicationInfo.dgname.gname) {
                    let notify = {
                        isShown: true,
                        message: "Medication Already taken",
                        severity: "warning"
                    };
                    dispatch(triggerNotification(notify));
                    // break;
                    return;
                }
            }
        }

        var newDosage = {

            patient_id: patientBasics.patientId,
            dgname: medicationInfo.dgname.gname,
            dosg: medicationInfo.dosg || " ",
            dosgtype: medicationInfo.dosgtype,
            days: medicationInfo.days,
            times: medicationInfo.times || " ",
            prn: medicationInfo.prn,
            prn30: medicationInfo.prn30,
            // allergy: medicationInfo.allergy.map(a => a).join(),
            dsgform: medicationInfo.dsgform,
            route: medicationInfo.route,
            stpdate: medicationInfo.stpdate || " ",
            ainfo: medicationInfo.ainfo || " ",
            allergy: medicationInfo.allergy || " "
        }

        // console.log(newDosage);

        if (newDosage !== '') {


            dosageArray.push(newDosage);
            // dosageData.push(newDosage);
            dispatch(addDosage(newDosage))
            // let newUrl = url.replace(/.$/, "4");
            // history.push(newUrl)
            setMedicationInfo({
                // patient_id: '',
                // dgname: '',
                // dosg: '',
                // dosgtype: '',
                // days: '',
                // times: '',
                // prn: '',
                // prn30: '',
                // allergy: [],
                // dsgform: '',
                // route: '',
                // stpdate: new Date(),
                // ainfo: ''
                patient_id: '',
                dgname: '',
                dosg: '',
                dosgtype: 'mg',
                days: 'QD',
                times: '',
                prn: 'No',
                prn30: 'No',
                allergy: [],
                dsgform: "Tabs/Caps",
                route: 'P.O',
                stpdate: "",
                ainfo: ''
            })
        }

    }

    useEffect(() => {
        if (data !== '') {
            setRows([...rows, data]);
        }
        // eslint-disable-next-line
    }, [data])



    // 

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getMinDate = () => {
        const d = new Date();
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
       
        return [year, month, day].join('-').toString();
    }

    const formatDate = (stopdate) => {
        var date = new Date(stopdate);
        if (!isNaN(date.getTime())) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [month, day, year].join('-').toString();
        }
    }

    const filterOptions = createFilterOptions({
        // matchFrom: 'start',
        stringify: (option) => option.gname + option.bname,
    });


    // 
    const renderHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Medication</b>

                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Strength</b>

                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Units</b>

                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Admin Qty</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Dosage Form</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Route</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Frequency</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>PRN</b>
                        </Typography>
                    </StyledTableCell>
                    {/* <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Allergy</b>
                        </Typography>
                    </StyledTableCell> */}
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Stop Date</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Additional Info</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Action</b>
                        </Typography>
                    </StyledTableCell>

                </TableRow>
            </TableHead>
        )
    }


    const renderContent = () => {
        return (
            <TableBody>
                {(
                    rowsPerPage > 0
                        ? dosageData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : dosageData

                ).map((data, index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell >
                            <Typography variant="caption" className={classes.customtablecell} noWrap>
                                <b className={classes.tableTitle}>{data.dgname}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell >
                            <Typography variant="caption" className={classes.customtablecell} noWrap>
                                {/*<b className={classes.tableTitle}>{data.strength}</b> */}
                                <b className={classes.tableTitle}>{data.dosg}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell >
                            <Typography variant="caption" className={classes.customtablecell} noWrap>
                                {/*<b className={classes.tableTitle}>{data.units}</b> */}
                                <b className={classes.tableTitle}>{data.dosgtype}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell >
                            <Typography variant="caption" className={classes.customtablecell} noWrap>
                                {/*<b className={classes.tableTitle}>{data.adminQty}</b> */}
                                <b className={classes.tableTitle}>{data.times}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell >
                            <Typography variant="caption" className={classes.customtablecell} noWrap>
                                {/*<b className={classes.tableTitle}>{data.dosageForm}</b> */}
                                <b className={classes.tableTitle}>{data.dsgform}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell >
                            <Typography variant="caption" className={classes.customtablecell} noWrap>
                                {/*<b className={classes.tableTitle}>{data.route}</b> */}
                                <b className={classes.tableTitle}>{data.route}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell >
                            <Typography variant="caption" className={classes.customtablecell} noWrap>
                                {/*<b className={classes.tableTitle}>{data.frequency}</b> */}
                                <b className={classes.tableTitle}>{data.days}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell >
                            <Typography variant="caption" className={classes.customtablecell} noWrap>
                                {/*<b className={classes.tableTitle}>{data.prn}</b> */}
                                <b className={classes.tableTitle}>{data.prn}</b>
                            </Typography>
                        </StyledTableCell>
                        {/* <StyledTableCell >
                            <Typography variant="caption" className={classes.customtablecell} noWrap>
                                
                                <b className={classes.tableTitle}>{data.allergy}</b>
                            </Typography>
                        </StyledTableCell> */}
                        <StyledTableCell >
                            <Typography variant="caption" className={classes.customtablecell} noWrap>
                                {/*<b className={classes.tableTitle}>{data.stopDate}</b> */}
                                <b className={classes.tableTitle}>{formatDate(data.stpdate)}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell >
                            <Typography variant="caption" className={classes.customtablecell} noWrap>
                                {/*<b className={classes.tableTitle}>{data.aditionalInfo}</b> */}
                                <b className={classes.tableTitle}>{data.ainfo}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Icon
                                onClick={() => removeDosage(data)}
                                classes={{
                                    root: classes.customSVG
                                }}>
                                <img alt="add" src={DeleteSvg} height={24} width={24} />
                            </Icon>
                        </StyledTableCell>



                    </StyledTableRow>
                ))}
            </TableBody>
        )
    }

    const renderFooter = () => {
        return (
            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5]}
                        colSpan={12}
                        count={dosageData.length}
                        rowsPerPage={5}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationAction}
                    />
                </TableRow>
            </TableFooter>
        )
    }





    const renderTable = () => {



        return (
            <>

                {

                    loading ?

                        null
                        :
                        <Paper elevation={3} className={classes.tableRoot}>
                            <TableContainer className={classes.tableRoot}>
                                <Table stickyHeader aria-label="sticky table">
                                    {dosageData.length !== 0 ? renderHeader() : null}
                                    {dosageData.length !== 0 ? renderContent() : null}
                                    {dosageData.length !== 0 ? renderFooter() : null}
                                    {/* <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[2]}
                                                colSpan={11}
                                                count={dosageData.length}
                                                rowsPerPage={2}
                                                page={page}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationAction}
                                            />
                                        </TableRow>
                                    </TableFooter> */}
                                </Table>
                            </TableContainer>
                        </Paper>

                }
            </>
        )
    }


    return (

        <>
            <Typography variant="h6" gutterBottom>
                ADD DRUG DOSAGE
            </Typography>
            <Container className={classes.container} fixed>
                <div className={classes.root}>

                    <Grid container spacing={2} >

                        <Grid item xs={4}>
                            <FormControl className={classes.addDrugDropDown}>
                                {/* <InputLabel id="medication-label">Select Drug</InputLabel>
                                <Select
                                    autoWidth="false"
                                    labelId="medication-select-label"
                                    id="medication-select"
                                    onChange={handleChangeMedication}
                                    value={medicationInfo.dgname}
                                >

                                    {drugColl?.map((drug) => (<MenuItem value={`${drug.gname}`} key={drug.drug_id} > {drug.bname} {":"} {drug.combs} </MenuItem>))}

                                </Select> */}

                                <Autocomplete
                                    openOnFocus={true}
                                    id="medication-select-id"
                                    options={drugColl}
                                    noOptionsText={"No Options Found"}
                                    // loading={true}       
                                    value={medicationInfo.dgname}
                                    filterOptions={filterOptions}
                                    onChange={handleChangeMedication}
                                    getOptionLabel={(option) => option.gname}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {// eslint-disable-next-line
                                                option.bname + ":" + "( " + option.gname + " )"}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Select medication"
                                            placeholder="Type here"
                                        />
                                    )}
                                />
                            </FormControl>

                        </Grid>
                        <Grid item xs={1}>
                            <TextField id="strength" value={medicationInfo.dosg} onChange={handleChangeStrength} label="Strength" />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl className={classes.units}>

                                <Autocomplete
                                    freeSolo
                                    openOnFocus={true}
                                    id="select-units"
                                    options={unitsColl}
                                    defaultValue={"mg"}
                                    value={medicationInfo.dosgtype}
                                    noOptionsText={"No Options Found"}
                                    onInputChange={handleChangeUnits}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="units"
                                            placeholder="Type here"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2} >
                            <TextField id="admin-qty" value={medicationInfo.times} onChange={handleChangeAdminQty} type='number' label="Admin QTY" />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl className={classes.dosageForm}>

                                <Autocomplete
                                    freeSolo
                                    openOnFocus={true}
                                    id="select-units"
                                    options={dosageFormColl}
                                    defaultValue={"Tabs/Caps"}
                                    noOptionsText={"No Options Found"}
                                    onInputChange={handleChangeDosageForm}
                                    value={medicationInfo.dsgform}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Dosage Form"
                                            placeholder="Type here"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                    </Grid>

                    <Grid container spacing={2}  >


                        <Grid item xs={2}>
                            <FormControl className={classes.route} >

                                <Autocomplete
                                    freeSolo
                                    openOnFocus={true}
                                    id="select-units"
                                    options={routeColl}
                                    defaultValue={"P.O"}
                                    noOptionsText={"No Options Found"}
                                    onInputChange={handleChangeRoute}
                                    value={medicationInfo.route}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="units"
                                            placeholder="Type here"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl className={classes.frequency}>

                                <Autocomplete
                                    freeSolo
                                    openOnFocus={true}
                                    id="select-units"
                                    options={frequencyColl}
                                    defaultValue={"P.O"}
                                    noOptionsText={"No Options Found"}
                                    onInputChange={handleChangeFrequency}
                                    value={medicationInfo.days}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Frequency"
                                            placeholder="Type here"
                                        />
                                    )}
                                />

                                {/* <InputLabel id="frequency-label">Frequency</InputLabel>
                                <Select
                                    labelId="frequency-select-label"
                                    id="frequency-select"
                                    onChange={handleChangeFrequency}
                                    value={medicationInfo.days}
                                >
                                    <MenuItem value={'QD'}>QD</MenuItem>
                                    <MenuItem value={'ACQD'}>ACQD</MenuItem>
                                    <MenuItem value={'PCQD'}>PCQD</MenuItem>
                                </Select> */}
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl component="fieldset"  >
                                <FormLabel component="legend" className={classes.lable}>PRN</FormLabel>
                                <RadioGroup row aria-label="prn" name="prn"  value={medicationInfo.prn} onChange={handleChangePRN} defaultValue="top">
                                    <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} >
                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                <KeyboardDatePicker
                                    // disableToolbar
                                    // autoOk={true}
                                    // variant="inline"
                                    // format="dd/MM/yyyy"
                                    // margin="normal"
                                    // id="date-stop"
                                    // label="Stop Date (If applicable)"
                                    // onChange={handleStopDate}
                                    // KeyboardButtonProps={{
                                    //     'aria-label': 'change date',
                                    // }}
                                    autoOk={true}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Stop Date (If applicable)"
                                    value={medicationInfo.stpdate}
                                    onChange={handleStopDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />

                            </MuiPickersUtilsProvider> */}
                            <TextField
                                id="date"
                                label="Stop Date"
                                type="date"
                                value={medicationInfo.stpdate}
                                onChange={handleStopDate}
                                // defaultValue="2020-05-24"
                                className={classes.dateofbirth}
                                InputLabelProps={{

                                    shrink: true,

                                }}
                                inputProps={{ min: getMinDate() }}
                            />

                        </Grid>

                        <Grid item xs={3} >
                            <TextField
                                style={{ marginTop: "18px" }}
                                label="Additional Info"
                                // placeholder="Aditional Info"
                                variant='outlined'
                                multiline
                                onChange={handleChangeAditionalInfo}
                                value={medicationInfo.ainfo}
                                rows={2}
                                rowsMax={4}
                            />

                        </Grid>

                    </Grid>

                    <Grid container  alignItems="center"  >
                       
                       
                        <Grid item xs={12} alignItems="center" >
                            <div className={classes.addButton} >
                                <Button variant="contained" onClick={() => addDosageArray()} color="primary">
                                    Add Medication
                                </Button>

                            </div>
                        </Grid>
                    </Grid>
                    {/* <div className={classes.addButton} >
                        <Button variant="contained" onClick={() => addDosageArray()} color="primary">
                            Add Medicondition
                    </Button>

                    </div> */}


                </div>

                {/* {display === true ? renderTable() : null} */}
                {renderTable()}
                {/* {
                    dosageData.length !== 0 ?
                        <div className={classes.nextButton} >
                            <Button variant="contained" className={classes.next} onClick={() => next()} color="primary">
                                Next
                    </Button>
                        </div> : null} */}
                <div className={classes.nextButton} >
                    <Button variant="contained" className={classes.back} onClick={() => back()} color="primary">
                        Back
                    </Button>
                    <Button variant="contained" className={classes.next} onClick={() => next()} color="primary">
                        Next
                    </Button>
                </div>
            </Container>

            <Notify />
        </>
    )





}

export default AddDrugDosage;