import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import { closeDialog } from "../../../../store/dialog/actionCreator";
import TextField from '@material-ui/core/TextField';
import { updateClass } from "../../../../util/api";
import Notify from "../../../Notification/Notify";
import { triggerNotification } from "../../../../store/notification/actionCreator";
import DrugSVG from "../../../../images/Drug.svg";
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { updateData } from "../../../../store/tableData/actionCreator";
import { dialogType } from "../../../../util/constants/dialogConstants";
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const drugClassStyles = makeStyles(theme => ({
    root: {
        width: "20vw"
    },
    tokenFileNameList: {
        fontSize: "1rem"
    },
    textroot: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    dialogTitle: {
        height: "20vh",
        width: "30vw",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FF6E4E",
        color: "white"
    },
    dialogContent: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    },
    checkBoxStyle: {
        marginTop: "16px"
    },
    buttonStyle: {
        marginTop: "16px"
    },
    textFieldStyle: {
        marginTop: "16px"
    },
    titleContent: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around"
    },
    closeButton: {
        position: 'absolute',
        right: "5px",
        top: "5px",
        color: "#FFFFFF",
    }
}));

export const EditDrugClass = () => {

    const classes = drugClassStyles();
    const [status, setStatus] = useState(false);
    const [drugcat, setDrugCat] = useState("");
    const dialog = useSelector(state => state.dialog);
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(false);
    const drugClassData = useSelector(state => state.tableData.tableDataList)

    const descriptionElementRef = React.useRef(null);
    useEffect(() => {
        var open = dialog.open;
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
        if (dialog.data !== {}) {
            setDrugCat(dialog.data.drugcat)
            dialog.data.isactive === 1 ? setStatus(true) : setStatus(false)
        }
        // eslint-disable-next-line
    }, [dialog.open]);

    const handleStatusCheck = (event) => {
        setStatus(event.target.checked);
    };

    const handleClose = () => {
        dispatch(closeDialog());

    };


    const editDrugClassAPI = () => {

        if (drugcat === "") {

            let notify = {
                isShown: true,
                message: "Please Enter Value",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (drugClassData.find(a => a.drugcat === drugcat)) {

            let notify = {
                isShown: true,
                message: " Drug Class already exists ",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        setDisable(true)

        var editDrugClass = {

            slno: dialog.data.slno,
            drugcat: drugcat,
            isactive: status === true ? 1 : 0
        }

        updateClass(editDrugClass).then(response => {
            if (response.status === 200) {

                let notify = {
                    isShown: true,
                    message: "Drug Class Updated successfully",
                    severity: "success"
                };
                dispatch(triggerNotification(notify));
                setDisable(false)
                dispatch(updateData(editDrugClass, "DRUGCLASS"));
                handleClose();
            }
        }).catch(error => {
            setDisable(false)
            if (error.response.status === 400) {
                let notify = {
                    isShown: true,
                    message: "Bad Request",
                    severity: "error"
                };
                dispatch(triggerNotification(notify));
                handleClose();
            }
            if (error.response.status === 500) {
                let notify = {
                    isShown: true,
                    message: "Internal server Error",
                    severity: "error"
                };
                dispatch(triggerNotification(notify));
                handleClose();
            }

        });
    }

    const updateDrugClass = (event) => {
        setDrugCat(event.target.value)
    }



    return (
        <div>
            <Notify />
            <Dialog
                open={dialog.open && dialog.dialog === dialogType.editDrugClass}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    className={classes.dialogTitle}
                    disableTypography>
                    <div className={classes.titleContent}>
                        <Typography variant="h5" className={classes.title} noWrap>
                            Update New Drug Class
                        </Typography>
                        <img src={DrugSVG} alt="newdrug" height={120} width={120} />
                        <IconButton color="primary" aria-label="Close" className={classes.closeButton} onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                    <form className={classes.textRoot} noValidate autoComplete="off">


                        <TextField
                            id="standard-basic"
                            label="Drug Class"
                            size="medium"
                            value={drugcat}
                            className={classes.textFieldStyle}
                            onChange={updateDrugClass}
                        />
                        <br />
                        <br />
                        <FormControlLabel
                            className={classes.checkBoxStyle}
                            control={<Checkbox checked={status} onChange={handleStatusCheck} name="status" />}
                            label="Status"
                        />

                    </form>
                    <Button
                        className={classes.buttonStyle}
                        variant="contained"
                        color="primary"
                        disabled={disable}
                        onClick={() => editDrugClassAPI()}>
                        Update Drug Class
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    )


};

export default EditDrugClass;