import { ADD_REQUESTS, ADD_SELECTED_PATIENT, UPDATE_MED_LIST, ADD_DEPS, UPDATE_DEPS, SET_MED, ADD_PHYSICIAN, SET_DISABLE, SET_ENABLE } from './actionType'

export function addPendingReq(dataArray, count) {
    return {
        type: ADD_REQUESTS,
        payload: { dataArray, count }
    }
}

export const addSelectedPatient = (patient) => {
    return {
        type: ADD_SELECTED_PATIENT,
        payload: patient
    }
}

export const updateMedList = (medList) => {
    return {
        type: UPDATE_MED_LIST,
        payload: { medList }
    }
}

export const addDeps = (depsData) => {
    return {
        type: ADD_DEPS,
        payload: depsData
    }
}

export const updateDeps = (depsEdited) => {
    return {
        type: UPDATE_DEPS,
        payload: depsEdited
    }
}

export const setMed = (index, medicine) => {
    return {
        type: SET_MED,
        payload: { index, medicine }
    }
}

export const addPhysician = (physician) => {
    return {
        type: ADD_PHYSICIAN,
        payload: physician
    }
}

export function setDisableMode() {
   
    return {
        type: SET_DISABLE,
        payload: {  }
    }
}

export function setEnable() {
   
    return {
        type: SET_ENABLE,
        payload: {  }
    }
}