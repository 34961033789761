import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { getFaxFileData } from '../../../util/api';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";


const useStyles = makeStyles(theme => ({
    document: {
        boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)"
    },
    main: {
        marginLeft: "35vw"
    },
    page: {
        marginTop: "10px",
        marginBottom: "10px",
        textAlign: "end"
    },
    button: {
        marginRight: "10px",
        background: "#3f51b5",
        color: "white",
        border: "none",
        padding: "5px 10px",
        width: "100px",
        cursor: "pointer",
        boxShadow: "2px 2px 2px 1px #ccc"
    },
    pageC: {
        marginRight: "10px",
        marginTop: "15px",
        marginBottom: "15px"
    },
    patientDetails: {
        marginBottom: "15px",
        marginTop: "10px"
    },
    patientID: {
        textAlign: "center"
    },
    adfile: {
        textAlign: "left"
    },
    container: {
        height: "100%",
        maxHeight: "570px",
        overflow: "auto",
        borderBottom: "2px solid #d5d5d5",
        marginBottom: "50px",
        boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)"
    },
    image: {
        width: "100%",
        maxWidth: "700px",
        maxHeight: "570px",
        overflow: "auto",
    },
    imageContainer: {
        height: "100%",
        maxHeight: "570px",
        overflow: "auto",
        // borderBottom: "2px solid #d5d5d5",
        marginBottom: "50px",
        // boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)"
    },
    back: {
        textAlign: "center",
        marginBottom: "30px"
    }
}))


const ViewFaxFile = () => {

    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    // const [pageNumber, setPageNumber] = useState(1);
    const classes = useStyles();

    const [url, setUrl] = useState('');

    const patientId = useSelector(state => state.viewFiles.faxFile.patientId);
    const faxFileName = useSelector(state => state.viewFiles.faxFile.faxFileName);

    let history = useHistory();


    /*To Prevent right click on screen*/
    document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
    });

    /*When document gets loaded successfully*/
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        // setPageNumber(1);
    }

    const handleBack = () => {
        history.goBack()
    }


    // function changePage(offset) {
    //     setPageNumber(prevPageNumber => prevPageNumber + offset);
    // }

    // function previousPage() {
    //     changePage(-1);
    // }

    // function nextPage() {
    //     changePage(1);
    // }

    useEffect(() => {


        if (patientId) {

            if (faxFileName) {

                let payload = {
                    patientId : patientId,  
                    faxFileName : faxFileName
                }
                
                getFaxFileData(payload).then(res => {
                    if (res.status === 200 && res.data) {
                        setUrl(res.data);
                    }

                })

            }

        }


        // eslint-disable-next-line
    }, [])



    const pdf = () => {
        return (
            <>
                <div className={classes.container}>
                    <Document
                        file={{ data: url || '' }}
                        onLoadSuccess={onDocumentLoadSuccess}
                        className={classes.document}
                    >
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} className={classes.document} />
                        ))}
                    </Document>

                </div>
            </>
        )
    }


    const faxFileHeader = () => {
        return (
            <>
                <div className={classes.patientDetails}>
                    <Typography variant="subtitle2" className={classes.patientID} noWrap>
                        <b> Patient Id : {patientId} </b>
                    </Typography>
                    <Typography variant="subtitle2" className={classes.adfile} noWrap>
                        <b> Fax File :</b>
                    </Typography>

                </div>

            </>
        )
    }

    return (
        <>
            <div className={classes.main}>
                {faxFileHeader()}
                {pdf()}

                <div className={classes.back}>
                    <Button variant="contained" onClick={handleBack} color="primary">
                        Go Back
                    </Button>
                </div>
            </div>
        </>
    );
}

export default ViewFaxFile;