import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import { closeDialog } from "../../../../store/dialog/actionCreator";
import TextField from '@material-ui/core/TextField';
import { updateUser, getUserRoles, getFacility, resetPasswordApi } from "../../../../util/api";
import Notify from "../../../Notification/Notify";
import { triggerNotification } from "../../../../store/notification/actionCreator";
import usersvg from "../../../../images/User.svg";
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { updateData } from "../../../../store/tableData/actionCreator";
import { dialogType } from "../../../../util/constants/dialogConstants";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';

const userStyles = makeStyles(theme => ({
    root: {
        width: "20vw"
    },
    tokenFileNameList: {
        fontSize: "1rem"
    },
    textroot: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    dialogTitle: {
        height: "20vh",
        width: "55vw",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FF6E4E",
        color: "white"
    },
    dialogContent: {
        display: "flex",
        width: "55vw",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    },
    checkBoxStyle: {
        marginTop: "16px"
    },
    buttonStyle: {
        marginTop: "40px",
        marginBottom: "15px"
    },
    textFieldStyle: {
        minWidth: "210px",
        margin: "0px",
        marginTop: "18px",
        marginLeft: "8px"
    },
    titleContent: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around"
    },
    margin: {
        // margin: theme.spacing(1),
        margin: "0px",
        minWidth: "210px",
        marginTop: "16px",
        marginLeft: "8px"
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '25ch',
        minWidth: "210px",
        marginTop: "16px",
        marginLeft: "8px"
    },
    formControl: {
        // margin: theme.spacing(1),
        // width: "-webkit-fill-available",
        minWidth: "210px",
        margin: "0px",
        marginTop: "17px",
        marginLeft: "8px"
    },
    // margin:{
    //     margin:"8px"
    // },
    password: {
        marginTop: "8px"
    },
    userType: {
        margin: "8px"
    },
    userStatus: {
        margin: "8px"
    },
    dialog: {
        // width:"55vw"
    },
    paper: { minWidth: "55vw" },
    closeButton: {
        position: 'absolute',
        right: "5px",
        top: "5px",
        color: "#FFFFFF",
    }
}));

export const EditUser = () => {

    const classes = userStyles();
    const [status, setStatus] = useState(false);
    const [User, setUser] = useState({
        userId: 0,
        userName: "",
        emailAddress: "",
        mobileNumber: "",
        userStatus: "",
        smsno1: "",
        faxno1: ""
    })

    const [facilityId, setFacilityId] = useState('');
    const [utype, setUtype] = useState('');
    const [userRoles, setUserRoles] = useState([]);
    const [facilityColl, setFacilityColl] = useState([]);
    const [disableButton, SetDisableButton] = useState(false);
    const [disableResetButton, SetDisableResetButton] = useState(false);


    const handleStatusCheck = (event) => {
        setStatus(event.target.checked);
    };
    const dialog = useSelector(state => state.dialog);
    const dispatch = useDispatch();

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        var open = dialog.open;
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }

        if (dialog.data !== {}) {
            setUser({
                userId:dialog.data.user_id,
                userName: dialog.data.userName,
                emailAddress: dialog.data.emailAddress,
                mobileNumber: dialog.data.mobile_number,
                smsno1: dialog.data.sms_no1,
                faxno1: dialog.data.fax_no1
            })

            setUtype(dialog.data.user_type_id);
            setFacilityId(dialog.data.facility_id);
            dialog.data.isactive === 1 ? setStatus(true) : setStatus(false)
        }

        // eslint-disable-next-line 
    }, [dialog.open]);

    useEffect(() => {
        getUserRoles().then(res => {
            setUserRoles(res.data);

        })

        getFacility().then(res1 => {
            if (res1.status === 200) {
                setFacilityColl(res1.data)

            }
        });
    }, [])


    const handleClose = () => {
        dispatch(closeDialog());
    };

    const editUserAPI = () => {


        if (User.emailAddress !== "") {

            if (/\S+@\S+\.\S+/.test(User.emailAddress)) {
                // value is ok, use it
            } else {
                let notify = {
                    isShown: true,
                    message: "Enter Valid Email Address",
                    severity: "warning"
                };
                dispatch(triggerNotification(notify));
                return;
            }

        } else {
            let notify = {
                isShown: true,
                message: "Email Address Mandatory",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (utype === "") {

            let notify = {
                isShown: true,
                message: "Please Select User Type",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (User.facility === "") {

            let notify = {
                isShown: true,
                message: "Please Select Facility",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }


        if (User.mobileNumber === "") {

            let notify = {
                isShown: true,
                message: "Please Enter Mobile number",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (/^\d{10}$/.test(User.mobileNumber)) {

        } else {
            let notify = {
                isShown: true,
                message: "Please Enter Valid Mobile number",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (User.faxno1 === "") {

            let notify = {
                isShown: true,
                message: "Please Enter Fax number",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        SetDisableButton(true);

        let role = [];

        // eslint-disable-next-line
        userRoles.map(r => {

            if (r.userTypeId === utype) {
                return role.push(r.userTypeName)
            }

        })

        var editUser = {
            user_id: User.userId,
            userName: User.userName,
            password: '',
            // address: User.address,
            emailAddress: User.emailAddress,
            user_type_id: utype,
            mobile_number: User.mobileNumber,
            sms_no1: User.smsno1,
            sms_no2: '',
            sms_no3: '',
            fax_no1: User.faxno1,
            fax_no2: '',
            fax_no3: '',
            facility_id: facilityId,
            role: role,
            isactive: status ? 1 : 0
            // work_number: User.workNumber,

        }
        // console.log(editUser);
        // dispatch(updateData(editUser, "USER"));
        // handleClose();
        updateUser(editUser).then(response => {
            if (response.status === 200) {

                let notify = {
                    isShown: true,
                    message: "New User Updated successfully",
                    severity: "success"
                };
                dispatch(triggerNotification(notify));
                dispatch(updateData(editUser, "USER"));
                dispatch(closeDialog());
                setUser('');
                setUtype('');
                setFacilityId('');
                SetDisableButton(false);
            }
        }).catch(error => {
            if (error.response.status === 400) {
                let notify = {
                    isShown: true,
                    message: error.response.data.message,
                    severity: "warning"
                };
                dispatch(triggerNotification(notify));

            } else {
                let notify = {
                    isShown: true,
                    message: "something went wrong",
                    severity: "warning"
                };
                dispatch(triggerNotification(notify));

            }
            SetDisableButton(false);
        })
    }

    const updateName = (event) => {
        setUser({
            ...User,
            name: event.target.value
        })
    }

    // const updateAddress = (event) => {
    //     setUser({
    //         ...User,
    //         address: event.target.value
    //     })
    // }

    const updateEmail = (event) => {
        setUser({
            ...User,
            emailAddress: event.target.value
        })
    }
    const updateMobile = (event) => {
        setUser({
            ...User,
            mobileNumber: event.target.value
        })
    }
    // const updateUpwd = (event) => {
    //     setUser({
    //         ...User,
    //         upwd: event.target.value
    //     })
    // }

    const handleRole = (event) => {
        setUtype(event.target.value);
        // switch (event.target.value) {
        //     case '1':
        //         return setRole(["NURSE"]);
        //     case '2':
        //         return setRole(["PHARMACIST"]);
        //     case '3':
        //         return setRole(["PHYSICIAN"]);
        //     case '4':
        //         return setRole(["FACILITY"]);
        //     case '5':
        //         return setRole(["ADMIN"]);
        //     default:
        //         return '';
        // }
        // setRole([`${event.target.value.userTypeName}`]);
    }



    // const updateSMS1 = (event) => {
    //     setUser({
    //         ...User,
    //         smsno1: event.target.value
    //     })
    // }
    // const updateSMS2 = (event) => {
    //     setUser({
    //         ...User,
    //         smsno2: event.target.value
    //     })
    // }
    // const updateSMS3 = (event) => {
    //     setUser({
    //         ...User,
    //         smsno3: event.target.value
    //     })
    // }
    const updateFaxNo1 = (event) => {
        setUser({
            ...User,
            faxno1: event.target.value
        })
    }
    // const updateFaxNo2 = (event) => {
    //     setUser({
    //         ...User,
    //         faxno2: event.target.value
    //     })
    // }
    // const updateFaxNo3 = (event) => {
    //     setUser({
    //         ...User,
    //         faxno3: event.target.value
    //     })
    // }

    const handleChangeFacility = (event) => {
        setFacilityId(event.target.value);
    }



    // const updateWorkNumber = (event) => {
    //     setUser({
    //         ...User,
    //         workNumber: event.target.value
    //     })
    // }

    // const hadleRoleChange = ()
    // const hadleRoleChange = (event) => {
    //     setUser( {

    //         utype: event.target.value.id,
    //         // ...User
    //     });
    //     setRole(event.target.value.Role);
    // }

    const resetPassword = () => {

        if (User.userId > 0) {

            SetDisableResetButton(true);

            resetPasswordApi(User.userId).then(response => {
                if (response.status === 200) {

                    let notify = {
                        isShown: true,
                        message: "Your New password sent to email",
                        severity: "success"
                    };
                    dispatch(triggerNotification(notify));
                    dispatch(closeDialog());
                }
            }).catch(error => {
               
                if (error.response.status === 400) {
                    let notify = {
                        isShown: true,
                        message: error.response.data,
                        severity: "warning"
                    };
                    dispatch(triggerNotification(notify));
    
                } else {
                    let notify = {
                        isShown: true,
                        message: "something went wrong",
                        severity: "warning"
                    };
                    dispatch(triggerNotification(notify));
    
                }
            
                SetDisableResetButton(false);
    
            })
        }


    }


    return (
        <div>
            <Notify />
            <Dialog
                open={dialog.open && dialog.dialog === dialogType.editUser}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                classes={{ paper: classes.paper }}
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    className={classes.dialogTitle}
                    disableTypography>
                    <div className={classes.titleContent}>
                        <Typography variant="h5" className={classes.title} noWrap>
                            Update User
                        </Typography>
                        <img src={usersvg} alt="newdrug" height={120} width={120} />
                        <IconButton color="primary" aria-label="Close" className={classes.closeButton} onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                    <form className={classes.textRoot} noValidate autoComplete="off">

                        <Grid container >
                            <Grid item xs={4}>
                                <TextField
                                    label="User Name"
                                    size="medium"
                                    value={User.userName}
                                    className={classes.textFieldStyle}
                                    onChange={updateName}
                                    disabled={true}
                                />

                                {/* <TextField

                                    label="Address"
                                    size="medium"
                                    className={classes.textFieldStyle}
                                    onChange={updateAddress}
                                /> */}



                                {/* <TextField

                                    margin="dense"
                                    id="workNumber"
                                    label="Work Number"
                                    className={classes.textFieldStyle}
                                    type="number"
                                    size="medium"
                                    onChange={updateWorkNumber}
                                /> */}

                                <FormControl className={classes.formControl}>
                                    <InputLabel id="userTypelabel" size="medium"  >User Type</InputLabel>
                                    <Select
                                        labelId="role-select-lable"
                                        id="role"
                                        size="medium"
                                        value={utype}
                                        onChange={handleRole}
                                    >
                                        {userRoles.map((data, index) => (<MenuItem value={data.userTypeId} key={index} > {data.userTypeName} </MenuItem>))}
                                    </Select>
                                </FormControl>



                            </Grid>
                            <Grid item xs={4}>

                                <TextField
                                    margin="dense"
                                    id="email"
                                    label="Email Address"
                                    type="email"
                                    value={User.emailAddress}
                                    className={classes.textFieldStyle}
                                    size="medium"
                                    onChange={updateEmail}
                                />

                                <FormControl className={classes.formControl}>
                                    <InputLabel id="facility-lable" size="medium"  >Select Facility</InputLabel>
                                    <Select
                                        labelId="facility-select-lable"
                                        id="facility"
                                        size="medium"
                                        value={facilityId}
                                        onChange={handleChangeFacility}
                                    >
                                        {facilityColl.map((data, index) => (<MenuItem value={data.facilityId} key={index} > {data.facilityName} </MenuItem>))}
                                    </Select>
                                </FormControl>



                                {/* <TextField
                                    id="remarks"
                                    label="Remarks"
                                    size="medium"
                                    className={classes.margin}
                                    onChange={updateRemarks}
                                />                                */}


                                {/* <TextField
                                    id="smsno1"
                                    label="SMS Number 1"
                                    size="medium"
                                    type="number"
                                    value={User.smsno1}
                                    style={{ display: "none" }}
                                    className={classes.margin}
                                    onChange={updateSMS1}
                                /> */}
                                {/* <TextField
                                    id="smsno2"
                                    label="SMS Number 2"
                                    size="medium"
                                    type="number"
                                    value={User.smsno2}
                                    style={{ display: "none" }}
                                    className={classes.margin}
                                    onChange={updateSMS2}
                                />
                                <TextField
                                    id="smsno3"
                                    label="SMS Number 3"
                                    size="medium"
                                    type="number"
                                    value={User.smsno3}
                                    style={{ display: "none" }}
                                    className={classes.margin}
                                    onChange={updateSMS3}
                                /> */}
                            </Grid>
                            <Grid item xs={4}>

                                <TextField
                                    margin="dense"
                                    id="mobileNumber"
                                    label="Mobile Number"
                                    className={classes.textFieldStyle}
                                    type="number"
                                    size="medium"
                                    value={User.mobileNumber}
                                    onChange={updateMobile}
                                />


                                <TextField
                                    id="faxno1"
                                    label="Fax Number"
                                    size="medium"
                                    type="number"
                                    value={User.faxno1}
                                    className={classes.margin}
                                    onChange={updateFaxNo1}
                                />

                                {/* <TextField
                                    id="faxno2"
                                    label="Fax Number 2"
                                    size="medium"
                                    type="number"
                                    value={User.faxno2}
                                    className={classes.margin}
                                    onChange={updateFaxNo2}
                                />

                                <TextField
                                    id="faxno3"
                                    label="Fax Number 3"
                                    size="medium"
                                    type="number"
                                    value={User.faxno3}
                                    className={classes.margin}
                                    onChange={updateFaxNo3}
                                /> */}



                            </Grid>

                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <FormControlLabel
                                    className={classes.checkBoxStyle}
                                    control={<Checkbox checked={status} onChange={handleStatusCheck} name="status" />}
                                    label="Status"
                                />
                            </Grid>

                        </Grid>
                    </form>

                    <Grid container >
                        <Grid item xs={12} style={{ textAlign: "center" }}>

                            <Button
                                className={classes.buttonStyle}
                                variant="contained"
                                color="primary"
                                style={{ marginRight: "10px" }}
                                disabled={disableButton}
                                onClick={() => editUserAPI()}>
                                Update User
                            </Button>

                            <Button
                                className={classes.buttonStyle}
                                variant="contained"
                                // style={{ backgroundColor: "#ff0e0e", color: "#fff" }}
                                disabled={disableResetButton}
                                onClick={() => resetPassword()}>
                                Reset Password
                            </Button>

                        </Grid>
                    </Grid>




                </DialogContent>
            </Dialog>
        </div>
    )


};

export default EditUser;