import React, { useState, useEffect } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper, TextareaAutosize, TextField, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, notify, sendSMS, sendFax, sendMail } from '../../../util/api';
import { triggerNotification } from '../../../store/notification/actionCreator';
import Notify from '../../Notification/Notify';
import { useHistory } from 'react-router';
import {
    getPendingPhyReviews
} from "../../../util/api";
import { addPendingReq } from "../../../store/pharmacist/actionCreator";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width: "100vw",
        flexDirection: "column"
    },
    paperRoot: {
        borderRadius: "15px",
        // left: "25%",
        width: "65vw",
        position: "relative",
        padding: "10px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        borderTop: "1px solid #dadada",
        borderBottom: "1px solid #dadada"
    },
    header: {
        // marginLeft: "25%",
        fontWeight: "bold",
        marginBottom: "10px"
    }
}))

const NotifyFacility = () => {
    const classes = useStyles();
    const auth = useSelector(state => state.auth)
    const selectedPatient = useSelector(state => state.pharmacist.selectedPatient)
    const [notifyData, setNotifyData] = useState({
        smsNumber: '',
        emailId: '',
        subject: `Record : ${selectedPatient.patientId}`,
        emailBody: `Record : ${selectedPatient.patientId}`,
        fax: ''
    })
    
    const physician = useSelector(state => state.pharmacist)
    const dispatch = useDispatch()
    const history = useHistory()
    // const { url } = useRouteMatch()

    useEffect(() => {
        // console.log(url)
        getUser().then((response) => {
            if (response.status === 200) {
                let users = response.data
                // eslint-disable-next-line
                users.map((user) => {
                    if (user.userName === auth.username) {
                        setNotifyData({
                            ...notifyData,
                            smsNumber: user.mobile_number,
                            emailId: user.emailAddress,
                            fax: user.fax_no1
                        })
                    }
                })
            }
        })
        // eslint-disable-next-line
    }, []);

    const handleChange = (prop) => (event) => {
        setNotifyData({ ...notifyData, [prop]: event.target.value });
    };

    // const handleChangeFax = (event) => {
    //     setNotifyData({
    //         ...notifyData,
    //         fax: event.target.value
    //     })
    // }

    const sendNotification = () => {


        if (!notifyData.emailBody) {
            let notify = {
                isShown: true,
                message: 'Please Enter Message',
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }





        let notifyRequest = {
            "patient_id": selectedPatient.patientId,
            "to_user_id": physician.physician.split(":")[0],
            "from_user_id": auth.userId,
            "sms_number": "" + notifyData.smsNumber,
            "email_address": notifyData.emailId,
            "fax_number": notifyData.fax,
            "message": notifyData.emailBody
        }
        notify(notifyRequest).then((response) => {
            if (response.status === 201) {
                dispatch(triggerNotification({
                    isShown: true,
                    message: physician.physician.split(":")[1] + " successfully notified",
                    severity: "success"
                }))
                getPendingPhyReviews(auth.userId, 20).then(response => {
                    if (response.status === 200) {
                        dispatch(addPendingReq(response.data.reverse(), response.data.length))
                        //     setLoading(false)
                    }
                });
                let data = {
                    fax_no : notifyData.fax,
                    patient_id : selectedPatient.patientId,
                    phy_id : auth.userId
                }
                sendFax(data).then(res1 => {
                    if (res1.status === 200) {
                        // console.log("fax sent");
                    }
                })
                let data1 = {
                    sms_to: notifyData.smsNumber,
                    message: notifyData.emailBody
                }
                sendSMS(data1).then(res1 => {
                    if (res1.status === 200) {
                        // console.log("sms sent");
                    }
                })
                let data2 = {
                    emailTO: notifyData.emailId,
                    subject: notifyData.subject,
                    message: notifyData.emailBody
                }
                sendMail(data2).then(res2 => {
                    if (res2.status === 200) {
                        // console.log("mail sent");
                    }
                })
                setTimeout(() => history.push("/physician/pending_requests/list"), 1000)
            }
        })
    }

    return (
        <>
            <Notify />
            <Typography
                className={classes.header}
                variant="h5">Submit Phycisian to Facility</Typography>
            <Paper className={classes.paperRoot}>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Typography variant="body1">PatientId</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            id="patientno"
                            placeholder="Patient number"
                            size="medium"
                            type="number"
                            disabled
                            value={selectedPatient.patientId}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body1">Sl no</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            id="slno"
                            placeholder="Sl number"
                            size="medium"
                            type="number"
                            disabled
                            value={selectedPatient.patientId}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <Typography variant="body1">SMS to</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            id="smsTo"
                            placeholder="SMS to"
                            size="medium"
                            value={physician.physician}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body1">Fax Number</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            id="fax"
                            placeholder="Fax Number"
                            size="medium"
                            value={notifyData.fax}
                            // onChange={handleChangeFax}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body1">SMS number</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            id="smsNumber"
                            placeholder="SMS number"
                            size="medium"
                            type="number"
                            value={notifyData.smsNumber}
                            // onChange={handleChange('smsNumber')}
                        />
                    </Grid>


                    <Grid item xs={3}>
                        <Typography variant="body1">Select Email id</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            id="emailid"
                            placeholder="Email ID"
                            size="medium"
                            value={notifyData.emailId}
                            // onChange={handleChange('emailId')}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <Typography variant="body1">Subject for email</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            id="subject"
                            placeholder="Email subject"
                            size="medium"
                            value={notifyData.subject}
                            // onChange={handleChange('subject')}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <Typography variant="body1">Message </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextareaAutosize
                            style={{ height: "100px", width: "27%" }}
                            className={classes.commentsArea}
                            rowsMax={4}
                            placeholder="Message body"
                            value={notifyData.emailBody}
                            onChange={handleChange('emailBody')}
                        />
                    </Grid>
                </Grid>

                <Button
                    style={{ marginTop: "20px" }}
                    variant="contained"
                    color="secondary"
                    onClick={() => sendNotification()}>SUBMIT</Button>
            </Paper>

        </>
    )
}

export default NotifyFacility;