import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import { closeDialog } from "../../../../store/dialog/actionCreator";
import TextField from '@material-ui/core/TextField';
import { updateCategory } from "../../../../util/api";
import Notify from "../../../Notification/Notify";
import { triggerNotification } from "../../../../store/notification/actionCreator";
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CategorySVG from "../../../../images/Category.svg";
import { updateData } from "../../../../store/tableData/actionCreator";
import { dialogType } from "../../../../util/constants/dialogConstants";
import FormControl from '@material-ui/core/FormControl';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const categoryStyles = makeStyles(theme => ({
    root: {
        width: "20vw"
    },
    tokenFileNameList: {
        fontSize: "1rem"
    },
    textroot: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    dialogTitle: {
        height: "20vh",
        width: "30vw",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FF6E4E",
        color: "white"
    },
    dialogContent: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    },
    buttonStyle: {
        marginTop: "30px",
        marginBottom: "20px"
    },
    textFieldStyle: {
        marginTop: "16px",
        margin: "8px"
    },
    titleContent: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around"
    },
    formControl: {
        margin: theme.spacing(1),
        width: "-webkit-fill-available"
    },
    closeButton: {
        position: 'absolute',
        right: "5px",
        top: "5px",
        color: "#FFFFFF",
    }
}));

export const EditCategory = () => {

    const classes = categoryStyles();
    const [status, setStatus] = useState(false);
    const [Category, setCategory] = useState({
        dcatname: "",
        dcgtype: ""
    })
    const dialog = useSelector(state => state.dialog);
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(false);

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        var open = dialog.open;
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
        if (dialog.data !== {}) {
            setCategory({
                dcatname: dialog.data.dcatname,
                dcgtype: dialog.data.dcgtype
            })
            dialog.data.isactive === 1 ? setStatus(true) : setStatus(false)
        }
        // eslint-disable-next-line
    }, [dialog.open]);

    const handleStatusCheck = (event) => {
        setStatus(event.target.checked);
    };

    const handleClose = () => {
        dispatch(closeDialog());
    };

    const editCategoryAPI = () => {

        if (Category.dcgtype === "") {

            let notify = {
                isShown: true,
                message: "Please Select Drug Category Type",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (Category.dcatname === "") {

            let notify = {
                isShown: true,
                message: "Please Enter Drug Category Name",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        setDisable(true)


        var editCategory = {
            did: dialog.data.did,
            dcatname: Category.dcatname,
            dcgtype: Category.dcgtype,
            isactive: status === true ? 1 : 0
        }

        // console.log(editCategory);
        // dispatch(updateData(editCategory, "CATEGORY"));
        // handleClose();
        updateCategory(editCategory).then(response => {
            if (response.status === 200) {

                let notify = {
                    isShown: true,
                    message: "Updated Category successfully",
                    severity: "success"
                };
                dispatch(triggerNotification(notify));
                setDisable(false)
                dispatch(updateData(editCategory, "CATEGORY"));
                handleClose();
            }
        }).catch((error) => {

            let notify = {
                isShown: true,
                message: "Something went wrong!!",
                severity: "error"
            };
            dispatch(triggerNotification(notify));
            setDisable(false)

        });
    }


    const updateCategoryType = (event) => {
        setCategory({
            ...Category,
            dcgtype: event.target.value,

        })
    }

    const updateCategoryName = (event) => {
        setCategory({
            ...Category,
            dcatname: event.target.value
        })
    }




    return (
        <div>
            <Notify />
            <Dialog
                open={dialog.open && dialog.dialog === dialogType.editCategory}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    className={classes.dialogTitle}
                    disableTypography>
                    <div className={classes.titleContent}>
                        <Typography variant="h5" className={classes.title} noWrap>
                            Update Category
                        </Typography>
                        <img src={CategorySVG} alt="newdrug" height={120} width={120} />
                        <IconButton color="primary" aria-label="Close" className={classes.closeButton} onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                    <form className={classes.textRoot} noValidate autoComplete="off">

                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label" size="medium"  >Drug Category Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="medium"
                                value={Category.dcgtype}
                                onChange={updateCategoryType}
                            >
                                <MenuItem value={"Drug Category"}>Drug Category</MenuItem>
                                <MenuItem value={"Sub-Category"}>Sub-Category</MenuItem>
                                <MenuItem value={"Second Sub-Category"}>Second Sub-Category</MenuItem>
                            </Select>
                        </FormControl>
                        <br />
                        <TextField
                            id="drug-category-name"
                            label="Drug Category Name"
                            size="medium"
                            className={classes.textFieldStyle}
                            value={Category.dcatname}
                            onChange={updateCategoryName}
                        />

                        <br />
                        <FormControlLabel
                            className={classes.checkBoxStyle}
                            control={<Checkbox checked={status} onChange={handleStatusCheck} name="status" />}
                            label="Status"
                        />
                    </form>
                    <Button
                        className={classes.buttonStyle}
                        variant="contained"
                        color="primary"
                        disabled={disable}
                        onClick={() => editCategoryAPI()}>
                        Update Category
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    )


};

export default EditCategory;