import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import { closeDialog } from "../../../../store/dialog/actionCreator";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { addDrug, getDrugs } from "../../../../util/api";
import Notify from "../../../Notification/Notify";
import { triggerNotification } from "../../../../store/notification/actionCreator";
import DrugSVG from "../../../../images/Drug.svg";
import Typography from '@material-ui/core/Typography';
import { dialogType } from "../../../../util/constants/dialogConstants";
import { addNewData } from "../../../../store/tableData/actionCreator";
import { appRegex } from "../../../../util/constants/appRegex";
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles(theme => ({
    root: {
        width: "20vw"
    },
    tokenFileNameList: {
        fontSize: "1rem"
    },
    textroot: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    dialogTitle: {
        height: "20vh",
        width: "30vw",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FF6E4E",
        color: "white"
    },
    dialogContent: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    },
    checkBoxStyle: {
        marginTop: "16px"
    },
    buttonStyle: {
        marginTop: "16px"
    },
    textFieldStyle: {
        // marginTop: "16px"
    },
    titleContent: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around"
    },
    closeButton: {
        position: 'absolute',
        right: "5px",
        top: "5px",
        color: "#FFFFFF",
    }
}));

export const AddDrug = () => {
    const classes = useStyles();
    const [combiDrugCheck, setCombiDrugCheck] = useState(false);
    const [disable, setDisable] = useState(false);
    const drugData = useSelector(state => state.tableData.tableDataList)
    const [drug, setDrug] = useState({
        brandName: "",
        genericName: ""
    })
    // const [fieldError, setFieldError] = useState(true)
    const dialog = useSelector(state => state.dialog);
    const dispatch = useDispatch();

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        var open = dialog.open;
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [dialog.open]);

    const handleCheck = (event) => {
        setCombiDrugCheck(event.target.checked);
    };

    const handleClose = () => {
        dispatch(closeDialog());
    };

    const addDrugAPI = () => {


        if (drug.brandName === "") {

            let notify = {
                isShown: true,
                message: "Brand Name Mandatory",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (drug.genericName === "") {

            let notify = {
                isShown: true,
                message: "Generic Name Mandatory",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        if (drugData.find(a => a.gname === drug.genericName || a.bname === drug.brandName)) {

            let notify = {
                isShown: true,
                message: "Drug name already exists",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }
        setDisable(true)

        var newDrug = {
            bname: drug.brandName,
            gname: drug.genericName,
            cdrug: combiDrugCheck === true ? 1 : 0,
            // combs: "ABACAVIR"
        }
        if ((newDrug.bname !== "" && !validateField(newDrug.bname)) && (newDrug.gname !== "" && !validateField(newDrug.gname))) {
            addDrug(newDrug).then(response => {
                if (response.status === 201) {
                    let notify = {
                        isShown: true,
                        message: "New Drug added successfully",
                        severity: "success"
                    };
                    handleClose();
                    setDisable(false)
                    setCombiDrugCheck(false)
                    setDrug({
                        brandName: "",
                        genericName: ""
                    })
                    getDrugs().then(res1 => {
                        if (res1.status === 200) {
                            dispatch(addNewData(res1.data, res1.data.reverse(), res1.data.length))
                        }
                    });
                    dispatch(triggerNotification(notify));
                }
            }).catch((error) => {
                let notify = {
                    isShown: true,
                    message: " Something went wrong ",
                    severity: "error"
                };
                dispatch(triggerNotification(notify));
                setDisable(true)

            });
        }
        else {
            let notify = {
                isShown: true,
                message: "Invalid Drug name or Generic name",
                severity: "error"
            };
            setDisable(true)
            dispatch(triggerNotification(notify));
        }
    }

    const updateBrandName = (event) => {
        setDrug({
            ...drug,
            brandName: event.target.value
        })
    }

    const updateGenericName = (event) => {
        setDrug({
            ...drug,
            genericName: event.target.value
        })
    }

    const validateField = (name) => {
        let error = (name.search(appRegex.name) === -1) ? true : false
        return error
    }

    const helperText = (name) => {
        if (name === "") {
            return false
        }
        else if (validateField(name) === false) {
            return false
        }
        else {
            return true
        }
    }

    return (
        <div>
            <Notify />
            <Dialog
                open={dialog.open && dialog.dialog === dialogType.addDrug}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="add-drug"
                    className={classes.dialogTitle}
                    disableTypography>
                    <div className={classes.titleContent}>
                        <Typography variant="h5" className={classes.title} noWrap>
                            Add New Drug
                        </Typography>
                        <img src={DrugSVG} alt="newdrug" height={120} width={120} />
                        <IconButton color="primary" aria-label="Close" className={classes.closeButton} onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                    <form className={classes.textRoot} noValidate autoComplete="off" >
                        <TextField
                            required
                            error={drug.brandName === "" ? false : validateField(drug.brandName)}
                            helperText={helperText(drug.brandName) ? "Invalid drug name" : " "}
                            // onBlur={() => validateField(drug.brandName) ? setFieldError(true) : setFieldError(false)}
                            id="brand-name"
                            label="Brand Name"
                            size="medium"
                            onChange={updateBrandName} />
                        <br />
                        <TextField
                            required
                            error={drug.genericName === "" ? false : validateField(drug.genericName)}
                            helperText={helperText(drug.genericName) ? "Invalid generic name" : " "}
                            // onBlur={() => validateField(drug.genericName) ? setFieldError(true) : setFieldError(false)}
                            id="generaic-name"
                            label="Generic Name"
                            size="medium"
                            className={classes.textFieldStyle}
                            onChange={updateGenericName}
                        />
                        <br />
                        <FormControlLabel
                            className={classes.checkBoxStyle}
                            control={<Checkbox checked={combiDrugCheck} onChange={handleCheck} name="combiDrugCheck" />}
                            label="Combinatin Drug"
                        />
                    </form>
                    <Button
                        className={classes.buttonStyle}
                        variant="contained"
                        color="primary"
                        disabled={disable}
                        onClick={() => addDrugAPI()}>
                        ADD DRUG
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default AddDrug;