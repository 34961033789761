import { combineReducers } from "redux";
import { RESET_STORE } from "./resetStore/actionType";
import auth from "./auth/reducer";
import dialog from "./dialog/reducer";
import notification from "./notification/reducer";
import tableData from "./tableData/reducer";
import patientSearch from "./patientSearch/reducer";
import drugDosage from "./drugDosage/reducer";
import pharmacist from "./pharmacist/reducer";
import drugRuleReducer from "./DrugRule/reducer";
import viewFiles from "./viewFiles/reducer";

const appReducer = combineReducers({
  auth,
  dialog,
  notification,
  tableData,
  patientSearch,
  drugDosage,
  pharmacist,
  drugRuleReducer,
  viewFiles
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === RESET_STORE) state = undefined;

  return appReducer(state, action);
};

export default rootReducer;