import React from 'react';
import { MuiThemeProvider } from "@material-ui/core/styles";
import muiTheme from "./config/themeConfig";
import Main from "./components/Main/Main";
import { BrowserRouter as Router } from "react-router-dom";
import './App.css';

function App() {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <Router>
        <Main />
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
