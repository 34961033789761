import { OPEN_DIALOG, CLOSE_DIALOG } from "./actionType";

export function openDialog(dialogType, data) {
  return {
    type: OPEN_DIALOG,
    payload: {dialogType, data}
  };
}

export function closeDialog() {
  return {
    type: CLOSE_DIALOG,
    payload: false
  };
}