import axios from "axios";

// const baseURL = "http://3.140.144.73:9191/api"
// const nurseBaseURL = "http://3.140.144.73:9191/nurse/api";
// const physicianBaseURL = "http://3.140.144.73:9191/physician/api";
// const ruleEngineURL = "http://3.140.144.73:9192/rule-engine";

const URL = "https://dev2.rxoptimizer.com";
const baseURL = "https://dev2.rxoptimizer.com/api";
const nurseBaseURL = "https://dev2.rxoptimizer.com/nurse/api";
const physicianBaseURL = "https://dev2.rxoptimizer.com/physician/api";
const ruleEngineURL = "https://dev2.rxoptimizer.com/rule-engine";

// const URL = "http://localhost:9191";
// const baseURL = "http://localhost:9191/api";
// const nurseBaseURL = "http://localhost:9191/nurse/api";
// const physicianBaseURL = "http://localhost:9191/physician/api";
// const ruleEngineURL = "http://localhost:9192/rule-engine";


const makeGetCall = (api) => {
    return axios({
        url: api,
        method: "GET",
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
    });
};

const makePostCall = (api, postData) => {
    return axios({
        url: api,
        method: "POST",
        headers: {
            "content-type": "application/json",
            "Authorization": (api.includes("signin")) ? "" : "Bearer " + localStorage.getItem("token")
        },
        data: postData
    });
};

const makePostCallWithOutToken = (api, postData) => {
    return axios({
        url: api,
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        data: postData
    });
};


const makePutCall = (api, postData) => {
    return axios({
        url: api,
        method: "PUT",
        headers: {
            "content-type": "application/json",
            "Authorization": (api.includes("signin")) ? "" : "Bearer " + localStorage.getItem("token")
        },
        data: postData
    });
};

const makeGetCallWithoutToken = (api) => {
    return axios({
        url: api,
        method: "GET",
        headers: {
            "content-type": "application/json"
        },
    });
};

const makeDeleteCall = (api, postData) => {
    return axios({
        url: api,
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: postData
    });
};


const makeFileUpload = (api, postData) => {
    return axios({
        url: api,
        method: "POST",
        headers: {
            'Content-Type': 'multipart/form-data',
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: postData
    });
};

const getFileAsBuffer = (api) => {
    return axios({
        url: api,
        method: "GET",
        headers: {
            "content-type": "arraybuffer, application/pdf"
        },
        responseType: 'arraybuffer'
    });
}

/**
 * API Calls for Login
 */
export const signin = (loginCred) => {
    let loginAPI = baseURL + "/auth/signin";
    return makePostCall(loginAPI, loginCred)
}

/**
 * API Calls for Admin drug
 */
export const getDrugs = () => {
    let drugsApi = baseURL + "/drugs/getdrugs";
    return makeGetCall(drugsApi)
}

export const getAllPendingDeprescribedDrugs = () => {
    let drugsApi = baseURL + "/drugs/getallpendingdeprescribeddrugs";
    return makeGetCall(drugsApi)
}

export const addDrug = drug => {
    let api = baseURL + "/drugs/createdrug";
    return makePostCall(api, drug);
};

export const updateDrug = drug => {
    let api = baseURL + "/drugs/updatedrug";
    return makePutCall(api, drug);
}
/**
 * API Calls for Admin Allergy
 */
export const addAllergy = Allergy => {
    let apiAllergy = baseURL + "/allergy/createallergy";
    return makePostCall(apiAllergy, Allergy)
}

export const getAllergy = () => {
    let allergyApi = baseURL + "/allergy/getallergy";
    return makeGetCall(allergyApi);
}


export const updateAllergy = (Allergy) => {
    let updateAllergy = baseURL + "/allergy/updateallergy";
    return makePutCall(updateAllergy, Allergy);
}
/**
 * API Calls for Admin Category
 */
export const addCategory = Category => {
    let categoryApi = baseURL + "/drugscategory/createdrugcategory";
    return makePostCall(categoryApi, Category)
}

export const getCategory = () => {
    let drugCategoryApi = baseURL + "/drugscategory/getdrugcategories";
    return makeGetCall(drugCategoryApi);
}

export const updateCategory = (category) => {
    let updateCategory = baseURL + "/drugscategory/updatedrugcategory";
    return makePutCall(updateCategory, category);
}
/**
 * API Calls for Admin MedCondition
 */
export const addMedCondition = MedCondition => {
    let MedConditionapi = baseURL + "/medcondition/addmedcondition";
    return makePostCall(MedConditionapi, MedCondition)
}

export const updateMedCondition = (MedCondition) => {
    let updateMedCondition = baseURL + "/medcondition/updatemedcondition";
    return makePutCall(updateMedCondition, MedCondition);
}

export const getMedCondition = () => {
    let medAPi = baseURL + "/medcondition/getmedcondition";
    return makeGetCall(medAPi);
}
/**
 * API Calls for Admin User
 */
export const addUser = (user) => {
    let userApi = baseURL + "/auth/signup";
    return makePostCallWithOutToken(userApi, user)
}

export const getUser = () => {
    let userApi = baseURL + "/user/getusers";
    return makeGetCall(userApi);
}

export const updateUser = (user) => {
    let updateUserApi = baseURL + "/user/updateuser";
    return makePutCall(updateUserApi, user)
}

export const getUserById = (id) => {
    let api = baseURL + "/user/getuser/" + id;
    return makePostCall(api)
}


/**
 * API Calls for Nurse getPatients
 */
export const getAllPatients = (userId) => {
    let getPatientsAPI = nurseBaseURL + "/patient/getpatients/" + userId;
    return makeGetCall(getPatientsAPI);
}

export const getAllPharmaPendingPatients = (userId) => {
    let getParmaPendingPatientsAPI = nurseBaseURL + "/phreview/getphreviewstatus/" + userId;
    return makeGetCall(getParmaPendingPatientsAPI);
}

export const addPatientAPI = (patient) => {
    let addPatientAPI = nurseBaseURL + "/patient/addpatient";
    return makePostCall(addPatientAPI, patient);
}

export const addPatientDosageAPI = (dosage) => {
    let addDosage = nurseBaseURL + "/patient/addpatientDrugDosage";
    return makePostCall(addDosage, dosage);
}

export const getPatientDosageById = (id) => {
    let api = nurseBaseURL + `/patient/getpatientDrugDosage/${id}`;
    return makeGetCall(api);
}

export const updatePatient = (patient) => {
    let updatePatientApi = nurseBaseURL + "/patient/updatepatient";
    return makePutCall(updatePatientApi, patient)
}

export const updatePatientDrugDosage = (patient) => {
    let updateDosageAPI = nurseBaseURL + "/patient/updatepatientDrugDosage";
    return makePutCall(updateDosageAPI, patient)
}


/**
 * API Calls for Deprescribing
 */
export const getDeprescribing = (depres) => {
    let getDeprescribing = baseURL + "/deprescribing/getdeprescribing";
    return makeGetCall(getDeprescribing, depres);
}

export const addDeprescribing = (deprescribing) => {
    let addDeprescribing = baseURL + "/deprescribing/adddeprescribing";
    return makePostCall(addDeprescribing, deprescribing);
}

export const updateDeprescribing = (deprescribing) => {
    let editDeprescribing = baseURL + "/deprescribing/updatedeprescribing";
    return makePutCall(editDeprescribing, deprescribing);
}

export const depdone = (status) => {
    let depdone = baseURL + "/drugs/updatedrugdepdone";
    return makePutCall(depdone, status);
}

/**
 * API Calls for Drug Class
 */

export const getDrugClass = () => {
    let getDrugClass = baseURL + "/drugclass/getdrugclasses";
    return makeGetCall(getDrugClass);
}

export const addDrugClass = (drugclass) => {
    let addDrugClass = baseURL + "/drugclass/addnewdrugclass";
    return makePostCall(addDrugClass, drugclass);
}

export const updateClass = (editdrugclass) => {
    let editDrugClass = baseURL + "/drugclass/updatedrugclass";
    return makePutCall(editDrugClass, editdrugclass);
}
/**
 * Rule Engine
 */
export const executeRUleEngine = (patientId) => {
    let executeRule = ruleEngineURL + "?patient_id=" + patientId;
    return makeGetCall(executeRule);
}

export const getPatientById = (patientId) => {
    let executeRule = nurseBaseURL + "/patient/getpatient/" + patientId;
    return makeGetCall(executeRule);
}

export const getOrigMedList = (patientId) => {
    let drugDosage = nurseBaseURL + "/rule_engine/orig_med_list/" + patientId;
    return makeGetCall(drugDosage);
}

export const getDepDirections = (patientId) => {
    let depDirections = nurseBaseURL + "/rule_engine/dps_directions/" + patientId;
    return makeGetCall(depDirections);
}

export const submitRequestAPI = (patientDetails) => {
    let submitReq = nurseBaseURL + "/pharmrcmd/addpharmrcmd"
    return makePostCall(submitReq, patientDetails);
}

export const submitPhyRecom = (data) => {
    let api = physicianBaseURL + "/phyrcmd/addphyrcmd"
    return makePostCall(api, data)
}

/**
 * API Calls for DrugRule in Admin
 */
export const getDrugRules = () => {
    let Api = baseURL + "/drugrules/getdrugrules";
    return makeGetCall(Api);
}

export const updateDrugRule = (rule) => {
    let Api = baseURL + "/drugrules/updatedrugrule";
    return makePutCall(Api, rule)
}

export const maxDslno = () => {
    let api = baseURL + "/drugrules/getmaxdslno";
    return makeGetCall(api);
}

export const addDrugRule = (rule) => {
    let api = baseURL + "/drugrules/adddrugrule";
    return makePostCall(api, rule);
}


/**
 * API Calls for Email and sms, fax
 */

export const sendMail = (data) => {
    let mail = baseURL + "/email/sendmail";
    return makePostCall(mail, data);
}

export const sendSMS = (data) => {
    let sms = baseURL + "/sms/sendsms";
    return makePostCall(sms, data);
}

export const sendFax = (data) => {
    let api = baseURL + "/fax/sendfax";
    return makePostCall(api, data);
}

export const notify = (notify) => {
    let communication = nurseBaseURL + "/communicationThread/addcommunicationThread"
    return makePostCall(communication, notify)
}

/**
 * API Calls physician 
 */
export const getPendingReviews = () => {
    let reviews = nurseBaseURL + "/pharmrcmd/getpendingphysicianreview";
    return makeGetCall(reviews);
}

export const getPendingPhyReviews = (userId, currentStatus) => {
    let reviews = physicianBaseURL + "/phyrcmd/getphypendingrecords/" + userId + "/" + currentStatus;
    return makeGetCall(reviews);
}

/**
 * API Calls Admin facility 
 */

export const getFacility = () => {
    let api = baseURL + "/admin/facility/getfacilities";
    return makeGetCall(api);
}

export const getAllStates = () => {
    let api = baseURL + "/admin/facility/getallstatelist";
    return makeGetCall(api);
}

export const addFacility = (facility) => {
    let api = baseURL + "/admin/facility/createfacility";
    return makePostCall(api, facility)
}

export const editFacilityApi = (facility) => {
    let api = baseURL + "/admin/facility/updatefacility";
    return makePutCall(api, facility)
}

export const getUserRoles = () => {
    let api = baseURL + "/userrole/getuserroles";
    return makeGetCall(api);
}

export const getPccPatients = (id,patientName) => {
    let api = baseURL + `/pcc/getpatients/${id}/${patientName}`;
    return makeGetCallWithoutToken(api);
}

export const getPccMedicalConditions = (pccPatientId) =>{
    let api = baseURL + `/pcc/medconditions/${pccPatientId}`;
    return makeGetCallWithoutToken(api);
}

// export const getFacility = () => {
//     let api = baseURL + "/admin/facility/getfacilities";
//     return makeGetCall(api);
// }

export const getAllUserfacilities = () => {
    let api = baseURL + "/admin/userfacility/getAllUserfacilities";
    return makeGetCall(api);
}

export const deleteUserFacility = (fac) => {
    let api = baseURL + "/admin/userfacility/deleteuserfacility";
    return makeDeleteCall(api, fac);
}

export const createUserFac = (fac) => {
    let api = baseURL + "/admin/userfacility/createuserfacilities";
    return makePostCall(api, fac);
}

export const getPharmrcmd = (id) => {
    let api = nurseBaseURL + `/pharmrcmd/getpharmrcmd/${id}`;
    return makeGetCall(api);
}

export const getUserFacilityById = (id) => {
    let api = baseURL + `/admin/facility/getfacilitiesbyUserId/${id}`;
    return makeGetCall(api);
}

export const getPatientsByFacId = (id) => {
    let api = nurseBaseURL + `/patient/getpatientsbyfacid/${id}`;
    return makeGetCall(api);
}

export const getPhyReviewedPatients = (userId, currentStatus) => {
    let api = URL + "/physician/api/phyrcmd/getphyreviewedrecords/" + userId + "/" + currentStatus;
    return makeGetCall(api);
}

export const getPccPatientVitals = (id) => {
    let api = URL + "/api/pcc/getpatientvitals/" + id;
    return makeGetCall(api);
}

export const getPccDrugDosage = (facId, pId) => {
    let api = URL + "/api/pcc/getpatientdrugdosage/" + facId + "/" + pId;
    return makeGetCall(api);
}

export const getPccHospice = (pId) => {
    let api = baseURL + "/pcc/getpatienthospice/" + pId;
    return makeGetCall(api);
}

export const getPccPatientAllergies = (id) => {
    let api = baseURL + "/pcc/getpatientallergies/" + id;
    return makeGetCall(api);
}

export const getPccPatientMedications = (id,facId) => {
    let api = baseURL + "/pcc/getpatientmedcondition/" +facId+"/"+ id;
    return makeGetCall(api)
}

export const makeFileUploadApi = (formData) => {
    let api = baseURL + "/file/uploadfile";
    return makeFileUpload(api, formData);
}

export const getPatientByPccId = (id) => {
    let api = nurseBaseURL + "/patient/getbypccid/" + id;
    return makeGetCall(api);
}

export const getFileById = (pid) => {
    let api = baseURL + "/file/getfile/" + pid;
    return makeGetCall(api);
}

export const signatureUpload = (formdata) => {
    let api = baseURL + "/file/uploadsignaturefile";
    return makeFileUpload(api, formdata);
}

export const getFiles = () => {
    let api = baseURL + "/file/getfiles";
    return makeGetCall(api);
}

// define drug rule
export const getDrugsInfo = () => {
    let api = baseURL + "/drugs/getdrugsinfo";
    return makeGetCall(api);
}

export const getMedCondInfo = () => {
    let api = baseURL + "/drugs/getdrugmedicalconditioninfo";
    return makeGetCall(api);
}

export const getDrugsCatInfo = () => {
    let api = baseURL + "/drugs/getdrugcatnamesinfo";
    return makeGetCall(api);
}

export const uploadDrugFile = (formdata) => {
    let api = baseURL + "/file/drug/uploadfile";
    return makeFileUpload(api, formdata);
}

export const getAllDrugFiles = () => {
    let api = baseURL + "/file/drug/getfiles";
    return makeGetCall(api);
}

export const getAdmissionFileById = (file) => {
    let api = baseURL + "/file/fetchfile/" + file.patientId + "/" + file.admissionFileName;
    return getFileAsBuffer(api);
}


export const getDrugFileNameById = (dslno) => {
    let api = baseURL + "/file/drug/getfile/" + dslno;
    return makeGetCall(api);
}

export const getDrugFileById = (dslno) => {
    let api = baseURL + "/file/drugfile/" + dslno;
    return getFileAsBuffer(api);
}

export const getAllFaxFiles = () => {
    let api = baseURL + "/faxfile/getfiles";
    return makeGetCall(api);
}

export const getFaxFileData = (payload) => {
    let api = baseURL + "/faxfile/fetchfile/" + payload.patientId + "/" + payload.faxFileName;
    return getFileAsBuffer(api);
}

// reset password

export const resetPasswordApi = (userId) => {
    let api = baseURL + "/user/resetpassword/" + userId;
    return makeGetCall(api);
}