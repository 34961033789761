import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Divider, Grid, Paper, TextField, Typography, TextareaAutosize } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { useParams, useRouteMatch } from 'react-router';
import { executeRUleEngine, getDepDirections, getOrigMedList, getPatientById, getUser, submitRequestAPI, updatePatient } from '../../../util/api';
import { useDispatch, useSelector } from 'react-redux';
import { addSelectedPatient, addDeps, updateDeps, addPhysician } from '../../../store/pharmacist/actionCreator';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EmptySVG from '../../../images/Empty.svg';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SendSVG from '../../../images/Send.svg';
import IconButton from '@material-ui/core/IconButton';
import Icon from "@material-ui/core/Icon";
import { useHistory } from 'react-router-dom';
import { triggerNotification } from '../../../store/notification/actionCreator';

const StyledTableCell = withStyles((theme) => ({
    root: {
        padding: "12px",
        borderBottom: "none",
        textAlign: "center"
    },
    head: {
        backgroundColor: theme.palette.header,
        color: theme.palette.common.white,
        fontWeight: "bold"
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const StyledTypography = withStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "left",
        marginLeft: "20px",
        marginTop: "5px",
        marginBottom: "5px",
        overflowWrap: "anywhere"
    },
}))(Typography)

const useStyles = makeStyles(theme => ({
    containerRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        width: "100vw",
        justifyContent: "center",
        // marginLeft: "25%",
        flexDirection: "column"
    },
    paperRoot: {
        position: "relative",
        width: "65vw",
        borderRadius: "15px",
        marginBottom: "10px"
    },
    detailTitle: {
        color: "white",
        padding: "5px",
        display: "flex",
        justifyContent: "center",
        // fontWeight: "bold",
        backgroundColor: theme.palette.header,
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px"
    },
    gridItem: {
        display: "flex",
        fontWeight: "bold"
    },
    gridItemEven: {
        borderRight: "1px solid #e0e0e0"
    },
    grid2: {
        backgroundColor: theme.palette.header
    },
    tableRoot: {
        borderRadius: "15px",
        position: "relative",
        width: "65vw",
        marginBottom: "10px"
    },
    tableContainer: {
        borderRadius: "15px"
    },
    emptyDosage: {
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        textAlign: "center"
    },
    comments: {
        paddingLeft: "50px",
        paddingRight: "50px",
        display: "flex",
        flexDirection: "row",
        marginBottom: "20px",
        marginTop: "20px"
    },
    commentsArea: {
        width: "100%"
    },
    depComments: {
        padding: "10px",
        textAlign: "center",
    },
    commentsAreaDep: {
        paddingLeft: "30px",
        paddingRight: "30px",
        width: "100%"
    },
    sendToHeader: {
        display: "flex",
        width: "65vw",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    formControl: {
        position: "relative",
        top: "-15px",
        minWidth: "45%",
        marginRight: "30px"
    },
    sendToReview: {
        width: "45%",
        display: "flex",
        justifyContent: "flex-end"
    },
    send: {
        position: "relative",
        top: "-5px"
    }
}))

const PendingRequestDetails = () => {
    const classes = useStyles();
    const selectedPatient = useSelector(state => state.pharmacist.selectedPatient)
    const [origMed, setOrigMed] = useState([])
    // const [depDirections, setDepDirections] = useState(null)
    const auth = useSelector(state => state.auth)
    const depres = useSelector(state => state.pharmacist.deps)
    const [physician, setPhysician] = useState([])
    const [defaultPhysician, setDefaultPhysician] = useState('')
    const dispatch = useDispatch();
    const { action } = useParams();
    const history = useHistory();
    const { url } = useRouteMatch();
    const [addComments, setAddComments] = useState("");
    
    const {disable} = useSelector(state => state.pharmacist);
    const disableMode = disable;
    

    useEffect(() => {
        setOrigMed([])
        dispatch(addDeps([]))
        executeRUleEngine(action).then((response) => {
            if (response.status === 200) {

                Promise.all([
                    getPatientById(action),
                    getOrigMedList(action),
                    getDepDirections(action),
                ]).then(([res1, res2, res3]) => {
                    if (res1.status === 200) {
                        dispatch(addSelectedPatient(res1.data))
                    }
                 
                    if (res2.status === 200) {
                        var medListResponse = res2.data
                        if (medListResponse !== null) {
                            var medListMeta = [...medListResponse]
                            let newList = medListMeta.filter(med => med.patient_id === parseInt(action))

                            // mani                            
                            //  for (let i = 0; i < newList.length; i++) {
                            //     newList[i].dgname = newList[i].dgname.split('*')[i]   
                            //     newList[i]["change"] = ""
                            //     newList[i]["ko"] = 0                            
                            // }

                            //  prasanth
                            for (var i = 0; i < newList.length; i++) {
                                newList[i]["change"] = ""
                                newList[i]["ko"] = 0
                            }
                            setOrigMed(newList)
                        }
                    }
                    if (res3.status === 200) {
                        var depsData = res3.data
                        var depsFiltered = depsData.filter(dep => dep.slno === parseInt(action))
                        if (depsFiltered !== null) {
                            dispatch(addDeps(depsFiltered))
                        }
                    }
                });
            }
        })

        getUser().then((response) => {
            if (response.status === 200) {
                var physician = []
                var users = response.data
                users.forEach((user) => {
                    // if (user.roles.length > 0) {
                        if (user.user_type_id === 3) {
                            physician.push(user.user_id + " : " + user.userName.toUpperCase())
                        }
                    // }
                })
                setPhysician(physician)
            }
        })
        // eslint-disable-next-line
    }, []);

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('-').toString();
    }

    const formatDateYear = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-').toString();
    }

    const formateTime = () => {
        var t = new Date(),
            h = t.getHours(),
            m = t.getMinutes(),
            s = t.getSeconds();

        return [h, m, s].join(':').toString();
    }

    const calculateAge = (dob) => {
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms);

        return Math.abs(age_dt.getUTCFullYear() - 1970).toString();
    }

    const handleMedChange = (index, event) => {
        let tempArray = [...origMed]
        origMed[index]["dgname"] = event.target.value
        setOrigMed(tempArray)
    }

   const checkIsAllUnCheck = (index) =>{
        if(origMed[index]["fill"] == 0 && 
        origMed[index]["ko"] == 0 && origMed[index]["dc"] ==0 ){
            let notify = {
                isShown: true,
                message: "One of DC, Fill, Keep Original Order should be selected.",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
        }
    }

    const handleDcChange = (index, event) => {
        let tempArray = [...origMed]
        let toSet = event.target.checked ? true : false
        origMed[index]["dc"] = (toSet === true) ? 1 : 0
        if(toSet){
        origMed[index]["fill"] = 0;
        origMed[index]["ko"] = 0;
        }else{
            checkIsAllUnCheck(index)
        }
        setOrigMed(tempArray)
    }

    const handleFillChange = (index, event) => {
        let tempArray = [...origMed]
        let toSet = event.target.checked ? true : false
        origMed[index]["fill"] = (toSet === true) ? 1 : 0
        if(toSet){
            origMed[index]["ko"] = 0;
            origMed[index]["dc"] = 0;
            }else{
                checkIsAllUnCheck(index)
            }
        setOrigMed(tempArray)
    }

    const handleKoChange = (index, event) => {
        let tempArray = [...origMed]
        let toSet = event.target.checked ? true : false
        origMed[index]["ko"] = (toSet === true) ? 1 : 0
        if(toSet){
            origMed[index]["fill"] = 0;
            origMed[index]["dc"] = 0;
            }else{
                checkIsAllUnCheck(index)
            }
        setOrigMed(tempArray)
    }

    const handleChange = (index, event) => {
        let tempArray = [...origMed]
        origMed[index]["change"] = event.target.value
        setOrigMed(tempArray)
    }

    const renderHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b>Original Med List</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b>DC</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b>Fill </b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b>Change</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            Keep Original Order</Typography>
                    </StyledTableCell>
                </TableRow>
            </TableHead>
        )
    }

    const renderContent = () => {
   
        if (origMed !== undefined || origMed !== null) {
            return (
                <TableBody>
                    {
                        origMed.map((med, index) => {
                            return (
                                <StyledTableRow key={index}>
                                    <StyledTableCell>
                                        <TextField
                                            // name={"medname-" + index}
                                            multiline
                                            rows={2}
                                            size="medium"
                                            value={med.dgname}
                                            onChange={(event) => handleMedChange(index, event)}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Checkbox
                                            // name={"dc-" + index}
                                            checked={med.dc === 1 ? true : false}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                            onChange={(event) => handleDcChange(index, event)}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <Checkbox
                                            // name={"fill-" + index}
                                            checked={med.fill === 1 ? true : false}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                            onChange={(event) => handleFillChange(index, event)}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TextField
                                            // name={"change" - index}
                                            size="medium"
                                            value={med.change}
                                            onChange={(event) => handleChange(index, event)}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Checkbox
                                            // name={"orinalOrder" - index}
                                            checked={med.ko === 1 ? true : false}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                            onChange={(event) => handleKoChange(index, event)}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                            )
                        })
                    }
                </TableBody>
            )
        }
    }

    const getCombinedList = (name) => {
        var combiData = ""
        origMed.forEach((med, index, array) => {
            if (index === array.length - 1) {
                combiData += med[name]
            } else {
                combiData += med[name] + " : "
            }

        })
        return combiData
    }

    const submitRequest = () => {

        if (defaultPhysician === '') {

            let notify = {
                isShown: true,
                message: 'Please select Physician',
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        var requestBody = {
            "patient_id": action,
            "orig_med_list": getCombinedList("dgname"),
            "is_discontinued": getCombinedList("dc"),
            "change_med_list": getCombinedList("change"),
            "is_original_order": getCombinedList("ko"),
            "additonal_comments": addComments,
            "deprescribing_direction_one": depres,
            "from_phrarmacist_id": auth.userId,
            "to_physician_id": defaultPhysician.split(":")[0],
            "prescription_date": formatDateYear(new Date()),
            "prescription_time": formateTime(),
            "is_reviewed": "Reviewed",
        }
        submitRequestAPI(requestBody)
            .then((response) => {
                if (response.status === 201) {
                    var patient = {
                        patient_id: selectedPatient.patientId,
                        first_name: selectedPatient.firstName,
                        last_name: selectedPatient.lastName,
                        middle_name: selectedPatient.middle_name,
                        age: selectedPatient.age,
                        birth_date: selectedPatient.dateOfBirth,
                        facility_id: selectedPatient.facility,
                        gender: selectedPatient.gender,
                        height: selectedPatient.height,
                        weight: selectedPatient.weight,
                        systolic: selectedPatient.systolic,
                        kidneyfx: selectedPatient.kidneyfx,
                        life_expectancy: selectedPatient.life_expectancy,
                        diastolic: selectedPatient.diastolic,
                        hospice: selectedPatient.hospice,
                        phq9: selectedPatient.phq9,
                        bmi: selectedPatient.bmi,
                        // medcond: selectedPatient.diagnosis,
                        medcond: selectedPatient.medcond,
                        // pcc_patientid: selectedPatient.pccPatientId,

                        createdatetime: selectedPatient.createdatetime,
                        updatedatetime: new Date(),
                        lastaccessedby: auth.roles[0],
                        createdby: selectedPatient.createdby,
                        updatedby: auth.roles[0],
                        isactive: selectedPatient.isactive,

                        // status 20
                        cur_status: 20
                    }
                    updatePatient(patient).then(res => {
                        if (res.status === 200) {
                            // console.log("updated -", res);
                            dispatch(addPhysician(defaultPhysician))
                            history.push(url.replace(url.split("/")[3], "notify"))
                        }
                    }).catch(error => {
                        let notify = {
                            isShown: true,
                            message: "something went wrong please try again",
                            severity: "warning"
                        };
                        dispatch(triggerNotification(notify));
                    })

                }
            })
    }

    const getDiagnosesData = () => {
        let medList = [];
        try {

            // if (selectedPatient.diagnosis.includes(',Drug Allergy-')) {
            //     let split = selectedPatient.diagnosis.split(',Drug Allergy-');
            //     medList = split[0];
            // } else if (selectedPatient.diagnosis.includes('Drug Allergy-')) {
            //     medList = [];
            // } else {
            //     medList = selectedPatient.diagnosis;
            // }

            // var split = selectedPatient.diagnosis.split(',Drug Allergy-')
            // var medList = split[0];


            if (selectedPatient.diagnosis.includes(',')) {
                let split = selectedPatient.diagnosis.split(',');

                if (split.length !== 0) {

                    for (let index = 0; index < split.length; index++) {

                        if (split[index].includes('Drug Allergy-')) {

                            // let s = split[index].split("Drug Allergy-")
                            // allergyList.push(s[1])
                        } else {
                            medList.push(split[index])
                        }

                    }
                }
            }

        }
        catch {
            medList = [];
        }
        return medList.length !== 0 ? medList.join(', ') : ''
    }

    const getAllergyData = () => {

        let allergyList = [];

        try {

            // if (selectedPatient.diagnosis.includes(',Drug Allergy-')) {
            //     let split = selectedPatient.diagnosis.split(',Drug Allergy-')
            //     allergyList = split[1];
            // } else if (selectedPatient.diagnosis.includes('Drug Allergy-')) {
            //     let split = selectedPatient.diagnosis.split('Drug Allergy-')
            //     allergyList = split[1];
            // }

            // var split = selectedPatient.diagnosis.split(',Drug Allergy-')
            // var allergyList = split[1];

            if (selectedPatient.diagnosis.includes(',')) {
                let split = selectedPatient.diagnosis.split(',');

                if (split.length !== 0) {

                    for (let index = 0; index < split.length; index++) {

                        if (split[index].includes('Drug Allergy-')) {

                            let s = split[index].split("Drug Allergy-")
                            allergyList.push(s[1])
                        }

                    }
                }
            }

        }
        catch {
            allergyList = [];
        }
        return allergyList.length !== 0 ? allergyList.join(", ") : ''
    }

    const handleChangeAdditionalComments = (event) => {
        setAddComments(event.target.value)
    }

    return (
        <div className={classes.containerRoot}>
            <div className={classes.sendToHeader}>
                <Typography variant="h5"><b>{disableMode ? "Sent for Review" : "Review and Send to"}</b></Typography>
               {!disableMode && <div className={classes.sendToReview}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="pharmacist">Physician</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            displayEmpty
                            value={defaultPhysician}
                            onChange={(event) => setDefaultPhysician(event.target.value)}
                        >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            {
                                physician.map((name, index) => {
                                    return <MenuItem key={index} value={name}>{name}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                    <IconButton edge="start" color="inherit" aria-label="send" className={classes.send} onClick={() => submitRequest()}>
                        <Icon>
                            <img src={SendSVG} alt="" height={24} width={24} />
                        </Icon>
                    </IconButton>
                </div>}
            </div>
            <Paper className={classes.paperRoot}>
                <Typography
                    className={classes.detailTitle}
                    variant="h5"> <b style={{ color: 'white' }}>&nbsp;{selectedPatient.firstName + " " + selectedPatient.lastName}</b>
                </Typography>
                <Divider />
                <Grid container style={{ marginTop: "5px" }}>
                    <Grid item xs={2}>
                        <StyledTypography className={classes.gridItem} variant="body1">Date : </StyledTypography>
                        <Divider />
                        <StyledTypography className={classes.gridItem} variant="body1">Date of Birth : </StyledTypography>
                        <Divider />
                        <StyledTypography className={classes.gridItem} variant="body1">BP : </StyledTypography>
                        <Divider />
                        <StyledTypography className={classes.gridItem} variant="body1">Allergies : </StyledTypography>
                    </Grid>
                    <Grid item xs={2} className={classes.gridItemEven}>
                        <StyledTypography variant="body1">{formatDate(new Date())}</StyledTypography>
                        <Divider />
                        <StyledTypography variant="body1">{formatDate(selectedPatient.dateOfBirth)}</StyledTypography>
                        <Divider />
                        <StyledTypography variant="body1">{`${selectedPatient.systolic}/${selectedPatient.diastolic}`}</StyledTypography>
                        <Divider />
                        <StyledTypography variant="body1">{getAllergyData()}</StyledTypography>
                    </Grid>
                    <Grid item xs={2}>
                        <StyledTypography className={classes.gridItem} variant="body1">Facility :</StyledTypography>
                        <Divider />
                        <StyledTypography className={classes.gridItem} variant="body1">Age : </StyledTypography>
                        <Divider />
                        <StyledTypography className={classes.gridItem} variant="body1">Kidneyfx : </StyledTypography>
                        <Divider />
                        <StyledTypography className={classes.gridItem} variant="body1">Diagnoses : </StyledTypography>
                    </Grid>
                    <Grid item xs={2} className={classes.gridItemEven}>
                        <StyledTypography variant="body1">{selectedPatient.facility}</StyledTypography>
                        <Divider />
                        <StyledTypography variant="body1">{calculateAge(new Date(selectedPatient.dateOfBirth))}</StyledTypography>
                        <Divider />
                        <StyledTypography variant="body1">{selectedPatient.kidneyfx}</StyledTypography>
                        <Divider />
                        <StyledTypography variant="body1">{getDiagnosesData()}</StyledTypography>
                    </Grid>
                    <Grid item xs={2}>
                        <StyledTypography className={classes.gridItem} variant="body1">Patient ID : </StyledTypography>
                        <Divider />
                        <StyledTypography className={classes.gridItem} variant="body1">Height : </StyledTypography>
                        <Divider />
                        <StyledTypography className={classes.gridItem} variant="body1">Weight : </StyledTypography>
                        <Divider />
                    </Grid>
                    <Grid item xs={2}>
                        <StyledTypography variant="body1">{selectedPatient.patientId}</StyledTypography>
                        <Divider />
                        <StyledTypography variant="body1">{selectedPatient.height}</StyledTypography>
                        <Divider />
                        <StyledTypography variant="body1">{selectedPatient.weight}</StyledTypography>
                        <Divider />
                    </Grid>
                </Grid>
            </Paper>

            <Paper className={classes.tableRoot}>
                {
                    (origMed === undefined || origMed === null) ?
                        <div className={classes.emptyDosage}>
                            <img src={EmptySVG} alt="empty" height={100} weight={100} />
                            <Typography variant="h5">No drug dosage available</Typography>
                        </div>
                        :
                        <>
                            <TableContainer classes={{
                                root: classes.tableContainer
                            }}>
                                <Table stickyHeader aria-label="sticky table">
                                    {renderHeader()}
                                    {renderContent()}
                                </Table>
                            </TableContainer>
                            <Divider />
                            <div className={classes.comments}>
                                <Typography variant="caption"><b>Additional Comments</b></Typography>
                                <TextareaAutosize
                                    style={{ height: "50px" }}
                                    className={classes.commentsArea}
                                    rowsMax={4}
                                    value={addComments}
                                    placeholder="Add additional comments"
                                    onChange={handleChangeAdditionalComments}
                                />
                            </div>
                        </>
                }
            </Paper>

            <Paper className={classes.tableRoot}>
                <Typography
                    className={classes.detailTitle}
                    variant="h6">Deprescribing Directions</Typography>
                <Divider />
                <Typography
                    className={classes.depComments}
                    variant="body1">Modify the directions below in case you need to</Typography>
                <div className={classes.commentsAreaDep}>
                    <TextareaAutosize
                        style={{ width: "100%" }}
                        rowsMax={6}
                        aria-label="maximum height"
                        placeholder="Maximum 4 rows"
                        value={depres}
                        onChange={(event) => dispatch(updateDeps(event.target.value))}
                    />
                </div>
            </Paper>
        </div>
    )
}

export default PendingRequestDetails;