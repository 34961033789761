import { ADD_REQUESTS, ADD_SELECTED_PATIENT, UPDATE_MED_LIST, ADD_DEPS, UPDATE_DEPS, SET_MED, ADD_PHYSICIAN, SET_DISABLE, SET_ENABLE } from './actionType'

export const initialState = {
  pendingRequests: [],
  requestCount: 0,
  disable:false,
  selectedPatient: {
    // firstName: "",
    // lastName: "",
    // patientId: 0,
    // facility: "",
    // height: 0,
    // weight: 0,
    // dateOfBirth: "",
    // diagnosis: "",
    // systolic: "",
    // diastolic: "",
    // kidneyfx: ""
    firstName: '',
    patientId: '',
    lastName: '',
    middle_name: '',
    age: '',
    dateOfBirth: '',
    facility: '',
    gender: '',
    height: '',
    weight: '',
    systolic: '',
    kidneyfx: '',
    life_expectancy: '',
    diastolic: '',
    hospice: '',
    phq9: '',
    bmi: '',
    diagnosis: '',
    pcc_patientid: '',
    createdatetime: '',
    updatedatetime: '',
    lastaccessedby: '',
    createdby: '',
    updatedby: '',
    isactive: '',
    medcond:''
  },
  medList: [],
  deps: "",
  physician: ""
}

const pharmacistReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_REQUESTS: {
      const { payload } = action;
      const data = payload;

      const newState = {
        ...state,
        pendingRequests: data.dataArray,
        requestCount: data.count,
      }
      return newState
    }

    case ADD_SELECTED_PATIENT: {
      const { payload } = action;
      const data = payload;
      const newState = {
        ...state,
        selectedPatient: {
          firstName: data.first_name,
          patientId: data.patient_id,
          lastName: data.last_name,
          middle_name: data.middle_name,
          age: data.age,
          dateOfBirth: data.birth_date,
          facility: data.facility_id,
          gender: data.gender,
          height: data.height,
          weight: data.weight,
          systolic: data.systolic,
          kidneyfx: data.kidneyfx,
          life_expectancy: data.life_expectancy,
          diastolic: data.diastolic,
          hospice: data.hospice,
          phq9: data.phq9,
          bmi: data.bmi,
          diagnosis: data.medcond,
          medcond: data.medcond,
          pcc_patientid: data.pccPatientId,
          createdatetime: data.createdatetime,
          updatedatetime: data.updatedatetime,
          lastaccessedby: data.lastaccessedby,
          createdby: data.lastaccessedby,
          updatedby: data.updatedby,
          isactive: data.isactive
          
        }
      }
      return newState
    }

    case UPDATE_MED_LIST: {
      const { payload } = action;
      const data = payload;
      // var medList = []
      // for (var i = 0; i < data.dgName.length; i++) {
      //   let combinedMed = data.dgName.length > 0 ? data.dgName[i] : ""
      //     + data.dosage.length > 0 ? data.dosage[i] : ""
      //     + data.dosageType[i]
      //   let dc = false
      //   let fill = false
      //   let change = ''
      //   let originalOrder = true
      //   let medListObj = {
      //     med: combinedMed,
      //     dc: dc,
      //     fill: fill,
      //     change: change,
      //     originalOrder: originalOrder
      //   }
      //   medList.push(medListObj)
      // }
      const newState = {
        ...state,
        medList: data.medList
      }
      return newState
    }

    case ADD_DEPS: {
      const data = action;
      var depsArray = data.payload

      var depres = ""
      // eslint-disable-next-line
      depsArray.map((depresElement, index) => {
        // depres += depresElement.rcmd + " "
        // depres += depresElement.rcmd + " " + index + 1 + ")"
        depres = depres +( index + 1 ) + ") " + depresElement.rcmd + " " 
      })
      const newState = {
        ...state,
        deps: depres
      }
      return newState
    }

    case UPDATE_DEPS: {
      const data = action;
      var depsEdited = data.payload

      const newState = {
        ...state,
        deps: depsEdited
      }
      return newState
    }

    case SET_MED: {
      const { payload } = action;
      var data = payload

      const newState = {
        ...state,
        medList: state.medList.map((medicine, medIndex) => data.index === medIndex ?
          {
            ...medicine,
            med: medicine,
          }
          :
          state.medList
        )
      }
      return newState
    }

    case ADD_PHYSICIAN: {
      var physician = action.payload
      const newState = {
        ...state,
        physician: physician
      }
      return newState
    }

    case SET_DISABLE : {
     
       const newState = { ...state, disable:true}
       
       return newState;
    }
    case SET_ENABLE : {
   
       const newState = { ...state, disable:false}
       
       return newState;
    }

    default:
      return state
  }
}

export default pharmacistReducer;