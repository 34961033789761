import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
// import LogoSVG from "../../images/Logo.svg";
import Logo from "../../images/RxOptimizer-logo-for-web.png"
import { signin } from "../../util/api";
import { addUser } from "../../store/auth/actionCreator";
import { useHistory } from 'react-router-dom';
import { roles } from "../../util/constants/roles";
import { triggerNotification } from "../../store/notification/actionCreator";
import { resetStore } from "../../store/resetStore/actionCreator";
import storage from "redux-persist/lib/storage";
// import Notify from "../Notification/Notify";

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#1877f2',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: '#1877f2',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#1877f2',
            },
        },
    },
})(TextField);

const useStyles = makeStyles(theme => ({
    root: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        backgroundColor: "#0000001a"
    },
    loginContainer: {
        minHeight: "50vh",
        minWidth: "50vw",
        borderRadius: "15px"
    },
    loginSection: {
        height: "50vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    login: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center"
    },
    textFieldAlign: {
        marginBottom: "10px"
    },
    imageGrid: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        // backgroundColor: "#ff6e4e",
        backgroundColor: "#babdd4",
        color: "white",
        borderTopLeftRadius: "15px",
        borderBottomLeftRadius: "15px"
    },
    loginGrid: {

    }
}))

const Signin = () => {
    const classes = useStyles();
    const [loginCred, setLoginCred] = useState({
        username: "",
        password: ""
    });
    const [isSuccess, setIsSucccess] = useState(true)
    const dispatch = useDispatch();
    const history = useHistory();

    const handleChange = (prop) => (event) => {
        setLoginCred({ ...loginCred, [prop]: event.target.value });
    }

    useEffect(() => {
        dispatch(resetStore({}));
        storage.removeItem("persist:root");
        storage.removeItem("token");

        // eslint-disable-next-line
    }, [])

    const login = () => {
        signin(loginCred).then((response) => {
            if (response.status === 200) {
                dispatch(addUser(response.data))
                if (response.data.roles[0] === roles.admin) {
                    localStorage.setItem('token', response.data.accessToken)
                    updateNotifyContent(response.data)
                    history.push("/admin");
                }
                else if (response.data.roles[0] === roles.nurse) {
                    localStorage.setItem('token', response.data.accessToken)
                    updateNotifyContent(response.data)
                    history.push("/nurse");
                } else if (response.data.roles[0] === roles.pharmacist) {
                    localStorage.setItem('token', response.data.accessToken)
                    updateNotifyContent(response.data)
                    history.push("/pharmacist")
                } else if (response.data.roles[0] === roles.physician) {
                    localStorage.setItem('token', response.data.accessToken)
                    updateNotifyContent(response.data)
                    history.push("/physician");
                } else if (response.data.roles[0] === roles.facility) {
                    localStorage.setItem('token', response.data.accessToken)
                    updateNotifyContent(response.data)
                    history.push("/facility");
                }

            }
            else if (response.status === 401) {
                // console.log("invalid")
                setIsSucccess(false)
            }
        }).catch((err) => {
            // console.log("invalid")
            setIsSucccess(false)
        })
    }

    const updateNotifyContent = (data) => {
        var notify = {
            isShown: true,
            message: "Logged in as " + data.username,
            severity: "success"
        }
        dispatch(triggerNotification(notify))
    }

    const handleKeyPress = (target) => {
        if (target.charCode === 13) {
            login();
        }
    }

    // const ForgotPassword = () => {
    //     history.push("/forgotpassword")
    // }

    return (
        <div className={classes.root}>
            <Paper elevation={2} className={classes.loginContainer}>
                <Grid container>
                    <Grid item xs={6} className={classes.imageGrid}>
                        <div >
                            <img src={Logo} alt="logo" style={{ width: "15vw" }} />
                        </div>

                        {/* <div>
                            <img src={LogoSVG} alt="" height={200} width={200} />
                            <Typography
                                variant="h4"
                                className={classes.textFieldAlign}><b>RX Optimizer</b></Typography>
                        </div> */}
                    </Grid>
                    <Grid item xs={6} className={classes.loginGrid}>
                        <div className={classes.loginSection}>
                            <div className={classes.login}>
                                <Typography
                                    variant="body1"
                                    className={classes.textFieldAlign}><b>Already member? Login</b></Typography>
                                <CssTextField
                                    id="username"
                                    label="User Name"
                                    variant="outlined"
                                    onChange={handleChange("username")}
                                    className={classes.textFieldAlign}
                                    onKeyPress={handleKeyPress}
                                />
                                <CssTextField
                                    id="password"
                                    type="password"
                                    variant="outlined"
                                    label="Password"
                                    onChange={handleChange("password")}
                                    className={classes.textFieldAlign}
                                    onKeyPress={handleKeyPress}
                                />
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.textFieldAlign}
                                    disableElevation
                                    onClick={() => login()}>
                                    Signin
                                </Button>
                                <Typography
                                    style={!isSuccess ? { visibility: 'visible', color: 'red' } : { visibility: 'hidden' }}
                                    variant="caption">
                                    The username or password is incorrect </Typography>
                                {/* <Typography
                                    variant="body1"                                    
                                >
                                    forgot password? <br /><Link style={{color:"black"}} onClick={() => ForgotPassword()} ><b>Reset</b></Link></Typography> */}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default Signin;