import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Fab from '@material-ui/core/Fab';
import AddAllergy from './dialog/AddAllergy';
import { useDispatch } from "react-redux";
import { openDialog } from "../../../store/dialog/actionCreator";
import AddSVG from "../../../images/Add.svg";
import Icon from "@material-ui/core/Icon";
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import SearchSVG from "../../../images/Search.svg";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditSvg from "../../../images/Edit.svg";
import { getAllergy } from "../../../util/api";
import Skeleton from '@material-ui/lab/Skeleton';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationAction from "../../common/TablePaginationAction";
import { addNewData, searchData } from "../../../store/tableData/actionCreator";
import { dialogType } from "../../../util/constants/dialogConstants";
import EditAllergy from "../Admin/dialog/EditAllergy";

const StyledTableCell = withStyles((theme) => ({
    root: {
        padding: "12px",
        borderBottom: "none ",
        textAlign: "center"
    },
    head: {
        backgroundColor: theme.palette.header,
        color: theme.palette.common.white,
        fontWeight: "bold"
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: "none"
    },
    lightToolBar: {
        background: theme.palette.toolbarLight,
        color: "black",
        paddingRight: "0px",
        paddingLeft: "0px"
    },
    allergyContainer: {
        paddingTop: "0.5%",
        paddingLeft: "25%",
        paddingRight: "10%",
        width: "100vw",
        paddingBottom:"0.5%"
    },
    allergyTitle: {
        fontSize: "2em",
        fontWeight: "bold"
    },
    allergyTitleroot: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        fontSize: "2.5em",
        color :theme.palette.primary.main,
        flexGrow: 1,
        display: 'none',
        fontWeight: "bold",
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        backgroundColor: theme.palette.search,
        position: 'relative',
        borderRadius: "10px",
        // backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: theme.palette.search
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
            backgroundColor: theme.palette.search,
            borderRadius: "10px",
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    searchSVG: {
        height: "1.1em"
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    tableRoot: {
        borderRadius: "15px"
    },
    table: {
        minWidth: 700,
    },
    fabRoot: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    fabAdd: {
        position: "fixed",
        bottom: "25px",
        right: "25px",
        height: "75px",
        width: "75px",
    },
    addSVG: {
        height: "1.5em",
        width: "1.5em"
    },
    skeletonStyle: {
        marginBottom: "10px",
        borderRadius: "15px"
    },
    tableTitle:{
        fontWeight:"500"
    }
}))


const Allergy = () => {
    const classes = useStyles();
    const [searchText, setSearchText] = useState("")
    const allergyData = useSelector(state => state.tableData.tableDataList)
    const [loading, setLoading] = useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const dispatch = useDispatch();


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
 

    useEffect(() => {
        // if(searchText !== ''){
            dispatch(searchData(searchText, allergyData.tableData, "ALLERGY"))
        // }       
        // eslint-disable-next-line
    }, [searchText])



    useEffect(() => {
        getAllergy().then(response => {
            if (response.status === 200) {
                dispatch(addNewData(response.data, response.data.reverse(), response.data.length))
                setLoading(false)
            }
        });
        // console.log( `${theme.palette}`);
        // eslint-disable-next-line
    }, []);


    const renderHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>Allergy ID</b>

                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>Allergy Name</b>

                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}> Status </b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        {/* <Typography variant="subtitle2" noWrap>
                            <b> Status </b>
                        </Typography> */}
                    </StyledTableCell>

                </TableRow>
            </TableHead>
        )
    }

    const renderAllergyContent = () => {
        return (
            <TableBody>
                {(rowsPerPage > 0
                    ? allergyData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : allergyData
                ).map((allergy, index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell align="left">
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableTitle}>{allergy.allergy_id}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableTitle}>{allergy.allergy_name}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableTitle}> {allergy.isactive ? 'Active' : 'Inactive'} </b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Icon classes={{
                                root: classes.addSVG
                            }}>
                                <img alt="add" src={EditSvg} height={24} width={24}
                              onClick={() => dispatch(openDialog(dialogType.editAllergy, allergy))}
                                
                                />                                
                            </Icon>                                                     
                        </StyledTableCell>

                    </StyledTableRow>

                ))}
            </TableBody>
        )
    }

    const filterSearchResults = (event) => {
        var searchText = event.target.value;
        setSearchText(searchText)
    }

    return (
        <div className={classes.root}>
            <div className={classes.allergyContainer}>
                {/* Display Manage drug row with title and search box */}
                <div className={classes.allergyTitleroot}>
                    <AppBar className={classes.appBar} position="static">
                        <Toolbar className={classes.lightToolBar}>
                            <Typography className={classes.title} variant="h6" noWrap>
                                Manage Allergy
                            </Typography>
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <Icon classes={{
                                        root: classes.searchSVG
                                    }}>
                                        <img alt="SearchIcon" src={SearchSVG} height={24} width={24} />
                                    </Icon>
                                </div>
                                <InputBase
                                    placeholder="Search…"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={filterSearchResults}
                                />
                            </div>
                        </Toolbar>
                    </AppBar>
                </div>

                {/* Display content header row */}
                {
                    loading ?
                        <div>
                            <Skeleton className={classes.skeletonStyle}
                                variant="rect"
                                animation="wave"
                                height={400} />
                        </div>
                        :
                        <Paper elevation={3} className={classes.tableRoot}>
                            <TableContainer className={classes.tableRoot}>
                                <Table stickyHeader aria-label="sticky table">
                                    {renderHeader()}
                                    {renderAllergyContent()}
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[5]}
                                                colSpan={4}
                                                count={allergyData.length}
                                                rowsPerPage={5}
                                                page={page}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationAction}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Paper>
                }
            </div>

            {/* Display FAB to add new drug */}
            <div className={classes.fabRoot}>
                <Fab color="primary" aria-label="add" className={classes.fabAdd} 
                  onClick={() => dispatch(openDialog(dialogType.addAllergy, {}))}>
                    <Icon classes={{
                        root: classes.addSVG
                    }}>
                        <img alt="add" src={AddSVG} height={24} width={24} />
                    </Icon>
                </Fab>
            </div>
            <AddAllergy />
            <EditAllergy />
        </div>
    )



}

export default Allergy;