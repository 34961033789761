import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from "react-redux";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Landing from './Admin/Landing';
import NurseLanding from './Nurse/NurseLanding';
// import LogoSVG from '../../images/Logo.svg'
import LogoSvg from '../../images/RxOptimizer-logo-for-web.png'
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Icon from "@material-ui/core/Icon";
import UserSVG from "../../images/User.svg";
import Signin from '../common/Signin';
import { useSelector } from 'react-redux';
import storage from "redux-persist/lib/storage";
import { resetStore } from "../../store/resetStore/actionCreator";
import { useHistory } from 'react-router-dom';
import { roles } from '../../util/constants/roles';
import UnAuthorised from '../common/UnAuthorised';
import PharmacistLanding from '../Main/Pharmacist/PharmacistLanding'
import PhysicianLanding from './Physician/PhysicianLanding';
import FacilityLanding from './Facility/FacilityLanding';
import CapsuleSVG from '../../images/not-allowed.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        overflowX: 'hidden',
        overflowY: 'hidden'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: "none"
    },
    lightToolBar: {
        // background: "#E7EEF8",
        background: 'white',
        color: "black"
    },
    title: {
        flexGrow: 1,
        textAlign: "center",
        fontSize: "18px",
        // color: "#ff6e4e"
        color: "#092F5F",
        fontWeight: "400"
    },
    name: {
        position: "absolute",
        right: "5%",
        fontSize: "1em",
    },
    capsule: {
        width: "3%"
    }
}));

const Main = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const clearAndLogout = () => {
        dispatch(resetStore({}));
        history.push("/signin")
        storage.removeItem("persist:root");
        storage.removeItem("token");
        window.location.reload();
    }

    const renderComponents = () => {
        if (auth.isLoggedIn) {
            switch (auth.roles[0]) {

                case roles.nurse:
                    return <Redirect to="/nurse" />
                case roles.admin:
                    return <Redirect to="/admin" />
                case roles.pharmacist:
                    return <Redirect to="/pharmacist" />
                case roles.facility:
                    return <Redirect to="/facility" />
                default:
                    return <Redirect to="/signin" />
            }
        }
        else {
            return <Redirect to="/signin" />
        }
    }

    return (
        <div className={classes.root}>
            <BrowserRouter>
                <CssBaseline />
                {auth.isLoggedIn ?
                    <AppBar position="fixed" className={classes.appBar}>
                        <Toolbar className={classes.lightToolBar}>
                            {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                                <Icon>
                                    <img alt="drug" src={LogoSVG} height={24} width={24} />

                                </Icon>
                            </IconButton> */}
                            <img alt="drug" src={LogoSvg} style={{ width: '11%' }} />
                            <Typography variant="body1" className={classes.title} noWrap>
                                <b style={{ fontWeight: "400" }}>Not a pill for every ill</b> <img alt="drug" src={CapsuleSVG} width={20} height={20} />
                            </Typography>

                            <Typography variant="h6" className={classes.name} noWrap>
                                {"Welcome!  "}<b>{auth.username}</b>
                            </Typography>

                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                                edge="end"
                            >
                                <Icon>
                                    <img alt="drug" src={UserSVG} height={24} width={24} />
                                </Icon>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => clearAndLogout()}>Logout</MenuItem>
                            </Menu>
                        </Toolbar>
                    </AppBar>
                    :
                    <div></div>
                }

                <Switch>
                    <Route
                        exact
                        path="/"
                        render={() => (
                            // <>{<Redirect to="/nurse" />}</>
                            <>{renderComponents()}</>
                        )}
                    />
                    <Route
                        path="/signin"
                        render={() => (
                            <Signin />
                        )}
                    />
                    <Route
                        path="/admin"
                        render={() => (
                            auth.roles.includes(roles.admin) ?
                                <Landing />
                                :
                                <UnAuthorised />
                        )}
                    />
                    <Route
                        path="/nurse"
                        render={() => (
                            auth.roles.includes(roles.nurse) ?
                                <NurseLanding />
                                :
                                <UnAuthorised />
                        )}
                    />
                    <Route
                        path="/pharmacist"
                        render={() => (
                            auth.roles.includes(roles.pharmacist) ?
                                <PharmacistLanding />
                                :
                                <UnAuthorised />
                        )}
                    />
                    <Route
                        path="/physician"
                        render={() => (
                            auth.roles.includes(roles.physician) ?
                                <PhysicianLanding />
                                :
                                <UnAuthorised />
                        )}
                    />
                    <Route
                        path="/facility"
                        render={() => (
                            auth.roles.includes(roles.facility) ?
                                <FacilityLanding />
                                :
                                <UnAuthorised />
                        )}
                    />
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default Main;