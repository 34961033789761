import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationAction from "../../common/TablePaginationAction";
import Icon from "@material-ui/core/Icon";
import {
    // getAllPatients,
    // getPendingReviews,
    getPendingPhyReviews
} from "../../../util/api";
import { addPendingReq } from "../../../store/pharmacist/actionCreator";
import { Typography, Paper } from "@material-ui/core";
import RightArrowSVG from "../../../images/RightArrow.svg";
import { Link, useParams, useRouteMatch, useHistory } from "react-router-dom";
import PendingReviewDetails from "./PendingReviewDetails";
import NotifyFacility from "./NotifyFacility";
import Skeleton from '@material-ui/lab/Skeleton';
import SearchSVG from "../../../images/Search.svg";
import InputBase from '@material-ui/core/InputBase';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { roles } from "../../../util/constants/roles";
import { addAdmissionFile } from "../../../store/viewFiles/actionCreator";
import EmptySvg from "../../../images/Empty.svg";

const StyledTableCell = withStyles((theme) => ({
    root: {
        padding: "12px",
        borderBottom: "none",
        textAlign: "center"
    },
    head: {
        backgroundColor: theme.palette.header,
        color: theme.palette.common.white,
        fontWeight: "bold"
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width: "100vw",
        flexDirection: "column",
        paddingBottom: "0.5%"
    },
    paperRoot: {
        padding: "10px"
    },
    tableRoot: {
        borderRadius: "15px",
        position: "relative",
        // left: "25%",
        // width: "65vw"
    },
    title: {
        fontSize: "2.5em",
        color: theme.palette.primary.main,
        flexGrow: 1,
        display: 'none',
        fontWeight: "bold",
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    tableContainer: {
        borderRadius: "15px"
    },
    header: {
        marginLeft: "25%",
        fontWeight: "bold",
        marginBottom: "10px"
    },
    tableTitle: {
        fontWeight: "500"
    },
    // title: {
    //     marginLeft: "25%",
    //     fontSize: "2.5em",
    //     color: theme.palette.primary.main,
    //     flexGrow: 1,
    //     display: 'none',
    //     fontWeight: "bold",
    //     [theme.breakpoints.up('sm')]: {
    //         display: 'block',
    //     },
    // },
    skeletonStyle: {
        marginBottom: "10px",
        borderRadius: "15px",
        // left: "25%",
        // width: "65vw"
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: "none"
    },
    lightToolBar: {
        background: theme.palette.toolbarLight,
        color: "black",
        paddingRight: "0px",
        paddingLeft: "0px"
    },
    allergyContainer: {
        paddingTop: "0.5%",
        paddingLeft: "25%",
        paddingRight: "10%",
        width: "100vw",
        paddingBottom: "0.5%"
    },
    allergyTitle: {
        fontSize: "2em",
        fontWeight: "bold"
    },
    allergyTitleroot: {
        flexGrow: 1,
    },
    search: {
        backgroundColor: theme.palette.search,
        position: 'relative',
        borderRadius: "10px",
        // backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: theme.palette.search
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
            backgroundColor: theme.palette.search,
            borderRadius: "10px",
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    searchSVG: {
        height: "1.1em"
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}))

const PendingReview = () => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const pendingRequests = useSelector(state => state.pharmacist.pendingRequests);
    const { action } = useParams();
    const { url } = useRouteMatch();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const auth = useSelector(state => state.auth);
    const history = useHistory();
    
    // for search funtionality
    const [records, setRecords] = useState([]);


    useEffect(() => {
        // getPendingReviews().then(response => {
        //     if (response.status === 200) {
        //         dispatch(addPendingReq(response.data, response.data.length))
        //         //     setLoading(false)
        //     }
        // });

        getPendingPhyReviews(auth.userId, 20).then(response => {
            if (response.status === 200) {
                dispatch(addPendingReq(response.data.reverse(), response.data.length))
                setRecords(response.data);
                setLoading(false)
            }
        });
        // eslint-disable-next-line
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    // const formatDate = (date) => {
    //     var d = new Date(date),
    //         month = '' + (d.getMonth() + 1),
    //         day = '' + d.getDate(),
    //         year = d.getFullYear();

    //     if (month.length < 2)
    //         month = '0' + month;
    //     if (day.length < 2)
    //         day = '0' + day;

    //     return [month, day, year].join('-').toString();
    // }


    const viewAdmissionFile = (patient) => {
        dispatch(addAdmissionFile(patient))

        switch (auth.roles[0]) {

            case roles.nurse:
                return history.push("/nurse/viewfile")
            case roles.pharmacist:
                return history.push("/pharmacist/viewfile")
            case roles.physician:
                return history.push("/physician/viewfile")
            default:
                return
        }

    }

    const renderHeader = () => {
        return (
            <TableHead>
                <TableRow>
                <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Patient Id</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>First Name</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Last Name</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                            <b className={classes.tableTitle}>Facility </b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>Admission File</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">

                    </StyledTableCell>
                </TableRow>
            </TableHead>
        )
    }

    // const viewPatient = (patient) => {
    //     console.log(url)
    // }

    const filterSearchResults = (event) => {
        let searchText = event.target.value;
        let tableDataMatched = [];
        records.forEach((patient) => {
            if (patient['first_name'] !== undefined) {
                if (patient['first_name'].toLowerCase().includes(searchText.toLowerCase()) || patient['last_name'].toLowerCase().includes(searchText.toLowerCase())) {
                    tableDataMatched.push(patient)
                }
            }
        })

        dispatch(addPendingReq(tableDataMatched, tableDataMatched.length))
    }

    const renderContent = () => {
        return (
            <TableBody>
                {(rowsPerPage > 0
                    ? pendingRequests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : pendingRequests
                ).map((patient, index) => (
                    <StyledTableRow key={index}>
                         <StyledTableCell>
                            <Typography variant="caption" noWrap>
                                <b className={classes.tableTitle}>{patient.patient_id}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="caption" noWrap>
                                <b className={classes.tableTitle}>{patient.first_name}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="caption" noWrap>
                                <b className={classes.tableTitle}>{patient.last_name}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                            <Typography variant="caption" noWrap>
                                <b className={classes.tableTitle}>{patient.facility_name}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Icon
                                style={{ cursor: "pointer" }}
                                onClick={() => viewAdmissionFile(patient)}
                                classes={{
                                    root: classes.customSVG
                                }}>
                                {patient.admission_file_name ? <img alt="add" src={EmptySvg} height={24} width={24} /> : ''}                               
                            </Icon>                           
                        </StyledTableCell>                        
                        <StyledTableCell>
                            <Link to={url.replace(url.split("/")[3], patient.patient_id)} >
                                <Icon
                                    // onClick={() => viewPatient(patient)}
                                    classes={{
                                        root: classes.customSVG
                                    }}>
                                    <img alt="add" src={RightArrowSVG} height={24} width={24} />
                                </Icon>
                            </Link>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        )
    }

    const renderComponent = () => {
        if (action === "list") {
            return (
                <>
                    {/* <Typography
                        className={classes.header}
                        variant="h5"> */}
                    {/* <Typography className={classes.title} variant="h6" noWrap>
                        Pending Reviews
                    </Typography> */}
                    <div className={classes.allergyTitleroot}>
                        <AppBar className={classes.appBar} position="static">
                            <Toolbar className={classes.lightToolBar}>
                                <Typography className={classes.title} variant="h6" noWrap>
                                    Pending Reviews
                                </Typography>
                                <div className={classes.search}>
                                    <div className={classes.searchIcon}>
                                        <Icon classes={{
                                            root: classes.searchSVG
                                        }}>
                                            <img alt="SearchIcon" src={SearchSVG} height={24} width={24} />
                                        </Icon>
                                    </div>
                                    <InputBase
                                        placeholder="Search…"
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput,
                                        }}
                                        inputProps={{ 'aria-label': 'search' }}
                                        onChange={filterSearchResults}
                                    />
                                </div>
                            </Toolbar>
                        </AppBar>
                    </div>
                    <Paper elevation={3} className={classes.tableRoot}>
                        <TableContainer classes={{
                            root: classes.tableContainer
                        }}>
                            <Table stickyHeader aria-label="sticky table">
                                {renderHeader()}
                                {renderContent()}
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5]}
                                            colSpan={7}
                                            count={pendingRequests.length}
                                            rowsPerPage={5}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationAction}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Paper>
                </>
            )
        }
        else if (action === "notify") {
            return <NotifyFacility />
        }
        else {
            return <PendingReviewDetails />
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.allergyContainer}>

                {
                    loading ?
                        <Skeleton className={classes.skeletonStyle}
                            variant="rect"
                            animation="wave"
                            height={300} />
                        :
                        renderComponent()
                }
            </div>
        </div>
    )
}

export default PendingReview;