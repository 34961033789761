import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { Divider, Grid, Typography } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import {
    getAllPatients, getPccPatients,
    // getFacility, 
    getUserFacilityById,
    // getPatientsByFacId,
    getFacility,
    getPatientByPccId,
    getFiles
} from "../../../util/api"
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationAction from "../../common/TablePaginationAction";
import Icon from "@material-ui/core/Icon";
// import FilterSVG from "../../../images/Search.svg"
import SearchSVG from '../../../images/search-patient.svg';
import patientSVG from '../../../images/add-user.svg';
import EmptySvg from "../../../images/Empty.svg";
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import { addPatients, editPatient, addNewPatient } from "../../../store/patientSearch/actionCreator";
import { useHistory, useRouteMatch } from 'react-router-dom';
import RightArrowSVG from "../../../images/RightArrow.svg";
import Notify from "../../Notification/Notify";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { addNewData, searchData } from "../../../store/tableData/actionCreator";
import { resetDosage } from "../../../store/drugDosage/actionCreator";
import { roles } from '../../../util/constants/roles';
import { addAdmissionFile } from '../../../store/viewFiles/actionCreator';
import { setPatientId } from "../../../store/patientSearch/actionCreator";
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import { triggerNotification } from "../../../store/notification/actionCreator";

const StyledTableCell = withStyles((theme) => ({
    root: {
        padding: "12px",
        borderBottom: "none",
        textAlign: "center"
    },
    head: {
        // backgroundColor: theme.palette.header,
        backgroundColor: "#36485c",
        color: theme.palette.common.white,
        fontWeight: "bold"
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    paperRoot: {
        padding: "10px"
    },
    seachForm: {
        display: "flex",
        justifyContent: "space-between"
    },
    textField: {
        marginTop: "10px"
    },
    tableRoot: {
        borderRadius: "15px",
        width: "100vw"
    },
    tableContainer: {
        borderRadius: "15px"
    },
    title: {
        // fontSize: "2.5em",
        fontSize: "1.5em",
        // color: theme.palette.primary.main,
        color: "#36485c",
        fontWeight: "600",
        // marginBottom: "10px",
        marginTop: '16px',
        marginRight: "auto",
        fontFamily: 'Open Sans',
        marginLeft: "auto",
        // textAlign: "center"
    },
    searchTitle: {
        width: "100%",
        display: "flex",
        // justifyContent: "space-between"
    },
    customSVG: {
        height: "1.5em",
        width: "1.5em",
        marginTop: "8px"
    },
    popover: {
        padding: "20px"
    },
    textFieldStyle: {
        marginTop: "16px"
    },
    popoverStyle: {
        left: "25% !important",
        width: "35vw",
        borderRadius: "15px"
    },
    dividerStyle: {
        marginTop: "10px"
    },
    filterButtons: {
        padding: "10px",
        float: "right"
    },
    buttonMargin: {
        marginRight: "5px",
        padding: "3px"
    },
    pickerStyle: {
        width: "13vw"
    },
    button: {
        textAlign: 'center',
        paddingTop: "3px",
        marginRight: '10px',
        // marginLeft: 'auto',
        marginTop: "10px"
    },
    tableTitle: {
        fontWeight: "600",
        fontFamily: 'Open Sans'
    },
    tableCell: {
        fontWeight: "600",
        fontFamily: 'Open Sans'
    },
    button1: {
        textAlign: 'center',
        paddingTop: "3px",
        marginRight: '20px',
        marginLeft: '15px',
    },
}));

const SearchPatient = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [facilityColl, setFacilityColl] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    // const patientData = useSelector(state => state.patientSearch.patientList);
    const patientData = useSelector(state => state.tableData.tableDataList)
    const userID = useSelector(state => state.auth.userId)
    const [filter, setFilter] = useState({
        firstName: "",
        lastName: "",
        facility: "",
        // dateOfBirth: "",
        // tableData: "rxo"
    });
    const dispatch = useDispatch();
    const history = useHistory();
    const { url } = useRouteMatch();
    const [facColl, setFacColl] = useState([]);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [filesColl, setFilesColl] = useState([]);
    const [disable, setDisable] = useState(0);
    const auth = useSelector(state => state.auth);

    useEffect(() => {

        getFiles().then(res3 => {
            if (res3.status === 200 && res3.data) {
                setFilesColl(res3.data);
            }
        })


        getAllPatients(auth.userId).then(response => {
            if (response.status === 200) {
                // dispatch(addPatients(response.data, response.data.length))
                dispatch(addNewData(response.data, response.data.reverse(), response.data.length))
                setLoading(false)
                setDisable(0);
            }
        }).catch(error => {
            // if (error.response.status === 401) {
                let notify = {
                    isShown: true,
                    message: "something went wrong",
                    severity: "error"
                };
                dispatch(triggerNotification(notify));
            // }
        })
        // getFacility().then(res1 => {
        //     if (res1.status === 200 && res1.data) {
        //         setFacilityColl(res1.data)
        //     }
        // })
        getUserFacilityById(userID).then(res1 => {
            if (res1.status === 200 && res1.data) {
                setFacilityColl(res1.data)
            }
        })

        getFacility().then(res2 => {
            if (res2.status === 200) {
                setFacColl(res2.data)

            }
        })





        // eslint-disable-next-line
    }, []);

    const getfile = (patient_id) => {

        if (filesColl.length !== 0) {
            for (var i = 0; i < filesColl.length; i++) {
                if (filesColl[i].pid === patient_id) {
                    return (
                        <>
                            <img alt="add" src={EmptySvg} height={24} width={24} />
                        </>
                    )
                }
            }
        }
    }

    const getfilename = (patient_id) => {

        if (filesColl.length !== 0) {
            for (var i = 0; i < filesColl.length; i++) {
                if (filesColl[i].pid === patient_id) {
                    return filesColl[i].admission_file_name;
                    
                }
            }
        }
    }

    const getFacName = (facilityId) => {

        if (facColl.length !== 0) {
            for (var i = 0; i < facColl.length; i++) {
                if (facColl[i].facilityId === facilityId) {
                    return facColl[i].facilityName || facColl[i].facilityId;
                }

            }
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeFacility = (event) => {
        setFilter({
            ...filter,
            facility: event.target.value
        })
        // if (event.target.value.facilityIdPcc) {
        //     getPccPatients(event.target.value.facilityIdPcc).then(response1 => {
        //         if (response1.status === 200) {
        //             console.log(response1);
        //             // setFacilityColl([])
        //             dispatch(addPatients(response1.data.data, response1.data.data.length))
        //         }
        //     });
        // } else {
        //     getPatientsByFacId(event.target.value.facilityId).then(response => {
        //         if (response.status === 200) {
        //             dispatch(addNewData(response.data, response.data.reverse(), response.data.length))

        //         }
        //     });
        // }
    };

    const handleFilterChange = (prop) => (event) => {
        setFilter({ ...filter, [prop]: event.target.value });
    };

    //  comments


    const renderHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>First Name</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>Last Name</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>Facility </b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>Date of Birth</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>Alert</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>Admission File</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">

                    </StyledTableCell>
                </TableRow>
            </TableHead>
        )
    }

    const renderPCCHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>First Name</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>Last Name</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>Facility </b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                        <Typography variant="subtitle1" noWrap>
                            <b className={classes.tableTitle}>Date of Birth</b>
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">

                    </StyledTableCell>
                </TableRow>
            </TableHead>
        )
    }

    const updateAndNext = (patient) => {
        dispatch(addNewPatient())
        dispatch(resetDosage())
        // console.log(patient)
        // dispatch(editPatient(patient))
        // let newUrl = url.replace(/.$/, "2");
        // history.push(newUrl)

        // when data comming from pcc pccpatient id is -patient.patientId
        // when data comming from rxo id is -patient.patient_id
        if (patient.patientId) {
            getPatientByPccId(patient.patientId).then(res => {
                if (res.status === 200 && res.data) {
                    // console.log(" pcc id res", res.data);
                    dispatch(editPatient(res.data))
                    let newUrl = url.replace(/.$/, "2");
                    history.push(newUrl)
                } else {
                    dispatch(editPatient(patient))
                    let newUrl = url.replace(/.$/, "2");
                    history.push(newUrl)
                }
            })
        } else {
            dispatch(editPatient(patient))
            let newUrl = url.replace(/.$/, "2");
            history.push(newUrl)
        }

    }

    const addNewPatients = () => {
        dispatch(resetDosage())
        dispatch(addNewPatient())
        let newUrl = url.replace(/.$/, "2");
        history.push(newUrl)
    }

    const viewAdmissionFile = (patient) => {

        let payload = {
            patient_id : patient.patient_id,
            admission_file_name: getfilename(patient.patient_id)
        }
        dispatch(addAdmissionFile(payload))

        switch (auth.roles[0]) {

            case roles.nurse:
                return history.push("/nurse/viewfile")
            case roles.pharmacist:
                return history.push("/pharmacist/viewfile")
            case roles.physician:
                return history.push("/physician/viewfile")
            default:
                return
        }

    }

    const sendAlert = (patientId) => {

        dispatch(setPatientId(patientId))
        let newUrl = url.replace(/.$/, "5");
        history.push(newUrl)
    }


    // const formatDate = (date) => {
    //     var d = date,
    //         month = '' + (d.getMonth() + 1),
    //         day = '' + d.getDate(),
    //         year = d.getFullYear();

    //     if (month.length < 2)
    //         month = '0' + month;
    //     if (day.length < 2)
    //         day = '0' + day;

    //     return [year, month, day].join('-').toString();
    // }
    // const formatDate = (d) => {
    //     var date = new Date(d);
    //     if (!isNaN(date.getTime())) {
    //         // Months use 0 index.
    //         return date.getMonth() + '-' + date.getDate() + '-' + date.getFullYear();
    //     }
    // }
    const formatDate = (date) => {
        var d = new Date(date) || date,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('-').toString();
    }

    const renderContent = () => {
        return (
            <TableBody>
                {(rowsPerPage > 0
                    ? patientData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : patientData
                ).map((patient, index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableCell}>{patient.first_name || patient.firstName}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableCell}>{patient.last_name || patient.lastName}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableCell}>{getFacName(parseInt(patient.facility_id)) || patient.facId}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableCell}>{patient.birthDate || formatDate(patient.birth_date)}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Icon
                                style={{ cursor: "pointer" }}
                                onClick={() => sendAlert(patient.patient_id)}
                                classes={{
                                    root: classes.customSVG
                                }}>
                                <NotificationImportantIcon />
                            </Icon>
                            {/* <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableCell}>{getfile(patient.patient_id)}</b>
                            </Typography> */}
                        </StyledTableCell>
                        <StyledTableCell>
                            <Icon
                                style={{ cursor: "pointer" }}
                                onClick={() => viewAdmissionFile(patient)}
                                classes={{
                                    root: classes.customSVG
                                }}>
                                {getfile(patient.patient_id)}
                                {/* <img alt="add" src={EmptySvg} height={24} width={24} /> */}
                            </Icon>
                            {/* <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableCell}>{getfile(patient.patient_id)}</b>
                            </Typography> */}
                        </StyledTableCell>
                        <StyledTableCell>
                            <Icon
                                onClick={() => updateAndNext(patient)}
                                classes={{
                                    root: classes.customSVG
                                }}>
                                <img alt="add" style={{ cursor: "pointer" }} src={RightArrowSVG} height={24} width={24} />
                            </Icon>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        )
    }

    const renderPCCContent = () => {
        return (
            <TableBody>
                {(rowsPerPage > 0
                    ? patientData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : patientData
                ).map((patient, index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableCell}>{patient.first_name || patient.firstName}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableCell}>{patient.last_name || patient.lastName}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableCell}>{getFacName(parseInt(patient.facility_id)) || patient.facId}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="subtitle2" noWrap>
                                <b className={classes.tableCell}>{formatDate(patient.birthDate) || formatDate(patient.birth_date)}</b>
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Icon
                                onClick={() => updateAndNext(patient)}
                                classes={{
                                    root: classes.customSVG
                                }}>
                                <img alt="add" src={RightArrowSVG} height={24} width={24} />
                            </Icon>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        )
    }

    const filterPatients = () => {
        // console.log("Filter", filter)

        // if (filter.facility.facilityIdPcc) {
        //     getPccPatients(filter.facility.facilityIdPcc).then(response1 => {
        //         if (response1.status === 200) {
        //             dispatch(addNewData(response1.data.data, response1.data.data, response1.data.data.length))
        //             if (filter.firstName !== '' || filter.lastName !== '') {
        //                 dispatch(searchData(filter, response1.data.data, "PATIENTFILTER"))
        //             }
        //         }
        //     })
        // } else {
        //     getAllPatients().then(response => {
        //         if (response.status === 200) {
        //             dispatch(addNewData(response.data, response.data, response.data.length))
        //             if (filter.firstName !== '' || filter.lastName !== '') {
        //                 dispatch(searchData(filter, patientData, "PATIENTFILTER"))
        //             }
        //         }
        //     });
        // }

        if (filter.facility.facilityIdPcc) {

            if (filter.firstName !== '' || filter.lastName !== '') {
                const pccPatientName = filter.firstName &&  filter.lastName ? filter.firstName+" "+filter.lastName :  (filter.firstName || filter.lastName) ;
                getPccPatients(filter.facility.facilityIdPcc,pccPatientName).then(response => {
                    if (response.status === 200) {
                        dispatch(searchData(filter, response.data.data, "PATIENTFILTER"))
                        handleClose();
                        setDisable(1);
                    }
                })


            } else {
                getPccPatients(filter.facility.facilityIdPcc).then(response => {
                    if (response.status === 200) {
                        dispatch(addPatients(response.data.data.reverse(), response.data.data.length))
                        handleClose();
                        setDisable(1);
                    }
                })
            }

        } else if (filter.firstName !== '' || filter.lastName !== '') {

            getAllPatients(auth.userId).then(response1 => {
                if (response1.status === 200) {
                    dispatch(searchData(filter, response1.data, "PATIENTFILTER"))
                    handleClose();
                    setDisable(0);
                }
            })
        } else {
            getAllPatients(auth.userId).then(response2 => {
                if (response2.status === 200) {
                    dispatch(addPatients(response2.data.reverse(), response2.data.length))
                    handleClose();
                    setDisable(0);
                }
            })
        }
    }
    const clearSearch = () => {
        if (filter.facility.facilityIdPcc) {
            setFilter({ ...filter, firstName: '', lastName: '' })
            getPccPatients(filter.facility.facilityIdPcc).then(response1 => {
                if (response1.status === 200) {
                    // console.log(response1);
                    dispatch(addPatients(response1.data.data.reverse(), response1.data.data.length))
                    handleClose();
                    setDisable(1);
                }
            });

        } else {
            setFilter({ ...filter, firstName: '', lastName: '' })
            getAllPatients(auth.userId).then(response => {
                if (response.status === 200) {
                    dispatch(addPatients(response.data.reverse(), response.data.length))
                    handleClose();
                    setDisable(0);
                }
            });

        }
    }

    const renderFilterContents = () => {
        return (
            <div className={classes.popover}>
                <Typography variant="h6"><b>Add Filter</b></Typography>
                <Divider />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <InputLabel style={{ marginTop: "18px" }} id="demo-simple-select-label">facility</InputLabel>
                        <Select
                            style={{ minWidth: "190px" }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={filter.facility}
                            onChange={handleChangeFacility}
                        // onChange={handleFilterChange("facility")}
                        >
                            <MenuItem value=""></MenuItem>
                            {facilityColl?.map((fac, index) => <MenuItem value={fac} key={index} > {fac.facilityName} </MenuItem>)}
                        </Select>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            id="first-name"
                            label="First Name"
                            size="medium"
                            value={filter.firstName}
                            className={classes.textFieldStyle}
                            onChange={handleFilterChange("firstName")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="last-name"
                            label="Last Name"
                            size="medium"
                            value={filter.lastName}
                            className={classes.textFieldStyle}
                            onChange={handleFilterChange("lastName")}
                        />
                    </Grid>
                </Grid>
                <Divider className={classes.dividerStyle} />
                <div className={classes.filterButtons}>
                    <Button variant="contained"
                        color="primary"
                        disableElevation
                        className={classes.buttonMargin}
                        onClick={() => clearSearch()}
                    >
                        CLEAR
                    </Button>
                    <Button variant="contained"
                        color="primary"
                        disableElevation
                        style={{ "padding": "3px" }}
                        onClick={() => filterPatients()}>
                        SEARCH
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <div className={classes.root}>
            <div className={classes.searchTitle}>

                <div className={classes.button1} >
                    <img alt="add" src={SearchSVG} onClick={handleClick} height={40} width={40} />
                </div>
                <Typography variant="h5" className={classes.title}>Patients</Typography>
                <div className={classes.button} >
                    <img alt="add" src={patientSVG} onClick={() => addNewPatients()} height={40} width={40} />
                </div>

                {/* <Icon classes={{
                    root: classes.button
                }}
                    aria-describedby={id}
                    onClick={handleClick}>
                    <img alt="add" src={SearchSVG} height={40} width={40} />
                </Icon> */}
                {/* <div className={classes.button} >
                    <Button variant="contained" onClick={() => addNewPatients()} color="primary">
                        Add New Patient
                    </Button>
                     <img alt="add" src={pAddSVG} height={30} width={30} />
                </div> */}
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    classes={{
                        paper: classes.popoverStyle
                    }}
                >
                    {renderFilterContents()}
                </Popover>
            </div>
            {
                loading ?
                    <div>
                        <Skeleton className={classes.skeletonStyle}
                            variant="rect"
                            animation="wave"
                            height={400} />
                    </div>
                    :
                    <>
                        <Paper elevation={3} className={classes.tableRoot}>
                            <TableContainer classes={{
                                root: classes.tableContainer
                            }}>
                                <Table stickyHeader aria-label="sticky table">
                                    {/* {renderHeader()} */}
                                    {/* {renderContent()} */}
                                    {disable === 1 ? renderPCCHeader() : renderHeader()}
                                    {disable === 1 ? renderPCCContent() : renderContent()}
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[5]}
                                                colSpan={7}
                                                count={patientData.length}
                                                rowsPerPage={5}
                                                page={page}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationAction}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </>
            }
            {/* <div className={classes.button} >
                <Button variant="contained" onClick={() => addNewPatients()} color="primary">
                    Add New Patient
                </Button>
            </div> */}
            <Notify />
        </div>
    )
}

export default SearchPatient;