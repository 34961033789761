import { NEW_DATA, PAGE_NO, SEARCH_DATA, UPDATE_DATA } from './actionType'

export function addNewData(data, dataArray, count) {
    return {
        type: NEW_DATA,
        payload: { data, dataArray, count }
    }
}

export function searchData(query, dataArray, type) {
    return {
        type: SEARCH_DATA,
        payload: { query, dataArray, type }
    }
}

export function updateData(Data, type) {
    return {
        type: UPDATE_DATA,
        payload: { Data, type }
    }
}

export function updatePageNumber(no) {
    return {
        type: PAGE_NO,
        payload: no
    }
}