import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import { closeDialog } from "../../../../store/dialog/actionCreator";
import TextField from '@material-ui/core/TextField';
import { addMedCondition, getMedCondition } from "../../../../util/api";
import Notify from "../../../Notification/Notify";
import { triggerNotification } from "../../../../store/notification/actionCreator";
import Condition from "../../../../images/Condition.svg";
import Typography from '@material-ui/core/Typography';
import { updateData } from "../../../../store/tableData/actionCreator";
import { dialogType } from "../../../../util/constants/dialogConstants";
import { addNewData } from "../../../../store/tableData/actionCreator";
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const medConditionStyles = makeStyles(theme => ({
    root: {
        width: "20vw"
    },
    tokenFileNameList: {
        fontSize: "1rem"
    },
    textroot: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    dialogTitle: {
        height: "20vh",
        width: "30vw",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FF6E4E",
        color: "white"
    },
    dialogContent: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    },
    checkBoxStyle: {
        marginTop: "16px"
    },
    buttonStyle: {
        marginTop: "16px"
    },
    textFieldStyle: {
        marginTop: "16px"
    },
    titleContent: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around"
    },
    closeButton: {
        position: 'absolute',
        right: "5px",
        top: "5px",
        color: "#FFFFFF",
    }
}));

export const AddMedCondition = () => {

    const classes = medConditionStyles();
    const [disable, setDisable] = React.useState(false);
    const medData = useSelector(state => state.tableData.tableDataList)
    const [MedCondition, setMedConition] = useState({
       
        medicalConditionName:""
 
    })
    const dialog = useSelector(state => state.dialog);
    const dispatch = useDispatch();

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        var open = dialog.open;
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [dialog.open]);



    const handleClose = () => {
        dispatch(closeDialog());
    };

    const addMedConditionAPI = () => {

        if (MedCondition.medicalConditionName === "") {

            let notify = {
                isShown: true,
                message: "Please Enter Value",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }


        if (medData.some(a => a.medical_condition_name === MedCondition.medicalConditionName)) {

            let notify = {
                isShown: true,
                message: "Medical Condition already exists ",
                severity: "warning"
            };
            dispatch(triggerNotification(notify));
            return;
        }

        setDisable(true)


        var newMedCondition = {
           
            medical_condition_name: MedCondition.medicalConditionName,
           

        }


        addMedCondition(newMedCondition).then(response => {
            if (response.status === 201) {
                
                let notify = {
                    isShown: true,
                    message: "New Parameter added successfully",
                    severity: "success"
                };
                dispatch(triggerNotification(notify));
                setDisable(false)
                dispatch(updateData(newMedCondition, "MEDCONDITION"));
                handleClose(); 
                getMedCondition().then(response1 => {
                    if (response1.status === 200) {
                        dispatch(addNewData(response1.data, response1.data.reverse(), response1.data.length))                    
                    }
                });
                          
            }
        }).catch((error) => {

            let notify = {
                isShown: true,
                message: "Something went wrong!!",
                severity: "error"
            };
            dispatch(triggerNotification(notify));
            setDisable(false)

        });
    }

    const updatemedicalConditionName = (event) => {
        setMedConition({
            medicalConditionName: event.target.value
        })
    }
    




    return (
        <div>
            <Notify />
            <Dialog
                open={dialog.open && dialog.dialog === dialogType.addMedCondition}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    className={classes.dialogTitle}
                    disableTypography>
                    <div className={classes.titleContent}>
                        <Typography variant="h5" className={classes.title} noWrap>
                            Add New Medical Condition
                        </Typography>
                        <img src={Condition} alt="newdrug" height={120} width={120} />
                        <IconButton color="primary" aria-label="Close" className={classes.closeButton} onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                    <form className={classes.textRoot} noValidate autoComplete="off">

                      
                        <TextField
                            id="standard-basic"
                            label="Parameter Name"
                            size="medium"
                            required
                            className={classes.textFieldStyle}
                            onChange={updatemedicalConditionName}
                        />
                          <br />
                          
                        <br />

                    </form>
                    <Button
                        className={classes.buttonStyle}
                        variant="contained"
                        color="primary"
                        disabled={disable}
                        onClick={() => addMedConditionAPI()}>
                        ADD Medical Condition
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    )


};

export default AddMedCondition;